import { atom } from 'recoil';

// removedBgPpState
// 업로드한 상품의 원본, pp 상태관리
// id는 저장이 될 시 얻는 id값
export const productImageState = atom({
  key: 'ppImageState',
  default: {
    id: 0,
    uploadImage: '',
    maskImage: '',
    ppImage: null,
    width: null,
    height: null,
    fileName: 'file',
  },
});

export const normalImageState = atom({
  key: 'nmImageState',
  default: {
    id: 0,
    uploadImage: '',
    ppImage: null,
    width: null,
    height: null,
    fileName: 'file',
  },
});

// BackgroundInitState
// 배경의 타입(single, cross, half, template), 색상, 템플릿 id 관리
export const bgImageState = atom({
  key: 'bgImage',
  default: {
    type: null,
    templateId: null,
    colors: null,
    name: '',
  },
});

export const thumbnailState = atom({
  key: 'thumbnailState',
  default: [],
});

// canvas에서 pp의 이미지들을 담기 위한 state
// id: 0, => 데이터베이스 id
// key:  =>캔버스 상의 id
// uploadImage: '', =>업로드 이미지
// maskImage: '', => 마스크 이미지
// width: null, => 이미지의 너비
// height: null, => 높이
// fileName: 'file', => 파일 이름
export const productImageListState = atom({
  key: 'ppImageListState',
  default: [],
});

// brush를 하기 위해 선택된 이미지
// productImageState 랑 같은 상태여야 함
export const selectedImageState = atom({
  key: 'selectedImageState',
  default: {
    id: 0,
    src: '',
    uploadImage: '',
    maskImage: '',
    ppImage: null,
    width: null,
    height: null,
    fileName: 'file',
  },
});

export const myPpState = atom({
  key: 'myPpState',
  default: [],
});

export const myNormalImgState = atom({
  key: 'myNormalImgState',
  default: [],
});

export const canvasListState = atom({
  key: 'canvasListState',
  default: [],
});
