import * as style from './styles';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';

function ChangePassword() {
  const { t } = useTranslation();
  const Back = () => {};
  return (
    <div style={{ display: 'flex', flexDirection: 'column', position: 'relative', height: '100%' }}>
      <style.LoginMessage>{t('login.ChangePassword.ChangePassword')}</style.LoginMessage>
      <style.LoginText>
        {t('login.ChangePassword.LoginText1_1')}
        <br />
        {t('login.ChangePassword.LoginText1_2')}
      </style.LoginText>
      <style.inputBoxInfo>{t('login.ChangePassword.Password')}</style.inputBoxInfo>
      <style.inputBox placeholder={t('login.ChangePassword.inputBox1')} type="password" required />
      <style.inputBoxInfo marginTop={'18px'}>{t('login.ChangePassword.ConfirmPassword')}</style.inputBoxInfo>
      <style.inputBox placeholder={t('login.ChangePassword.inputBox2')} type="password" required />
      <style.GrayButton marginTop={'42px'}>{t('login.ChangePassword.ChangePasswordComplete')}</style.GrayButton>
      <style.BackBtn src={process.env.PUBLIC_URL + '/images/Signup/back-arrow.png'} onClick={Back} />
    </div>
  );
}

export default ChangePassword;
