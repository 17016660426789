import { useState, useEffect } from 'react';
import * as style from 'components/MyPage/styles';
import * as Style from 'components/Canvas/styles';
import { useDropzone } from 'react-dropzone';
import { useUpload } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { bgImageState, productImageState } from 'state/imageState';
import templateAPI from 'api/templateAPI';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import Slider from 'react-slick';
import { IsImageUploadingState, MyPageLeftState } from 'state/state';
import SyncLoader from 'react-spinners/SyncLoader';
import { authState } from 'state';
import Swal from 'sweetalert2';

const NextArrow = ({ className, style, onClick }) => <Style.Arrow style={{ ...style, left: '92%', height: '100%' }} onClick={onClick} className={className}></Style.Arrow>;
const PrevArrow = ({ className, style, onClick }) => <Style.Arrow style={{ ...style, height: '100%' }} onClick={onClick} className={className}></Style.Arrow>;

// eslint-disable-next-line
function Make() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [thumbnail, setThumbnail] = useState({});
  const [elementCnt, setElementCnt] = useState(1);

  const thumbnailKey = {
    yellow: { name: t('canvas.defaultView.yellow'), id: 4 },
    skyblue: { name: t('canvas.defaultView.skyblue'), id: 10 },
    white: { name: t('canvas.defaultView.white'), id: 1 },
    beige: { name: t('canvas.defaultView.beige'), id: 2 },
    brown: { name: t('canvas.defaultView.brown'), id: 3 },
    purple: { name: t('canvas.defaultView.purple'), id: 5 },
    grey: { name: t('canvas.defaultView.grey'), id: 6 },
    black: { name: t('canvas.defaultView.black'), id: 7 },
    green: { name: t('canvas.defaultView.green'), id: 8 },
    coral: { name: t('canvas.defaultView.coral'), id: 11 },
    pink: { name: t('canvas.defaultView.pink'), id: 12 },
  };

  const [colorPages, setColorPages] = useState({
    yellow: { id: 4, page: 1 },
    skyblue: { id: 10, page: 1 },
    white: { id: 1, page: 1 },
    beige: { id: 2, page: 1 },
    brown: { id: 3, page: 1 },
    purple: { id: 5, page: 1 },
    grey: { id: 6, page: 1 },
    black: { id: 7, page: 1 },
    green: { id: 8, page: 1 },
    coral: { id: 11, page: 1 },
    pink: { id: 12, page: 1 },
  });

  const settings = {
    speed: 500,
    initialSlide: -1,
    centerMode: true,
    slidesToShow: elementCnt, // 화면 해상도에 따라 다르게하기
    slidesToScroll: elementCnt,
    textAlign: 'center',
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const MinimalColor = ['#ede4ea', '#f7dfd8', '#f9e7d5', '#faf9dc', '#d8eadd', '#d8f6f7'];

  const [productImage, setProductImage] = useRecoilState(productImageState);
  const [bgImage, setBgImage] = useRecoilState(bgImageState);

  // 이미지가 업로드중인지 아닌지 구분하여 spinner 띄우기 위한 state
  const [isImageUploading, setIsImageUploading] = useRecoilState(IsImageUploadingState);
  const [userInfo, setUserInfo] = useRecoilState(authState);
  const [leftSide, setLeftSide] = useRecoilState(MyPageLeftState);

  const onDrop = useUpload(true);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
  });

  useEffect(() => {
    getThumbnail();
    const settingElement = () => setElementCnt((window.innerWidth - 250) / 320 - 0.8);

    // 초기 탬플릿 개수 설정 및 브라우저 크기 변경에 따라 동적으로 개수 변경
    settingElement();
    window.addEventListener('resize', settingElement);

    return () => window.removeEventListener('resize', settingElement);
  }, []);

  const getThumbnail = async () => {
    // 정의해 둔 카테고리를 돌면서
    Object.keys(thumbnailKey).map(async (key) => {
      // server 값을 받아오고
      await templateAPI.templateList(thumbnailKey[key].id).then((res) => {
        // thumbnail이라는 state를 업데이트 시켜줌
        setThumbnail((prev) => ({ ...prev, [key]: res.data.results }));
      });
    });
  };

  const thumbnailPagination = async (color, id, page) => {
    await templateAPI.templatePaginationList(id, `page=${page}`).then((res) => {
      setThumbnail((prev) => ({
        ...prev,
        [color]: [...(prev[color] || []), ...res.data.results],
      }));
    });
  };

  const loadNextTemplates = async (color, index) => {
    if (index === thumbnail[color].length - 5) {
      setColorPages((prev) => ({ ...prev, [color]: { ...prev[color], page: colorPages[color].page + 1 } }));
      await thumbnailPagination(color, colorPages[color].id, colorPages[color].page + 1);
    }
  };

  const imageToURL = (image) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    canvas.width = image.width;
    canvas.height = image.height;
    context.drawImage(image, 0, 0);

    return canvas.toDataURL('image/png');
  };

  // color template click
  const colorTemplateClick = async (e) => {
    if (userInfo.canvas_sum < userInfo.tier.canvas_sum) {
      e.preventDefault();
      const color = e.target.id;
      const samplePp = new Image();
      samplePp.src = process.env.PUBLIC_URL + '/images/MyPage/productEx.png';
      samplePp.onload = () => {
        const samplePpURL = imageToURL(samplePp);

        setBgImage({ type: 'single', colors: [color] });
        setProductImage({ uploadImage: samplePpURL, maskImage: samplePpURL, ppImage: samplePpURL, fileName: 'sample-pp', width: 250, height: 250 });
        navigate('/canvas');
      };
    } else {
      Swal.fire({
        text: 'you can no longer create canvas \nPlease delete your canvas or upgrade your plan',
        customClass: {
          popup: 'custom-popup-class', // 모달 다이얼로그 스타일
        },
      });

      setLeftSide('canvas');
    }
  };
  // backgroundimage template click
  const bgTemplateClick = async (e) => {
    if (userInfo.canvas_sum < userInfo.tier.canvas_sum) {
      e.preventDefault();
      const templateId = parseInt(e.target.id);
      const samplePp = new Image();
      samplePp.src = process.env.PUBLIC_URL + '/images/MyPage/productEx.png';
      samplePp.onload = () => {
        const samplePpURL = imageToURL(samplePp);

        setBgImage({ type: 'template', templateId: templateId });
        setProductImage({ uploadImage: samplePpURL, maskImage: samplePpURL, ppImage: samplePpURL, fileName: 'sample-pp', width: 250, height: 250 });
        navigate('/canvas');
      };
    } else {
      Swal.fire({
        text: 'you can no longer create canvas \nPlease delete your canvas or upgrade your plan',
        customClass: {
          popup: 'custom-popup-class', // 모달 다이얼로그 스타일
        },
      });

      setLeftSide('canvas');
    }
  };

  return (
    <style.Wrapper>
      <style.UploadImg {...getRootProps()}>
        {isImageUploading ? (
          <div style={{ border: 'none' }}>
            <SyncLoader color={'#f54545'} loading={true} size={20} aria-label="Loading Spinner" data-testid="loader" />
            <p>Image Uploading ...</p>
          </div>
        ) : (
          <div>
            <h4>{t('mypage.make.UploadImg_h4')}</h4>
            <span>{t('mypage.make.UploadImg_span')}</span>
            <p>{t('mypage.make.Or')}</p>
            <input {...getInputProps()} />
            {isDragActive ? <span style={{ textAlign: 'center' }}>{t('mypage.make.FileUpload')}</span> : <button>{t('mypage.make.ChooseImage')}</button>}
          </div>
        )}
      </style.UploadImg>
      <div>
        <style.ImgType>
          <h6>{t('mypage.make.Classic')}</h6>
          <div>
            <style.ImgTypeColor background={'#ffffff'} onClick={colorTemplateClick}>
              <img src={process.env.PUBLIC_URL + '/images/MyPage/productEx.png'} id={'#ffffff'} style={{ width: 210, height: 210 }} />
              <span>{t('mypage.make.White')}</span>
            </style.ImgTypeColor>
            <style.ImgTypeColor background={'#000000'} onClick={colorTemplateClick}>
              <img src={process.env.PUBLIC_URL + '/images/MyPage/productEx.png'} id={'#000000'} style={{ width: 210, height: 210 }} />
              <span>{t('mypage.make.Black')}</span>
            </style.ImgTypeColor>
            <style.ImgTypeColor
              background={
                'linear-gradient(45deg, #ededed 25%, transparent 25%, transparent 75%,#ededed 75%, #ededed),linear-gradient(45deg, #ededed 25%, transparent 25%, transparent 75%,#ededed 75%, #ededed)'
              }
              onClick={colorTemplateClick}
            >
              <img className={'checkers'} src={process.env.PUBLIC_URL + '/images/MyPage/productEx.png'} id={'#00000000'} style={{ width: 210, height: 210 }} />
              <span>{t('mypage.make.Transparency')}</span>
            </style.ImgTypeColor>
          </div>
        </style.ImgType>
        <style.ImgType>
          <h6>{t('mypage.make.MinimalShop')}</h6>
          <div>
            {MinimalColor.map((bg, index) => {
              return (
                <style.MinimalType key={index} bgColor={bg} onClick={colorTemplateClick}>
                  <img src={process.env.PUBLIC_URL + '/images/MyPage/productEx.png'} id={bg} style={{ cursor: 'pointer', width: 210, height: 210 }} />
                </style.MinimalType>
              );
            })}
          </div>
        </style.ImgType>
        {Object.keys(thumbnailKey).map((key, index) => {
          return (
            <Style.Section key={index}>
              <Style.TitleDiv>
                <h6
                  style={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    letterSpacing: '-0.5px',
                    color: '#394050',
                    marginBottom: '26px',
                  }}
                >
                  {thumbnailKey[key].name}
                </h6>
              </Style.TitleDiv>
              <Slider {...settings} afterChange={(index) => loadNextTemplates(key, index)}>
                {thumbnail[key] && // thumbnail을 서버에서 못받아왔을 경우를 고려하여
                  thumbnail[key].map((i, idx) => {
                    return (
                      <div key={idx} style={{ textAlign: 'center' }} onClick={bgTemplateClick} id={i.id}>
                        <img
                          src={process.env.PUBLIC_URL + '/images/MyPage/productEx.png'}
                          id={i.id}
                          style={{ position: 'absolute', marginLeft: 55, marginTop: 15, cursor: 'pointer', width: 210, height: 210 }}
                        />
                        <Style.TemplateThumbnail style={{ height: '240px', width: '320px' }} key={idx} id={i.id} onClick={bgTemplateClick} src={i.image_small} />
                      </div>
                    );
                  })}
              </Slider>
            </Style.Section>
          );
        })}
      </div>
    </style.Wrapper>
  );
}

export default Make;
