import * as styles from './styles';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { SketchPicker } from 'react-color';
import { rightSidebarState } from 'state/sidebarState';
import { selectedShapeState, selectedObject, shadowHandleState } from 'state/canvasState';
import ObjectControlButtonList from './common/objectControlButtonList';

// eslint-disable-next-line
function ShapeEditor() {
  const { t } = useTranslation();
  const setRightState = useSetRecoilState(rightSidebarState);
  const setSelectedIndex = useSetRecoilState(selectedObject);
  const [selectedShape, setSelectedShape] = useRecoilState(selectedShapeState);
  const [shadowHandler, setShadowHandler] = useRecoilState(shadowHandleState);

  // TODO: 그림자 리팩토링 완료 시 수정
  const handleShadowBlur = (e, newValue) => {
    setShadowHandler({ ...shadowHandler, blur: Number(newValue) });
  };
  const handleShadowOffsetX = (e, newValue) => {
    setShadowHandler({ ...shadowHandler, offsetX: Number(newValue) });
  };
  const handleShadowOffsetY = (e, newValue) => {
    setShadowHandler({ ...shadowHandler, offsetY: Number(newValue) });
  };

  // input 에서 키보드로 값 입력시 관리
  const handleInputShadow = (event) => {
    const { name, value } = event.target;
    setShadowHandler({ ...shadowHandler, [name]: Number(value) });
  };

  /**
   * 도형 객체 조작 함수: fill(도형 색상), stroke(테두리 색상), storkeWidth(테두리 두께)
   * @param {object} e 조작할 속성 이벤트 정보
   * @param {string} key 조작할 속성명
   * @param {number} newValue 조작할 속성값
   * @returns void
   */
  const handleSelectedShape = (e, key, newValue) => {
    const { fabric: canvas } = document.getElementById('canvas');
    if (key === 'strokeWidth') {
      const strokeWidth = newValue ? Number(newValue) : e.target.value;
      canvas.getActiveObject().set({ strokeWidth });
      setSelectedShape({ ...selectedShape, strokeWidth });
    } else if (key === 'fill' || key === 'stroke') {
      canvas.getActiveObject().set({ [key]: e.hex });
      setSelectedShape({ ...selectedShape, [key]: e.hex });
    }
    canvas.requestRenderAll();
  };

  return (
    <>
      <styles.Wrapper id={'layer'}>
        <div style={{ textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '5px 10px 15px 10px' }}>
          <h2 style={{ userSelect: 'none' }}>{t('canvas.canvas.Shapes')}</h2>
          <img
            style={{ cursor: 'pointer', boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.1)', backgroundColor: '#fff', borderRadius: '70%', padding: '5px' }}
            src={process.env.PUBLIC_URL + '/images/Canvas/SideBar/cancleBtn.svg'}
            onClick={() => {
              setSelectedIndex('');
              setRightState('components');
            }}
            alt={'close'}
          />
        </div>
        <styles.SettingImg>
          {shadowHandler.used ? (
            <>
              <styles.HSVDiv>
                <styles.HSVHeader>
                  <styles.OptionText>{t('canvas.shadow.Intensity')}</styles.OptionText>
                </styles.HSVHeader>
                <styles.HSVBody>
                  <styles.HSVSlider
                    max={40}
                    name="blur"
                    value={shadowHandler.blur}
                    onChange={handleShadowBlur}
                    onClick={() => {
                      document.getElementById('canvas').fabric.fire('object:modified');
                    }}
                    valueLabelDisplay="auto"
                  />
                  <styles.ValueInput
                    name="blur"
                    value={shadowHandler.blur}
                    onChange={handleInputShadow}
                    onBlur={() => {
                      document.getElementById('canvas').fabric.fire('object:modified');
                    }}
                  />
                </styles.HSVBody>
              </styles.HSVDiv>
              <styles.HSVDiv>
                <styles.HSVHeader>
                  <styles.OptionText>{t('canvas.shadow.Horizontal')}</styles.OptionText>
                </styles.HSVHeader>
                <styles.HSVBody>
                  <styles.HSVSlider
                    min={-100}
                    max={100}
                    name="offsetY"
                    value={shadowHandler.offsetY}
                    onChange={handleShadowOffsetY}
                    onClick={() => {
                      document.getElementById('canvas').fabric.fire('object:modified');
                    }}
                    valueLabelDisplay="auto"
                  />
                  <styles.ValueInput
                    name="offsetY"
                    value={shadowHandler.offsetY}
                    onChange={handleInputShadow}
                    onBlur={() => {
                      document.getElementById('canvas').fabric.fire('object:modified');
                    }}
                  />
                </styles.HSVBody>
              </styles.HSVDiv>
              <styles.HSVDiv>
                <styles.HSVHeader>
                  {/* <styles.OptionIcon bg="to left, #f34181 -6%, #4d4e4f" /> */}
                  <styles.OptionText>{t('canvas.shadow.Vertical')}</styles.OptionText>
                </styles.HSVHeader>
                <styles.HSVBody>
                  <styles.HSVSlider
                    min={-100}
                    max={100}
                    name="offsetX"
                    value={shadowHandler.offsetX}
                    onChange={handleShadowOffsetX}
                    onClick={() => {
                      document.getElementById('canvas').fabric.fire('object:modified');
                    }}
                    valueLabelDisplay="auto"
                  />
                  <styles.ValueInput
                    name="offsetX"
                    value={shadowHandler.offsetX}
                    onChange={handleInputShadow}
                    onBlur={() => {
                      document.getElementById('canvas').fabric.fire('object:modified');
                    }}
                  />
                </styles.HSVBody>
              </styles.HSVDiv>
            </>
          ) : null}

          <styles.RangeWrap>
            <span>{t('canvas.option.Color')}</span>
            <div>
              <SketchPicker
                width={'90%'}
                color={selectedShape.fill ? selectedShape.fill : ''}
                onChange={(e) => handleSelectedShape(e, 'fill')}
                onChangeComplete={() => {
                  document.getElementById('canvas').fabric.fire('object:modified');
                }}
              />
            </div>
          </styles.RangeWrap>

          <styles.RangeWrap>
            <span>{t('canvas.option.StrokeColor')}</span>
            <div>
              <SketchPicker
                width={'90%'}
                color={selectedShape.stroke ? selectedShape.stroke : ''}
                onChange={(e) => handleSelectedShape(e, 'stroke')}
                onChangeComplete={() => {
                  document.getElementById('canvas').fabric.fire('object:modified');
                }}
              />
            </div>
          </styles.RangeWrap>

          <styles.RangeWrap>
            <span>{t('canvas.option.StrokeWidth')}</span>
            <styles.HSVSlider
              max={20}
              value={selectedShape.strokeWidth ? selectedShape.strokeWidth : 0}
              onChange={(e, newValue) => handleSelectedShape(e, 'strokeWidth', newValue)}
              onClick={() => {
                document.getElementById('canvas').fabric.fire('object:modified');
              }}
              valueLabelDisplay="auto"
            />
            <styles.ValueInput
              value={selectedShape.strokeWidth ? selectedShape.strokeWidth : null}
              onChange={(e, newValue) => handleSelectedShape(e, 'strokeWidth', newValue)}
              onBlur={() => {
                document.getElementById('canvas').fabric.fire('object:modified');
              }}
            />
          </styles.RangeWrap>
        </styles.SettingImg>

        {/* 앞으로 가기, 뒤로 가기, 뒤집기, 복사, 삭제 */}
        <ObjectControlButtonList type={'shape'} />
      </styles.Wrapper>
    </>
  );
}

export default ShapeEditor;
