import React, { useState, useEffect } from 'react';
import * as style from './headerStyles';
import LoginButton from './loginButton';
import { useRecoilState } from 'recoil';
import { authState } from 'state';
import { useTranslation } from 'react-i18next';

function Header() {
  const { t, i18n } = useTranslation();
  const [isEn, setIsEn] = useState(true);
  const [userInfo, setUserInfo] = useRecoilState(authState);

  // 컴포넌트가 마운트될 때 언어에 따라 isEn 값을 설정
  useEffect(() => {
    setIsEn(i18n.language === 'en');
  }, []);

  const changeLangToKo = () => {
    i18n.changeLanguage('ko');
    setIsEn(false);
  };

  const changeLangToEn = () => {
    i18n.changeLanguage('en');
    setIsEn(true);
  };

  return (
    <style.Positioner>
      <style.Background>
        <style.HeaderContents>
          <a style={{ height: '30px' }} href={userInfo.isLoggedIn ? '/mypage' : '/'}>
            <style.Logo src={process.env.PUBLIC_URL + '/images/Header/favorfitLogoPblack.svg'}></style.Logo>
          </a>
          <style.isMobileHeaderTxt>
            {t('Header.header.isMobileHeaderTxt1_1')}
            <br /> {t('Header.header.isMobileHeaderTxt1_2')}
          </style.isMobileHeaderTxt>
          <div style={{ display: 'flex', gap: '25px' }}>
            <LoginButton />
            {isEn ? <style.engBtn onClick={changeLangToKo}>ko</style.engBtn> : <style.engBtn onClick={changeLangToEn}>en</style.engBtn>}
          </div>{' '}
        </style.HeaderContents>
      </style.Background>
    </style.Positioner>
  );
}

export default Header;
