import axios from './config';
import { tokenConfig } from './TokenConfig';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  postProductPicture(data) {
    return axios.post('studio/create_product_picture/', data, tokenConfig());
  },
  putProductPicture(pp_id, data) {
    return axios.post(`studio/update_product_picture/${pp_id}`, data, tokenConfig());
  },
  getProductPictures() {
    return axios.get('studio/product_picture/', tokenConfig());
  },
  getProductPicture(id) {
    return axios.get(`studio/product_picture/${id}`);
  },
  getNormalPictures() {
    return axios.get('studio/normal_picture/', tokenConfig());
  },
  postNormalPicture(data) {
    return axios.post('studio/create_normal_picture/', data, tokenConfig());
  },
  deleteProductPicture(id) {
    return axios.delete(`studio/delete_product_picture/${id}`, tokenConfig());
  },
  deleteNormalPicture(id) {
    return axios.delete(`studio/delete_normal_picture/${id}`, tokenConfig());
  },
  saveBg(data) {
    return axios.post('studio/save_bg/', data, tokenConfig());
  },
  generatePpShadow(data) {
    return axios.post('studio/generate_pp_shadow/', data, tokenConfig());
  },
  getDownloadImages() {
    return axios.get('studio/download_image/', tokenConfig());
  },
  deleteDownloadImage(id) {
    return axios.delete(`studio/delete_download_image/${id}`, tokenConfig());
  },
};
