import * as style from './styles';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';

function JoinEmail(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  // 입력값 확인
  const [checkInput, setCheckInput] = useState({ focusOn: false, input: false });
  const [userEmail, setUserEmail] = useState(props.joinInfo.email);

  const validateEmail = (email) => {
    const emailReg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailReg.test(email);
  };

  // 입력값이 있을때만 다음 과정으로 넘어가도록
  const nextJoinProcess = () => {
    if (!validateEmail(userEmail)) {
      setCheckInput({ ...checkInput, input: false });
      Swal.fire({
        text: t('login.JoinEmail.ErrorMessage'),
        customClass: {
          popup: 'custom-popup-class',
        },
      }).then((res) => {
        if (res.isConfirmed) {
          return;
        }
      });
      return;
    } else {
      setCheckInput({ ...checkInput, input: true });
      navigate('/join?phase=2');
    }
  };
  const prevJoinProcess = () => {
    navigate('/login');
  };

  const handleLoginInfo = (e) => {
    const { name, value } = e.target;
    if (value) {
      props.setJoinInfo({ ...props.joinInfo, [name]: value });
      setUserEmail(value);
      setCheckInput({ ...checkInput, input: true });
    } else {
      setCheckInput({ ...checkInput, input: false });
      setUserEmail('');
    }
  };

  return (
    <style.SignUpWrapper>
      <style.LoginMessage>{t('login.JoinEmail.LoginMessage')}</style.LoginMessage>
      <style.LoginText>
        {t('login.JoinEmail.LoginText1_1')}
        <br />
        {t('login.JoinEmail.LoginText1_2')}
      </style.LoginText>
      <style.inputBoxInfo>{t('login.JoinEmail.inputBoxInfo')}</style.inputBoxInfo>
      <style.inputBox
        onChange={handleLoginInfo}
        onFocus={() => setCheckInput({ ...checkInput, focusOn: true })}
        placeholder={t('login.JoinEmail.inputBox')}
        value={userEmail}
        name={'email'}
        type="email"
        required
      />
      {userEmail === '' && checkInput.focusOn && !checkInput.input && <style.WarningMessage color={'#e61503'}>{t('login.JoinEmail.inputBox')}</style.WarningMessage>}
      <div>
        <style.GrayButton marginTop={'42px'} onClick={nextJoinProcess}>
          {t('login.JoinEmail.GrayButton')}
        </style.GrayButton>
        <style.BackBtn src={process.env.PUBLIC_URL + '/images/Signup/back-arrow.png'} onClick={prevJoinProcess} />
      </div>
    </style.SignUpWrapper>
  );
}

export default JoinEmail;
