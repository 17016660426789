import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Base64State, ImageNameState, IsImageUploadingState, MyPageLeftState } from 'state/state';
import { authState } from 'state';
import imageCompression from 'browser-image-compression';
import heic2any from 'heic2any';
import ReactGA from 'react-ga4';
import Swal from 'sweetalert2';

/* eslint-disable max-lines-per-function, no-unused-vars */
function useUpload(isNavigate) {
  const [, setImgValues] = useState(null);
  const [, setImgBase64] = useState('');
  const [, setImgShow] = useState(false);
  const [, setUploadClick] = useState(false);
  const [, setBase64] = useRecoilState(Base64State);
  const [, setLeftSide] = useRecoilState(MyPageLeftState);
  const [, setIsImageUploading] = useRecoilState(IsImageUploadingState); // pp 이름
  const [ppName, setPPName] = useRecoilState(ImageNameState); // pp 이름
  const userInfo = useRecoilValue(authState);

  const navigate = useNavigate();

  const compressImage = async (image) => {
    setIsImageUploading(true);
    //이미지 파일을 압축시켜줌
    try {
      const options = {
        // maxSizeMB: 0.2,
        maxWidthOrHeight: 1920,
      };
      return await imageCompression(image, options);
    } catch (e) {
      console.log(e);
    }
  };

  const onDrop = useCallback(async (files) => {
    const acceptTypes = ['png', 'jpg', 'jpeg', 'heic'];
    const type = files[0].name.split('.');
    if (!acceptTypes.includes(type.pop().toLowerCase())) {
      Swal.fire({
        text: '파일 양식이 잘못되었습니다. 이미지 파일을 업로드해주세요.',
        customClass: { popup: 'custom-popup-class' }, // 모달 다이얼로그 스타일
      });
      return;
    }

    if (type === 'heic') {
      let blob = files[0];
      await heic2any({ blob: blob, toType: 'image/jpeg' })
        .then(function (resultBlob) {
          files = new File([resultBlob], files[0].name.split('.')[0] + '.jpg', {
            type: 'image/jpeg',
            lastModified: new Date().getTime(),
          });
          reader.readAsDataURL(files);
        })
        .catch(function (x) {
          console.log(x);
        });
    } else {
      files = files[0];
    }
    setPPName(files.name);
    const compressed = await compressImage(files);
    const compressed_file = await new File([compressed], files.name);
    let reader = new FileReader();
    if (compressed_file) {
      const imgTarget = compressed_file;
      if (imgTarget) {
        reader.readAsDataURL(imgTarget); // buffer에 저장함!!
        reader.onloadend = () => {
          const base64 = reader.result;
          if (base64) {
            setImgValues(compressed_file);
            setImgBase64(base64.toString());
            setBase64(base64.toString());
            setImgShow(true);
            setUploadClick(true);
          }
        };
      }
    }
    ReactGA.event({
      category: 'User',
      action: 'upload',
      label: 'upload file from home',
    });

    if (isNavigate) {
      if (!userInfo.isLoggedIn || userInfo.canvas_sum < userInfo.tier.canvas_sum) {
        setIsImageUploading(false);
        navigate('/aiproduct', { state: { fileName: ppName } });
      } else {
        Swal.fire({
          text: 'you can no longer create canvas \nPlease delete your canvas or upgrade your plan',
          customClass: {
            popup: 'custom-popup-class', // 모달 다이얼로그 스타일
          },
        });

        setIsImageUploading(false);
        navigate('/mypage');
        setLeftSide('canvas');
      }
    }
  });

  return onDrop;
}

export default useUpload;
