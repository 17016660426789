import * as styles from './styles';

export default function TemplateThumbnail(props) {
  return (
    <styles.TemplateThumbnail {...props}>
      <styles.Thumbnail src={props.src} crossOrigin="anonymous" />
      <styles.ProdImage src={props.prod} left={props.left} top={props.top} crossOrigin="anonymous" />
    </styles.TemplateThumbnail>
  );
}
