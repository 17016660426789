import styled from 'styled-components';

export const Wrap = styled.div`
  width: fit-content;
  position: absolute;
  z-index: 2; // canvas 에 가려질 수 있음.
  right: 0;
  top: 0;
  display: flex;
`;

export const UndoButton = styled.button`
  border-radius: 3px;
  background-size: 36px;
  border-width: 0;
  width: 30px;
  height: 30px;
  padding: 10px;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  user-select: none;
  margin-right: 8px;
`;
export const RedoButton = styled.button`
  border-radius: 3px;
  background-size: 36px;
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  user-select: none;
`;
