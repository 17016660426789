import React from 'react';
import { useRecoilState } from 'recoil';
import { selectedObject } from 'state/canvasState';
import { rightSidebarState } from 'state/sidebarState';

export default function CancelButton() {
  const [, setSelectedIndex] = useRecoilState(selectedObject);
  const [, setRightState] = useRecoilState(rightSidebarState);
  return (
    <img
      style={{ cursor: 'pointer', boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.1)', backgroundColor: '#fff', borderRadius: '70%', padding: '5px' }}
      src={process.env.PUBLIC_URL + '/images/Canvas/SideBar/cancleBtn.svg'}
      onClick={() => {
        setSelectedIndex('');
        setRightState('components');
      }}
      alt="cancleBtn"
    />
  );
}
