import * as style from './styles';
import { useTranslation } from 'react-i18next';

/* eslint-disable max-lines-per-function, no-unused-vars */
function Partners() {
  const { t } = useTranslation();

  return (
    <style.MainContainer>
      <style.Title>
        {t('home.partners.Title1_1')}
        <br />
        {t('home.partners.Title1_2')}
      </style.Title>
      <style.logos>
        <img src={process.env.PUBLIC_URL + '/images/Home/Partners/1.png'} alt="" />
        <img src={process.env.PUBLIC_URL + '/images/Home/Partners/2.png'} alt="" />
        <img src={process.env.PUBLIC_URL + '/images/Home/Partners/3.png'} alt="" />
      </style.logos>
    </style.MainContainer>
  );
}

export default Partners;
