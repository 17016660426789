import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1140px;
  width: 100%;
  gap: 100px;
  @media screen and (max-width: 1024px) {
    gap: 80px;
  }
  @media screen and (max-width: 768px) {
    gap: 60px;
    padding: 0 20px;
  }
`;

export const SubContent = styled.div`
  display: flex;
  width: 100%;
  gap: 45px;
  @media screen and (max-width: 768px) {
    flex-direction: ${(props) => props.flexDirection};
  }
`;

export const ImgWrap = styled.div`
  width: 50%;
  border-radius: 20px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
export const SubContentImg = styled.img`
  object-fit: cover;
  border-radius: 20px;
  width: 100%;
  height: 320px;
  background-color: #f0f0f0;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  @media screen and (max-width: 1024px) {
    height: 280px;
  }
  @media screen and (max-width: 768px) {
    height: 240px;
  }
`;

export const SubContentText = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const subTitle = styled.p`
  all: unset;
  font-weight: bold;
  font-size: 22px;
  color: #d33321;
  margin-bottom: 14px;
  @media screen and (max-width: 1440px) {
    font-size: 20px;
  }
  @media screen and (max-width: 768px) {
    font-size: 18px;
  }
  @media screen and (max-width: 500px) {
    font-size: 14px;
  }
`;

export const Title = styled.span`
  font-weight: 700;
  line-height: 1.4em;
  color: #222222;
  font-size: 32px;
  margin-bottom: 24px;
  @media screen and (max-width: 1440px) {
    font-size: 28px;
  }
  @media screen and (max-width: 1024px) {
    font-size: 26px;
  }
  @media screen and (max-width: 768px) {
    font-size: 24px;
  }
  @media screen and (max-width: 500px) {
    font-size: 20px;
  }
`;

export const Info = styled.span`
  font-weight: 500;
  font-size: 24px;
  line-height: 1.4em;
  color: #767676;
  @media screen and (max-width: 1440px) {
    font-size: 22px;
  }
  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
  @media screen and (max-width: 500px) {
    font-size: 16px;
  }
`;
