/**********************************
 * Name : canvas.js
 * Author : Park JeongMyeong
 * Introduction : Canvas 페이지
 ********************************** */
import * as style from './styles';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useBeforeunload } from 'react-beforeunload';
import { useRecoilState, useRecoilValue } from 'recoil';
import { zoomlevelState, zoomState, ppZoomState, ImageNameState, TempCanvasContentState } from 'state/state';
import { productImageState, bgImageState, productImageListState, selectedImageState } from 'state/imageState';
import { showUploadState } from 'state/sidebarState';
import studioAPI from 'api/studioAPI';
// import { VideoModal } from 'containers/videoModal'; 템플릿 찍을때 보여줄 비디오
import { ReplaceImage, CanvasSubHeader, ProductPhotography, SelectTemplate, Font, FabricCanvas, PpEdit, Resize, Shapes } from 'components/Canvas';
import { CanvasHeader } from 'components/Header';
import { useTranslation } from 'react-i18next';
import { leftSidebarState, rightSidebarState } from 'state/sidebarState';
import { objectList, selectedObject, zoomTriggerState } from 'state/canvasState';
import LeftSideBar from 'components/Canvas/leftSidebar/leftSidebar';
import RightSidebar from 'components/Canvas/rightSideBar/rightSideBar';
import CanvasWorkspace from 'components/Canvas/canvasWorkspace/canvasWorkspace';
import CanvasLoadingBackDrop from 'components/Canvas/loading/loading';

// eslint-disable-next-line
function Canvas() {
  const locationSearch = useLocation();
  const param = new URLSearchParams(locationSearch.search).get('background');
  const [useTemplate, setUseTemplate] = useState(param);
  useBeforeunload((event) => event.preventDefault());

  // QR 생성위한 배경 id 저장
  const [bgTemplateId, setBgTemplateId] = useState(0);
  const [ppZoom, setPpZoom] = useRecoilState(ppZoomState);
  // none 꺼버림 create 이미지 업로드 update 이미지 리플레이스
  const [showUpload, setShowUpload] = useRecoilState(showUploadState);
  const [download, setDownload] = useState(0);
  const [svgPP, setSvgPP] = useState({
    src: '',
    width: 0,
    height: 0,
    fileName: '',
    key: '',
    status: 'read',
    // status = 'create', 'update', 'read'
    type: '',
  });
  const [ImgValue, setImgValue] = useState([]);
  // rightSideBar에서 브러쉬(제품사진)/필터(합성편집) 나누기 위함
  // const [videoCheck, setVideoCheck] = useState(1);

  const [redo, setRedo] = useState(0);
  const [undo, setUndo] = useState(0);
  const [bg, setBg] = useState({
    backImg: '',
    backName: '',
  });

  // sidebar state
  const [leftState, setLeftState] = useRecoilState(leftSidebarState);
  const [rightState, setRightState] = useRecoilState(rightSidebarState);

  const [zoom, setZoom] = useRecoilState(zoomState);

  useEffect(() => {
    setLeftState(null);
    setRightState('components');
  }, []);

  useEffect(() => {
    if (rightState === 'brush') {
      document.getElementById('block2').style.display = 'none';
      document.getElementById('blockContainer').style.justifyContent = 'center';
      document.getElementById('blockContainer').style.alignItems = 'center';
      setLeftState(null);
    } else {
      document.getElementById('block2').style.display = 'flex';
    }
  }, [rightState]);

  // type: null, templateId: null, colors: null, name: '' bg 이미지 형식
  const getTemplate = async (id) => {
    await studioAPI.getTemplate(id).then((res) => {
      setBg((state) => ({ ...state, backImg: res.data.img, backName: res.data.name }));
      setBgTemplateId(id);
    });
  };

  const ppZoomAction = ({ target }) => {
    const value = target.value;
    setPpZoom(value === 'in' ? ppZoom + 0.25 : ppZoom > 0.25 ? ppZoom - 0.25 : ppZoom);
  };

  return (
    <style.Wrap>
      <CanvasLoadingBackDrop />
      <CanvasHeader DownloadCanvas={() => setDownload(download + 1)} />
      <div id={'resizeBackground'} style={{ position: 'fixed', display: 'none', zIndex: 2, width: '100vw', height: '100vh', background: 'rgba(0, 0, 0, 0.4)' }} />
      <style.Test id="canvasEditor">
        {showUpload !== 'none' ? <ReplaceImage bgTemplateId={bgTemplateId} setSvgPP={(value) => setSvgPP(value)} svgPP={svgPP} /> : null}

        {rightState === 'brush' ? null : <LeftSideBar />}

        <style.MainContents id={'mainContents'}>
          {/* canvas Header */}
          <CanvasSubHeader handleZoom={ppZoomAction} clickRedo={() => setRedo(redo + 1)} clickUndo={() => setUndo(undo + 1)}></CanvasSubHeader>
          {/* 캔버스가 위치한 캔버스 */}
          <style.mainCanvas id={'canvasShow'}>
            <style.BlockContainer id={'blockContainer'}>
              <div id={'block1'} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
                {rightState === 'brush' ? (
                  <PpEdit
                    clickCombinePic={(callback) => {
                      if (rightState === 'brush') {
                        callback();
                      }
                    }}
                    redo={redo}
                    undo={undo}
                    ppSize={{ width: svgPP.width, height: svgPP.height }}
                    svgPP={svgPP}
                    setSvgPP={(value) => setSvgPP(value)}
                    useTemplate={useTemplate}
                  />
                ) : null}
              </div>
              <style.Block2 id={'block2'} zoom={zoom}>
                <CanvasWorkspace />
              </style.Block2>
            </style.BlockContainer>
          </style.mainCanvas>
        </style.MainContents>
        <RightSidebar bgTemplateId={bgTemplateId} propsSetImgValue={setImgValue} getTemplate={getTemplate} setSvgPP={setSvgPP} />
      </style.Test>
    </style.Wrap>
  );
}

export default Canvas;
