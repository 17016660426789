import styled from 'styled-components';

export const uploadPP = styled.p`
  width: 100%;
  height: 40px;
  cursor: pointer;
  background-color: #fd5c5c;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.25);
  margin-bottom: 20px;
  line-height: 1.5em;
  border-radius: 4px;
  font-weight: bold;
  transition: all 0.3s ease;

  :hover {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  }
`;
export const PPvideo = styled.div`
  width: 100%;
  height: 240px;
  background-color: #000000;
  margin-bottom: 50px;
  color: #ffffff;
`;

export const SliderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
export const SliderItem = styled.div`
  display: flex;
  flex-direction: column;
`;
export const SliderTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const RefreshButton = styled.span`
  cursor: pointer;
`;
export const video = styled.video`
  width: 100%;
  height: 100%;
`;

export const AIrecom = styled.label`
  width: calc(100% - 20px);
  height: 40px;
  cursor: pointer;
  background-color: #ffefef;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.25);
  position: absolute;
  bottom: 20px;
`;
