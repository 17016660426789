import { fabric } from 'fabric';
import { generateGuideLine } from './canvasObjectGenerateUtil';
import icons from 'constants/icons';

/********** Canvas Event 등록 **********/

// GuildeLine 생성 이벤트 등록
// 객체가 움직일 때마다 가장 가까운 객체를 찾고, guideLine을 그릴지 말지 결정
fabric.Canvas.prototype.on('object:moving', (event) => {
  generateGuideLine(event);
});

fabric.Canvas.prototype.on('mouse:up:before', (event) => {
  if (event.target) {
    const { canvas } = event.target;
    canvas.forEachObject((obj) => {
      if (obj.type === 'guideLine') {
        canvas.off('object:removed');
        canvas.remove(obj);
        canvas.on('object:removed');
      }
    });
  }
});

export const addZoomEvent = (canvas, setZoom) => {
  const onWheel = (opt) => {
    const delta = opt.e.deltaY;
    let zoom = canvas.getZoom();
    zoom *= 0.999 ** delta;
    if (zoom > 10) zoom = 10;
    if (zoom < 0.1) zoom = 0.1;
    canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
    setZoom(zoom);
    opt.e.preventDefault();
    opt.e.stopPropagation();
    canvas.requestRenderAll();
  };
  canvas.on('mouse:wheel', onWheel);
  console.log('Zoom Event Added');
  return {
    remove: () => {
      canvas.off('mouse:wheel', onWheel);
    },
  };
};

// Panning Event 등록
fabric.Canvas.prototype.isDragging = false;
fabric.Canvas.prototype.lastPosX = 0;
fabric.Canvas.prototype.lastPosY = 0;

// eslint-disable-next-line
export const addPanningEvent = (canvas) => {
  canvas.isDragging = false;
  canvas.lastPosX = 0;
  canvas.lastPosY = 0;

  const onMouseDown = (opt) => {
    var evt = opt.e;
    if (evt.shiftKey === true) {
      canvas.isDragging = true;
      canvas.lastPosX = evt.clientX;
      canvas.lastPosY = evt.clientY;
      canvas.selection = false;
    }
  };
  const onMouseMove = (opt) => {
    if (canvas.isDragging) {
      var e = opt.e;
      var vpt = canvas.viewportTransform;
      vpt[4] += e.clientX - canvas.lastPosX;
      vpt[5] += e.clientY - canvas.lastPosY;
      canvas.requestRenderAll();
      canvas.lastPosX = e.clientX;
      canvas.lastPosY = e.clientY;
    }
  };
  const onMouseUp = (opt) => {
    canvas.setViewportTransform(canvas.viewportTransform);
    canvas.isDragging = false;
    canvas.selection = true;
  };

  canvas.on('mouse:down', onMouseDown);
  canvas.on('mouse:move', onMouseMove);
  canvas.on('mouse:up', onMouseUp);

  return {
    remove: () => {
      canvas.off('mouse:down', onMouseDown);
      canvas.off('mouse:move', onMouseMove);
      canvas.off('mouse:up', onMouseUp);
    },
  };
};

export const addSelectionClearEvent = (canvas, setSelectedIndex, setRightState) => {
  const onSelectionCleared = (input) => {
    setSelectedIndex('');
    setRightState('components');
  };
  canvas.on('selection:cleared', onSelectionCleared);

  return {
    remove: () => {
      canvas.off('selection:cleared', onSelectionCleared);
    },
  };
};

export const addSingleSelectionEvent = (canvas) => {
  const onSelectionCreated = (event) => {
    const selectedObjects = event.selected;
    if (selectedObjects.length > 1) {
      canvas.discardActiveObject();
      const objectToSelect = selectedObjects[selectedObjects.length - 1];
      canvas.setActiveObject(objectToSelect);
      canvas.requestRenderAll();
    }
  };

  canvas.on('selection:created', onSelectionCreated);

  return {
    remove: () => {
      canvas.off('selection:created', onSelectionCreated);
    },
  };
};

export const addKeyListeners = (canvas) => {
  console.log('addKeyListeners 실행');
  const keyDownHandler = (e) => {
    if (!document.getElementById('undo')?.disabled && (e.ctrlKey || e.metaKey) && e.keyCode == 90) {
      // console.log('ctrl + z 인식');
      document.getElementById('undo').click(); // ctrl + z
    } else if (!document.getElementById('redo')?.disabled && (((e.ctrlKey || e.metaKey) && e.shiftKey && e.keyCode == 90) || ((e.ctrlKey || e.metaKey) && e.keyCode == 89))) {
      document.getElementById('redo').click(); // ctrl + shift + z OR ctrl + y
    }

    // ctrl + c
    if (document.getElementById('canvas')?.fabric?.getActiveObject() && (e.ctrlKey || e.metaKey) && e.keyCode === 67) {
      document.getElementById('copy')?.click();
    }
  };

  document.addEventListener('keydown', keyDownHandler);
  return {
    remove: () => document.removeEventListener('keydown', keyDownHandler),
  };
};

/********** Canvas 기본 설정 **********/
// Canvas History 초기 설정
export const initCanvasHistory = (canvas) => {
  console.log('history 설정');

  // 이전 canvas history 초기화
  fabric.Canvas.prototype.off('object:added');
  fabric.Canvas.prototype.off('object:modified');
  fabric.Canvas.prototype.off('object:removed');
  fabric.Canvas.prototype.history = null;
  fabric.Canvas.prototype.historyTemp = null;
  fabric.Canvas.prototype.preAction = null;
  fabric.Canvas.prototype.undo = null;
  fabric.Canvas.prototype.redo = null;
  fabric.Canvas.prototype.saveHistory = null;
  fabric.Canvas.prototype.addHistoryEventListener = null;
  fabric.Canvas.prototype.deleteHistoryEventListener = null;

  // canvas history 속성 및 메드 정의
  canvas.history = []; // undo stack
  canvas.historyTemp = []; // redo stack
  canvas.preAction = ''; // previous action

  // canvas history 관련 이벤트 등록
  canvas.addHistoryEventListener = function () {
    this.on('object:added', this.saveHistory);
    this.on('object:modified', this.saveHistory);
    this.on('object:removed', this.saveHistory);
  };

  // canvas history 여러 개 쌓임 현상 방지
  canvas.deleteHistoryEventListener = function () {
    this.off('object:added');
    this.off('object:modified');
    this.off('object:removed');
  };

  // canvas history 저장
  canvas.saveHistory = function () {
    this.history.push(this.toDatalessJSON(propertiesWhenSerializing));

    if (this.history.length > 1) disabledToggle('undo', false);

    if (this.preAction === 'undo') {
      this.historyTemp = [];
      disabledToggle('redo', true);
    }

    console.log('history 저장', this.history);

    this.preAction = 'save';
  };

  // canvas undo 기능 정의
  canvas.undo = function () {
    this.deleteHistoryEventListener();

    const history = this.history.pop();
    if (history) {
      this.preAction = 'undo';
      this.historyTemp.push(history);
    }

    return this.history[this.history.length - 1];
  };

  // canvas redo 기능 정의
  canvas.redo = function () {
    this.deleteHistoryEventListener();

    const history = this.historyTemp.pop();
    if (history) {
      this.preAction = 'redo';
      this.history.push(history);
    }

    return history;
  };
};

// Canvas Filter 적용을 위한 객체 최대 크기 설정
fabric.textureSize = 4096;

// Canvas 초기 설정
const originCanvasAdd = fabric.Canvas.prototype.add;

fabric.Canvas.prototype.preserveObjectStacking = true; // 객체의 앞뒤 순서를 유지해주는 설정
fabric.Canvas.prototype.enableRetinaScaling = true;
fabric.Canvas.prototype.renderOnAddRemove = false;

// canvas.add() Override
fabric.Canvas.prototype.add = function (target) {
  originCanvasAdd.call(this, target);

  // 배경 객체인 경우 Canvas에 맞게 크기 조정 및 레이어 가장 뒤로 이동
  if (target.isBackground) {
    this.sendToBack(target);
    if (this.clipPath.width / target.width < this.height / target.height) target.scaleToWidth(Math.max(this.clipPath.width, 500) * this.getZoom());
    else target.scaleToHeight(Math.max(this.clipPath.height, 500) * this.getZoom());
  } else if (target.isShadow) {
  } else if (target.type === 'guideLine') {
  }
};

// Object 기본 스타일 설정
fabric.Object.prototype.cornerColor = '#FFFFFF';
fabric.Object.prototype.cornerSize = 9;
fabric.Object.prototype.cornerStyle = 'circle';
fabric.Object.prototype.borderColor = '#4292F1';
fabric.Object.prototype.borderScaleFactor = 3;
fabric.Object.prototype.cornerStrokeColor = '#000000';
fabric.Object.prototype.transparentCorners = false;
fabric.Object.prototype.strokeWidth = 0;
fabric.Object.NUM_FRACTION_DIGITS = 20;

// 원하는 특정 속성을 포함시키기 위해 추가 작업
fabric.Object.prototype.toJSON = function () {
  const object = this.toObject();

  object.name = this.name;
  object.blur = this.blur;
  object.isShadow = this.isShadow;
  object.parentId = this.parentId;
  object.shadowAngle = this.shadowAngle;
  object.maskImage = this.maskImage;
  object.uploadImage = this.uploadImage;
  object.src = this.src;
  object.templateId = this.templateId;
  object.originSrc = this.originSrc;
  object.originWidth = this.originWidth;
  object.originHeight = this.originHeight;

  return object;
};

// Textbox 기본 스타일 설정
fabric.Textbox.prototype.setControlVisible('mt', false);
fabric.Textbox.prototype.setControlVisible('mb', false);

// Canvas Json 변경 시 저장할 추가 속성
export const propertiesWhenSerializing = [
  'id',
  'name',
  'blur',
  'shadow',
  'isShadow',
  'parentId',
  'templateId',
  'correction',
  'maskImage',
  'uploadImage',
  'width',
  'height',
  'originWidth',
  'originHeight',
  'shadow2',
  'selectable',
  'shadowAngle',
  'isManual',
  'clipPath',
  'src',
  'originSrc',
];

// redo, undo disalbed on off 함수
export const disabledToggle = (target, isOn) => {
  const targetEl = document.getElementById(target);
  targetEl.disabled = isOn;
  targetEl.style.cursor = isOn ? 'not-allowed' : 'pointer';
  targetEl.style.border = isOn ? '2px #F0353F30 solid' : '2px #F0353FAA solid';
  targetEl.style.backgroundColor = isOn ? '#CCCCCC30' : '#FFFFFF';
  if (target === 'redo') {
    targetEl.style.backgroundImage = isOn ? `url(${process.env.PUBLIC_URL + '/images/Canvas/redoIconDisabled.svg'} )` : `url(${process.env.PUBLIC_URL + '/images/Canvas/redoIcon.svg'} )`;
  } else {
    targetEl.style.backgroundImage = isOn ? `url(${process.env.PUBLIC_URL + '/images/Canvas/undoIconDisabled.svg'} )` : `url(${process.env.PUBLIC_URL + '/images/Canvas/undoIcon.svg'} )`;
  }
};

/********** Save & Download 관련 함수 **********/

/**
 * 캔버스의 배경을 투명 패턴으로 설정하는 함수
 * @param {fabric.canvas} canvas
 * @returns void
 */
export const settingTransparentBg = (canvas) => {
  canvas.setBackgroundColor({ source: icons.transparentBg, repeat: 'repeat' }, () => canvas.requestRenderAll());
};

/**
 * 인자로 전달한 Viewport에 맞게 캔버스의 Viewport 설정하는 함수
 * @param {fabric.canvas} canvas
 * @param {array} vpt
 * @returns void
 */
export const settingViewport = (canvas, vpt) => {
  const currentVpt = canvas.viewportTransform;
  currentVpt[4] = vpt.x;
  currentVpt[5] = vpt.y;

  canvas.setViewportTransform(currentVpt);
  canvas.requestRenderAll();
};

/**
 * 캔버스의 Viewport, Zoom 을 초기 상태로 돌리며 배경을 제거하는 함수
 * @param {fabric.canvas} canvas
 * @returns 이전 상태의 Zoom, Viewport 정보
 */
export const settingInitialCanvas = (canvas) => {
  const preZoom = canvas.getZoom();
  const vpt = canvas.viewportTransform;
  const [preVptX, preVptY] = vpt.slice(4, 6);
  vpt[4] = 0;
  vpt[5] = 0;

  canvas.setZoom(1);
  canvas.backgroundColor = null;

  return { preZoom, preVpt: { x: preVptX, y: preVptY } };
};

export const generateCanvas = () => {
  const canvas = new fabric.Canvas('canvas', { controlsAboveOverlay: true, selectionKey: [null] });
  canvas.setBackgroundColor({ source: `${process.env.PUBLIC_URL}/images/ChoiceDesign/transparentBg.jpeg`, repeat: 'repeat' });

  return canvas;
};

/**
 * 캔버스의 크기를 부모 컴포넌트의 크기로 채워주는 함수
 * @param {*} canvas 캔버스
 * @returns void
 */
export const fillCanvasSize = (canvas) => {
  const parentComponent = document.getElementById('mainContents');

  canvas.setWidth(parentComponent?.offsetWidth);
  canvas.setHeight(parentComponent?.offsetHeight);

  console.log('Canvas Filled');
};

export const moveObjectsWhenResize = (canvas, beforeClipPath, afterClipPath) => {
  const objects = canvas.getObjects();
  const moveX = afterClipPath.left - beforeClipPath.left;
  const moveY = afterClipPath.top - beforeClipPath.top;

  objects.forEach((object) => {
    object.left += moveX;
    object.top += moveY;
  });
};

/**
 * 캔버스의 뷰포트를 초기화하는 함수, 리사이즈 이벤트마다 호출 필요
 * @param {fabric.canvas} canvas
 */
export const resetViewPort = (canvas) => {
  const vpt = canvas.viewportTransform;
  vpt[4] = 0;
  vpt[5] = 0;
  canvas.setViewportTransform(vpt);
};

export const settingObjectOnCenter = (canvas, target) => {
  target.originX = 'center';
  target.originY = 'center';
  target.left = canvas.clipPath.getCenterPoint().x;
  target.top = canvas.clipPath.getCenterPoint().y;
  target.setCoords();
  canvas.requestRenderAll();
};

export const initialSize = { scaleX: 1, scaleY: 1, width: 300, height: 300 };

// 이미지 로드 시 pre-signed expire time 이슈 해결을 위한 함수
export const removeXAmzParams = (url) => {
  if (url) {
    const urlParts = url.split('?');

    if (urlParts.length >= 2) {
      let queryParams = urlParts[1].split('&');

      queryParams = queryParams.filter(function (param) {
        return !param.startsWith('X-Amz');
      });

      urlParts[1] = queryParams.join('&');
      return urlParts.join('?');
    }

    return url;
  }
};
