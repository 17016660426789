// LayerComponent.js
import React from 'react';
import * as styles from './styles';

function LayerComponent({ obj, index, selected, source, onComponentClick, onComponentDoubleClick, onComponentBlur }) {
  return (
    <styles.LayerComponent key={index} kind={obj.type} onClick={(e) => onComponentClick(e, obj)}>
      <img src={source} alt={obj.name} />
      <span
        className={'canvasComponent'}
        style={{
          padding: '5px',
          fontWeight: obj.id === selected ? 'bold' : 'normal',
        }}
        onDoubleClick={onComponentDoubleClick}
        onBlur={(e) => onComponentBlur(e, obj, index)}
        contentEditable={false}
      >
        {obj.name}
      </span>
    </styles.LayerComponent>
  );
}

export default LayerComponent;
