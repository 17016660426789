/**********************************
 * 파일명: icons.js
 * 작성자: Jiwon Kang
 * 설명: icon SVG 또는 이미지 파일들의 상대 경로를 저장
 **********************************/

const icons = {
  make: process.env.PUBLIC_URL + '/images/MyPage/make.svg',
  canvas: process.env.PUBLIC_URL + '/images/MyPage/canvas.svg',
  product: process.env.PUBLIC_URL + '/images/MyPage/product.svg',
  asset: process.env.PUBLIC_URL + '/images/MyPage/asset.svg',
  download: process.env.PUBLIC_URL + '/images/MyPage/download.svg',

  template: process.env.PUBLIC_URL + '/images/Canvas/templateIcon.svg',
  text: process.env.PUBLIC_URL + '/images/Canvas/textIcon.svg',
  image: process.env.PUBLIC_URL + '/images/Canvas/SideBar/imgChangeIcon.svg',
  shape: process.env.PUBLIC_URL + '/images/Canvas/shapeIcon.svg',
  resize: process.env.PUBLIC_URL + '/images/Canvas/combineIcon.svg',

  iconArrow: process.env.PUBLIC_URL + '/images/Analysis/icon-arrow.png',
  beforeArrow: process.env.PUBLIC_URL + '/images/Component/beforeIcon.png',
  afterArrow: process.env.PUBLIC_URL + '/images/Component/afterIcon.png',

  transparentBg: process.env.PUBLIC_URL + '/images/ChoiceDesign/transparentBg.jpeg',
};

export default icons;
