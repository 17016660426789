import styled from 'styled-components';

export const PpEditWrapper = styled.div`
  touch-action: none;
  display: flex;
  justify-content: center;
  align-items: center;
  //width: 95%;
  //height: 95%;
  //확대하는 만큼 크기 조정 임시로 적용
  transform: scale(${(props) => props.zoom}) translate(${(props) => (props.zoom >= 1 ? (-(props.zoom - 1) * 100) / (props.zoom * 2) + '%, ' + (-(props.zoom - 1) * 100) / (props.zoom * 2) + '%' : 0)});
`;

export const CheckImg = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  touch-action: none;
  border: 1px solid #cbcbcb;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3LjkzNyA3LjkzOCIgaGVpZ2h0PSIzMCIgd2lkdGg9IjMwIj48cGF0aCBwYWludC1vcmRlcj0ic3Ryb2tlIGZpbGwgbWFya2VycyIgZD0iTS4wMTQuMDE0SDMuOTdWMy45N0guMDE0ek0zLjk3IDMuOTY4aDMuOTU0djMuOTU1SDMuOTd6IiBmaWxsPSIjZWVlZmYwIi8+PC9zdmc+);
  background-size: 25px;
  background-repeat: repeat;
  width: 100%;
  height: 100%;
  font-size: 0;
`;

export const DoButton = styled.img`
  width: 25px;
  cursor: pointer;
  margin-bottom: 5px;
`;

export const Circle = styled.div`
  border: 2px solid rgba(84, 84, 84, 0.7);
  border-radius: 50%;
  z-index: 100;
  pointer-events: none;
  width: ${(props) => (props.size ? `${props.size}px` : '13px')};
  height: ${(props) => (props.size ? `${props.size}px` : '13px')};
  transform: scale(${(props) => props.zoom});
  position: absolute;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.7);
`;

export const EditMain = styled.div`
  touch-action: none;
  margin: 0;
  padding: 0;
  float: left;
  position: relative;
  //width: 100%;
  //height: 100%;
  transform: scale(${(props) => props.zoom}) translate(${(props) => (props.zoom >= 1 ? ((props.zoom - 1) * 100) / (props.zoom * 2) + '%, ' + ((props.zoom - 1) * 100) / (props.zoom * 2) + '%' : 0)});
  width: ${(props) => props.width};
  height: ${(props) => props.height};
`;

// 밑에건 zoom 이랑 redo undo에 대한 css
export const IconsDiv = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const leftIcons = styled.div`
  width: 300px;
  display: flex;
  align-items: center;
`;

export const rightIcons = styled.div`
  //width: 445px;
  align-items: center;
  display: flex;
  width: 80%;
  padding: 20px 0;
  margin: auto;
  gap: 20px;
  justify-content: center;
`;

export const canvasName = styled.input`
  height: 30px;
  width: 100%;
  color: black;
  cursor: text;
  font-size: 1rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 800;
  line-height: 1.1876em;
  letter-spacing: 0.00938em;
  border: none;
  border-radius: 2px;
`;

export const simpleIcons = styled.img`
  width: 25px;
  height: 25px;
  margin-right: 10px;
  background-color: transparent;
  cursor: pointer;
`;

export const processControl = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;
export const zoomContainer = styled.div`
  background: #dcdcdc;
  display: flex;
  align-items: center;
`;
export const zoomBtn = styled.button`
  color: #6e6e6e;
  height: 100%;
  width: 26px;
  font-size: 20px !important;
  font-family: 'NS-R';
  border-radius: 2px;
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #dcdcdc !important;
  background: #fff;
  cursor: pointer;
`;
export const zoomStatus = styled.p`
  width: 68px;
  display: inline;
  color: #6e6e6e;
  font-size: 12px;
  font-family: 'NS-B';
  text-align: center;
  padding: 0 18px;
`;

export const btnIcons = styled.button`
  width: 100px;
  background-color: #81c7ee;
  font-size: 12px;
  font-weight: bold;
  border: none;
  border-radius: 10px;
  margin-left: 20%;
  height: 30px;
  &:hover {
    cursor: pointer;
  }
`;
