import * as style from './styles';
import React, { useEffect, useState } from 'react';
import { RedoButton, UndoButton } from './styles';

function HistoryManageButtons(props) {
    return (
        <style.Wrap>
            <UndoButton id="undo" onClick={() => props.undo()} />
            <RedoButton id="redo" onClick={() => props.redo()} />
        </style.Wrap>
    );
}

export default HistoryManageButtons;
