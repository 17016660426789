/**********************************
 * Name : progressBar.js
 * Author : Park Jeongmyeong
 * Final_Editor : Park Jeongmyeong -> Bug fix
 * Introduction : 이미지 배경 제거 업로드 시 동작하는 로딩바
 ********************************** */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as style from 'components/ProgressBar/styles';

export default function ProgressBar(props) {
  const { t } = useTranslation();
  // Progress Bar 상태 % (퍼센트) 를 담당하는 State
  const [percent, setPercent] = useState(Math.floor(Math.random() * 16));

  // Progress Bar가 정기적으로 값이 올라가도록 설정하는 useEffect 함수 (percent 또는 props.percent가 바뀔 때마다 함수 실행)
  useEffect(() => {
    // Percent State가 100%를 넘어가지 않았을 경우,
    if (percent < 100) {
      // props에서 받아오는 값이 있다면 설정해주고
      if (props.percent) setPercent(props.percent);
      // 아니라면 설정한 시간 값 (현재 700ms) 에 따라 설정한 만큼 (현재 3%) 증가시킴 (setTimeout 함수 사용)
      else {
        setTimeout(() => {
          setPercent((value) => value + 3);
        }, 700);
      }
    }
  }, [percent, props.percent]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <style.ProgressBarTitle>{t('ProgressBar.progressBar.ProgressBarTitle')}</style.ProgressBarTitle>
      <style.BackgroundBar width={props.width}>
        <style.Progress percent={percent} />
      </style.BackgroundBar>
      {/* percent state 값을 출력하되, 100%가 넘어가면 100%로 표시해 줌. */}
      <style.ProgressBarPercent>{percent <= 100 && percent >= 0 ? percent : 100}%</style.ProgressBarPercent>
    </div>
  );
}
