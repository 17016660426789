export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;

  return {
    width,
    height,
  };
};

export const openNewWinodwTab = (url) => {
  window.open(url, '_blank');
};
