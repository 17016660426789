import styled from 'styled-components';

export const UploadWrapper = styled.div`
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  font-stretch: normal;
  font-style: normal;
  width: 100vw;
  position: fixed;
  z-index: 100;
  top: 0;

  > img {
    position: absolute;
    right: 20px;
    top: 25px;
    cursor: pointer;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
    background-color: #fff;
    border-radius: 70%;
    padding: 5px;
  }

  > h1 {
    font-size: 32px;
    font-weight: bold;
    line-height: 1.4em;
    letter-spacing: -0.8px;
    color: #222;
    margin-bottom: 18px;
  }
  > span {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.4em;
    letter-spacing: -0.4px;
    color: #767676;
    margin-bottom: 50px;

    &:first-child {
      background-color: #f54545;
      color: #ffffff;
      cursor: pointer;
      float: right;
      font-size: 20px;
      font-weight: bold;
      letter-spacing: -0.5px;
      padding: 12px 18px;
      border-radius: 10px;
      margin-top: 20px;
    }
  }
  > div {
    border-radius: 20px;
    border: solid 2px #dbdbdb;
    width: 800px;
    height: 480px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  > div > button {
    all: unset;
    cursor: pointer;
    border-radius: 10px;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.3);
    background-color: #f54545;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: -0.5px;
    color: #fff;
    padding: 18px 24px;
  }
  @media screen and (max-width: 1440px) {
    > h1 {
      font-size: 28px;
      margin-bottom: 16px;
    }
    > span {
      font-size: 14px;
      margin-bottom: 45px;
    }
    > div {
      width: 700px;
      height: 420px;
    }
  }
  @media screen and (max-width: 1024px) {
    > h1 {
      font-size: 24px;
    }
    > span {
      font-size: 12px;
      margin-bottom: 40px;
    }
    > div {
      width: 600px;
      height: 380px;
    }
    > div > button {
      font-size: 18px;
      padding: 14px 20px;
    }
  }
  @media screen and (max-width: 768px) {
    padding: 20px;
    > h1 {
      font-size: 20px;
      margin-bottom: 14px;
    }
    > span {
      font-size: 10px;
      margin-bottom: 30px;
    }
    > div {
      max-width: 600px;
      width: 100%;
      max-height: 340px;
    }
    > div > button {
      font-size: 14px;
      padding: 12px 18px;
    }
  }
`;
export const CropDivBox = styled.div`
  object-fit: contain;
  gap: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const CropDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  max-height: 320px;
  .ReactCrop__image {
    height: 100%;
    object-fit: contain;
  }
  div {
    max-height: 100%;
    display: flex;
  }
`;

export const ProductImg = styled.img`
  object-fit: contain; // 이미지가 컨테이너 내부에 맞도록 설정
  height: auto;
  width: auto;
  box-sizing: border-box; // 요소의 패딩과 테두리를 포함한 전체 너비와 높이를 계산
`;

export const qrButton = styled.button`
  all: unset;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.3);
  background-color: #f54545;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: -0.5px;
  color: #fff;
  padding: 18px 24px;
  margin-bottom: 40px;
`;

export const switchBtn = styled.label`
  position: relative;
  display: inline-block;
  width: 56px;
  height: 34px;

  > input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  > span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  .slider:before {
    position: absolute;
    content: '';
    height: 22px;
    width: 22px;
    left: 4px;
    top: 6px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  > input:checked + .slider {
    background-color: #f54545;
  }
  > input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  > span {
    border-radius: 24px;
  }
  .slider.round:before {
    border-radius: 50%;
  }
  @media screen and (max-width: 1024px) {
    width: 52px;
    height: 30px;
    .slider:before {
      position: absolute;
      content: '';
      height: 20px;
      width: 20px;
      left: 3px;
      top: 5px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  }
  @media screen and (max-width: 768px) {
    width: 48px;
    height: 26px;
    .slider:before {
      position: absolute;
      content: '';
      height: 16px;
      width: 16px;
      left: 3px;
      top: 5px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  }
`;

export const selectButton = styled.button`
  all: unset;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.3);
  background-color: #f54545;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: -0.5px;
  color: #fff;
  padding: 18px 24px;
  margin-bottom: 40px;
  margin-right: 10px;
  margin-top: 30px;
`;

export const SyncLoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const UploadingText = styled.p`
  margin-top: 40px;
  font-weight: 700;
  font-size: 18px;
  color: #aaaaaa;
`;
