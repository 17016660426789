import axios from './config';
import { tokenConfig } from './TokenConfig';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  grabCutBase64(data) {
    return axios.post('studio/remove_bg/', data, { timeout: 30000 });
  },
  svgToPng(data) {
    return axios.post('canvas/convert_svg_to_png', data);
  },
  postCanvas(data) {
    return axios.post('canvas/canvas/', data, tokenConfig());
  },
  getCanvasList(data) {
    return axios.get('canvas/canvas/', tokenConfig());
  },
  deleteCanvasImg(id) {
    return axios.delete(`canvas/delete_cp_img/${id}`, tokenConfig());
  },
  getQrImgArrive(data) {
    return axios.post('canvas/get_qr_img_arrive/', data, tokenConfig());
  },
  getEachFabrics(data) {
    return axios.post('canvas/get_fabrics/', data, tokenConfig());
  },
  saveDownloadImage(data) {
    return axios.post('studio/save_download_image/', data, tokenConfig());
  },
};
