import * as style from './styles';
import { useUpload } from 'hooks';
import { useDropzone } from 'react-dropzone';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { SyncLoader } from 'react-spinners';
import { IsImageUploadingState } from 'state/state';

/* eslint-disable max-lines-per-function, no-unused-vars */
function UploadImage() {
  const onDrop = useUpload(true);
  const { t } = useTranslation();
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple: false });

  // 이미지가 업로드중인지 아닌지 구분하여 spinner 띄우기 위한 state
  const isImageUploading = useRecoilValue(IsImageUploadingState);

  return (
    <style.Content>
      <style.Title>
        {t('home.uploadImage.Title1_1')}
        <br />
        {t('home.uploadImage.Title1_2')}
      </style.Title>
      <style.SubTitle>{t('home.uploadImage.SubTitle1')}</style.SubTitle>
      <style.UploadImgWrap {...getRootProps()}>
        <style.UploadImg>
          {isImageUploading ? (
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
              <SyncLoader color={'#f54545'} loading={true} size={20} aria-label="Loading Spinner" data-testid="loader" />
              <p>Image Uploading ...</p>
            </div>
          ) : (
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
              <h4>{t('home.uploadImage.UploadImg_h4')}</h4>
              <span>{t('home.uploadImage.UploadImg_span')}</span>
              <p>------------------ {t('home.uploadImage.Or')} ------------------</p>
              <input {...getInputProps()} />
              {isDragActive ? <span style={{ textAlign: 'center' }}>{t('home.uploadImage.FileUpload')}</span> : <button>{t('home.uploadImage.UploadImg_button')}</button>}
            </div>
          )}
        </style.UploadImg>
      </style.UploadImgWrap>
    </style.Content>
  );
}

export default UploadImage;
