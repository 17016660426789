import styled from 'styled-components';

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url('/images/Home/backgroundDesktop.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: -5vh calc(-8vh - 60px);

  > div:not(:nth-of-type(2)):not(:nth-of-type(4)) {
    padding-top: 120px;
  }

  @media screen and (max-width: 1024px) {
    gap: 90px;
  }
  @media screen and (max-width: 768px) {
    gap: 60px;
  }

  @media only screen and (max-width: 500px) {
    background: url('/images/Home/backgroundMobile.jpg');
    background-size: cover;
    background-repeat: no-repeat;
  }
`;
