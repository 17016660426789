/**********************************
 * Name : canvasHeader.js
 * Author : ChanMi Kim
 * Introduction : 캔버스에 필요한 기능들
 ********************************** */

/* eslint-disable react-hooks/rules-of-hooks */
import * as styles from './styles';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ppZoomState, zoomState } from 'state/state';
import { leftSidebarState, rightSidebarState } from 'state/sidebarState';
import icons from 'constants/icons';
import { workspaceSizeState } from 'state/canvasState';

// canvas페이지 헤더 컴포넌트 생성
function canvasHeader(props) {
  const ppZoom = useRecoilValue(ppZoomState);
  const workspace = useRecoilValue(workspaceSizeState);
  const rightState = useRecoilValue(rightSidebarState);
  const [zoom, setZoom] = useRecoilState(zoomState);
  const [leftState, setLeftState] = useRecoilState(leftSidebarState);

  const setZoomByButton = (type) => {
    const delta = type === '+' ? 0.1 : -0.1;
    const canvas = document.getElementById('canvas')?.fabric;
    canvas.zoomToPoint({ x: 0, y: 0 }, zoom + delta);
    canvas.requestRenderAll();
    setZoom(zoom + delta);
  };
  return (
    <styles.IconsDiv>
      {rightState !== 'brush' ? (
        <styles.rightIcons>
          <styles.processControl>
            <styles.zoomContainer>
              <styles.zoomBtn onClick={() => setZoomByButton('-')}>-</styles.zoomBtn>
              {zoom ? <styles.zoomStatus>{Math.round(zoom * 100) + '%'}</styles.zoomStatus> : null}
              <styles.zoomBtn onClick={() => setZoomByButton('+')}>+</styles.zoomBtn>
            </styles.zoomContainer>
          </styles.processControl>
          <styles.canvasSizeViewContainer
            onClick={() => {
              if (leftState != 'Resize') setLeftState('Resize');
              else setLeftState(null);
            }}
          >
            {workspace.width ? <p>{+workspace.width.toFixed(1)}</p> : null}
            {workspace.width ? <p>X</p> : null}
            {workspace.width ? <p>{+workspace.height.toFixed(1)}</p> : null}
          </styles.canvasSizeViewContainer>
        </styles.rightIcons>
      ) : (
        <styles.rightIcons>
          <styles.ArrowWrapper>
            <styles.simpleIcons onClick={props.clickRedo} src={icons.beforeArrow} />
            <styles.simpleIcons onClick={props.clickUndo} src={icons.afterArrow} />
          </styles.ArrowWrapper>

          <styles.processControl>
            <styles.zoomContainer>
              <styles.zoomBtn onClick={(e) => props.handleZoom(e)} value="out">
                -
              </styles.zoomBtn>
              <styles.zoomStatus>{Math.floor(ppZoom * 100) + '%'}</styles.zoomStatus>
              <styles.zoomBtn onClick={(e) => props.handleZoom(e)} value="in">
                +
              </styles.zoomBtn>
            </styles.zoomContainer>
          </styles.processControl>
        </styles.rightIcons>
      )}
    </styles.IconsDiv>
  );
}

export default canvasHeader;
