import styled from 'styled-components';

export const MainContainer = styled.div`
  width: 350px;
  height: 100%;
  padding: 18px 10px 18px 18px;
  overflow-y: scroll;
  position: absolute;
  background-color: #ffffff;

  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #fd5c5c;
    opacity: 0.75;
    border-radius: 10px;
  }

  @media screen and (max-width: 1440px) {
    max-width: 270px;
  }
  @media screen and (max-width: 1024px) {
    max-width: 250px;
  }
`;

export const Title = styled.h1`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #222;
  letter-spacing: -0.36px;
`;
export const CustomSizeBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

export const WidthHeightText = styled.h4`
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.35px;
  color: rgb(170, 170, 170);
  margin-bottom: 5px;
`;

export const AllSizeBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SubTitle = styled.h4`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 25px;
  margin-top: 30px;
  color: #222;
  letter-spacing: -0.36px;
`;

export const WidthHeightBox = styled.div`
  display: flex;
  flex-direction: row;
`;

export const WidthHeightCol = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ResizeBtnBox = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
`;
export const ResizeBtn = styled.button`
  all: unset;
  cursor: pointer;
  font-style: normal;
  letter-spacing: -0.4px;
  color: rgb(245, 69, 69);
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  margin: 0 auto;
  margin-top: 20px;
`;

export const DivLine = styled.div`
  margin: 40px 5px 0px 5px;
  border: 0.5px solid #dbdbdb;
`;

export const WidthHeightInput = styled.input`
  all: unset;
  height: 35px;
  border-radius: 10px;
  border: solid 1px #dbdbdb;
  background-color: #fff;
  padding-left: 10px;
  width: calc(100% - 10px);

  // type number 시 표시되는 화살표 제거
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const SearchBar = styled.input`
  font-size: 20px;
  all: unset;
  height: 35px;
  border-radius: 5px;
  border: solid 1px #dbdbdb;
  background-color: #fff;
  padding-left: 10px;
  width: 100%;
`;

export const SizeList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SizeRow = styled.div`
  margin: 10px 0px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
`;

export const Icon = styled.img`
  width: 35px;
  height: 35px;
  object-fit: contain;
`;

export const TextBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ShopTitle = styled.p`
  margin-left: 10px;
  width: 100%;
  display: flex;
  justify-content: start;
`;

export const SizeInfo = styled.p`
  width: 100%;
  margin-right: 10px;
  display: flex;
  justify-content: end;
  color: #dbdbdb;
  font-size: 14px;
`;

export const linkIcon = styled.img`
  width: 24px;
  height: 35px;
  align-self: flex-end;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
`;
