import * as style from './styles';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { UserLoginState } from 'state/state';
import { useInput } from 'hooks';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import auth from 'api/accountAPI';

function LoginEmail(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  // 유저 상태 체크
  const [checkLogin, setCheckLogin] = useRecoilState(UserLoginState);
  const [loginInfo, handleLoginInfo, reset] = useInput({
    email: '',
    password: '',
  });

  const startWithEmail = () => {
    setCheckLogin('loginInfo');
  };
  const ForgetPassword = () => {
    setCheckLogin('forgetPW');
  };

  // 로그인 시도 - 에러 처리 필요, 토큰 저장하는 방식 고민
  const handleSubmit = async (event) => {
    event.preventDefault();
    await auth
      .login(loginInfo)
      .then((res) => {
        props.saveUserInfo(res.data.access_token, res.data.refresh_token);
        navigate('/mypage');
      })
      .catch((e) => {
        // setLoading(false);
        Swal.fire({
          text: t('alert.loginFailedEmail'),
          customClass: {
            popup: 'custom-popup-class', // 모달 다이얼로그 스타일
          },
        });
        console.log(e);
      });
  };
  return (
    <form style={{ display: 'flex', flexDirection: 'column', position: 'relative', height: '100%' }} onSubmit={handleSubmit}>
      <style.LoginMessage>{t('login.LoginEmail.LoginMessage')}</style.LoginMessage>
      <style.LoginText>
        {t('login.LoginEmail.LoginText1_1')}
        <br />
        {t('login.LoginEmail.LoginText1_2')}
      </style.LoginText>
      <style.SocialLoginWrap>
        <style.inputBox placeholder={t('login.LoginEmail.inputBox1')} name="email" value={loginInfo.email} onChange={handleLoginInfo} type="email" required />
        <style.inputBox placeholder={t('login.LoginEmail.inputBox2')} name="password" value={loginInfo.password} onChange={handleLoginInfo} type="password" required />
      </style.SocialLoginWrap>
      <style.LoginBtn>{t('login.LoginEmail.LoginBtn')}</style.LoginBtn>
      <style.JoinUsBtn type={'button'} onClick={ForgetPassword}>
        {t('login.LoginEmail.JoinUsBtn')}
      </style.JoinUsBtn>
      <style.BackBtn src={process.env.PUBLIC_URL + '/images/Signup/back-arrow.png'} onClick={startWithEmail} />
    </form>
  );
}

export default LoginEmail;
