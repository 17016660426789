import * as style from './styles';
import { useRecoilState } from 'recoil';
import { UserLoginState } from 'state/state';
import { useTranslation } from 'react-i18next';

function FindPassword() {
  const { t } = useTranslation();
  //유저 상태 체크
  const [, setCheckLogin] = useRecoilState(UserLoginState);
  const ReturnToLogin = () => {
    setCheckLogin('loginEmail');
  };

  const ChangeUserPassword = () => {};
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <style.LoginMessage>{t('login.FindPassword.ChangePassword')}</style.LoginMessage>
      <style.LoginText>
        {t('login.FindPassword.LoginText1_1')}
        <br />
        {t('login.FindPassword.LoginText1_2')}
      </style.LoginText>
      <style.inputBoxInfo>{t('login.FindPassword.Email')}</style.inputBoxInfo>
      <style.inputBox placeholder={t('login.FindPassword.inputBox1')} type="email" required />
      <style.GrayButton marginTop={'42px'} onClick={ChangeUserPassword}>
        {t('login.FindPassword.GrayButton')}
      </style.GrayButton>
      <style.BackBtn src={process.env.PUBLIC_URL + '/images/Signup/back-arrow.png'} onClick={ReturnToLogin} />
    </div>
  );
}

export default FindPassword;
