import * as styles from './styles';
import { useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { SketchPicker } from 'react-color';
import { rightSidebarState } from 'state/sidebarState';
import { fontFamilyList, fontSizeList } from 'constants/list';
import { selectedObject, selectedTextState } from 'state/canvasState';
import ObjectControlButtonList from './common/objectControlButtonList';
import FontFaceObserver from 'fontfaceobserver';

// eslint-disable-next-line
function TextEditor() {
  const { t } = useTranslation();
  const setRightState = useSetRecoilState(rightSidebarState);
  const setSelectedIndex = useSetRecoilState(selectedObject);
  const [selectedText, setSelectedText] = useRecoilState(selectedTextState);

  // font size 목록 랜더링 여부
  const [showFontSizeList, setShowFontSizeList] = useState(false);
  // font family 목록 랜더링 여부
  const [showFontFamilyList, setShowFontFamilyList] = useState(false);
  // text color sketch picker 목록 랜더링 여부
  const [showTextColorSketchPicker, setShowTextColorSketchPicker] = useState(false);
  const [showBgColorSketchPicker, setShowBgColorSketchPicker] = useState(false);
  const [showStrokeColorSketchPicker, setShowStrokeColorSketchPicker] = useState(false);

  // text editor 랜더링 시 font를 미리 불러오기 위함
  // useEffect(() => {
  //   fontFamilyList.slice(4).forEach((font) => {
  //     new FontFaceObserver(font).load();
  //   });
  // }, []);

  // font size click 이벤트 핸들러
  const handleFontSizeListClick = () => {
    setShowFontSizeList(!showFontSizeList);
    setShowFontFamilyList(false);
  };

  // font family click 이벤트 핸들러
  const handleFontFamilyListClick = () => {
    setShowFontSizeList(false);
    setShowFontFamilyList(!showFontFamilyList);
  };

  // picker 핸들러
  const handlePickerClick = (type) => {
    if (type === 'fill') setShowTextColorSketchPicker(!showTextColorSketchPicker);
    else if (type === 'backgroundColor') setShowBgColorSketchPicker(!showBgColorSketchPicker);
    else if (type === 'stroke') setShowStrokeColorSketchPicker(!showStrokeColorSketchPicker);
    setShowFontSizeList(false);
    setShowFontFamilyList(false);
  };

  // text 수정 함수
  const updateSelectedText = (newValue) => {
    const { fabric: canvas } = document.getElementById('canvas');
    canvas.getActiveObject().set(newValue);
    canvas.requestRenderAll();
    setSelectedText({ ...selectedText, ...newValue });
  };

  // selected text 제어 핸들러
  const handleSelectedText = (e, key, newValue) => {
    if (['fill', 'backgroundColor', 'stroke'].includes(key)) updateSelectedText({ [key]: newValue ? newValue : e.hex });
    else if (['underline', 'linethrough', 'overline'].includes(key)) updateSelectedText({ [key]: !selectedText[key] });
    else if (key === 'text') updateSelectedText({ [key]: e.target.value });
    else if (key === 'textAlign') updateSelectedText({ [key]: e.target.id });
    else if (key === 'strokeWidth') updateSelectedText({ [key]: Math.min(newValue ? newValue : e.target.value, 20) });
    else if (key === 'fontFamily') updateSelectedText({ [key]: e.target.textContent });
    else if (key === 'fontStyle') updateSelectedText({ [key]: selectedText[key] === 'italic' ? 'normal' : 'italic' });
    else if (key === 'fontWeight') updateSelectedText({ [key]: selectedText[key] === e.target.id ? 'normal' : e.target.id });
    else if (key === 'fontSize') {
      const { fabric: canvas } = document.getElementById('canvas');
      const fontSize = Math.min(+e.target.textContent || +e.target.value, 1000) || 1;
      const newWidth = canvas.getActiveObject().width * (fontSize / selectedText.fontSize);
      updateSelectedText({ fontSize, width: newWidth });
    }
  };

  // 컬러 팔레트
  const colorPalette = (name, key, callback) => {
    return (
      <styles.RangeWrap>
        <h3>{name}</h3>
        <styles.ColorIconWrap>
          <styles.ColorIcon bgColor="black" width="25px" height="25px" onClick={(e) => handleSelectedText(e, key, 'black')} />
          <styles.ColorIcon bgColor="white" width="25px" height="25px" onClick={(e) => handleSelectedText(e, key, 'white')} />
          <styles.ColorIcon bgColor="red" width="25px" height="25px" onClick={(e) => handleSelectedText(e, key, 'red')} />
          <styles.ColorIcon bgColor="green" width="25px" height="25px" onClick={(e) => handleSelectedText(e, key, 'green')} />
          <styles.ColorIcon bgColor="blue" width="25px" height="25px" onClick={(e) => handleSelectedText(e, key, 'blue')} />
          <styles.ColorIcon bgSrc={process.env.PUBLIC_URL + '/images/Canvas/sidebar/colorWheel.svg'} width="27px" height="27px" onClick={() => callback.click(key)} />
        </styles.ColorIconWrap>
        {callback.element ? (
          <div>
            <SketchPicker
              width={'90%'}
              color={selectedText[key] ? selectedText[key] : ''}
              onChange={(e) => callback.change(e, key)}
              onChangeComplete={() => {
                document.getElementById('canvas').fabric.fire('object:modified');
              }}
            />
          </div>
        ) : null}
      </styles.RangeWrap>
    );
  };

  return (
    <>
      {/******************** Text 입력 부분 ********************/}
      <styles.Wrapper id={'layer'}>
        <div style={{ textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '5px 10px 15px 10px' }}>
          <h2 style={{ userSelect: 'none' }}>{t('canvas.layer.Text')}</h2>
          <img
            style={{ cursor: 'pointer', boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.1)', backgroundColor: '#fff', borderRadius: '70%', padding: '5px' }}
            src={process.env.PUBLIC_URL + '/images/Canvas/SideBar/cancleBtn.svg'}
            onClick={() => {
              setSelectedIndex('');
              setRightState('components');
            }}
            alt={'close'}
          />
        </div>
        <styles.SettingImg>
          <textarea
            id="text"
            name="text"
            rows={5}
            cols={30}
            placeholder={t('canvas.option.TextPlaceholder')}
            onChange={(e) => handleSelectedText(e, 'text')}
            // blur 이벤트 발생 시 canvas 수정 이벤트 발생
            onBlur={() => {
              document.getElementById('canvas').fabric.fire('object:modified');
            }}
            value={selectedText.text}
            style={{ border: 'none', outline: 'none', width: '100%' }}
          />
        </styles.SettingImg>
        <styles.SettingImg>
          <styles.RangeWrap>
            <h3>{t('canvas.layer.Text')}</h3>
            {/******************** Font family  부분 ********************/}
            <styles.FontFamily>
              <input readOnly onMouseDown={handleFontFamilyListClick} value={selectedText.fontFamily ? selectedText.fontFamily : 'Select Font'} />
              <button onClick={handleFontFamilyListClick}>
                <img src={process.env.PUBLIC_URL + '/images/Canvas/sidebar/fontSizeBtn.svg'} alt="fontSizeBtn" />
              </button>
              {showFontFamilyList && (
                <div>
                  {fontFamilyList.map((item, index) => (
                    <ul
                      key={index}
                      className="fontFamily"
                      onMouseOver={(e) => handleSelectedText(e, 'fontFamily', item)}
                      onClick={() => {
                        handleFontFamilyListClick();
                        document.getElementById('canvas').fabric.fire('object:modified');
                      }}
                    >
                      {item}
                    </ul>
                  ))}
                </div>
              )}
            </styles.FontFamily>
          </styles.RangeWrap>

          {/******************** Text Size 변경 부분 ********************/}
          <styles.RangeWrap>
            <styles.FontSize>
              <input id="fontSize" name="fontSize" type={'number'} value={selectedText.fontSize || 0} onChange={(e) => handleSelectedText(e, 'fontSize')} />
              <button onClick={handleFontSizeListClick}>
                <img src={process.env.PUBLIC_URL + '/images/Canvas/sidebar/fontSizeBtn.svg'} alt="fontSizeBtn" />
              </button>
              {showFontSizeList && (
                <div>
                  {fontSizeList.map((size, index) => (
                    <ul
                      key={index}
                      onClick={(e) => {
                        handleSelectedText(e, 'fontSize');
                        document.getElementById('canvas').fabric.fire('object:modified');
                      }}
                    >
                      {size}
                    </ul>
                  ))}
                </div>
              )}
            </styles.FontSize>
            <br />
          </styles.RangeWrap>

          {/******************** Color 변경 부분 ********************/}
          {colorPalette(t('canvas.option.Color'), 'fill', { change: handleSelectedText, click: handlePickerClick, element: showTextColorSketchPicker })}
          {colorPalette(t('canvas.option.BackgroundColor'), 'backgroundColor', { change: handleSelectedText, click: handlePickerClick, element: showBgColorSketchPicker })}
          {colorPalette(t('canvas.option.StrokeColor'), 'stroke', { change: handleSelectedText, click: handlePickerClick, element: showStrokeColorSketchPicker })}

          {/******************** Storke Width 변경 부분 ********************/}
          <styles.RangeWrap>
            <h3>{t('canvas.option.StrokeWidth')}</h3>
            <styles.HSVSlider
              max="20"
              value={selectedText.strokeWidth ? selectedText.strokeWidth : 0}
              onChange={(e, newValue) => handleSelectedText(e, 'strokeWidth', newValue)}
              onDragEnd={() => {
                document.getElementById('canvas').fabric.fire('object:modified');
              }}
              valueLabelDisplay="auto"
            />
            <styles.ValueInput type={'number'} value={selectedText.strokeWidth || 0} onChange={(e) => handleSelectedText(e, 'strokeWidth')} />
          </styles.RangeWrap>
        </styles.SettingImg>

        {/******************** Text Align 변경 부분 ********************/}
        <styles.BtnWrap>
          <styles.TextAlign>
            <styles.AlignOption
              id="left"
              name="textAlign"
              onClick={(e) => {
                handleSelectedText(e, 'textAlign');
                document.getElementById('canvas').fabric.fire('object:modified');
              }}
              src={process.env.PUBLIC_URL + '/images/Canvas/SideBar/textLeft.svg'}
            />
            <styles.AlignOption
              id="center"
              name="textAlign"
              onClick={(e) => {
                handleSelectedText(e, 'textAlign');
                document.getElementById('canvas').fabric.fire('object:modified');
              }}
              src={process.env.PUBLIC_URL + '/images/Canvas/SideBar/textCenter.svg'}
            />
            <styles.AlignOption
              id="right"
              name="textAlign"
              onClick={(e) => {
                handleSelectedText(e, 'textAlign');
                document.getElementById('canvas').fabric.fire('object:modified');
              }}
              src={process.env.PUBLIC_URL + '/images/Canvas/SideBar/textRight.svg'}
            />
            <styles.AlignOption
              id="justify"
              name="textAlign"
              onClick={(e) => {
                handleSelectedText(e, 'textAlign');
                document.getElementById('canvas').fabric.fire('object:modified');
              }}
              src={process.env.PUBLIC_URL + '/images/Canvas/SideBar/textJustify.svg'}
            />
          </styles.TextAlign>
        </styles.BtnWrap>

        {/******************** Text Option 변경 부분 ********************/}
        <styles.SettingText>
          <styles.SettingOption
            id="indent"
            name="tab"
            onClick={() => {
              const paragraphList = selectedText.text.split(/(?<!\/)n/);
              paragraphList[0] = '\t' + paragraphList[0];

              setSelectedText({ ...selectedText, text: paragraphList.join('') });
            }}
            src={process.env.PUBLIC_URL + '/images/Canvas/SideBar/textIndentLeft.svg'}
          />
          <styles.SettingOption
            id="outdent"
            name="tab"
            onClick={() => {
              const paragraphList = selectedText.text.split(/(?<!\/)n/);
              if (paragraphList[0].startsWith('\t')) {
                paragraphList[0] = paragraphList[0].slice(1);
              }

              setSelectedText({ ...selectedText, text: paragraphList.join('') });
            }}
            src={process.env.PUBLIC_URL + '/images/Canvas/SideBar/textIndentRight.svg'}
          />
          <styles.SettingOption
            id="italic"
            name="fontStyle"
            onClick={(e) => {
              handleSelectedText(e, 'fontStyle');
              document.getElementById('canvas').fabric.fire('object:modified');
            }}
            src={process.env.PUBLIC_URL + '/images/Canvas/SideBar/textItalic.svg'}
          />
          <styles.SettingOption
            id="bold"
            name="fontWeight"
            onClick={(e) => {
              handleSelectedText(e, 'fontWeight');
              document.getElementById('canvas').fabric.fire('object:modified');
            }}
            src={process.env.PUBLIC_URL + '/images/Canvas/SideBar/textBold.svg'}
          />
          <styles.SettingOption
            name="underline"
            onClick={(e) => {
              handleSelectedText(e, 'underline');
              document.getElementById('canvas').fabric.fire('object:modified');
            }}
            src={process.env.PUBLIC_URL + '/images/Canvas/SideBar/textUnderline.svg'}
          />
          <styles.SettingOption
            name="linethrough"
            onClick={(e) => {
              handleSelectedText(e, 'linethrough');
              document.getElementById('canvas').fabric.fire('object:modified');
            }}
            src={process.env.PUBLIC_URL + '/images/Canvas/SideBar/textStrikethrough.svg'}
          />
        </styles.SettingText>

        {/* 앞으로 가기, 뒤로 가기, 뒤집기, 복사, 삭제 */}
        <ObjectControlButtonList type={'textbox'} />
      </styles.Wrapper>
    </>
  );
}

export default TextEditor;
