/**********************************
 * Name : ppEdit.js
 * Author : 강승원
 * Introduction : pp이미지 누끼 수정 기능
 ********************************** */
import React, { useEffect, useState, useRef } from 'react';
import { CheckImg, Circle, EditMain, PpEditWrapper } from './styles';
import { useRecoilState } from 'recoil';
import { brushState, ppZoomState, ProductCropState } from 'state/state';
import { rightSidebarState } from 'state/sidebarState';
import { selectedImageState } from 'state/imageState';
import { escapeRestoreState, svgConverterTriggerState } from 'state/triggerState';
import axios from 'axios';
import { colorPickerWidthState, shadowHandleState, selectedObject, objectList, canvasLoadingState } from 'state/canvasState';
import { shapeTypeList } from 'constants/list';

// { redo, undo, ppSize, setSvgPP, rightSidebarState, clickCombinePic, useTemplate} = props
// eslint-disable-next-line
function PpEdit(props) {
  const [brushStatus, setBrushStatus] = useRecoilState(brushState);
  // allowSketching -> 마우스 이동할때 드로잉이 허용되는지
  const [allowSketching, setAllowSketching] = useState(null);
  // stroke -> 마우스 클릭하며 이동할때, 이동 경로
  const [stroke, setStroke] = useState([]);
  // undo/redo를 위해 stacking 하는 state
  const [shapeStack, setShapeStack] = useState([]);
  // shapeList - stroke의 집합
  const [shapeList, setShapeList] = useState([]);
  const [ppZoom, setPpZoom] = useRecoilState(ppZoomState);
  const [rightState, setRightState] = useRecoilState(rightSidebarState);
  const [trigger, setTrigger] = useRecoilState(svgConverterTriggerState);
  const [selectedImage, setSelectedImage] = useRecoilState(selectedImageState);
  const [imageInfoArray, setImageInfoArray] = useState([]);
  const [escapeRestore, setEscapeRestore] = useRecoilState(escapeRestoreState);
  const [beforeRestoreImageInfoArray, setBeforeRestoreImageInfoArray] = useState('');
  const [shadowHandler, setShadowHandler] = useRecoilState(shadowHandleState);

  const [mainCanvas, setMainCanvas] = useState(null);

  const [target, setTarget] = useState(null);
  const svgRef = useRef();
  const [isPanning, setIsPanning] = useState(false);
  const [startPan, setStartPan] = useState({ x: 0, y: 0 });
  const [panOffset, setPanOffset] = useState({ x: 0, y: 0 });
  const [viewBox, setViewBox] = useState('0 0 0 0');
  const [src, setSrc] = useState('');
  const [originSrc, setOriginSrc] = useState('');
  const [isLoading, setIsLoading] = useRecoilState(canvasLoadingState);

  // eslint-disable-next-line
  useEffect(() => {
    const mainCanvas = document.getElementById('canvas').fabric;
    const target = mainCanvas.getActiveObject();
    const targetSrc = target.src;
    const targetOriginSrc = target.originSrc ? target.originSrc : targetSrc;
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
    if (!target.originSrc) {
      target.set({ originSrc: targetSrc });
    }
    setPpZoom(1);
    setMainCanvas(mainCanvas);
    // 만약 target의 src가 https로 시작한다면, base64로 변환
    if (targetSrc.startsWith('https')) {
      convertS3ImageUrlToBase64(targetSrc, (base64) => {
        setSrc(base64);
      });
    } else {
      setSrc(targetSrc);
    }

    if (targetOriginSrc.startsWith('https')) {
      convertS3ImageUrlToBase64(targetOriginSrc, (base64) => {
        setOriginSrc(base64);
      });
    } else {
      setOriginSrc(targetOriginSrc);
    }
    setTarget(target);
    setViewBox(`0 0 ${target.width} ${target.height}`);
    console.log('target', target);
  }, []);

  useEffect(() => {
    clickRedo();
  }, [props.redo]);

  useEffect(() => {
    clickUndo();
  }, [props.undo]);

  const convertS3ImageUrlToBase64 = (url, callback) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous'; // CORS 정책에 따라 필요할 수 있음
    img.onload = () => {
      // 이미지가 로드되면 캔버스에 그리고 Base64로 변환
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);

      // 캔버스 내용을 Base64로 변환 (PNG 형식)
      const dataURL = canvas.toDataURL('image/png');
      callback(dataURL); // 콜백 함수로 Base64 데이터 전달
    };

    img.onerror = () => {
      // 이미지 로딩 실패 처리
      console.error('이미지를 로드할 수 없습니다.');
      callback(null); // 실패했음을 나타내기 위해 null을 콜백으로 전달
    };

    img.src = url; // 이미지 로드 시작
  };
  const clickRedo = () => {
    if (shapeList.length !== 0) {
      let last_shape = shapeList.pop();
      setShapeList([...shapeList]);
      setShapeStack([...shapeStack, last_shape]);
    }
  };
  const clickUndo = () => {
    if (shapeStack.length !== 0) {
      let last_shape = shapeStack.pop();
      setShapeList([...shapeList, last_shape]);
      setShapeStack([...shapeStack]);
    }
  };

  // 마우스 휠 이벤트 핸들러
  const handleWheel = (e) => {
    e.preventDefault(); // 기본 스크롤 동작 방지
    const zoomFactor = 0.1; // 줌 인/아웃 비율
    if (e.deltaY < 0) {
      // 휠을 위로 스크롤 (줌 인)
      setPpZoom(ppZoom * (1 + zoomFactor));
    } else {
      // 휠을 아래로 스크롤 (줌 아웃)
      setPpZoom(ppZoom * (1 - zoomFactor));
    }
  };

  const handleMouseDown = (e) => {
    if (e.shiftKey) {
      setIsPanning(true);
      setStartPan({ x: e.clientX - panOffset.x, y: e.clientY - panOffset.y });
    }
  };

  const handleMouseMove = (e) => {
    if (isPanning) {
      const newX = e.clientX - startPan.x;
      const newY = e.clientY - startPan.y;
      setPanOffset({ x: newX, y: newY });
    }
  };

  const handleMouseUp = () => {
    setIsPanning(false);
  };

  useEffect(() => {
    const svgElement = svgRef.current;
    if (svgElement) {
      svgElement.addEventListener('wheel', handleWheel, { passive: false });
      svgElement.addEventListener('mousedown', handleMouseDown);
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
      console.log('addEventListener');
    }

    return () => {
      if (svgElement) {
        svgElement.removeEventListener('wheel', handleWheel);
        svgElement.removeEventListener('mousedown', handleMouseDown);
        window.removeEventListener('mousemove', handleMouseMove);
        window.removeEventListener('mouseup', handleMouseUp);
        console.log('removeEventListener');
      }
    };
  }, [ppZoom, isPanning, startPan, panOffset, target]);

  // 패닝과 줌을 위한 스타일
  const transformStyle = {
    transform: `translate(${panOffset.x}px, ${panOffset.y}px) scale(${ppZoom})`,
    transformOrigin: 'center center',
    cursor: 'none',
  };

  const addShapeToImage = (imageID, shape) => {
    // 이미지 정보 객체를 찾습니다.
    console.log('imageInfoArray', imageInfoArray);
    const imageInfoIndex = imageInfoArray.findIndex((image) => image.id === imageID);

    if (imageInfoIndex !== -1) {
      // 해당 이미지의 shapeList에 수정 내역을 추가합니다.
      const updatedImageInfoArray = [...imageInfoArray];
      updatedImageInfoArray[imageInfoIndex].shapeList.push(shape);

      const sl = updatedImageInfoArray[imageInfoIndex].shapeList;
      console.log('sl', sl);
      setImageInfoArray(updatedImageInfoArray);
      setShapeList(sl);
    } else {
      const imageInfo = {
        id: selectedImage.id, // 이미지 고유 ID
        shapeList: [], // 해당 이미지의 수정 내역
        shapeStack: [], // 해당 이미지의 undo 및 redo 스택
        // 기타 이미지 정보 필드...
      };
      const updatedImageInfoArray = [...imageInfoArray];
      imageInfo.shapeList.push(shape);
      updatedImageInfoArray.push(imageInfo);
      setImageInfoArray(updatedImageInfoArray);
      setShapeList([shape]);
    }
  };

  useEffect(() => {
    if (rightState === 'brush') {
      const imageInfoIndex = imageInfoArray.findIndex((image) => image.id === selectedImage.id);
      if (imageInfoIndex !== -1) {
        // brush 시작. 이미 수정했었던 객체

        // 수정 내역 가져오기 및 적용하기
        const sl = imageInfoArray[imageInfoIndex].shapeList;
        setShapeList(sl);

        // 수정전 초기 상태 저장하기. 나중에 casncel로 탈출을 할 때 적용하기 위함
        const initilState = JSON.parse(JSON.stringify(imageInfoArray));
        setBeforeRestoreImageInfoArray(initilState);
      } else {
        // brush 시작. 처음 수정하는 객체
        setBeforeRestoreImageInfoArray(imageInfoArray.slice());
        setShapeList([]);
      }
    } else if (shapeTypeList.includes(rightState)) {
      // setColorPickerWidth(document.getElementById('rightSideBar')?.firstChild.firstChild.childNodes[1].offsetWidth * 0.7);
    }
  }, [rightState]);

  // 현재 마우스 포인터 위치 가져오는 함수
  const getPointerPosition = (evt) => {
    let CTM = evt.target.getScreenCTM();
    return [(evt.clientX - CTM.e) / CTM.a, (evt.clientY - CTM.f) / CTM.d];
  };

  // 포인터 위치를 <polyline>(태그)에 적용하기 위해 변환
  const pointsToPath = (points) => {
    let d = '';
    points.forEach((point) => {
      d += point[0] + ',' + point[1] + ' ';
    });
    return d;
  };

  // 마우스 클릭 시 발생하는 이벤트
  const handlePointerDown = (e) => {
    if (brushStatus.type === null || e.shiftKey) return;
    e.target.setPointerCapture(e.pointerId);
    setAllowSketching(true); // 드로잉 허용
    setStroke([]); // setStroke는 새로운 이동 경로 추가
  };

  // 마우스 클릭 해제 시 발생하는 이벤트
  const handlePointerUp = (e) => {
    // 이동 경로가 없을시에는 shapes에 추가 안함
    // console.log('??', selectedImage);

    if (stroke.length) {
      addShapeToImage(selectedImage.id, { shape: stroke, size: brushStatus.size, type: brushStatus.type });
      //console.log('shapeList', shapeList);

      // setShapeList(sl);
      // setShapeList([...shapeList, { shape: stroke, size: brushStatus.size, type: brushStatus.type }]);
    }
    // if (stroke.length) {
    //   console.log('selectedImage.id', selectedImage);
    //   setShapeList([...shapeList, { shape: stroke, size: brushStatus.size, type: brushStatus.type }]);
    // }
    // 드로잉 해제
    setAllowSketching(false);
    setStroke([]);
  };

  // 마우스 이동시 발생하는 이벤트
  const handlePointerMove = (e) => {
    const cursorSize = brushStatus.size;
    let cursor = document.querySelector('.cursor');
    cursor.style.left = e.pageX - cursorSize / 2 + 'px';
    cursor.style.top = e.pageY - cursorSize / 2 + 'px';
    // 드로잉이 허용될때만
    if (allowSketching) {
      // 포인터 좌표값을 가져와서 이동 경로에 저장
      const p = getPointerPosition(e);
      setStroke([...stroke, p]);
    }
  };

  // 마우스가 벗어나거나 진입할 시 발생하는 이벤트
  const handlePointerOver = (e) => {
    const cursor = document.querySelector('.cursor');
    if (e.type === 'mouseleave') {
      cursor.style.visibility = 'hidden';
    } else if (e.type === 'mouseenter') {
      cursor.style.visibility = 'visible';
    }
  };

  const deleteShadow = async () => {
    const canvas = document.getElementById('canvas').fabric;
    const object = canvas.getActiveObject();
    const shadow = canvas.getObjects().find((obj) => obj?.parentId === object.id);
    if (shadow) canvas.remove(shadow);
  };

  // eslint-disable-next-line
  const convertSVGString = async () => {
    // document.getElementById('canvas').fabric.deleteHistoryEventListener();
    const svgData = document.getElementById('pp').outerHTML;
    const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
    // const svgUrl = URL.createObjectURL(svgBlob);
    //URL Object에서 Base64로 변경
    const fileReader = new FileReader();
    fileReader.readAsDataURL(svgBlob);
    fileReader.onloadend = () => {
      props.setSvgPP((state) => ({ ...state, src: fileReader.result, status: 'update', shapeList: [...shapeList], shapeStack: [...shapeStack] }));

      // 현재 활성화 되있는 객체를 직접 찾아서 src등을 바로 바꿔줌
      const canvas = document.getElementById('canvas').fabric;
      const curr = canvas.getActiveObject();

      curr.set({ src: fileReader.result });
      curr.setSrc(fileReader.result, (obj) => {
        canvas.renderAll();
      });

      // complete 누르면 깜빡이는 것 때문에 timeout 설정
      setTimeout(() => {
        setSelectedImage((prevSelectedImage) => ({
          ...prevSelectedImage,
          src: fileReader.result,
        }));

        // document.getElementById('canvas').fabric.addHistoryEventListener();
        document.getElementById('canvas').fabric.fire('object:modified');
      }, 1100);
    };
  };

  useEffect(() => {
    if (trigger === true) {
      console.log('작업 완료');
      setPanOffset({ x: 0, y: 0 });
      setPpZoom(1);
      setIsLoading(true);
      setTimeout(() => {
        deleteShadow();
        convertSVGString();
        setRightState('image');
        setTrigger(false);
      }, 1000);
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
  }, [trigger]);

  useEffect(() => {
    if (escapeRestore === true) {
      setRightState('image');
      setTrigger(false);
      setEscapeRestore(false);
      setImageInfoArray(beforeRestoreImageInfoArray);
      setBeforeRestoreImageInfoArray([]);

      const imageInfoIndex = imageInfoArray.findIndex((image) => image.id === selectedImage.id);
      if (beforeRestoreImageInfoArray.length === 0) {
        setBeforeRestoreImageInfoArray([]);
        setShapeList([]);
      } else if (imageInfoIndex !== -1) {
        // cancel로 탈출하기. 이미 수정을 했었었던 이미지일 때
        const sl = imageInfoArray[imageInfoIndex].shapeList.slice();
        setShapeList(beforeRestoreImageInfoArray[imageInfoIndex].shapeList?.slice());
      } else {
        // cancel로 탈출하기. 처음 수정을 시도한 이미지일 때
        setBeforeRestoreImageInfoArray([]);
        setShapeList([]);
      }
    }
  }, [escapeRestore]);

  useEffect(() => {
    if ('shapeList' in selectedImage) {
      setShapeList([...selectedImage.shapeList]);
    }
    if ('shapeStack' in selectedImage) {
      setShapeStack([...selectedImage.shapeStack]);
    }
    // console.log(selectedImage.maskImage);
  }, [selectedImage.maskImage]);

  return (
    <div style={{ touchAction: 'none', width: '100%', height: '100%' }} id={'newDiv'}>
      {target && (
        <CheckImg>
          <svg
            id="pp"
            height={target.height}
            width={target.width}
            xmlns="http://www.w3.org/2000/svg"
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerMove={handlePointerMove}
            onMouseEnter={handlePointerOver}
            onMouseLeave={handlePointerOver}
            style={transformStyle}
            ref={svgRef}
            viewBox={viewBox}
          >
            <g>
              <g></g>
            </g>
            <g mask="url(#SvgjsMask1)">
              {/* 1masked.png -> 누끼 딴 이미지 */}
              <image height={target.height} width={target.width} href={src} crossOrigin="anonymous" />
            </g>
            {/* 1origin.png -> 원본 이미지 */}
            <image height={target.height} width={target.width} href={originSrc} mask="url(#SvgjsMask3)" crossOrigin="anonymous" />
            <defs>
              <mask id="SvgjsMask1">
                <rect height={target.height} width={target.width} fill="#ffffff"></rect>
                <g id="SvgjsG2">
                  {shapeList.map((item, idx) => {
                    // To Do
                    // 이 부분 strokeWidth에 props 브러쉬 값을 넣어주면 기존 값들도 모두 변경되어 버립니다..
                    // 아마도 props.brushStatus.size의 값이 바뀔때마다 Shape나 Stroke에 props의 값도 저장해줘야 할 것 같습니다..
                    return (
                      <polyline
                        key={idx}
                        points={pointsToPath(item.shape)}
                        strokeWidth={item.size}
                        stroke={item.type === 'eraser' ? '#000000' : '#ffffff'}
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    );
                  })}
                  <polyline
                    key="current_stroke"
                    id="current_stroke"
                    points={pointsToPath(stroke)}
                    strokeWidth={brushStatus.size}
                    stroke={brushStatus.type === 'eraser' ? '#000000' : '#ffffff'}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
              </mask>
              <mask id="SvgjsMask3">
                <rect width={target.width} height={target.height} fill="#000000"></rect>
                <use href="#SvgjsG2"></use>
              </mask>
            </defs>
          </svg>
        </CheckImg>
      )}
      <Circle className="cursor" size={brushStatus.size} zoom={ppZoom}></Circle>
    </div>
  );
}

export default PpEdit;
