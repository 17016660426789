import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: #f8f8f8;
  display: flex;
  height: 100vh;
  @media screen and (max-width: 800px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 24px;
  }
`;
export const EditImageWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    max-height: 70vh;
    max-width: 800px;
    height: 100%;
    width: 100%;
  }
  @media screen and (max-width: 800px) {
    max-height: 50vh;
    max-width: 80vw;
  }
`;
export const CropDivBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
`;
export const CropDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${(props) => (props.srcRatio > 1 ? 'width: 100%; object-fit: contain; height:auto;' : 'height: 100%; width: auto; object-fit: contain;')};

  .ReactCrop__image {
    height: 100%;
    object-fit: contain;
  }
  div {
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;
export const RightSide = styled.div`
  background-color: #f9fafb;
  max-width: 400px;
  width: 100%;
  position: relative;
  padding: 24px 18px 0;

  @media screen and (max-width: 800px) {
    max-width: unset;
    padding: 20px 0 0;
  }
`;

export const InfoWrap = styled.div`
  font-stretch: normal;
  font-style: normal;
  display: flex;
  flex-direction: column;

  > div {
    font-size: 24px;
    font-weight: bold;
    letter-spacing: -0.36px;
    color: #222;
    margin-bottom: 24px;
  }

  > span {
    font-size: 18px;
    font-weight: 500;
    color: #222;
    margin-bottom: 6px;
  }

  > p {
    font-size: 14px;
    font-weight: normal;
    letter-spacing: -0.35px;
    color: #aaa;
    margin-bottom: 18px;
  }

  > button {
    all: unset;
    height: 44px;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    letter-spacing: -0.35px;
    cursor: pointer;
    border-radius: 6px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.04);
    border: ${(props) => (props.aFewProduct ? 'solid 1px #f54545;' : 'solid 1px #ededed;')};
    background-color: ${(props) => (props.aFewProduct ? '#ffffff' : '#f2f4f4')};
    color: ${(props) => (props.aFewProduct ? '#f54545' : '#aaa')};
    transition: all 0.5s ease 0s;
  }

  @media screen and (max-width: 800px) {
    margin-bottom: 50px;
  }
`;

export const Buttons = styled.div`
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.4px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: calc(100% - 36px);
  height: 52px;
  border-radius: 6px;
  cursor: pointer;
  border: ${(props) => (props.color ? '1px solid' + props.color : null)};
  color: ${(props) => props.color};
  bottom: ${(props) => props.bottom};
  background-color: ${(props) => props.backgroundColor};
  transition: all 0.5s ease;

  @media screen and (max-width: 800px) {
    max-width: unset;
    width: 100%;
    position: relative;
    bottom: unset;
    margin-bottom: ${(props) => (props.margin ? props.margin : null)};
  }

  &:hover {
    box-shadow: ${(props) => (props.boxShadow ? props.boxShadow : null)};
    text-decoration: ${(props) => (props.textDeco ? props.textDeco : null)};
  }
`;

export const ProgressBarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  background: rgb(255, 255, 255, 0.9);
  > img {
    width: 150px;
  }
`;

export const ProductImg = styled.img`
  object-fit: contain; // 이미지가 컨테이너 내부에 맞도록 설정
  max-height: 100%; // 최대 높이 제한
  max-width: 100%; // 최대 너비 제한
  border: 2.5px solid #d9d9d9; // 스트로크 스타일
  box-sizing: border-box; // 요소의 패딩과 테두리를 포함한 전체 너비와 높이를 계산
`;
