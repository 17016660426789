import axios from './config';
import { tokenConfig } from './TokenConfig';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  signUp(data) {
    return axios.post('dj-rest-auth/registration/', data);
  },
  login(data) {
    return axios.post('dj-rest-auth/login/', data);
  },
  logout(data) {
    return axios.post('dj-rest-auth/logout/', data, tokenConfig());
  },
  kakaoAuthenticate(data) {
    return axios.post('accounts/kakao/authenticate/', data);
  },
  googleAuthenticate(data) {
    return axios.post('accounts/google/authenticate/', data);
  },
  getUser() {
    return axios.get('dj-rest-auth/user/', tokenConfig());
  },
  tokenpost(data) {
    return axios.post('accounts/google/refreshtoken/', data);
  },
};
