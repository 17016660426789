import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 30px;
`;

export const UploadImg = styled.div`
  height: 370px;
  border-radius: 10px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.04);
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  > div {
    margin: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    justify-content: center;
    width: 100%;
    border-radius: 10px;
    border: 5px dashed #f2f2f2;
  }

  > div > h4 {
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 12px;
  }
  > div > span {
    font-weight: 700;
    font-size: 14px;
    color: #bcbcbc;
    margin-bottom: 60px;
  }
  > div > p {
    font-weight: 700;
    font-size: 18px;
    color: #aaaaaa;
    margin-bottom: 45px;
  }
  > div > button {
    all: unset;
    background: #fd5c5c;
    border-radius: 5px;
    font-weight: 600;
    font-size: 18px;
    color: #ffffff;
    padding: 10px 18px;
    cursor: pointer;
  }
  @media screen and (max-width: 1024px) {
    height: 340px;
    > div > h4 {
      font-size: 20px;
    }
    > div > span {
      font-size: 12px;
      margin-bottom: 60px;
    }
    > div > p {
      font-size: 16px;
      margin-bottom: 36px;
    }
    > div > button {
      font-size: 14px;
      padding: 10px 18px;
    }
  }
  @media screen and (max-width: 768px) {
    height: 280px;

    > div > h4 {
      font-size: 18px;
    }
    > div > span {
      font-size: 10px;
      margin-bottom: 60px;
    }
    > div > p {
      font-size: 14px;
      margin-bottom: 28px;
    }
    > div > button {
      font-size: 12px;
      padding: 10px 18px;
    }
  }
`;

export const ImgType = styled.div`
  > h6 {
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.5px;
    color: #394050;
    margin-bottom: 26px;
  }
  > div {
    margin-bottom: 40px;
    display: flex;
    gap: 14px;
    overflow-x: auto;
    overflow-y: hidden; /* 세로 스크롤바 감추기 */
  }
  & .SNS {
    overflow-x: auto;
  }
`;

export const ImgTypeColor = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  > img {
    width: 210px;
    height: 210px;
    border-radius: 4px;
    object-fit: cover;
    background: ${(props) => props.background};
  }
  > span {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.4px;
    color: #394050;
  }
  .checkers {
    background-color: #ffffff;
    background-size: 60px 60px;
    background-position:
      0 0,
      30px 30px;
  }
`;

export const SocialType = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  border-radius: 4px;
  border: solid 1px #ededed;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
`;
export const MinimalType = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: ${(props) => props.bgColor};
`;

export const CanvasTop = styled.div`
  height: 74px;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.6px;
  color: #222;
  padding: 0 30px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dbdbdb;
`;

export const CanvasType = styled.div`
  display: flex;
  height: calc(100% - 74px);
  flex-direction: column;
  gap: 14px;

  &:first-child {
    margin-bottom: 40px;
  }

  > span {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.35px;
    color: #767676;
  }

  > div > span {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.35px;
    color: #767676;
  }
  > div {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    gap: 20px;
  }
`;

export const Folder = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
  padding: 18px 16px;
  border-radius: 6px;
  border: 1px solid #dbdbdb;
  min-width: 280px;
  cursor: pointer;
`;
export const NewProductButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1.5px solid #d9d9d9;
  border-radius: 20px;
  background-color: #ffffff;
  box-sizing: border-box;
  user-select: none;
  overflow: hidden;
  cursor: pointer;
  max-width: 100%;
  max-height: 100%;
  height: 250px;
  aspect-ratio: 1 / 1;
  font-size: 50px;
  color: lightgray;

  :hover {
    background-color: #d9d9d9;
    border-color: #898989;
    color: darkgray;
  }

  @media screen and (max-width: 1440px) {
    height: 220px;
  }
  @media screen and (max-width: 768px) {
    height: 200px;
  }
`;

export const Canvases = styled.div`
  border: 1.5px solid #d9d9d9;
  border-radius: 20px;
  background-color: #ffffff;
  box-sizing: border-box;
  user-select: none;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    background-color: #d9d9d9;
    border-color: #898989;
  }
  :hover img {
    filter: brightness(70%);
  }
  :hover button {
    display: block;
  }

  > div {
    display: flex;
    height: 250px;
    aspect-ratio: 1 / 1;
    position: relative;

    > img {
      max-width: 100%;
      max-height: 100%;
      height: auto;
      width: auto;
    }

    > button {
      position: absolute;
      top: 10px;
      right: 10px;
      display: none;
      color: #fff;
      border: 1px solid #dbdbdb;
      border-radius: 8px;
      background-color: #fd5c5c;
      padding: 8px 14px;
      cursor: pointer;

      &:hover {
        filter: brightness(90%);
      }
    }
  }
  @media screen and (max-width: 1440px) {
    > div {
      height: 220px;
    }
  }
  @media screen and (max-width: 768px) {
    > div {
      height: 200px;
    }
  }
`;

export const canvasImage = styled.figure`
  height: 0;
  padding-bottom: 60%; // 빈 공간에 하단 패딩 주어서 공간 확보
  background-repeat: no-repeat; // 이미지 반복 제거
  background-position: center; // 이미지 가운데 정렬
  background-size: cover; // 이미지가 꽉 차게
  @media (min-width: 600px) {
    width: 50%; // 카드 2단 배치
    padding: 0 1rem; // 카드 사이 간격
  }
  @media (min-width: 1200px) {
    width: 33.33333%; // 카드 3단 배치
  }
`;
export const canvasName = styled.div`
  flex: 1 1 auto; // 자식 요소에게 플랙스 그로우 1을 적용
  padding: 1em;
  background: white;
`;
export const canvasCardList = styled.ul`
  @media (min-width: 600px) {
    display: flex;
    flex-wrap: wrap; // 넘치는 카드 아래로 넘기기
    margin: 0 -1rem; // 카드 바깥 쪽 공간 없애기
  }
`;

export const canvasCard = styled.li`
  display: flex; // 부모요소에 플랙스 적용, 가로정렬 됨
  flex-direction: column; // 방향을 세로로 바꾸고
  margin-bottom: 2rem;
`;

export const AssetIcon = styled.img`
  width: 24px;
  height: 22px;
`;

export const SidebarItem = styled.div`
  img {
    filter: ${(props) =>
      props.isActive
        ? 'invert(41%) sepia(65%) saturate(4028%) hue-rotate(337deg) brightness(115%) contrast(116%)'
        : 'invert(0%) sepia(100%) saturate(22%) hue-rotate(7deg) brightness(102%) contrast(105%)'};
  }

  span {
    color: ${(props) => (props.isActive ? 'red' : 'black')};
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.35px;
  }
`;
