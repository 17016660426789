import * as styles from 'components/ChoiceDesign/styles';
import { useTranslation } from 'react-i18next';
import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { constSelector, useRecoilState } from 'recoil';
import { bgImageState, productImageState } from 'state/imageState';
import studioAPI from 'api/studioAPI';
import ReactGA from 'react-ga4';
import { fabric } from 'fabric';
import canvasAPI from 'api/canvasAPI';
import Swal from 'sweetalert2';
import { authState } from 'state';
import accountAPI from 'api/accountAPI';
import { DownloadImageListState } from 'state/state';

export default function ChoiceCanvas({ canvasObj, canvasList, setCanvasList }) {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [obj, setObj] = useState(canvasObj);
  const [user, setUser] = useRecoilState(authState);
  const [downloadImageList, setDownloadImageList] = useRecoilState(DownloadImageListState);

  useEffect(() => {
    canvasAPI
      .getEachFabrics({ id: canvasObj.id })
      .then((res) => {
        setObj((prev) => ({ ...prev, fabrics: res.data.fabrics }));
      })
      .catch((error) => {
        console.log(error);
      });
  }, [canvasObj]);

  // buttons
  const editButtonClick = () => {
    ReactGA.event({
      category: 'User',
      action: 'edit',
      label: 'edit image from choice',
    });
    navigate('/canvas', { state: { canvas: obj } });
  };

  const downloadButtonClick = async () => {
    ReactGA.event({
      category: 'User',
      action: 'download',
      label: 'download from choice',
    });
    let a = document.createElement('a');
    const img = new Image();

    img.crossOrigin = 'Anonymous';
    img.src = obj.origin_img;
    img.onload = () => {
      const canvas = document.createElement('canvas');

      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext('2d');

      ctx.drawImage(img, 0, 0, img.width, img.height);

      a.href = canvas.toDataURL();

      // 로그인 상태에서만 다운로드 목록 관리
      if (user?.isLoggedIn) {
        if (user?.canvas_sum < user?.tier.canvas_sum) {
          const data = { upload_img: a.href, upload_img_name: 'messi' };
          canvasAPI
            .saveDownloadImage(data)
            .then((res) => {
              accountAPI
                .getUser()
                .then((res2) => {
                  setUser({ ...user, download_sum: res2.data.download_sum });
                  setDownloadImageList([...downloadImageList, { id: res.data.id, user: res2.data.id, upload_img: res.data.upload_img, created_at: res.data.created_at }]);
                  a.download = 'myCanvas.png';
                  a.click();
                })
                .catch((e) => {
                  alert('Download failed. Please try again.');
                  console.log(e);
                });
            })
            .catch((e) => {
              alert('Download failed. Please try again.');
              console.log(e);
            });
        } else {
          alert('you can no longer create canvas \nPlease delete your canvas or upgrade your plan');
        }
      } else {
        a.download = 'myCanvas.png';
        a.click();
      }
    };
  };

  const prevButtonClick = () => {
    // console.log('prev button click');
    if (obj === canvasList[0]) {
      Swal.fire({
        text: t('canvas.choiceDesign.FirstAlert'),
        customClass: {
          popup: 'custom-popup-class', // 모달 다이얼로그 스타일
        },
      });
    } else {
      const indx = canvasList.findIndex((item) => item.id === obj.id);
      canvasObj = canvasList.find((item, index) => index === indx - 1);
      setObj(canvasObj);
    }
  };

  const nextButtonClick = () => {
    // console.log('next button click');
    if (obj === canvasList[canvasList.length - 1]) {
      Swal.fire({
        text: t('canvas.choiceDesign.LastAlert'),
        customClass: {
          popup: 'custom-popup-class', // 모달 다이얼로그 스타일
        },
      });
    } else {
      const indx = canvasList.findIndex((item) => item.id === obj.id);
      canvasObj = canvasList.find((item, index) => index === indx + 1);
      setObj(canvasObj);
    }
  };

  return (
    <styles.MainContainer>
      <styles.CanvasWrapper>
        <styles.CanvasButton src="/images/ChoiceDesign/prev.svg" onClick={prevButtonClick} />
        <img
          style={{ maxWidth: 'calc(100% - 100px)', maxHeight: '90%', width: 'auto', height: 'auto', border: '2.5px solid #d9d9d9', borderRadius: '20px', userSelect: 'none' }}
          src={obj.origin_img}
        />
        <styles.CanvasButton src="/images/ChoiceDesign/next.svg" onClick={nextButtonClick} />
      </styles.CanvasWrapper>
      <styles.ButtonWrapper>
        <styles.Button hover={true} onClick={editButtonClick}>
          <styles.ButtonIcon>
            <img src="/images/ChoiceDesign/edit-photo.svg" />
          </styles.ButtonIcon>
          <span>{t('canvas.choiceDesign.EditImage')}</span>
        </styles.Button>
        {/*<styles.Button hover={true}>*/}
        {/*  <styles.ButtonIcon>*/}
        {/*    <img src="/images/ChoiceDesign/mecro.svg" />*/}
        {/*  </styles.ButtonIcon>*/}
        {/*  <span>{t('canvas.choiceDesign.Mecro')}</span>*/}
        {/*</styles.Button>*/}
        <styles.Button>
          <styles.ButtonIcon color="#f54545" onClick={downloadButtonClick}>
            <img src="/images/ChoiceDesign/download.svg" />
          </styles.ButtonIcon>
          <span>{t('canvas.choiceDesign.Download')}</span>
        </styles.Button>
      </styles.ButtonWrapper>
    </styles.MainContainer>
  );
}

// ChoiceCanvas.propTypes = {
//   files: PropTypes.arrayOf(PropTypes.string),
// };
