/**********************************
 * Name : ModalFrame.js
 * Author : Nam Sanglim
 * Introduction : Modal에 공통으로 사용될 레이아웃 Container 컴포넌트
 ********************************** */

import React from 'react';
import ModalPortal from './modalPortal';
import * as style from './styles';

const ModalFrame = (props) => {
  return (
    <ModalPortal>
      {/* dim영역의 클릭 이벤트가 버블링되기 때문에 모달 외부 클릭 시 변화 없도록 setOnModal을 true로 설정 */}
      <style.ModalFrame
        onClick={(e) => {
          e.stopPropagation(); //버블링 막기
          props.setOnModal(true);
        }}
        height={props.height}
      >
        <div style={{ width: '100%', height: '100%' }}>{props.children}</div>
      </style.ModalFrame>
    </ModalPortal>
  );
};

export default ModalFrame;
