import { MyProduct, Flex, Column, Single, Wrap, SingleFlex } from './styles';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { productImageState, bgImageState } from 'state/imageState';
import { CrossColor, HalfColor, SingleColor, TemplateThumbnail } from 'components/AiResult';
import { useTranslation } from 'react-i18next';
import ppAPI from 'api/ppAPI';
import Swal from 'sweetalert2';
import { Header } from 'components/Header';

// eslint-disable-next-line
export default function AiResult(props) {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [thumbnails, setThumbnails] = useState([]);
  const [secondThumbnails, setScondThumbnails] = useState([]);
  const [bgColor, setBgColor] = useState('none');
  const [productImage, setProductImage] = useRecoilState(productImageState);
  const [product, setProduct] = useState('');
  const [aiColors, setAiColors] = useState([]);
  const [colorSets, setColorSets] = useState([]);
  const [, setBackground] = useRecoilState(bgImageState);
  const [remove, setRemove] = useState();

  useEffect(() => {
    checkConditionsAndHandleErrors(productImage, navigate, displayErrorModal);

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.onload = function () {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0, img.width, img.height);
      setProduct(canvas.toDataURL());
      setProductImage({
        ...productImage,
        ppImage: canvas.toDataURL(),
      });
    };
    img.src = productImage.maskImage;
    if ('state' in location) {
      setAiColors(location.state.colors);
      setThumbnails(location.state.templates);
      setScondThumbnails(location.state.templates);
      setRemove(location.state.remove);
    } else {
      displayErrorModal('alert.recommendFailed');
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClicked = async (e) => {
    const resultInfo = extractResultInfo(e);
    console.log('resultInfo', resultInfo);

    updateBackground(resultInfo, setBackground);

    await saveBackground(productImage, resultInfo, remove);

    navigateToChoice(navigate, thumbnails);
  };

  const checkConditionsAndHandleErrors = (productImage, navigate, displayErrorModal) => {
    if (!productImage.width) {
      displayErrorModal('alert.wrongAccess');
      navigate('/');
      return false;
    }
    return true;
  };

  // 이벤트 처리 및 결과 정보 추출
  const extractResultInfo = (event) => {
    event.preventDefault();
    return event.currentTarget.id.split('-');
  };

  // 배경 설정
  const updateBackground = (resultInfo, setBackground) => {
    if (resultInfo[0] === 'template') {
      setBackground({
        type: resultInfo[0],
        templateId: resultInfo[1],
      });
    } else {
      setBackground({
        type: resultInfo[0],
        colors: resultInfo[0] === 'single' ? [resultInfo[1]] : resultInfo[1].split('&'),
      });
    }
  };

  // API 호출 및 결과 처리
  const saveBackground = async (productImage, resultInfo, remove) => {
    try {
      await ppAPI.saveBg({
        pp_id: productImage.id,
        bg_id: resultInfo[1],
        remove: remove,
      });
      console.log('API 요청 성공');
    } catch (err) {
      console.error('API 요청 실패:', err);
    }
  };

  // 페이지 이동
  const navigateToChoice = (navigate, thumbnails) => {
    navigate('/choice', { state: thumbnails });
  };

  useEffect(() => {
    aiColors.forEach((color) => {
      aiColors.forEach((another) => {
        if (color !== another) setColorSets((prev) => [...prev, [color, another]]);
      });
    });
  }, [aiColors]);

  return (
    <Wrap>
      {/* <HeaderContainer lang={window.location.href.includes('eng') ? 'eng' : 'kor'} />  */}
      <Header />
      <Flex wrap={true} dir={'column'}>
        <Column center={true} dir={'row-reverse'}>
          <MyProduct id={`single-${bgColor}`} color={bgColor} src={product} shadow={true} onClick={handleClicked} />
          <SingleFlex width={'fit-content'}>
            <Single
              color={'url(' + process.env.PUBLIC_URL + '/images/Component/empty-layer.png) 0% 0% / 36px'}
              src={product}
              pointer={true}
              onClick={() => setBgColor('none')}
              active={bgColor === 'none'}
            />
            <Single color="white" src={product} pointer={true} onClick={() => setBgColor('white')} active={bgColor === 'white'} />
            <Single color="black" src={product} pointer={true} onClick={() => setBgColor('black')} active={bgColor === 'black'} />
          </SingleFlex>
        </Column>
        <Flex margin={'auto'} dir={'column'}>
          <Flex gap={'2%'} dir={'column'}>
            <Column>
              {aiColors.map((color) => (
                <SingleColor key={`single-${color}`} id={`single-${color}`} size="320px" color={color} src={product} pointer={true} active="off" onClick={handleClicked} />
              ))}
            </Column>
            <Column>
              {colorSets &&
                colorSets.map((colors) => (
                  <CrossColor key={`cross-${colors[0]}&${colors[1]}`} id={`cross-${colors[0]}&${colors[1]}`} src={product} left={colors[0]} right={colors[1]} pointer={true} onClick={handleClicked} />
                ))}
              {colorSets &&
                colorSets.map((colors) => (
                  <HalfColor key={`half-${colors[0]}&${colors[1]}`} id={`half-${colors[0]}&${colors[1]}`} src={product} top={colors[0]} bottom={colors[1]} pointer={true} onClick={handleClicked} />
                ))}
            </Column>
          </Flex>
          <Flex gap={'2%'} dir={'column'}>
            <Column>
              {thumbnails &&
                thumbnails.slice(0, 10).map((i, idx) => {
                  return <TemplateThumbnail src={i.image_small} prod={product} key={i.id} id={`template-${i.id}`} pointer={true} onClick={handleClicked} />;
                })}
            </Column>
            <Column>
              {secondThumbnails &&
                secondThumbnails.slice(10, 20).map((i, idx) => {
                  return <TemplateThumbnail src={i.image_small} prod={product} key={i.id} id={`template-${i.id}`} pointer={true} onClick={handleClicked} />;
                })}
            </Column>
          </Flex>
        </Flex>
      </Flex>
    </Wrap>
  );

  function displayErrorModal(message) {
    Swal.fire({
      text: t(message),
      customClass: {
        popup: 'custom-popup-class',
      },
    });
  }
}
