/**********************************`
 `* Name : HeaderStyles.js`
 `* Author : Kim Minjeong(minjj0905)`
 `* Introduction : 스타일을 적용한 컴포넌트를 생성하여 Header.js 에서 사용.`
 `*`
 `********************************** */

import styled from 'styled-components';
import oc from 'open-color';
import { media } from 'utils/styleUtil';

// 상단 고정, 그림자
export const Positioner = styled.div`
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0;
  width: 100%;
  height: 60px;
  border-bottom: 1px solid #dbdbdb;
  z-index: 5;
  background: #fff;
`;

// 흰 배경, 내용 중간 정렬
export const Background = styled.div`
  display: flex;
  height: auto;
  width: 100%;
  margin: 0px 32px;
`;

// 해더의 내용
export const HeaderContents = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const canvansHeader = styled.div`
  width: 90%;
  height: 47px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 1rem;
  ${media.wide`
         width: 992px;
     `}

  ${media.tablet`
    width: 100%;
    padding-right: 1rem;
  `}
`;

// 로고
export const Logo = styled.img`
  cursor: pointer;
  width: 30px;
  height: 30px;
  background-color: transparent;
`;
export const isMobileHeaderTxt = styled.p`
  display: none;

  @media screen and (max-width: 425px) {
    display: block;
    font-size: 10px;
    line-height: 1.2em;
  }
`;

// 중간 여백
export const Spacer = styled.div`
  flex-grow: 1;
`;
// 하단 그래디언트 테두리
export const GradientBorder = styled.div`
  height: 3px;
  background: linear-gradient(to right, ${oc.teal[6]}, ${oc.cyan[5]});
`;

export const engBtn = styled.button`
  font-family: 'NS-EB';
  color: #666;
  padding: 0.5rem 3rem 0.4rem 3rem;
  cursor: pointer;
  border: 1px solid #707070;
  background: transparent;
  border-radius: 4px;
  text-decoration: none;
  transition: 0.2s all;
  font-size: 17px;
  z-index: 5;

  &:hover {
    background: #f5f5f5;
    box-shadow: inset 2px 2px 3px rgba(0, 0, 0, 0.16);
  }

  &:active {
    /* 마우스 클릭시 움직임 */
    transform: translateY(1px);
  }
`;
