/**********************************
 * Name : useFetch.js
 * Author : Park JeongMyeong
 * Introduction : Pagination에서 Fetch를 위해 필요한 JS File.
 ********************************** */
import { useCallback, useEffect, useState } from 'react';
import templateAPI from 'api/templateAPI';

function useFetch(query, page, category) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [list, setList] = useState([]);
  const [hasNext, setHasNext] = useState(true);

  const sendQuery = useCallback(async () => {
    if (hasNext) {
      try {
        await setLoading(true);
        await setError(false);
        await templateAPI.templatePaginationList(category, query + page).then(function (res) {
          if (res.data.next === null) setHasNext(false);
          setLoading(true);
          setError(false);
          setList((prev) => [...prev, ...res.data.results]);
          setLoading(false);
        });
        setLoading(false);
      } catch (err) {
        setLoading(false);
        setError(err);
      }
    }
  }, [query, page]);

  useEffect(() => {
    sendQuery(query);
  }, [query, sendQuery, page]);

  return { loading, error, list };
}

export default useFetch;
