import styled from 'styled-components';
import React, { useEffect, useRef, useState } from 'react';
import CountUp from 'react-countup';
const MainContainer = styled.div`
  font-family: ${(props) => props.theme.fontFamily} !important;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  font-size: 24px;
  gap: 20px;
  position: relative;
  width: 100%;
  height: 100vh;
`;

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
  padding-top: 24px;
  max-width: 480px;
  background-color: #ffffff;
`;

const MainHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 70%;
  > img {
    width: 100%;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  background-color: #ed5151;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  color: white;
  padding: 4px 6px;
`;

const SelectedWorkflow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  align-self: flex-end;
  color: #fd3d3d;
  margin-top: 20px;
`;

const WFWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  margin-top: 20px;
  padding: 26px 0;
  background-color: #000000;
  flex: 1;
`;

const WFCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.selected ? `start` : 'center')};
  align-items: center;
  max-height: ${(props) => (props.selected ? `900px` : '86px')};
  font-size: 25px;
  font-weight: 600;
  background-color: #ffffff;
  border: 2px solid #e02c6b;
  border-radius: 40px;
  padding: 24px 0;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  position: relative;
  overflow: hidden;
  gap: ${(props) => (props.selected ? `28px` : '0')};
  width: 90%;
  -webkit-tap-highlight-color: transparent;
`;

const WFCardTitle = styled.div`
  font-size: 22px;
  font-weight: 600;
  color: #e02c6b;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 96%;
`;

const DescriptionWrapper = styled.div`
  opacity: ${(props) => (props.selected ? 1 : 0)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 28px;
  width: 80%;
  font-size: 16px;
  max-height: ${(props) => (props.selected ? `600px` : '0')};
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  color: #000000;
`;

const DescriptionImage = styled.img`
  width: 100%;
`;

const DescriptionTitle = styled.div`
  align-self: flex-start;
  font-size: 18px;
`;

const Description = styled.div`
  font-size: 14px;
  color: #00000060;
  align-self: flex-start;
`;

const TestBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 22px 52px;
  border-radius: 50px;
  background-color: #ff7074;
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
  //word-break: break-all;
  white-space: nowrap;
`;

const SelectWorkflow = () => {
  const serverAddress = process.env.REACT_APP_BRIDGE_SERVER_ADDRESS;

  const [wfList, setWfList] = useState([]);
  const cardRefs = useRef([]);

  const [workflow, setWorkflow] = useState('');

  const [count, setCount] = useState(0);

  useEffect(() => {
    const fetchCount = async () => {
      const countValue = await getCount();
      setCount(countValue);
    };
    fetchCount();
  }, []);

  const getCount = async () => {
    const response = await fetch(`http://${serverAddress}/generation-count`, {
      method: 'GET',
    });
    const data = await response.json();
    return data;
  };

  const clickItem = (wf) => {
    if (workflow === wf.alias) {
      setWorkflow('');
      return;
    }
    console.log('wf : ', wf);
    setWorkflow(wf.alias);
  };

  const clickBtn = (event, workflow) => {
    event.stopPropagation();
    window.location.href = `/workflow/${workflow}`;
  };

  async function getWorkflowList() {
    const headers = { 'Content-Type': 'application/json' };
    try {
      const response = await fetch(`http://${serverAddress}/workflow-list`, {
        method: 'GET',
        headers: headers,
        // body: JSON.stringify({ workflow: workflow }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json(); // 응답 데이터를 JSON으로 파싱
      const processedData = data.map((item) => {
        const [description, eng] = item.description.split('\n\n');
        return { ...item, description, eng };
      });

      return processedData;
    } catch (e) {
      console.error('Request failed:', e);
      return null; // 오류 발생 시 null 반환 또는 적절한 오류 처리
    }
  }

  useEffect(() => {
    getWorkflowList()
      .then((info) => {
        console.log(info);
        setWfList(info);
      })
      .catch((e) => {
        console.error('Error getting workflow info:', e);
      });
  }, []);

  return (
    <MainContainer>
      <MainWrapper>
        <MainHeader>
          <TitleWrapper>AI Module Test</TitleWrapper>
          <img src="/images/BridgeServer/logo.svg" alt="logo" />
          <SelectedWorkflow>
            Total Visits : <CountUp end={count ? count : 0} duration={1} />
          </SelectedWorkflow>
        </MainHeader>

        <WFWrapper>
          {wfList.map((wf, index) => (
            <WFCard key={wf.alias} ref={(el) => (cardRefs.current[index] = el)} onClick={() => clickItem(wf)} selected={workflow === wf.alias}>
              <WFCardTitle>{wf.alias}</WFCardTitle>
              <DescriptionWrapper selected={workflow === wf.alias}>
                <DescriptionImage src={`data:image/jpeg;base64,${wf.thumbnail}`} alt="thumbnail" />
                <DescriptionTitle>Description</DescriptionTitle>
                <Description>{wf.description}</Description>
                <Description>{wf.eng}</Description>
                <TestBtn onClick={(event) => clickBtn(event, wf.alias)}>Test Module</TestBtn>
              </DescriptionWrapper>
            </WFCard>
          ))}
        </WFWrapper>
      </MainWrapper>
    </MainContainer>
  );
};

export default SelectWorkflow;
