/**********************************
 * 파일명: colorPannel.js
 * 작성자: Jiwon Kang
 * 설명: 캔버스의 오른쪽 사이드바의 색 조정 담당 컴포넌트
 **********************************/

import React, { useState, useEffect } from 'react';
import { fabric } from 'fabric';
import * as styles from './styles';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line
function ColorPannel() {
  const { t } = useTranslation();
  const [correction, setCorrection] = useState({ saturation: 0, brightness: 0, contrast: 0 });
  const [isUsingColorCorrection, setIsUsingColorCorrection] = useState(false);
  const [target, setTarget] = useState();
  const [canvas] = useState(document.getElementById('canvas').fabric);

  useEffect(() => {
    const targetObject = canvas.getActiveObject();
    setTarget(targetObject);

    // targetObject.correction이 존재하지 않는다면, state를 업데이트한다.
    if(targetObject && !targetObject.correction) {
      setIsUsingColorCorrection(false);
      setCorrection({ saturation: 0, brightness: 0, contrast: 0 });
    }

    if (targetObject && targetObject.correction) {
      setIsUsingColorCorrection(true);
      setCorrection(targetObject.correction);
    }
    // eslint-disable-next-line
  }, []);

  const removeColorCorrection = () => {
    const targetObject = target;
    targetObject.correction = { saturation: 0, brightness: 0, contrast: 0 };
    targetObject.filters = [];
    targetObject.applyFilters();
    canvas.requestRenderAll();
  };

  const handleToggleColor = () => {
    if (isUsingColorCorrection) {
      removeColorCorrection();
      setIsUsingColorCorrection(false);
      setCorrection({ saturation: 0, brightness: 0, contrast: 0 });
    } else {
      setIsUsingColorCorrection(true);
    }
  };

  const handleSaturation = (event, value) => {
    setCorrection({ ...correction, saturation: value });
    target.correction = { ...correction, saturation: value };

    const existingFilterIndex = target.filters.findIndex((filter) => filter instanceof fabric.Image.filters.Saturation);
    if (existingFilterIndex !== -1) {
      target.filters.splice(existingFilterIndex, 1);
    }

    const filter = new fabric.Image.filters.Saturation({ saturation: value / 100 });
    target.filters.push(filter);
    target.applyFilters();

    canvas.requestRenderAll();
  };

  const handleBrightness = (event, value) => {
    setCorrection({ ...correction, brightness: value });
    target.correction = { ...correction, brightness: value };

    const existingFilterIndex = target.filters.findIndex((filter) => filter instanceof fabric.Image.filters.Brightness);
    if (existingFilterIndex !== -1) {
      target.filters.splice(existingFilterIndex, 1);
    }

    const filter = new fabric.Image.filters.Brightness({ brightness: value / 100 });
    target.filters.push(filter);
    target.applyFilters();

    canvas.requestRenderAll();
  };

  const handleContrast = (event, value) => {
    setCorrection({ ...correction, contrast: value });
    target.correction = { ...correction, contrast: value };

    const existingFilterIndex = target.filters.findIndex((filter) => filter instanceof fabric.Image.filters.Contrast);
    if (existingFilterIndex !== -1) {
      target.filters.splice(existingFilterIndex, 1);
    }

    const filter = new fabric.Image.filters.Contrast({ contrast: value / 100 });
    target.filters.push(filter);
    target.applyFilters();

    canvas.requestRenderAll();
  };

  const handleInputCorresion = (event) => {
    const { name, value } = event.target;
    setCorrection({ ...correction, [name]: value });
  };

  const handleKeyDown = (event) => {
    // 엔터 키가 눌렸는지 확인
    if (event.key === 'Enter') {
      const { name, value } = event.target;
      let newValue = typeof value === 'number' ? value : parseFloat(value);
      if (isNaN(newValue)) newValue = 0;

      if (name === 'saturation') {
        handleSaturation(event, newValue);
      } else if (name === 'brightness') {
        handleBrightness(event, newValue);
      } else if (name === 'contrast') {
        handleContrast(event, newValue);
      }

      // 입력 필드에서 포커스 제거
      event.target.blur();
    }
  };

  return (
      <>
        <styles.SwitchWrap>
        <span>
          <img src={process.env.PUBLIC_URL + '/images/Canvas/SideBar/reflectionIcon.svg'} alt="reflectionIcon" />
          <span>{t('canvas.layer.ColorCorrection')}</span>
        </span>
          <styles.switchBtn
              className="switch"
              onChange={() => {
                handleToggleColor();
                document.getElementById('canvas').fabric.fire('object:modified');
              }}
          >
            <input type="checkbox" checked={isUsingColorCorrection} onChange={() => {}} />
            <span className="slider round"></span>
          </styles.switchBtn>
        </styles.SwitchWrap>
        {isUsingColorCorrection ? (
            <>
              <styles.HSVDiv>
                <styles.HSVHeader>
                  <styles.OptionText>{t('canvas.layer.Saturation')}</styles.OptionText>
                </styles.HSVHeader>
                <styles.HSVBody>
                  <styles.HSVSlider
                      min={-100}
                      max={100}
                      value={correction.saturation === '-' ? 0 : correction.saturation}
                      onChange={handleSaturation}
                      onClick={() => {
                        document.getElementById('canvas').fabric.fire('object:modified');
                      }}
                      valueLabelDisplay="auto"
                  />
                  <styles.ValueInput
                      name="saturation"
                      value={correction.saturation}
                      onChange={handleInputCorresion}
                      onBlur={() => {
                        document.getElementById('canvas').fabric.fire('object:modified');
                      }}
                      onKeyDown={handleKeyDown}
                  />
                </styles.HSVBody>
              </styles.HSVDiv>
              <styles.HSVDiv>
                <styles.HSVHeader>
                  <styles.OptionText>{t('canvas.layer.Brightness')}</styles.OptionText>
                </styles.HSVHeader>
                <styles.HSVBody>
                  <styles.HSVSlider
                      min={-100}
                      max={100}
                      value={correction.brightness === '-' ? 0 : correction.brightness}
                      onChange={handleBrightness}
                      onClick={() => {
                        document.getElementById('canvas').fabric.fire('object:modified');
                      }}
                      valueLabelDisplay="auto"
                  />
                  <styles.ValueInput
                      name="brightness"
                      value={correction.brightness}
                      onChange={handleInputCorresion}
                      onBlur={() => {
                        document.getElementById('canvas').fabric.fire('object:modified');
                      }}
                      onKeyDown={handleKeyDown}
                  />
                </styles.HSVBody>
              </styles.HSVDiv>
              <styles.HSVDiv>
                <styles.HSVHeader>
                  <styles.OptionText>{t('canvas.layer.Contrast')}</styles.OptionText>
                </styles.HSVHeader>
                <styles.HSVBody>
                  <styles.HSVSlider
                      min={-100}
                      max={100}
                      value={correction.contrast === '-' ? 0 : correction.contrast}
                      onChange={handleContrast}
                      onClick={() => {
                        document.getElementById('canvas').fabric.fire('object:modified');
                      }}
                      valueLabelDisplay="auto"
                  />
                  <styles.ValueInput
                      name="contrast"
                      value={correction.contrast}
                      onChange={handleInputCorresion}
                      onBlur={() => {
                        document.getElementById('canvas').fabric.fire('object:modified');
                      }}
                      onKeyDown={handleKeyDown}
                  />
                </styles.HSVBody>
              </styles.HSVDiv>
            </>
        ) : null}
      </>
  );
}

export default ColorPannel;
