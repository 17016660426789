import styled from 'styled-components';

export const IconsDiv = styled.div`
  display: flex;
  justify-content: space-between;
  width: fit-content;
  position: fixed;
  z-index: 2;
`;
export const rightIcons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  margin: auto;
  gap: 12px;
`;
export const ArrowWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
`;
export const simpleIcons = styled.img`
  width: 25px;
  height: 25px;
  background-color: transparent;
  cursor: pointer;
`;

export const processControl = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;
export const zoomContainer = styled.div`
  background: #dcdcdc;
  display: flex;
  align-items: center;
`;
export const zoomBtn = styled.button`
  user-select: none;
  color: #6e6e6e;
  height: 100%;
  width: 26px;
  font-size: 20px !important;
  font-family: 'NS-R';
  border-radius: 2px;
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #dcdcdc !important;
  background: #fff;
  cursor: pointer;
`;
export const zoomStatus = styled.p`
  user-select: none;
  width: 68px;
  display: inline;
  color: #6e6e6e;
  font-size: 12px;
  font-family: 'NS-B';
  text-align: center;
  padding: 0 18px;
`;

export const canvasSizeViewContainer = styled.div`
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  width: 120px;
  height: 28px;
  font-size: 20px;
  line-height: 28px;
  justify-content: space-evenly;
  align-items: center;
  font-family: NS-R;
  border-radius: 2px;
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #dcdcdc;
  cursor: pointer;
`;
