/**********************************
 * 파일명: [list.js]
 * 작성자: [Donghwoo Cho]
 * 설명: [목록으로 관리할 상수 모음]
 **********************************/

// font size 목록
export const fontSizeList = [8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48, 72];

// font family 목록
export const fontFamilyList = [
  'serif',
  'monoSpace',
  'cursive',
  'normal',
  // 'Black And White Picture',
  // 'Gamja Flower',
  // 'Gmarket Sans',
  // 'Gothic A1',
  // 'Hi Melody',
  // 'Nanum Brush Script',
  // 'Nanum Gothic',
  // 'Nanum Myeongjo',
  // 'Nanum Pen Script',
  // 'Omyu Pretty',
  // 'Poor Story',
  // 'Pretendard',
  // 'Single Day',
  // 'Stylish',
];

// 도형 type 목록
export const shapeTypeList = ['shape', 'rect', 'circle', 'polygon', 'path', 'ellipse', 'group'];
