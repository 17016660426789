import styled from 'styled-components';

// Progress Bar에서 배경 (회색부분) 을 담당하는 DIV styled-component
export const BackgroundBar = styled.div`
  height: ${(props) => (props.height ? `${props.height}px` : '30px')};
  width: ${(props) => (props.width ? `${props.width}px` : '500px')};
  background: #d4d4d4;
  border-radius: 30px;
  @media screen and (max-width: 1440px) {
    max-width: 90%;
    width: 100%;
    height: 20px;
  }
`;

// Progress Bar에서 상태 (분홍색 부분) 을 담당하는 DIV styled-component
export const Progress = styled.div`
  transition: all 0.3s ease;
  border-radius: 30px;
  height: 100%;
  max-width: 100%;
  z-index: 1;
  width: ${(props) => (props.percent ? `${props.percent}%` : '0%')};
  background: #f54545;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5);
`;

// Progress 제목을 담당하는 DIV styled-component
export const ProgressBarTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 20px;
  margin-bottom: 50px;
`;

// Progress % (상태 퍼센트) 출력을 담당하는 DIV styled-component
export const ProgressBarPercent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 15px;
  font-size: 18px;
  font-weight: 800;
`;
