import { propertiesWhenSerializing } from './canvasSettingUtil';

export const canvasToJSON = (canvas, multiplier) => {
  console.log(canvas);
  const multiplierValue = multiplier || 1;
  const canvasJSON = canvas.toDatalessJSON(propertiesWhenSerializing);
  const data = canvas.toDataURL({
    left: canvas.clipPath.left - canvas.clipPath.width / 2,
    top: canvas.clipPath.top - canvas.clipPath.height / 2,
    width: canvas.clipPath.width,
    height: canvas.clipPath.height,
    format: 'png',
    multiplier: multiplierValue,
  });

  return { id: canvas.id || null, origin_img: data, fabrics: JSON.stringify(canvasJSON) };
};
