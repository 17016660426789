import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  height: calc(100vh - 60px);
  overflow: hidden;
`;

export const Left = styled.div`
  max-width: 300px;
  width: 100%;
  border-right: 1px solid #dbdbdb;
  padding: 0px 24px;
  font-stretch: normal;
  font-style: normal;
  position: relative;

  > h6 {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.3px;
    color: #222;
    margin-bottom: 8px;
  }

  > div {
    height: 44px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 8px;
    cursor: pointer;
    &:hover {
      background: #ffeded;
      span {
        color: #f64545;
      }
      img {
        filter: invert(41%) sepia(65%) saturate(4028%) hue-rotate(337deg) brightness(115%) contrast(116%);
      }
    }
  }

  > p {
    background: #ededed;
    height: 1px;
    width: 100%;
    margin: 10px 0;
  }
`;

export const Right = styled.div`
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
`;
