import * as style from 'components/MyPage/styles';
import { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { authState } from 'state';
import { bgImageState, productImageState } from 'state/imageState';
import Swal from 'sweetalert2';
import accountAPI from 'api/accountAPI';
import canvasAPI from 'api/canvasAPI';
import saveAPI from 'api/saveAPI';
import ChoiceCanvas from 'components/ChoiceDesign/choiceCanvas';

function Canvas({ canvasList, setCanvasList }) {
  const { t } = useTranslation();
  const [isChoice, setIsChoice] = useState(false);
  const [selected, setSelected] = useState();
  const [canvases, setCanvases] = useState([]);
  const navigate = useNavigate();
  const [bgImage, setBgImage] = useRecoilState(bgImageState);
  const [productImage, setProductImage] = useRecoilState(productImageState);
  const [userInfo, setUserInfo] = useRecoilState(authState);

  const myCanvases = async () => {
    await saveAPI.getWorkSave().then((res) => {
      const sortedList = res.data.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
      setCanvases(sortedList);
    });
  };
  useEffect(() => {
    myCanvases();
    console.log('canvasList', canvasList);
  }, []);

  const imageClick = (e, canvas) => {
    setSelected(canvas);
    setIsChoice(true);
  };

  const imageToURL = (image) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    canvas.width = image.width;
    canvas.height = image.height;
    context.drawImage(image, 0, 0);

    return canvas.toDataURL('image/png');
  };

  const newCanvas = async (e) => {
    if (userInfo.canvas_sum < userInfo.tier.canvas_sum) {
      e.preventDefault();
      const samplePp = new Image();
      samplePp.src = process.env.PUBLIC_URL + '/images/MyPage/productEx.png';
      samplePp.onload = () => {
        const samplePpURL = imageToURL(samplePp);

        setBgImage({ type: 'single', colors: ['#00000000'] });
        setProductImage({ uploadImage: samplePpURL, maskImage: samplePpURL, ppImage: samplePpURL, fileName: 'sample-pp', width: 250, height: 250 });
        navigate('/canvas');
      };
    } else {
      Swal.fire({ text: 'you can no longer create canvas \nPlease delete your canvas or upgrade your plan' });
    }
  };

  const handleDeleteCanvas = (id) => {
    Swal.fire({
      text: t('mypage.canvas.DeleteAlert'),
      showCancelButton: true,
      confirmButtonText: 'yes',
      cancelButtonText: 'no',
    }).then((res) => {
      if (res.isConfirmed) {
        canvasAPI
          .deleteCanvasImg(id)
          .then((res) => {
            setCanvasList(canvasList.filter((item) => item.id !== Number(id)));
            accountAPI
              .getUser()
              .then((res) => {
                console.log('에?', res);
                setUserInfo({ ...userInfo, canvas_sum: res.data.canvas_sum });
              })
              .catch((e) => {
                console.log(e);
              });
          })
          .catch((err) => {
            Swal.fire({ text: t('mypage.canvas.DeleteFail') });
            console.log(err);
          });
      }
    });
  };

  return (
    <div style={{ height: '100%' }}>
      <style.CanvasTop>{t('mypage.canvas.MyCanvas')}</style.CanvasTop>
      {isChoice ? (
        <style.CanvasType>
          <ChoiceCanvas canvasList={canvasList} canvasObj={selected} />
        </style.CanvasType>
      ) : (
        <style.Wrapper>
          <style.CanvasType>
            <div>
              <span>{t('mypage.canvas.RecentCanvas')}</span>
              <span>
                Usage {userInfo.canvas_sum} / {userInfo.tier.canvas_sum}
              </span>
            </div>
            <div>
              <style.NewProductButton onClick={newCanvas}>+</style.NewProductButton>
              {canvasList.map((canvas, index) => (
                <style.Canvases key={index}>
                  <div style={{ position: 'relative', justifyContent: 'center', alignItems: 'center' }}>
                    <img key={canvas.id} src={canvas.origin_img} onClick={(e) => imageClick(e, canvas)} crossOrigin="Anonymous" />
                    <button onClick={() => handleDeleteCanvas(canvas.id)}>delete</button>
                  </div>
                </style.Canvases>
              ))}
            </div>
          </style.CanvasType>
        </style.Wrapper>
      )}
    </div>
  );
}

export default Canvas;
