import styled from 'styled-components';

export const ModalFrame = styled.div`
  position: absolute;
  height: 100vh;
  width: 100%;
  display: flex;
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 4;
`;

export const closeButton = styled.button`
  all: unset;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 20px;
  font-weight: 700;
`;
