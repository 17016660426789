import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { canvasLoadingState } from 'state/canvasState';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

function CanvasLoadingBackDrop() {
  const open = useRecoilValue(canvasLoadingState);
  const { t } = useTranslation();
  return (
    <div>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} open={open}>
        <CircularProgress color="inherit" />
        <Typography variant="body1" mt="1rem">
          {t('alert.loading')}
        </Typography>
      </Backdrop>
    </div>
  );
}

export default CanvasLoadingBackDrop;
