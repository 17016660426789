import * as style from './styles';
import { useTranslation } from 'react-i18next';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { rightSidebarState } from 'state/sidebarState';
import { objectList, selectedObject, selectedTextState } from 'state/canvasState';
import { addObjectSelectEvent, addShape, addTextEvnet, generateObjectId, getDefaultTextbox, getTextProperties } from 'utils/canvasObjectGenerateUtil';
import { useRef } from 'react';

// eslint-disable-next-line
function Font() {
  const { t } = useTranslation();
  const inputTextRef = useRef('');
  const setRightState = useSetRecoilState(rightSidebarState);
  const setSelectedText = useSetRecoilState(selectedTextState);
  const setSelectedIndex = useSetRecoilState(selectedObject);
  const setCanvasObjects = useSetRecoilState(objectList);
  const resetSelectedText = useResetRecoilState(selectedTextState);
  const fontList = ['serif', 'monoSpace', 'cursive', 'normal'];

  const addEvent = (text) => {
    addObjectSelectEvent(text, setSelectedIndex, setRightState);
    addTextEvnet(text, resetSelectedText, setSelectedText, setCanvasObjects);
  };

  const handleInputText = (e) => {
    if (e.type === 'click' || e.keyCode === 13) {
      const { fabric: canvas } = document.getElementById('canvas');
      const options = { text: inputTextRef.current.value || 'text', fontFamily: e.target.id || 'serif' };
      const id = generateObjectId('text');
      const text = getDefaultTextbox(options, id);
      addShape(canvas, text, addEvent);
      inputTextRef.current.value = '';
    }
  };

  return (
    <style.MainContainer>
      <style.searchWrap>
        <input ref={inputTextRef} type={'text'} placeholder={t('canvas.option.TextPlaceholderLeft')} onKeyDown={handleInputText} />
      </style.searchWrap>
      <p>{t('canvas.layer.TextDescryption')}</p>
      <style.fontBlock>
        {fontList.map((font, index) => (
          <style.textImage key={index} src={process.env.PUBLIC_URL + `/images/fontExample/${font}.png`} id={font} onClick={handleInputText} onKeyDown={handleInputText} />
        ))}
      </style.fontBlock>
    </style.MainContainer>
  );
}

export default Font;
