import React, { useState } from 'react';
import { ChoiceDesign } from 'components';
import * as style from 'components/MyPage/styles';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { bgImageState, productImageState } from 'state/imageState';
import { TempMyPageState } from 'state/state';
import ppAPI from 'api/ppAPI';
import { useUpload } from 'hooks';
import { useDropzone } from 'react-dropzone';
import Swal from 'sweetalert2';

function Canvas({ myPps, setMyPps }) {
  const { t } = useTranslation();
  const resetBackground = useResetRecoilState(bgImageState);
  const [isChoice, setIsChoice] = useState(false);
  const [selected, setSelected] = useState();
  const [canvases, setCanvases] = useState([]);
  const [tempMyPage, setTempMyPage] = useRecoilState(TempMyPageState);

  // const folders = ['product 1', 'product 2', 'product 3'];
  const images = ['/images/GuidePhoto/mainGuide01.png', '/images/GuidePhoto/mainGuide02.png'];

  const imageClick = (image) => {
    console.log('image', image);
    resetBackground();
    setSelected(image);
    setIsChoice(true);
  };

  const handleDeleteCanvas = (id) => {
    Swal.fire({
      text: t('mypage.product.DeleteAlert'),
      showCancelButton: true,
      confirmButtonText: 'yes',
      cancelButtonText: 'no',
      customClass: {
        popup: 'custom-popup-class', // 모달 다이얼로그 스타일
      },
    }).then((res) => {
      if (res.isConfirmed) {
        console.log(myPps);
        console.log(id);

        ppAPI
          .deleteProductPicture(id)
          .then((res) => {
            // console.log('삭제됨');
            setMyPps(myPps.filter((item) => item.id !== Number(id)));
          })
          .catch((err) => {
            Swal.fire({
              text: t('mypage.product.DeleteFail'),
              customClass: {
                popup: 'custom-popup-class', // 모달 다이얼로그 스타일
              },
            });
            console.log(err);
          });
      }
    });
  };

  const onDrop = useUpload(true);
  const { getRootProps } = useDropzone({
    onDrop,
    multiple: false,
  });

  return (
    <div style={{ height: '100%' }}>
      <style.CanvasTop>{t('mypage.product.MyProduct')}</style.CanvasTop>
      {isChoice ? (
        <style.CanvasType>
          <ChoiceDesign pp={selected} ppList={myPps} />
        </style.CanvasType>
      ) : (
        <style.Wrapper>
          <style.CanvasType>
            <span>{t('mypage.product.RecentProducts')}</span>
            <div>
              <style.NewProductButton onClick={setTempMyPage('product')} {...getRootProps()}>
                +
              </style.NewProductButton>

              {myPps.map((image, index) => (
                <style.Canvases key={index}>
                  <div style={{ position: 'relative', justifyContent: 'center', alignItems: 'center' }}>
                    <img src={`${process.env.PUBLIC_URL}${image.remove_bg_img}`} onClick={() => imageClick(image)} crossOrigin="Anonymous" />
                    <button onClick={() => handleDeleteCanvas(image.id)}>{t('mypage.product.Delete')}</button>
                  </div>
                </style.Canvases>
              ))}
            </div>
          </style.CanvasType>
        </style.Wrapper>
      )}
    </div>
  );
}

export default Canvas;
