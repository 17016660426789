/**********************************
 * Name : selectTemplate.js
 * Author : Park JeongMyeong
 * Introduction : Template 선택 Component
 ********************************** */
import React, { useState } from 'react';
import { DefaultView, DetailView, SelectedView } from 'components/Canvas';

// eslint-disable-next-line
function SelectTemplate(props) {
  const [view, setView] = useState('default');
  const [category, setCategory] = useState(7);
  const [selectImg, setSelectImg] = useState(); // 선택된 이미지를 Props로 넘겨주기 위한 State
  if (view === 'default')
    return (
      <DefaultView
        viewDetail={(id) => {
          setView('detail');
          setCategory(id);
        }}
        viewSelect={(img) => {
          setView('selected_default');
          setSelectImg(img);
        }}
      />
    );
  else if (view === 'detail')
    return (
      <DetailView
        viewDefault={() => {
          setView('default');
        }}
        viewSelect={(img) => {
          setView('selected_detail');
          setSelectImg(img);
        }}
        category={category}
      />
    );
  else if (view === 'selected_default' || view === 'selected_detail')
    return (
      <SelectedView
        viewDefault={() => {
          view === 'selected_default' ? setView('default') : setView('detail');
        }}
        viewSelect={(img) => {
          setSelectImg(img);
        }}
        selectImage={selectImg}
      />
    );
}

export default SelectTemplate;
