import * as style from './styles';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function JoinTermsOfService(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  //체크박스 전체선택 상태 확인
  const [allCheck, setAllCheck] = useState(false);
  const prevJoinProcess = () => {
    navigate('/join?phase=4');
  };

  const handleAllCheckbox = () => {
    setAllCheck(!allCheck);
    if (allCheck === false) {
      props.setChecked({ serviceInfo: true, privateInfo: true, marketInfo: true });
    } else if (allCheck === true) {
      props.setChecked({ serviceInfo: false, privateInfo: false, marketInfo: false });
    }
  };

  const handleCheckboxChange = (e) => {
    const { value } = e.currentTarget;
    props.setChecked({ ...props.checked, [value]: !props.checked[value] });
    if (props.checked.serviceInfo && props.checked.privateInfo && props.checked.marketInfo) setAllCheck(false);
  };

  return (
    <style.SignUpWrapper>
      <style.LoginMessage>{t('login.JoinTermsOfService.LoginMessage')}</style.LoginMessage>
      <style.LoginText>{t('login.JoinTermsOfService.LoginText1_1')}</style.LoginText>
      <style.CheckboxWrapper>
        <style.Checkbox value="allAgree" onClick={handleAllCheckbox} checked={allCheck} />
        <style.TermsOfService color={'#222222'}>{t('login.JoinTermsOfService.FullConsent')}</style.TermsOfService>
      </style.CheckboxWrapper>
      <style.DivideSection></style.DivideSection>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <style.CheckboxWrapper>
          <style.Checkbox value="serviceInfo" onClick={handleCheckboxChange} checked={props.checked.serviceInfo} />
          <div>
            <style.TermsOfService color={'#8a24e2'}> {t('login.JoinTermsOfService.Needed')} </style.TermsOfService>
            <style.TermsOfService color={'#222222'} decoration={'underline'}>
              {t('login.JoinTermsOfService.TermsOfService1')}
            </style.TermsOfService>
            <style.ServiceComment>{t('login.JoinTermsOfService.ServiceComment1')}</style.ServiceComment>
          </div>
        </style.CheckboxWrapper>
        <style.CheckboxWrapper>
          <style.Checkbox value="privateInfo" onClick={handleCheckboxChange} checked={props.checked.privateInfo} />
          <div>
            <style.TermsOfService color={'#8a24e2'}> {t('login.JoinTermsOfService.Needed')} </style.TermsOfService>
            <style.TermsOfService color={'#222222'} decoration={'underline'}>
              {t('login.JoinTermsOfService.TermsOfService2')}
            </style.TermsOfService>
            <style.ServiceComment>{t('login.JoinTermsOfService.ServiceComment2')}</style.ServiceComment>
          </div>
        </style.CheckboxWrapper>
        <style.CheckboxWrapper>
          <style.Checkbox value="marketInfo" onClick={handleCheckboxChange} checked={props.checked.marketInfo} />
          <div>
            <style.TermsOfService color={'#8a24e2'}> {t('login.JoinTermsOfService.Choice')} </style.TermsOfService>
            <style.TermsOfService color={'#222222'} decoration={'underline'}>
              {t('login.JoinTermsOfService.TermsOfService3')}
            </style.TermsOfService>
            <style.ServiceComment>{t('login.JoinTermsOfService.ServiceComment3')}</style.ServiceComment>
          </div>
        </style.CheckboxWrapper>
        <style.GrayButton onClick={props.submitSignup}>{t('login.JoinTermsOfService.GrayButton')}</style.GrayButton>
        <style.BackBtn src={process.env.PUBLIC_URL + '/images/Signup/back-arrow.png'} onClick={prevJoinProcess} />
      </div>
    </style.SignUpWrapper>
  );
}

export default JoinTermsOfService;
