import * as style from './styles';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';

function JoinPhoneNum(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // 입력값 확인
  const [checkInput, setCheckInput] = useState({ focusOn: false, input: false });
  // DB에 들어가는 숫자만 있는 폰넘버
  const [userPhone, setUserPhone] = useState(props.joinInfo.phone_num);
  // 예쁘게 보여지는 폰넘버

  const validatePhone = (phone) => {
    const regExp = /^\d{3}\d{3,4}\d{4}$/;
    return regExp.test(phone);
  };

  const nextJoinProcess = () => {
    if (isNaN(userPhone) || userPhone === '') {
      Swal.fire({
        text: t('login.JoinPhoneNum.ErrorMessage'),
        customClass: {
          popup: 'custom-popup-class',
        },
      });
      return;
    } else if (userPhone !== '') navigate('/join?phase=5');
  };
  const prevJoinProcess = () => {
    navigate('/join?phase=3');
  };

  const handleLoginInfo = (e) => {
    const { name, value } = e.target;
    if (value) {
      props.setJoinInfo({ ...props.joinInfo, [name]: value });
      setUserPhone(value);
      setCheckInput({ ...checkInput, input: true });
    } else {
      setCheckInput({ ...checkInput, input: false });
      setUserPhone('');
    }
  };

  return (
    <style.SignUpWrapper>
      <style.LoginMessage>{t('login.JoinPhoneNum.LoginMessage')}</style.LoginMessage>
      <style.LoginText>
        {t('login.JoinPhoneNum.LoginText1_1')}
        <br />
        {t('login.JoinPhoneNum.LoginText1_2')}
      </style.LoginText>
      <style.inputBoxInfo>{t('login.JoinPhoneNum.PhoneNumber')}</style.inputBoxInfo>
      <style.inputBox
        onChange={handleLoginInfo}
        onFocus={() => setCheckInput({ ...checkInput, focusOn: true })}
        placeholder={t('login.JoinPhoneNum.inputBox')}
        value={userPhone}
        name={'phone_num'}
        type="tel"
        maxLength={11}
        required
      />
      {userPhone === '' && checkInput.focusOn && !checkInput.input && <style.WarningMessage color={'#e61503'}>{t('login.JoinPhoneNum.WarningMessage')}</style.WarningMessage>}
      <style.GrayButton marginTop={'42px'} onClick={nextJoinProcess}>
        {t('login.JoinPhoneNum.GrayButton')}
      </style.GrayButton>
      <style.BackBtn src={process.env.PUBLIC_URL + '/images/Signup/back-arrow.png'} onClick={prevJoinProcess} />
    </style.SignUpWrapper>
  );
}

export default JoinPhoneNum;
