import styled from 'styled-components';
import React, { useEffect, useRef, useState } from 'react';
import Swal from 'sweetalert2';
import { TextField } from '@mui/material';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import Slider from 'react-slick';
import './slider.css';
import { v4 as uuidv4 } from 'uuid';

const MainContainer = styled.div`
  font-family: ${(props) => props.theme.fontFamily} !important;
  width: 100vw;
  display: flex;
  flex-direction: column;

  //justify-content: center;
  align-items: center;
  font-size: 24px;
  * {
    box-sizing: border-box;
  }
  > div {
  }
  overflow-x: hidden;
`;

const MainHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  font-size: 16px;
  font-weight: 600;
`;
const BackBtn = styled.img`
  position: absolute;
  left: 5%;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  > div {
    svg {
      height: 100px !important;
    }
  }
`;

const MainWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 24px 30px;
  gap: 22px;
  max-width: 480px;

  @media screen and (max-width: 480px) {
    max-width: 100vw;
  }
  overflow-x: visible;
`;

const TestImg = styled.img`
  max-width: 100%;
  border-radius: 10px;
  background-color: #c4c4c4;
`;

const InputWrapper = styled.div`
  max-width: 100%;
  position: relative;
`;

const FormWrapper = styled.div`
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  font-size: 24px;
  position: relative;
  gap: 120px;
  max-width: 100%;
`;

const FormSubWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;

const FloatingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  position: absolute;
  width: 100%;
  //top: ${(props) => props.imgHeight}px;
  z-index: 100;
`;
const UploadInput = styled.input`
  display: none;
`;

const InputTitle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  font-size: 18px;
  font-weight: 500;
  padding: 0;
  gap: 0 !important;
`;

const DownloadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: flex-start;
`;

const ImageDisplay = styled.img`
  max-width: 100%;
  margin-top: 20px;
`;

const DownloadBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  width: 100%;
  border: 2px solid #c41d7c;
  background-color: #e02c6b;
  color: #ffffff;
  padding: 12px 20px;
  border-radius: 10px;
  transition: 0.2s;
  cursor: pointer;
  &:disabled {
    background-color: #f0f0f0;
    color: #c41d7c;
    cursor: not-allowed;
  }
`;

const PreviewImg = styled.img`
  max-width: 80%;
  cursor: pointer;
`;

const PreviewVideo = styled.video`
  max-width: 80%;
  //cursor: pointer;
`;

const SubmitImgWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ResultWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  justify-content: center;
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const LoadingImg = styled.img`
  width: 400px;
`;

const LoadingText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const WaitingText = styled.div`
  font-size: 20px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const blue = {
  100: '#DAECFF',
  200: '#b6daff',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  900: '#003A75',
};

const Textarea = styled(TextareaAutosize)(
  ({ theme }) => `
  height: 160px !important;
    max-width: 100% !important;
    min-width: 100% !important;
    font-size: 20px;
    box-sizing: border-box;
    width: 320px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    

    &:hover {
      border-color: ${blue[400]};
    }

    &:focus {
      border-color: ${blue[400]};
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `,
);

const NumberTF = styled(TextField)`
  > div {
    height: 60px !important;
    max-width: 100% !important;
    min-width: 100% !important;
    font-size: 20px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
  width: 100%;
`;
const HorizontalLine = styled.div`
  width: 480px;
  height: 1px;
  background-color: #000000;
  align-self: center;
`;

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  width: 48%;
  background-color: ${(props) => (props.islast ? '#e02c6b' : '#ff7074')};
  padding: 16px 0;
  border-radius: 16px;
  color: #ffffff;
  transition: 0.2s;
  font-weight: 600;
`;

const GunVideo = styled.video`
  max-width: 100%;
`;

const Workflow = () => {
  const workflow = window.location.pathname.split('/').pop();
  const serverAddress = process.env.REACT_APP_BRIDGE_SERVER_ADDRESS;

  const fileInputRefs = useRef({});

  const [clientId, setClientId] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  const [progress, setProgress] = useState(0);

  const [selectedFiles, setSelectedFiles] = useState([]);

  const [request, setRequest] = useState({});

  const [originRequest, setOriginRequest] = useState({});

  const [imageSrc, setImageSrc] = useState('');

  const [isDownLoaded, setIsDownLoaded] = useState(false);

  const [currentSlide, setCurrentSlide] = useState(0); // 현재 슬라이더 페이지(인덱스) 상태

  const [isError, setIsError] = useState(false);

  const [isLast, setIsLast] = useState(false);

  const mainRef = useRef(null);

  const [videoSrc, setVideoSrc] = useState(null);

  const sliderRef = useRef();

  const floatingRef = useRef(null);

  const [resType, setResType] = useState('image');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // 초기 렌더링 시 한번 실행
    handleResize();

    // 윈도우 리사이즈 이벤트 리스너 등록
    window.addEventListener('resize', handleResize);

    // 컴포넌트 언마운트 시 리스너 제거
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (window.innerWidth <= 400) {
      floatingRef.current.style.top = window.innerWidth * 0.8 + 20 + 'px';
    } else if (window.innerWidth <= 480) {
      floatingRef.current.style.top = window.innerWidth * 0.9 + 'px';
    } else {
      floatingRef.current.style.top = 446 + 'px';
    }
  }, [window.innerWidth]);

  // useEffect(() => {
  //   console.log('originRequest : ', originRequest);
  // }, [originRequest]);
  // useEffect(() => {
  //   console.log('request : ', request);
  // }, [request]);
  //
  // useEffect(() => {
  //   console.log('selectedFiles : ', selectedFiles);
  // }, [selectedFiles]);

  // 다운로드를 한번 진행한 후에 다시 진행하면 다운로드가 진행되지 않는 문제 해결 위해 window.loaction 사용
  const clickBack = () => {
    window.location.href = '/select-workflow';
  };

  const triggerFileInput = (key) => {
    // 해당 key의 파일 입력 창 열기
    fileInputRefs.current[key].click();
  };

  const handleUpload = async (clientId) => {
    const formData = new FormData();

    // 여러 파일과 데이터를 FormData에 추가
    for (let { key, file } of selectedFiles) {
      const replaceName = file.name.replaceAll('.', '_');

      if (file && replaceName.split('_')[replaceName.split('_').length - 1] === 'jpeg') {
        const newFileName = replaceName.replace(/_jpeg$/, '.jpg');
        formData.append(key, file, newFileName);
      } else if (file && replaceName.split('_')[replaceName.split('_').length - 1] === 'mov') {
        const newFileName = replaceName.replace(/_mov$/, '.mp4');
        formData.append(key, file, newFileName);
      } else {
        formData.append(key, file, file.name);
      }
    }

    try {
      const response = await fetch(`http://${serverAddress}/upload?clientId=${clientId}`, {
        method: 'POST',
        body: formData,
      });

      const data = await response.json();

      // console.log('Upload successful:', data);

      if (response.ok) {
        Object.keys(data).forEach((key) => {
          const newRequest = { ...request };
          newRequest[key].default = data[key];
          setRequest(newRequest);
        });
      } else {
        // console.error('Upload failed:', response.statusText);
      }
    } catch (error) {
      // console.error('Error uploading file:', error);
      alert('Failed to load the image.');
    }
  };

  const clickSubmit = async () => {
    // console.log('clickSubmit 실행');
    if (clientId === 0) return;

    // 스크롤 최상단으로
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    let flag = false;
    // mp4,
    Object.keys(request).forEach((key) => {
      if (
        (request[key].type === 'image/jpeg' || request[key].type === 'image/png' || request[key].type === 'image/gif' || request[key].type === 'video/mp4' || request[key].type === 'video/mpeg') &&
        !selectedFiles.find((file) => file.key === key)
      ) {
        if (request[key].type === 'image/jpeg' || request[key].type === 'image/png' || request[key].type === 'image/gif') {
          Swal.fire({
            icon: 'error',
            title: '모든 이미지를 선택해주세요.',
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: '모든 비디오를 선택해주세요.',
          });
        }

        flag = true;
        return;
      }
    });

    if (flag) return;
    handleNext();

    // 웹 소켓 연결 시작
    const ws = new WebSocket(`ws://${serverAddress}/ws?clientId=${clientId}`);
    ws.onopen = async () => {
      console.log('Connected to WebSocket server');

      // 모든 이미지에 대해 handleUpload 실행 및 request 에 저장
      await handleUpload(clientId);

      const data = {
        workflow: `${workflow}`,
      };
      Object.keys(request).forEach((key) => {
        // data[key] = request[key].default;
        if (request[key].type === 'int' && (request[key].default === 0 || request[key].default === '')) {
          data[key] = 1;
        } else if (request[key].type === 'float' && (request[key].default === 0 || request[key].default === '')) {
          data[key] = 0.0001;
        } else {
          if (request[key].default === '') {
            data[key] = originRequest[key];
          } else {
            data[key] = request[key].default;
          }
        }
      });

      await sendRequest(clientId, data);
    };

    ws.onmessage = async (event) => {
      const jsonRes = JSON.parse(event.data);
      console.log('jsonRes : ', jsonRes);

      if (jsonRes.status === 'progress') {
        setIsLoading(true);
        let percent = parseFloat(jsonRes.detail.split('%')[0]);
        if (percent <= 100) {
          setProgress(jsonRes.detail);
        }
      }
      if (jsonRes.status === 'error') {
        setIsError(true);
      }

      if (jsonRes.detail === 'Execution is done') {
        await getHistory(clientId);
      }
      if (jsonRes.status === 'closed') {
        setIsLoading(false);
        ws.close();
      }
    };

    ws.onclose = () => {
      console.log('Disconnected from WebSocket server');
    };

    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
      setIsError(true);
    };
  };

  const handleFileChange = (event, key) => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onloadend = (e) => {
      const arrayBuffer = reader.result;
      const blob = new Blob([arrayBuffer], { type: file.type });
      const imageUrl = URL.createObjectURL(blob);

      const idx = selectedFiles.findIndex((item) => item.key === key);
      if (idx !== -1) {
        const newSelectedFiles = [...selectedFiles];
        newSelectedFiles[idx] = { key, file, preview: imageUrl };
        setSelectedFiles(newSelectedFiles);
      } else {
        setSelectedFiles((prevSelectedFiles) => [...prevSelectedFiles, { key, file, preview: imageUrl }]);
      }

      if (file.type.startsWith('video/')) {
        generateVideoThumbnail(blob, key);
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const generateVideoThumbnail = (blob, key) => {
    const video = document.createElement('video');
    console.log('blob : ', blob);
    video.src = URL.createObjectURL(blob);

    video.addEventListener(
      'loadeddata',
      () => {
        video.currentTime = 0; // 비디오의 첫 번째 프레임으로 이동

        video.addEventListener(
          'seeked',
          () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
          },
          { once: true },
        ); // 이 이벤트 리스너는 한 번만 실행됩니다.
      },
      { once: true },
    ); // 이 이벤트 리스너는 한 번만 실행됩니다.

    video.load(); // 비디오 로드 시작
  };

  const handleValueChange = (index, value) => {
    const newRequest = { ...request };
    newRequest[index].default = value;
    setRequest(newRequest);
  };

  async function getHistory(clientId, download = true) {
    // console.log('getHistory 실행');
    try {
      const response = await fetch(`http://${serverAddress}/history?clientId=${clientId}&resType=base64`);
      console.log('getHistory response : ', response);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      if (download) {
        // await parseMultipartResponse(response);
        await handleResponse(response);
      }

      return response;
    } catch (e) {
      console.error('Request failed:', e.message);
    }
  }
  const handleResponse = async (response) => {
    console.log('response : ', response);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();

    if (data.files && data.files.length > 0) {
      const base64Content = data.files[0].content;
      const contentType = data.files[0].content_type;

      // Base64 데이터를 Blob으로 변환
      const byteCharacters = atob(base64Content);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: contentType });

      // Blob URL 생성
      const blobUrl = URL.createObjectURL(blob);

      if (contentType.split('/')[0] === 'image') {
        console.log('이미지 입니다 ContentType : ', contentType);
        setResType('image');
        setImageSrc(blobUrl);
      } else {
        console.log('contentType : ', contentType);
        setResType('video');

        setVideoSrc(blobUrl);
      }
    }
  };

  // 미리보기에도 워터마크 추가
  // const handleResponse = async (response) => {
  //   console.log('response : ', response);
  //   if (!response.ok) {
  //     throw new Error(`HTTP error! Status: ${response.status}`);
  //   }
  //
  //   const data = await response.json();
  //
  //   if (data.files && data.files.length > 0) {
  //     const base64Content = data.files[0].content;
  //     const contentType = data.files[0].content_type;
  //
  //     // Base64 데이터를 Blob으로 변환
  //     const byteCharacters = atob(base64Content);
  //     const byteNumbers = new Array(byteCharacters.length);
  //     for (let i = 0; i < byteCharacters.length; i++) {
  //       byteNumbers[i] = byteCharacters.charCodeAt(i);
  //     }
  //     const byteArray = new Uint8Array(byteNumbers);
  //     const blob = new Blob([byteArray], { type: contentType });
  //
  //     // Blob URL 생성
  //     const blobUrl = URL.createObjectURL(blob);
  //
  //     if (contentType.split('/')[0] === 'image') {
  //       console.log('이미지 입니다 ContentType : ', contentType);
  //       // 워터마크 추가 후 이미지 설정
  //       const watermarkedImageUrl = await addWatermarkToImage(blobUrl, '/images/BridgeServer/watermark-logo.png');
  //       setResType('image');
  //       setImageSrc(watermarkedImageUrl);
  //     } else {
  //       console.log('contentType : ', contentType);
  //       setResType('video');
  //       setVideoSrc(blobUrl);
  //     }
  //   }
  // };
  //
  // const addWatermarkToImage = (imageUrl, watermarkUrl) => {
  //   return new Promise((resolve, reject) => {
  //     const image = new Image();
  //     const watermark = new Image();
  //
  //     image.crossOrigin = 'Anonymous'; // CORS 설정이 필요할 때 사용
  //     watermark.crossOrigin = 'Anonymous';
  //
  //     image.src = imageUrl;
  //     watermark.src = watermarkUrl;
  //
  //     image.onload = () => {
  //       const canvas = document.createElement('canvas');
  //       const ctx = canvas.getContext('2d');
  //
  //       canvas.width = image.width;
  //       canvas.height = image.height;
  //
  //       // 이미지 그리기
  //       ctx.drawImage(image, 0, 0);
  //
  //       watermark.onload = () => {
  //         // 워터마크 크기와 위치 설정
  //         const watermarkWidth = 40;
  //         const watermarkHeight = 40;
  //         const xPosition = canvas.width - watermarkWidth - 20;
  //         const yPosition = canvas.height - watermarkHeight - 20;
  //
  //         // 워터마크 이미지 그리기
  //         ctx.drawImage(watermark, xPosition, yPosition, watermarkWidth, watermarkHeight);
  //
  //         // 워터마크가 추가된 이미지의 Blob 생성
  //         canvas.toBlob((blob) => {
  //           if (blob) {
  //             const watermarkedImageUrl = URL.createObjectURL(blob);
  //             resolve(watermarkedImageUrl);
  //           } else {
  //             reject(new Error('Failed to create watermarked image'));
  //           }
  //         }, 'image/png');
  //       };
  //
  //       watermark.onerror = () => {
  //         reject(new Error('Failed to load watermark image'));
  //       };
  //     };
  //
  //     image.onerror = () => {
  //       reject(new Error('Failed to load base image'));
  //     };
  //   });
  // };

  // 일반 다운로드 or 미리보기에도 워터마크 추가
  // function downloadImageWithCanvas(imageUrl) {
  //   var img = new Image();
  //   img.crossOrigin = 'Anonymous'; // 필요한 경우 CORS 설정
  //
  //   img.onload = function () {
  //     var canvas = document.createElement('canvas');
  //     var ctx = canvas.getContext('2d');
  //
  //     // 이미지 크기 조정 비율 설정
  //     var maxDimension = 1920; // 최대 허용 크기
  //     var ratio = Math.min(maxDimension / img.naturalWidth, maxDimension / img.naturalHeight);
  //     var width = img.naturalWidth * ratio;
  //     var height = img.naturalHeight * ratio;
  //
  //     canvas.width = width;
  //     canvas.height = height;
  //     ctx.drawImage(img, 0, 0, width, height);
  //
  //     canvas.toBlob(function (blob) {
  //       if (blob) {
  //         var url = URL.createObjectURL(blob);
  //         var a = document.createElement('a');
  //         a.href = url;
  //         a.download = 'downloaded_image.png';
  //         document.body.appendChild(a);
  //         a.click();
  //         document.body.removeChild(a);
  //         setIsDownLoaded(true);
  //       } else {
  //         console.error('Failed to create blob from canvas');
  //       }
  //     }, 'image/png');
  //   };
  //   img.src = imageUrl;
  // }

  // 다운로드 시 워터마크 추가
  function downloadImageWithCanvas(imageUrl) {
    var img = new Image();
    img.crossOrigin = 'Anonymous'; // 필요한 경우 CORS 설정
    var watermark = new Image();
    watermark.src = '/images/BridgeServer/watermark-logo.png'; // 워터마크 이미지 경로

    img.onload = function () {
      var canvas = document.createElement('canvas');
      var ctx = canvas.getContext('2d');

      // 이미지 크기 조정 비율 설정
      // var maxDimension = 1920; // 최대 허용 크기
      // var ratio = Math.min(maxDimension / img.naturalWidth, maxDimension / img.naturalHeight);
      // var width = img.naturalWidth * ratio;
      // var height = img.naturalHeight * ratio;

      var width = img.naturalWidth;
      var height = img.naturalHeight;

      canvas.width = width;
      canvas.height = height;

      // 원본 이미지 그리기
      ctx.drawImage(img, 0, 0, width, height);

      // 워터마크 이미지 로드 후 그리기
      watermark.onload = function () {
        var watermarkWidth = width * 0.1; // 워터마크 너비 고정

        var watermarkHeight = height * 0.1; // 워터마크 높이 고정
        const markSize = Math.min(watermarkWidth, watermarkHeight);

        var xPosition = width - markSize - 20; // 우측 하단 위치 (여백 포함)
        var yPosition = height - markSize - 20; // 하단 위치 (여백 포함)

        // 워터마크 이미지 그리기
        ctx.drawImage(watermark, xPosition, yPosition, markSize, markSize);

        // Blob 생성 및 다운로드
        canvas.toBlob(function (blob) {
          if (blob) {
            var url = URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = 'downloaded_image_with_watermark.png';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            setIsDownLoaded(true);
          } else {
            console.error('Failed to create blob from canvas');
          }
        }, 'image/png');
      };
    };
    img.src = imageUrl;
  }

  async function sendRequest(clientId, data) {
    const headers = { 'Content-Type': 'application/json' };

    console.log('sendRequest data : ', data);

    try {
      const response = await fetch(`http://${serverAddress}/generate-based-workflow?clientId=${clientId}`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data),
      });
      console.log('sendRequest response : ', response);

      return await response.json();
    } catch (e) {
      console.error('Request failed:', e.message);
    }
  }

  async function getWorkflowInfo(workflow) {
    try {
      const response = await fetch(`http://${serverAddress}/workflow-info?workflow=${workflow}`, {
        method: 'GET',
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return await response.json();
    } catch (e) {
      console.error('Request failed:', e);
      return null;
    }
  }

  useEffect(() => {
    const randomClientId = uuidv4();
    setClientId(randomClientId);

    getWorkflowInfo(`${workflow}`)
      .then((info) => {
        console.log('info : ', info);
        setRequest(info);
        let newOrigin = {};
        Object.keys(info).forEach((key) => {
          newOrigin[key] = info[key].default;
        });
        console.log('newOrigin : ', newOrigin);
        setOriginRequest(newOrigin);
      })
      .catch((e) => {
        console.error('Error getting workflow info:', e);
      });
  }, []);

  useEffect(() => {
    if (currentSlide === Object.keys(request).length - 1) {
      setIsLast(true);
    } else {
      setIsLast(false);
    }
  }, [currentSlide]);

  useEffect(() => {
    if (Object.keys(request).length === 1) {
      setIsLast(true);
    }
  }, [request]);

  const settings = {
    variableWidth: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    draggable: false,
    beforeChange: (current, next) => setCurrentSlide(next),
    swipe: false,
    speed: 200,
  };
  const handlePrev = () => {
    sliderRef.current.slickPrev();
  };

  const handleNext = () => {
    if (currentSlide < Object.keys(request).length) {
      sliderRef.current.slickNext();
    }
  };

  const downloadVideo = () => {
    const a = document.createElement('a');
    a.href = videoSrc;
    a.download = 'video.mp4';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    setIsDownLoaded(true);
  };

  return (
    <MainContainer id="main-container">
      <MainWrapper ref={mainRef}>
        <MainHeader>
          <BackBtn
            src={'/images/BridgeServer/arrow-back.svg'}
            onClick={() => {
              clickBack();
            }}
          />
          {workflow}
        </MainHeader>

        <InputWrapper>
          <FloatingWrapper ref={floatingRef}>
            {currentSlide !== Object.keys(request).length && (
              <ButtonWrapper>
                <Button onClick={handlePrev}>이전 &lt;Prev&gt;</Button>

                <Button
                  onClick={() => {
                    if (isLast) {
                      clickSubmit();
                    } else {
                      handleNext();
                    }
                  }}
                  islast={isLast}
                >
                  {isLast ? '선택 완료' : `다음 <Next>`}
                </Button>
              </ButtonWrapper>
            )}
            {currentSlide !== Object.keys(request).length && <HorizontalLine />}
          </FloatingWrapper>
          <Slider {...settings} ref={sliderRef}>
            {Object.entries(request).map(([key, value], index) => {
              if (value.type === 'str') {
                return (
                  <FormWrapper>
                    <TestImg src={`data:image/jpeg;base64,${value.descimage}`} />
                    <FormSubWrapper>
                      <InputTitle>
                        {value.title}
                        <span>Text 를 입력하세요.</span>
                      </InputTitle>
                      <Textarea placeholder={value.title} defaultValue={value.default} onChange={(e) => handleValueChange(key, e.target.value)} />
                    </FormSubWrapper>
                  </FormWrapper>
                );
              }
              return null;
            })}
            {Object.entries(request).map(([key, value], index) => {
              if (value.type === 'int') {
                return (
                  <FormWrapper>
                    <TestImg src={`data:image/jpeg;base64,${value.descimage}`} />
                    <FormSubWrapper>
                      <InputTitle>{value.title}</InputTitle>
                      <NumberTF
                        key={index}
                        type="number"
                        variant="outlined"
                        defaultValue={value.default}
                        inputProps={{ min: 1 }}
                        onChange={(e) => {
                          handleValueChange(key, Number(e.target.value));
                        }}
                      />
                    </FormSubWrapper>
                  </FormWrapper>
                );
              }
              return null;
            })}
            {Object.entries(request).map(([key, value], index) => {
              if (value.type === 'float') {
                return (
                  <FormWrapper>
                    <TestImg src={`data:image/jpeg;base64,${value.descimage}`} />
                    <FormSubWrapper>
                      <InputTitle>{value.title}</InputTitle>
                      <NumberTF
                        key={index}
                        type="number"
                        variant="outlined"
                        defaultValue={value.default}
                        inputProps={{ step: 0.0001, min: 0.0001 }}
                        onChange={(e) => {
                          handleValueChange(key, Number(e.target.value));
                        }}
                      />
                    </FormSubWrapper>
                  </FormWrapper>
                );
              }
              return null;
            })}
            {Object.entries(request).map(([key, value], index) => {
              if (value.type.startsWith('image/') && value.type.split('/')[1] !== 'gif') {
                return (
                  <FormWrapper>
                    <TestImg src={`data:image/jpeg;base64,${value.descimage}`} />
                    <FormSubWrapper>
                      <InputTitle>{value.title}</InputTitle>
                      <UploadInput type="file" accept="image/*" onChange={(e) => handleFileChange(e, key)} ref={(el) => (fileInputRefs.current[key] = el)} />
                      {selectedFiles.find((item) => item.key === key) ? (
                        <SubmitImgWrapper>
                          <PreviewImg src={selectedFiles.find((item) => item.key === key).preview} onClick={() => triggerFileInput(key)} alt="Preview" />
                        </SubmitImgWrapper>
                      ) : (
                        <SubmitImgWrapper>
                          <PreviewImg src={'/images/BridgeServer/image.svg'} onClick={() => triggerFileInput(key)} />
                        </SubmitImgWrapper>
                      )}
                    </FormSubWrapper>
                  </FormWrapper>
                );
              }
              return null;
            })}
            {Object.entries(request).map(([key, value], index) => {
              if ((value.type.startsWith('image/') && value.type.split('/')[1] === 'gif') || value.type.startsWith('video/')) {
                return (
                  <FormWrapper key={key}>
                    <TestImg src={`data:image/jpeg;base64,${value.descimage}`} />
                    <FormSubWrapper>
                      <InputTitle>{value.title}</InputTitle>
                      <UploadInput type="file" accept="video/mp4, video/mpeg" onChange={(e) => handleFileChange(e, key)} ref={(el) => (fileInputRefs.current[key] = el)} />
                      {selectedFiles.find((item) => item.key === key) ? (
                        <SubmitImgWrapper>
                          <PreviewVideo src={selectedFiles.find((item) => item.key === key).preview} onClick={() => triggerFileInput(key)} alt="Preview" />
                        </SubmitImgWrapper>
                      ) : (
                        <SubmitImgWrapper>
                          <PreviewImg src={'/images/BridgeServer/image.svg'} onClick={() => triggerFileInput(key)} />
                        </SubmitImgWrapper>
                      )}
                    </FormSubWrapper>
                  </FormWrapper>
                );
              }
              return null;
            })}
            <InputWrapper>
              {
                <ResultWrapper>
                  {!isLoading && !imageSrc && !videoSrc && !isError && (
                    <>
                      <WaitingText>잠시만 기다려 주세요.</WaitingText>
                    </>
                  )}
                  {isLoading && !isError && (
                    <LoadingWrapper>
                      <LoadingImg src={'/images/BridgeServer/spinner.svg'} />
                      <LoadingText>{progress}</LoadingText>
                    </LoadingWrapper>
                  )}
                  {!isLoading && imageSrc && !isError && (
                    <DownloadWrapper>
                      <ImageDisplay
                        id="result-image"
                        src={imageSrc}
                        onClick={() => {
                          downloadImageWithCanvas(imageSrc);
                        }}
                      />
                      <DownloadBtn
                        disabled={isDownLoaded}
                        onClick={() => {
                          downloadImageWithCanvas(imageSrc);
                        }}
                      >
                        다운로드
                      </DownloadBtn>
                    </DownloadWrapper>
                  )}

                  {!isLoading && !isError && videoSrc ? (
                    <>
                      <GunVideo controls>
                        <source src={videoSrc} type="video/mp4" />
                        Your browser does not support the video tag.
                      </GunVideo>
                      <DownloadBtn disabled={isDownLoaded} onClick={downloadVideo}>
                        다운로드
                      </DownloadBtn>
                    </>
                  ) : (
                    <>{!isLoading && !videoSrc && resType === 'video' && <p>Loading video...</p>}</>
                  )}
                  {isError && (
                    <>
                      <WaitingText>잠시 후 다시 시도해주세요.</WaitingText>
                    </>
                  )}
                </ResultWrapper>
              }
            </InputWrapper>
          </Slider>
        </InputWrapper>
      </MainWrapper>
    </MainContainer>
  );
};

export default Workflow;
