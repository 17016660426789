import axios from './config';
import { tokenConfig } from './TokenConfig';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getWorkSave() {
    return axios.get('canvas/worksave/', tokenConfig());
  },
  postWorkSave(data) {
    return axios.post('canvas/worksave/save_work/', data, tokenConfig());
  },
};
