import axios from './config';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getStyleList() {
    return axios.get('studio/style/');
  },

  getSampleList() {
    return axios.get('studio/sample_photo/');
  },
  getCategoryList() {
    return axios.get('studio/category/');
  },
  getTemplate(id) {
    return axios.get(`studio/background_image/${id}/`);
  },
  getRecommendColor(id) {
    return axios.get(`studio/recommend_color/${id}`);
  },
  getRecommendBase(id) {
    return axios.get(`studio/recommend_base/${id}`);
  },

  recommendColorPaginationList(template_id, query = '') {
    return axios.get(`studio/recommend_color_paginated/${template_id}?${query}`);
  },

  recommendBasePaginationList(template_id, query = '') {
    return axios.get(`studio/recommend_base_paginated/${template_id}?${query}`);
  },
};
