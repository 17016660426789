/**********************************
 * Name : ModalPortal.js
 * Author : Nam Sanglim
 * Introduction : Portal을 통해 #modal-root에 child 컴포넌트를 렌더링해주는 Container
 * Portal을 통해 #modal-root에 child 컴포넌트를 렌더링해준다
 ********************************** */

import React from 'react';
import ReactDOM from 'react-dom';

const ModalPortal = ({ children }) => {
  const modalRoot = document.getElementById('modal-root');
  return ReactDOM.createPortal(children, modalRoot); //렌더링할 컴포넌트, 타겟노드
};

export default ModalPortal;
