/**********************************
 * 파일명: [join.js]
 * 작성자: [Donghwoo Cho]
 * 설명: [회원가입 페이지]
 **********************************/

import * as style from './styles';
import { useState } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { setCookie, deleteCookie } from 'cookies-next';
import { UserLoginState } from 'state/state';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { JoinEmail, JoinPassword, JoinName, JoinPhoneNum, JoinTermsOfService } from 'components/Join';
import auth from 'api/accountAPI';
import authState from 'state/authState';
import tier from 'api/data/membershipBenefits';
import Swal from 'sweetalert2';

function Join() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [, setUser] = useRecoilState(authState);
  const [, setUserState] = useRecoilState(UserLoginState);
  const [searchParams] = useSearchParams();

  // 회원가입 정보 저장
  const [joinInfo, setJoinInfo] = useState({ email: '', password1: '', password2: '', name: '', phone_num: '' });
  const [checked, setChecked] = useState({ serviceInfo: false, privateInfo: false, marketInfo: false });

  const saveUserInfo = async (access, refresh) => {
    setCookie('access_token', access);
    setCookie('refresh_token', refresh);
    await auth
      .getUser()
      .then((res) => {
        setUser({
          isLoggedIn: true,
          name: res.data.name,
          email: res.data.email,
          social_img: res.data.social_img,
          user_id: res.data.id,
          access_token: access,
          refresh_token: refresh,
          canvas_sum: res.data.canvas_sum,
          download_sum: res.data.download_sum,
          tier: tier[res.data.level],
        });
        // setLoading(false);
        Swal.fire({
          text: t('alert.login'),
          customClass: {
            popup: 'custom-popup-class', // 모달 다이얼로그 스타일
          },
        });
        navigate('/mypage');
      })
      .catch((e) => {
        deleteCookie('access_token');
        deleteCookie('refresh_token');
        console.log(e);
        // setLoading(false);
        Swal.fire({
          text: t('alert.loginFailed'),
          customClass: {
            popup: 'custom-popup-class', // 모달 다이얼로그 스타일
          },
        });
      });
  };

  const submitSignup = async () => {
    // 필수항목 체크(동의)하지 않으면 회원가입 불가능
    if (checked.serviceInfo === false || checked.privateInfo === false) {
      Swal.fire({
        text: t('alert.requiredContents'),
        customClass: {
          popup: 'custom-popup-class', // 모달 다이얼로그 스타일
        },
      });

      return -1;
    }
    await auth
      .signUp(joinInfo)
      .then(function (res) {
        saveUserInfo(res.data.access_token);
        Swal.fire({
          text: t('alert.successSignUp'),
          customClass: {
            popup: 'custom-popup-class', // 모달 다이얼로그 스타일
          },
        });

        setUserState('loginInfo');
        navigator('/login');
      })
      .catch((error) => {
        if (error.response) {
          // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
          if ('password1' in error.response.data) {
            let p_list = error.response.data.password1;
            let warning = '';
            for (let p of p_list) {
              if (p === 'This password is too common.') {
                warning += t('alert.simplePassword');
              } else if (p === 'This password is too short. It must contain at least 8 characters.') {
                warning += t('alert.shortPassword');
              } else if (p === 'This password is entirely numeric.') {
                warning += t('alert.entirelyNumericPassword');
              }
            }
            if (warning === '') {
              Swal.fire({
                text: t('alert.failedSignUp'),
                customClass: {
                  popup: 'custom-popup-class',
                },
              });
            } else {
              Swal.fire({
                text: warning,
                customClass: {
                  popup: 'custom-popup-class',
                },
              });
            }
          } else {
            Swal.fire({
              text: t('alert.failedSignUp'),
              customClass: {
                popup: 'custom-popup-class',
              },
            });
          }
        } else {
          Swal.fire({
            text: t('alert.failedSignUp'),
            customClass: {
              popup: 'custom-popup-class',
            },
          });
        }
      });
  };

  /**
   * 회원가입 절차에 따라 컴포넌트를 렌더링하는 함수
   *
   * @param {String} 매개변수1 - 매개변수1에 대한 설명
   * @returns {Object} - 현재 회원가입 진행 컴포넌트
   */
  const renderJoinComponent = () => {
    switch (searchParams.get('phase')) {
      case '1':
        return <JoinEmail id={'join1'} joinInfo={joinInfo} setJoinInfo={setJoinInfo} />;
      case '2':
        return <JoinName id={'join2'} joinInfo={joinInfo} setJoinInfo={setJoinInfo} />;
      case '3':
        return <JoinPassword id={'join3'} joinInfo={joinInfo} setJoinInfo={setJoinInfo} />;
      case '4':
        return <JoinPhoneNum id={'join4'} joinInfo={joinInfo} setJoinInfo={setJoinInfo} />;
      case '5':
        return <JoinTermsOfService id={'join5'} checked={checked} setChecked={setChecked} submitSignup={submitSignup} />;
      default:
        navigate('/join?phase=1');
    }
  };

  return (
    <style.LoginContents>
      <style.Wrapper>
        <style.LoginDiv>
          <form style={{ position: 'relative', height: '100%' }}>{renderJoinComponent()}</form>
        </style.LoginDiv>
        <style.ImageDiv>
          <style.RightImage src={process.env.PUBLIC_URL + '/images/Signup/JoMaloneBG.jpg'} />
        </style.ImageDiv>
      </style.Wrapper>
    </style.LoginContents>
  );
}

export default Join;
