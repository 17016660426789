import * as style from '../Login/styles';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { TermsOfServiceModal } from 'containers/termsOfServiceModal';
import { UserLoginState } from 'state/state';
import { gapi } from 'gapi-script';
import auth from 'api/accountAPI';
import Swal from 'sweetalert2';
import GoogleLoginButton from './GoogleLoginButton';
import { GoogleOAuthProvider } from '@react-oauth/google';
import getKakaoToken from 'api/kakaoAuthenticate';

function RedirectURI() {
  if (window.location.hostname === 'localhost') return 'http://localhost:3000/login';
  // else return `https://${window.location.hostname}/login`;
  else return `http://${window.location.hostname}/login`;
}

function LoginInfo(props) {
  const { t } = useTranslation();

  // 서비스 이용약관 모달
  const [onModal, setOnModal] = useState(false);
  const [, setUserState] = useRecoilState(UserLoginState);
  const location = useLocation();
  const navigate = useNavigate();

  //카카오 소셜로그인 키
  const REST_API_KEY = process.env.REACT_APP_KAKAO_REST_API_KEY;
  const CLIENT_SECRET = process.env.REACT_APP_KAKAO_CLIENT_SECRET;
  const REDIRECT_URI = RedirectURI();

  // 구글 소셜로그인 clientID
  const clientID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?response_type=code&client_id=${REST_API_KEY}&redirect_uri=${REDIRECT_URI}`;
  const kakaoLogin = () => {
    window.location.href = KAKAO_AUTH_URL;
  };

  useEffect(() => {
    gapi.load('client:auth2', getGoogleToken);
    const params = new URLSearchParams(location.search);
    const code = params.get('code');
    if (code) {
      kakaoSocialLogin(code);
    }
  }, []);

  const getGoogleToken = () => {
    gapi.client.init({
      clientID,
      scope: 'email',
    });
  };
  const kakaoSocialLogin = async (code) => {
    try {
      console.log('REDIRECT_URI : ', REDIRECT_URI);

      const tokenResponse = await getKakaoToken(REST_API_KEY, REDIRECT_URI, code, CLIENT_SECRET);
      const data = await tokenResponse.json();
      console.log('data : ', data);
      if (data.access_token) {
        try {
          const authResponse = await auth.kakaoAuthenticate({ access_token: data.access_token, code: code });
          props.saveUserInfo(authResponse.data.access_token, authResponse.data.refresh_token);
        } catch (authErr) {
          console.log('authErr.response : ', authErr.response);
          if (authErr.response) {
            alert(authErr.response.data.err_msg);
          }
          console.error('Authentication 실패:', authErr);
        }
      } else {
        console.log('error : ', data);
        Swal.fire({
          text: t('alert.invalidMember'),
          customClass: {
            popup: 'custom-popup-class',
          },
        });
        navigate('/login');
      }
    } catch (err) {
      console.error('Token 요청 실패:', err);
      Swal.fire({
        text: 'An error occurred during the token request.',
        customClass: {
          popup: 'custom-popup-class',
        },
      });
      navigate('/login');
    }
  };

  const startWithEmail = () => {
    setUserState('loginEmail');
  };
  const JoinFavorfit = () => {
    // setUserState('joinUs');
    navigate('/join?phase=1');
  };

  return (
    <style.LoginInfoContainer>
      <style.LoginMessage>{t('login.LoginInfo.LoginMessage')}</style.LoginMessage>
      <style.LoginText>
        {t('login.LoginInfo.LoginText1_1')}
        <br />
        {t('login.LoginInfo.LoginText1_2')}
      </style.LoginText>
      <style.SocialLoginWrap>
        <GoogleOAuthProvider clientId={clientID}>
          <GoogleLoginButton saveUserInfo={props.saveUserInfo} />
        </GoogleOAuthProvider>
        <style.GoogleLogin onClick={kakaoLogin} BGcolor={'#fee500'}>
          <style.SocialLoginIcon src={'/images/Signup/kakao_icon.svg'} />
          {t('login.LoginInfo.KakaoLogin')}
        </style.GoogleLogin>
      </style.SocialLoginWrap>
      <style.DivideLine>
        <style.DivideSection></style.DivideSection>
        {t('login.LoginInfo.Or')}
        <style.DivideSection></style.DivideSection>
      </style.DivideLine>
      <style.GrayButton onClick={startWithEmail}>{t('login.LoginInfo.GrayButton')}</style.GrayButton>
      <style.LoginText2 color={'#aaaaaa'}>
        {t('login.LoginInfo.LoginText2_1')}
        <style.LoginText2 color={'#287ced'} decoration={'underline'} cursor={'pointer'} onClick={() => setOnModal(true)}>
          {t('login.LoginInfo.LoginText2_2')} {onModal && <TermsOfServiceModal setOnModal={(bool) => setOnModal(bool)} />}
        </style.LoginText2>
        {t('login.LoginInfo.LoginText2_3')}
        <style.LoginText2 color={'#287ced'} decoration={'underline'} cursor={'pointer'} onClick={() => navigate('/policy')}>
          {t('login.LoginInfo.LoginText2_4')}
        </style.LoginText2>
        {t('login.LoginInfo.LoginText2_5')}
        <br />
        {t('login.LoginInfo.LoginText2_6')}
      </style.LoginText2>
      <style.JoinUsText>
        <span>{t('login.LoginInfo.JoinUsText')}</span>
        <style.JoinUsBtn onClick={JoinFavorfit}>{t('login.LoginInfo.JoinUsBtn')}</style.JoinUsBtn>
      </style.JoinUsText>
    </style.LoginInfoContainer>
  );
}

export default LoginInfo;
