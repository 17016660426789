/**********************************
 * 파일명: rightSidebar.js
 * 작성자: Jiwon Kang
 * 설명: 캔버스의 오른쪽 사이드바
 **********************************/

import { useRecoilValue } from 'recoil';
import { rightSidebarState } from 'state/sidebarState';
import * as style from 'pages/Canvas/styles.js';
import ShapeEditor from './shapeEditor';
import TextEditor from './textEditor';
import ImageEditor from './imageEditor';
import BrushBlock from './brushBlock';
import Layer from './layer';
import QRGenerater from './qrGenerater';
import { shapeTypeList } from 'constants/list';

// eslint-disable-next-line
function RightSidebar({ bgTemplateId, propsSetImgValue, getTemplate, setSvgPP }) {
  const rightState = useRecoilValue(rightSidebarState);

  return (
    <style.RightSide id="rightSideBar">
      <style.RightSideComponents rightState={rightState}>
        {shapeTypeList.includes(rightState) ? <ShapeEditor propsSetImgValue={propsSetImgValue} /> : null}
        {rightState === 'text' ? <TextEditor propsSetImgValue={propsSetImgValue} /> : null}
        {rightState === 'image' ? <ImageEditor bgTemplateId={bgTemplateId} propsSetImgValue={propsSetImgValue} getTemplate={getTemplate} /> : null}
        {rightState === 'components' ? <Layer /> : null}
        {rightState === 'brush' ? <BrushBlock /> : null}
      </style.RightSideComponents>
      {rightState === 'components' ? <QRGenerater bgTemplateId={bgTemplateId} setSvgPP={setSvgPP} /> : null}
    </style.RightSide>
  );
}

export default RightSidebar;
