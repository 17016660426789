import { atom } from 'recoil';

export const saveHandclick = atom({
  key: 'saveHandclick',
  default: false,
});
// pp수정에서 fabric으로 이동할때를 감지하는 state
export const svgConverterTriggerState = atom({
  key: 'svgConverterTriggerState', // atom를 지칭할 수 있는 unique ID
  default: false,
});

export const escapeRestoreState = atom({
  key: 'escapeRestoreState', // atom를 지칭할 수 있는 unique ID
  default: false,
});

export const saveCanvasState = atom({
  key: 'saveCanvasState',
  default: false,
});

export const downloadCanvasState = atom({
  key: 'DownloadCanvasState',
  default: {
    on: false,
  },
});

export const saveSvgState = atom({
  key: 'saveSvgState',
  default: {
    on: false,
  },
});

export const genPpShadowState = atom({
  key: 'genPpShadowState',
  default: 0,
});

// shadow api 사용하기 위한 useEffect 사용하기 위한 값이 있는 state. null, 'manual', 'floor', 'topdown' 이 있음
export const postRequestShadowState = atom({
  key: 'postRequestShadowState',
  default: 0,
});
