/**********************************
 * Name : detailView.js
 * Author : Park JeongMyeong
 * Introduction : Template 선택 Component 중 '더보기' 클릭 시 보여주는 Detail View Component
 ********************************** */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as Style from '../styles';
import './style.css';
import useFetch from './useFetch';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { bgImageState } from 'state/imageState';
import { addImage, addObjectSelectEvent, generateObjectId, getTemplate } from 'utils/canvasObjectGenerateUtil';
import { objectList, selectedObject, workspaceSizeState } from 'state/canvasState';
import { rightSidebarState } from 'state/sidebarState';

function DetailView(props) {
  const { t } = useTranslation();
  const [canvasObjectList, setCanvasObjectList] = useRecoilState(objectList);
  const setRightState = useSetRecoilState(rightSidebarState);
  const setSelectedIndex = useSetRecoilState(selectedObject);
  const setWorkspaceSize = useSetRecoilState(workspaceSizeState);

  const { viewSelect } = props;
  const bgClick = async (imgData) => {
    const id = generateObjectId('bg');
    const { fabric: canvas } = document.getElementById('canvas');
    const template = await addImage(canvas, await getTemplate(imgData.id), { id, templateId: imgData.id, name: id, isBackground: true }, () => {
      canvas.forEachObject((obj) => {
        if (obj.id !== id && obj.isBackground) {
          canvas.remove(obj);
          canvas.requestRenderAll();
          console.log(canvasObjectList);
          setCanvasObjectList(
            [...canvasObjectList].filter((object) => {
              return object.id !== obj.id;
            }),
          );
        }
      });
    });
    const canvasObjectInfo = {
      id,
      name: template.name,
      type: template.type,
      src: template.src,
    };
    setCanvasObjectList((prev) => [...prev, canvasObjectInfo]);
    addObjectSelectEvent(template, setSelectedIndex, setRightState);
    setWorkspaceSize({
      width: template.width * template.scaleX,
      height: template.height * template.scaleY,
    });
    canvas.sendToBack(template);
  };

  // InfiniteScroll (무한스크롤 - 페이지네이션을 위한 코드)
  const MIN_HEIGHT = 100 * 8; // px * 갯수 (최소 높이)
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState('page=');
  const [bgImage, setBgImage] = useRecoilState(bgImageState);
  const { loading, list } = useFetch(query, page, props.category);
  const loader = useRef(null);
  const screen = useRef(null);

  const handleObserver = useCallback((entries) => {
    const target = entries[0];
    if (target.isIntersecting) {
      setPage((prev) => prev + 1);
    }
  }, []);

  useEffect(() => {
    const option = {
      root: null,
      threshold: 1,
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loader.current) observer.observe(loader.current);
  }, [handleObserver, screen]);

  useEffect(() => {
    const height = screen.current.clientHeight;
    const pageCnt = parseInt((((height - MIN_HEIGHT) / 100) * 2) / 8) + 1; // 남은 공간 스크롤 구현
    if (page < pageCnt && height >= MIN_HEIGHT) {
      setPage((prev) => prev + 1);
    }
  }, [page]);

  return (
    <>
      <Style.MainContainer ref={screen}>
        <Style.TitleDiv>
          <Style.TopTitle>{t('canvas.detailView.Title')}</Style.TopTitle>
          <Style.BackBtn onClick={props.viewDefault} style={{ marginBottom: '10px' }}>
            {t('canvas.detailView.Back')}
          </Style.BackBtn>
        </Style.TitleDiv>
        <Style.TemplateGrid>
          {list.map((i, idx) => {
            return (
              <Style.TemplateThumbnail
                key={idx}
                onClick={() => {
                  bgClick(i);
                  viewSelect(i.id);
                }}
                src={i.image_small}
              />
            );
          })}
          {loading && <img src="/logo.png" />}
          <div ref={loader} />
        </Style.TemplateGrid>
      </Style.MainContainer>
    </>
  );
}

export default DetailView;
