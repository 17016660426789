import styled from 'styled-components';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Slider as muiSlyder } from '@mui/material';
import { withStyles } from '@mui/styles';
import Slider from 'react-slick';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  align-content: space-between;
  height: 100%;
  padding: 10px 10px;
  > h2 {
    font-size: 20px;
    text-align: center;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.36px;
    color: #222;
    margin-bottom: 16px;
    height: 40px;
  }

  @media screen and (max-width: 1024px) {
    > h2 {
      font-size: 14px;
    }
  }
  @media screen and (max-width: 768px) {
    > h2 {
      font-size: 13px;
    }
  }
`;

export const ModifyImg = styled.div`
  display: flex;
  gap: 6px;
  margin-bottom: 18px;

  > div {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 18px 0;
    width: 50%;
    border-radius: 8px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.02);
    border: solid 1px #ededed;
    background-color: #fff;
    transition: all 0.3s ease;

    &:hover {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 15%);
    }
  }

  > div > span {
    font-size: 12px;
    text-align: center;
  }

  @media screen and (max-width: 1440px) {
    > div {
      padding: 16px 0;
    }
    > div > img {
      width: 24px;
    }
    > div > span {
      font-size: 12px;
    }
  }
  @media screen and (max-width: 1024px) {
    > div {
      padding: 14px 0;
    }
    > div > img {
      width: 20px;
    }
    > div > span {
      font-size: 11px;
    }
  }
`;

export const SettingImg = styled.div`
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.04);
  background-color: #fff;
  padding: 14px;
  margin-bottom: 20px;
  color: #394050;
  border-radius: 8px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.02);
  border: solid 1px #ededed;

  > div {
    padding: 4px 0;
  }
`;
// 유사 템플릿 추천

export const SimilarTemplate = styled.div`
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #fd5c5c;
    opacity: 0.75;
    border-radius: 10px;
  }
`;

export const Arrow = styled.button`
  width: 5vw;
  &::before {
    color: #f54545;
  }
`;

export const Section = styled.div`
  padding-right: 5px;
  margin-bottom: 20px;
`;

export const TitleDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
`;
export const SubTitle = styled.h1`
  font-size: 12pt;
  margin-top: 5px;
  margin-bottom: 14px;
`;

export const DetailButton = styled.button`
  cursor: pointer;
  border: none;
  padding: 0;
  font-size: 14px;
  color: #f54545;
  background-color: unset;
`;

export const TemplateThumbnail = styled.img`
  background-size: cover;
  object-fit: contain;
  user-select: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 120px;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  margin-top: 10px;
  cursor: pointer;
  @media screen and (max-width: 1440px) {
    height: 100px;
  }
  @media screen and (max-width: 1024px) {
    height: 80px;
  }
`;

export const SettingText = styled.div`
  border-radius: 2px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.04);
  background-color: #fff;
  padding: 10px;
  margin-bottom: 12px;

  display: flex;
  justify-content: space-between;
  gap: 3%;
`;

export const SettingOption = styled.img`
  cursor: pointer;
  border-radius: 2px;
  background-color: ${(props) => (props.data ? '#f5454570' : '#ffffff')};
  width: 100%;
  height: 100%;

  &:hover {
    background-color: ${(props) => (props.data ? '#f5454570' : '#ededed')};
  }
`;

export const BtnWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;

  @media screen and (max-width: 1024px) {
    > div {
      font-size: 14px;
    }
  }
`;

export const BtnWrapRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  > button {
    all: unset;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 8px;
    background-color: #fff;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.3px;
    color: #394050;
    margin: 0px 2px;
    width: 95%;
    transition: all 0.3s ease;
    border-radius: 8px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.02);
    border: solid 1px #ededed;

    &:hover {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 15%);
    }

    > img {
      margin-bottom: 10px;
    }
  }

  @media screen and (max-width: 1440px) {
    > button {
      padding: 16px;
    }
    > button > span {
      font-size: 12px;
    }
  }

  @media screen and (max-width: 1024px) {
    > button {
      padding: 14px;
    }
    > button > span {
      font-size: 11px;
    }
  }
`;

export const SwitchWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > span {
    display: flex;
    align-items: center;
    gap: 6px;
  }
  > span > span {
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.35px;
    color: #222;
  }
  @media screen and (max-width: 1024px) {
    > span > img {
      width: 19.5px;
    }
  }
  @media screen and (max-width: 768px) {
    > span > img {
      width: 18px;
    }
  }
`;

export const RangeWrap = styled.div`
  display: flex;
  flex-direction: column;

  > h3 {
    font-weight: bold;
    margin-bottom: 10px;
  }

  > span {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.35px;
    ${'' /* color: #222; */}
    margin-bottom: 4px;
  }

  > div {
    display: flex;
    gap: 8px;
    margin-top: 8px;
    margin-bottom: 20px;
  }

  > select {
    display: flex;
    gap: 8px;
    margin-top: 8px;
    margin-bottom: 10px;

    > option {
      background-color: red;
    }
  }
`;

export const Colors = styled.span`
  background-color: ${(props) => props.bgColor};
  width: 20px;
  height: 20px;
  border-radius: 70%;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
`;

export const LayerComponent = styled.div`
  user-select: none;
  padding: 20px 0;
  border-bottom: 1px solid #ededed;
  display: flex;
  align-items: center;
  gap: 20px;
  cursor: pointer;

  > img {
    width: 46px;
    height: 46px;
    object-fit: cover;
  }
  > span {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.35px;
    color: #222;
  }
  &:hover {
    background-color: #f0f0f0;
  }
  @media screen and (max-width: 1440px) {
    padding: 18px 0;
    > img {
      width: 42px;
      height: 42px;
    }
  }
  @media screen and (max-width: 1024px) {
    padding: 15px 0;
    > img {
      width: 36px;
      height: 36px;
    }
    > span {
      font-size: 1px;
    }
  }
  @media screen and (max-width: 768px) {
    padding: 12px 0;
    > img {
      width: 32px;
      height: 32px;
    }
    > span {
      font-size: 10px;
    }
  }
`;

export const switchBtn = styled.label`
  position: relative;
  display: inline-block;
  width: 38px;
  height: 25.5px;

  > input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  > span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  .slider:before {
    position: absolute;
    content: '';
    height: 18.5px;
    width: 18.5px;
    left: 3.5px;
    top: 3.5px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  > input:checked + .slider {
    background-color: #f54545;
  }
  > input:checked + .slider:before {
    -webkit-transform: translateX(13px);
    -ms-transform: translateX(13px);
    transform: translateX(13px);
  }
  > span {
    border-radius: 24px;
  }
  .slider.round:before {
    border-radius: 50%;
  }
  @media screen and (max-width: 1024px) {
    width: 42.75px;
    height: 22.5px;
    .slider:before {
      position: absolute;
      content: '';
      height: 15px;
      width: 15px;
      left: 3.75px;
      top: 3.75px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  }
  @media screen and (max-width: 768px) {
    width: 33.75px;
    height: 19.5px;
    .slider:before {
      position: absolute;
      content: '';
      height: 12px;
      width: 12px;
      left: 2.25px;
      top: 3.75px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  }
`;

export const ControllerDiv = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f8f8f8;
  justify-content: center;
  height: fit-content;
  padding: 30px 0;
  > span {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.35px;
    text-align: center;
    color: #767676;
    margin-bottom: 24px;
  }
  > button {
    all: unset;
    cursor: pointer;
    padding: 20px;
    background-color: #ffe5e5;
    margin: 10px;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: -0.35px;
    color: #767676;
    border-radius: 8px;
    transition: all 0.3s ease;

    &:hover {
      box-shadow: 0 4px 4px rgb(0 0 0 / 15%);
    }
  }
  @media screen and (max-width: 1024px) {
    > button {
      padding: 18px;
      margin: 16px;
    }
    > span {
      padding: 0 10px;
      font-size: 12px;
    }
  }
  @media screen and (max-width: 768px) {
    > button {
      padding: 16px;
      margin: 12px;
    }
    > span {
      font-size: 10px;
    }
  }
`;

export const BrushModeDiv = styled.div`
  padding: 0 0 20px 0;
  margin: 0 20px 18px;
  border-bottom: 1px solid #dcdcdc;
  display: flex;
  flex-direction: column;
`;

export const optionText = styled.div`
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.36px;
  color: #222;
  @media screen and (max-width: 1440px) {
    font-size: 18px;
  }
  @media screen and (max-width: 1024px) {
    font-size: 16px;
  }
`;

export const StyledToggleGroup = withStyles({
  root: {
    justifyContent: 'center',
    width: '100%',
  },
})(ToggleButtonGroup);

export const BrushToggleButton = withStyles({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
})(ToggleButton);

export const BrushSizeDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 5%;
  > span {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.35px;
  }
`;

export const SizeSlider = withStyles({
  root: {
    color: '#ffffff',
    height: 3,
    width: '100%',
  },
  thumb: {
    height: 12,
    width: 12,
    color: '#ffffff',
    border: '2px solid #f54545',
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },

  track: {
    //슬라이드 후 레일
    color: '#f54545',
    height: 3,
    borderRadius: 4,
  },
  rail: {
    //슬라이드 전 레일
    backgroundColor: '#f54545',
    color: '#f2f2f2',
    height: 3,
    borderRadius: 4,
    opacity: 1,
  },
})(muiSlyder);

export const sliderNotice = styled.p`
  font-size: 10px;
  font-family: NS;
  color: #6e6e6e;
  padding-top: 5px;
  padding-bottom: 35px;
  align-self: center;
`;

export const ButtonDiv = styled.div`
  margin-top: 8%;
  padding: 0 27px;
  position: absolute;
  bottom: 21px;
  width: 100%;
  ${(props) => (props.visibleModal ? 'z-index: 3;pointer-events: none;' : null)};
`;

export const MoodContainer = styled.div`
  width: 100%;
  margin-bottom: 22px;
  display: flex;
  flex-direction: column;
`;

export const MoodBackground = styled.div`
  width: 100%;
  height: 110px;
  background-image: linear-gradient(to right, #fdd5a7, #ff69a0 48%, #bda0e2);
  display: flex;
  flex-direction: column;
`;
export const MoodText = styled.div`
  height: 27px;
  color: #fff8f8;
  font-size: 12px;
  font-family: NS-B;
  padding: 8px 0;
  align-self: center;
`;
export const Mood = styled.div`
  width: 56px !important;
  height: fit-content;
  text-align: center;
  & img {
    width: 56px;
    height: 56px;
    margin: 0 auto;
  }
  & span {
    display: inline-block;
    color: #fff8f8;
    font-size: 1em;
    -webkit-transform: scale(0.7);
    word-break: keep-all;
  }
`;

export const moodSlider = styled(Slider)`
  width: 100%;
  height: 84px;
  background: rgba(255, 255, 255, 0.16);
  & .slick-list {
    margin: 0 auto;
  }
  & .slick-slide {
    height: 80px;
    opacity: 0;
    transition: opacity 0.5s;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 38px;
      height: 38px;
    }
    div:first-child {
      height: fit-content;
    }
    span {
      font-size: 10px;
      -webkit-transform: scale(0.8);
    }
  }
  & .slick-active {
    opacity: 1;
    width: 6% !important;
  }
  & .slick-center {
    img {
      width: 56px;
      height: 56px;
    }
    span {
      font-size: 10px;
      -webkit-transform: scale(1);
    }
  }
`;

export const HSVDiv = styled.div`
  width: 100%;
  ${'' /* margin: 0 auto 15px auto; */}
`;

export const HSVHeader = styled.div`
  display: flex;
  ${'' /* justify-content: center; */}
  align-items: center;
  gap: 8px;
  height: 23px;
  margin-bottom: 5px;
`;

export const HSVBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ValueInput = styled.input`
  all: unset;
  height: 20px;
  border: solid 1px #dbdbdb;
  background-color: #fff;
  padding-left: 10px;
  width: 25px;
  margin-left: 15px;
  font-size: small;
  border-left-width: 0;
  border-right-width: 0;
  border-top-width: 0;
  border-bottom-width: 1;
  /* Chrome, Safari, Edge, Opera */
  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const OptionIcon = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-image: linear-gradient(${(props) => props.bg});
  ${(props) => (props.border ? 'border: 1px solid #6e6e6e' : null)};
`;
export const OptionText = styled.div`
  font-size: 12px;
  color: #6e6e6e;
  font-family: 'NS-R';
  user-select: none;
  font-weight: 900;
`;

export const HSVSlider = withStyles({
  root: {
    color: '#F54545',
    height: 8,
    width: '100%',
    padding: 0,
  },
  thumb: {
    height: 12,
    width: 12,
    color: '#ffffff',
    border: '2px solid #F54545',
    borderRadius: '6px',
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: '-50%',
    color: '#000',
  },
  track: {
    height: 6,
    color: '#F54545',
    borderRadius: 4,
  },
  rail: {
    height: 4,
    borderRadius: 4,
    color: '#FFFFFF',
    opacity: 1,
    border: '1px solid #BEBEBE',
  },
})(muiSlyder);

export const SliderNextArrow = styled.div`
  width: 6px;
  height: 10.5px;
  right: 12% !important;
  background: url(${process.env.PUBLIC_URL + '/images/Analysis/white-arrow-right.png'}) no-repeat;
  background-size: contain;
  &:before {
    content: '' !important;
  }
  &:hover {
    background: url(${process.env.PUBLIC_URL + '/images/Analysis/white-arrow-right.png'}) no-repeat;
    background-size: contain;
  }
`;
export const SliderPrevArrow = styled.div`
  width: 6px;
  height: 10.5px;
  left: 12% !important;
  background: url(${process.env.PUBLIC_URL + '/images/Analysis/white-arrow-left.png'}) no-repeat;
  background-size: contain;
  &:before {
    content: '' !important;
  }
  &:hover {
    background: url(${process.env.PUBLIC_URL + '/images/Analysis/white-arrow-left.png'}) no-repeat;
    background-size: contain;
  }
`;

export const FontSize = styled.label`
  position: relative;

  input {
    padding: 0 5% 0 5px;
    width: 35%;
    height: 21.2px;

    :focus {
      outline: none;
    }

    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  button {
    position: absolute;
    height: 100%;
    right: 65%;
  }

  ul {
    padding: 0 0 0 5px;
    z-index: 1;
    cursor: pointer;

    &:hover {
      background-color: #f5454570;
    }
  }

  div {
    border: 1px solid black;
    border-top: 0px;
    width: 25%;

    background: white;
    position: absolute;

    z-index: 2;
  }
`;

export const FontWeight = styled.label`
  position: relative;

  div {
    display: flex;
    justify-content: space-between;

    width: 40%;
    height: 24px;
  }

  button {
    background: #ffffff;
    border: 1px black solid;
    border-radius: 8px;

    cursor: pointer;
  }

  button:hover {
    background-color: #f2f2f2;
  }
`;

export const FontFamily = styled.label`
  position: relative;

  input {
    padding: 0 5% 0 5px;
    width: 90%;
    height: 21.2px;

    :focus {
      outline: none;
    }
  }
  button {
    position: absolute;
    height: 100%;
    right: 10%;
  }

  ul {
    padding: 0 0 0 5px;
    min-height: 18px;
    z-index: 1;
    cursor: pointer;

    &:hover {
      background-color: #f5454570;
    }
  }

  div {
    border: 1px solid black;
    border-top: 0px;
    width: 81%;

    background: white;
    position: absolute;

    z-index: 2;
  }
`;

export const TextAlign = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: -7px;
  margin-bottom: 10px;
`;

export const AlignOption = styled.img`
  cursor: pointer;
  border-radius: 2px;
  background-color: ${(props) => (props.data ? '#f5454570' : '#ffffff')};
  padding-top: 4%;
  width: 23%;
  height: 100%;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.04);
  &:hover {
    background-color: ${(props) => (props.data ? '#f5454570' : '#ededed')};
    box-shadow: 0 2px 4px rgba(0, 0, 0, 15%);
  }
`;

export const ColorIcon = styled.div`
  cursor: pointer;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border: ${(props) => (props.bgSrc ? 'none' : '1px solid #00000040')};
  border-radius: 50rem;
  background-color: ${(props) => props.bgColor};
  background-image: ${(props) => (props.bgSrc ? `url(${props.bgSrc})` : null)};

  background-size: cover;
`;

export const ColorIconWrap = styled.div`
  width: 100%;
  height: 100%;
`;

export const QrButtonWrap = styled.div`
  display: flex;
  width: 100%;
  ${'' /* height: 100%; */}
  align-items: end;
`;

export const QrButton = styled.button`
  all: unset;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.3);
  background-color: #f54545;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: -0.5px;
  color: #fff;
  padding: 18px 24px;
  margin-bottom: 10px;
  margin-top: 20px;
  height: 18px;
  width: 100%;
  text-align: center;
`;

export const shadowModeBtn = styled.input`
  accent-color: #f0353f;
`;

export const shadowModeBtnWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 33.3%;
`;

export const makeShadowButton = styled.button`
  all: unset;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.3);
  background-color: #f54545;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: -0.5px;
  color: #fff;
  padding: 6px 6px;
  height: 18px;
  width: 100%;
  text-align: center;
`;

export const RecommendationContainer = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow-y: hidden;
`;
