import styled from 'styled-components';

export const Wrap = styled.div`
  height: 100vh;
`;
export const Test = styled.div`
  display: flex;
  background-color: #00000000;
  border-bottom: 1px solid;
  overflow: hidden;
  height: calc(100% - 60px);
`;
export const SideBar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80px;
  background-color: #f2f4f4;
  border-right: 1px solid #dbdbdb;

  @media screen and (max-width: 1440px) {
    width: 80px;
  }
  @media screen and (max-width: 1024px) {
    width: 60px;
  }
`;
export const SideBarIcon = styled.button`
  all: unset;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  //transition: all 0.5s ease;
  width: 100px;
  height: 100px;
  background: ${(props) => (props.current ? '#ffffff' : null)};
  cursor: ${(props) => (props.enabled ? '' : 'pointer')};
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 15%);
  }

  > img {
    max-width: 30px;
    width: 100%;
  }
  > span {
    font-size: 12px;
    text-align: center;
  }
  @media screen and (max-width: 1440px) {
    width: 80px;
    height: 80px;

    > img {
      max-width: 30px;
      width: 100%;
    }
    > span {
      font-size: 12px;
      padding: 0 5px;
    }
  }
  @media screen and (max-width: 1024px) {
    width: 70px;
    height: 70px;

    > img {
      max-width: 20px;
      width: 100%;
    }
    > span {
      font-size: 10px;
    }
  }
`;
export const SideBarSub = styled.div`
  max-width: 400px;
  width: 100%;
  min-width: 340px;
  background-color: #ffffff;
  border-right: 1px solid #dbdbdb;
  @media screen and (max-width: 1440px) {
    max-width: 350px;
  }
  @media screen and (max-width: 1020px) {
    max-width: 350px;
  }
`;
export const MainContents = styled.div`
  width: 100%;
  height: 100%;
  background-color: #e9e9e9;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #fd5c5c;
    opacity: 0.75;
    border-radius: 10px;
  }
`;

export const ProductEditShow = styled.div`
  display: none;
  width: 100%;
  height: calc(100% - 110px);
  padding-top: 50px;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(256, 256, 256, 0.8);
  z-index: 4;
`;

export const RightSide = styled.div`
  max-width: 230px;
  width: 100%;
  min-width: 150px;
  background-color: #f9fafb;
  border-left: 1px solid #dbdbdb;
  position: relative;
  transition: margin-right 1s ease;
  overflow-y: auto;
  @media screen and (max-width: 1440px) {
    max-width: 240px;
  }
  @media screen and (max-width: 1024px) {
    max-width: 200px;
  }
`;

export const mainCanvas = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BlockContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const Block2 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
`;

export const displayBtn = styled.div`
  cursor: pointer;
  position: absolute;
  left: -30px;
  top: 10px;

  > img {
    width: 20px;
  }
`;

export const leftSidebarCloseBtn = styled.div`
  cursor: pointer;
  position: absolute;
  width: 20px;
  height: 20px;
  background: #00000000;
  transform: scaleX(-1);
  align-self: center;
  left: 450px;
  z-index: 1;
  > img {
    width: 20px;
    height: 20px;
    filter: hue-rotate(30deg);
  }
  @media screen and (max-width: 1440px) {
    left: 370px;
  }
  @media screen and (max-width: 1024px) {
    left: 300px;
  }
`;

export const QrButtonWrap = styled.div`
  display: flex;
  width: 100%;
  ${'' /* height: 100%; */}
  align-items: end;
`;

export const QrButton = styled.button`
  all: unset;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.3);
  background-color: #f54545;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: -0.5px;
  color: #fff;
  padding: 18px 24px;
  margin-bottom: 10px;
  margin-top: 20px;
  height: 18px;
  width: 100%;
  text-align: center;
`;

export const RightSideComponents = styled(RightSide)`
  height: ${(props) => (props.rightState === 'components' ? 'calc(100% - 100px)' : '100%')};
`;

export const QrContainer = styled.div`
  height: 100px;
  padding: 10px;
`;
