import styled from 'styled-components';

export const MainContainer = styled.div`
  max-width: 350px;
  width: 350px;
  height: 100%;
  padding: 18px 5px 18px 18px;
  overflow-y: scroll;
  background-color: #ffffff;
  position: absolute;

  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #fd5c5c;
    opacity: 0.75;
    border-radius: 10px;
  }
  @media screen and (max-width: 1440px) {
    max-width: 270px;
  }
  @media screen and (max-width: 1024px) {
    max-width: 250px;
  }
`;
export const TopTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  letter-spacing: -0.36px;
  color: #222;
  margin-bottom: 20px;
`;

export const BackBtn = styled.button`
  all: unset;
  //box-shadow: 0 2px 2px 0 rgb(0 0 0 / 25%);
  //padding: 8px 16px;
  //background-color: #efefef;
  //border-radius: 4px;
  cursor: pointer;
  color: #f54545;
  font-size: 14px;
`;

export const TemplateGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px 10px;
`;

export const TemplateThumbnail = styled.img`
  background-size: cover;
  object-fit: contain;
  user-select: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 120px;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  margin-top: 10px;
  cursor: pointer;
  @media screen and (max-width: 1440px) {
    height: 100px;
  }
  @media screen and (max-width: 1024px) {
    height: 80px;
  }
`;

export const SubTitle = styled.h1`
  //display: inline;
  font-size: 12pt;
  margin-top: 5px;
  margin-bottom: 14px;
`;

export const Tcategory = styled.div`
  height: 100%;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #fd5c5c;
    opacity: 0.75;
    border-radius: 10px;
  }
`;

export const Section = styled.div`
  //background: #efefef;
  padding-right: 5px;
  margin-bottom: 20px;
  //border-radius: 5px;
`;
export const PPvideo = styled.div`
  width: 100%;
  height: 240px;
  background-color: #000000;
  margin-bottom: 50px;
  color: #ffffff;
`;

export const video = styled.video`
  width: 100%;
  height: 100%;
`;

export const DetailButton = styled.button`
  cursor: pointer;
  border: none;
  padding: 0;
  font-size: 14px;
  color: #f54545;
  background-color: unset;
`;

export const TitleDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
`;

export const SelectedTemplate = styled.img`
  width: 100%;
`;

export const Title = styled.h1`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
  font-size: large;
  margin-bottom: 10px;
`;

export const Arrow = styled.button`
  width: 5vw;
  &::before {
    color: #f54545;
  }
`;
