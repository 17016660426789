/**********************************`
 `* Name : LoginButtonStyles.js`
 `* Author : Kim Minjeong(minjj0905)`
 `* Introduction : 스타일을 적용한 컴포넌트를 생성, LoginButton.js에서 사용.`
 `*`
 `********************************** */

import styled from 'styled-components';
import oc from 'open-color';
import { shadow } from 'utils/styleUtil';

// 로그인/로그아웃 버튼
export const BorderedButton = styled.button`
  font-family: 'NS-B';
  color: black;
  padding: 0.5rem 0.5rem 0.4rem 0.5rem;
  margin-right: 0.5rem;
  cursor: pointer;
  border: none;
  background: transparent;
  border-radius: 2px;
  text-decoration: none;
  transition: 0.2s all;
  font-size: 15px;

  &:hover {
    background: ${oc.pink[6]};
    color: white;
    ${shadow(1)}
  }

  &:active {
    /* 마우스 클릭시 움직임 */
    transform: translateY(1px);
  }
`;
