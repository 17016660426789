import React, { useEffect, useRef, useState } from 'react';
import * as Style from '../styles';
import { useTranslation } from 'react-i18next';

function SelectedView(props) {
  const { t } = useTranslation();

  const bgClick = (imgData) => {
    props.getTemplate(imgData.id);
  };

  const { viewSelect } = props;

  // InfiniteScroll (무한스크롤 - 페이지네이션을 위한 코드)
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState('?page=');
  // const { loading, error, list } = useFetch(query, page);
  const loader = useRef(null);
  const checkScroll = useRef(null);

  // const handleObserver = useCallback((entries) => {
  //   const target = entries[0];
  //   if (target.isIntersecting) {
  //     setPage((prev) => prev + 1);
  //   }
  // }, []);
  //
  // useEffect(() => {
  //   // if (checkScroll.current.clientHeight < height)
  //   const option = {
  //     root: null,
  //     threshold: 1,
  //   };
  //   const observer = new IntersectionObserver(handleObserver, option);
  //   if (loader.current) observer.observe(loader.current);
  // }, [handleObserver]);

  // 관련된 배경 보여줌 (현재 백엔드 페이지네이션 구현 안되어 있어서 무한 스크롤 제외)

  // const [result, setResult] = useState([]);
  // const [loading, setLoading] = useState(true);
  // const getRecommandTemplate = (id) => {
  //   templateAPI.recommandList(id).then(function (res) {
  //     setResult(res.data);
  //     setLoading(false);
  //   });
  // };

  // useEffect(() => {
  //   setLoading(true);
  //   getRecommandTemplate(props.selectImage);
  // }, [props.selectImage]);

  return (
    <>
      <Style.MainContainer>
        <Style.BackBtn onClick={props.viewDefault} style={{ marginBottom: '10px' }}>
          {t('canvas.selectedView.Back')}
        </Style.BackBtn>
        <Style.Title> {t('canvas.selectedView.Title')} </Style.Title>
        <Style.Section>
          {/*<Style.SelectedTemplate src={props.selectImage} />*/}
          <Style.PPvideo>
            <Style.video video controls>
              <source src="/video/topViewKor.mp4" type="video/mp4" />
            </Style.video>
          </Style.PPvideo>
        </Style.Section>
        {/* <Style.SubTitle>{t('canvas.selectedView.SubTitle')}</Style.SubTitle> */}
        <Style.Section>
          <Style.TemplateGrid>
            {/*{loading && <img src="/logo.png" />}*/}
            {/*{error && <p>Error!</p>}*/}
            {/*{list.map((i, idx) => {*/}
            {/*  return (*/}
            {/*    <Style.TemplateThumbnail*/}
            {/*      key={idx}*/}
            {/*      onClick={() => {*/}
            {/*        bgClick(i);*/}
            {/*        viewSelect(i.image_small);*/}
            {/*      }}*/}
            {/*      src={i.image_small}*/}
            {/*    />*/}
            {/*  );*/}
            {/*})}*/}
            {/*<div ref={loader} />*/}
            {/* {loading && <img src="/logo.png" />} */}
            {/* {!loading &&
              result.map((i, idx) => {
                return (
                  <Style.TemplateThumbnail
                    key={idx}
                    onClick={() => {
                      bgClick(i);
                      viewSelect(i.id);
                    }}
                    src={i.image_small}
                  />
                );
              })} */}
          </Style.TemplateGrid>
        </Style.Section>
      </Style.MainContainer>
    </>
  );
}

export default SelectedView;
