/**********************************
 * 파일명: GoogleLoginButton.js
 * 작성자: Heegun Kwak
 * 설명: Google Social Login 기능 담당 컴포넌트
 **********************************/

import { useGoogleLogin } from '@react-oauth/google';
import * as style from './styles';
import { useTranslation } from 'react-i18next';
import auth from '../../api/accountAPI';
import Swal from 'sweetalert2';
// eslint-disable-next-line
function GoogleLoginButton(props) {
  const { t } = useTranslation();
  /**
   * Google에서 받은 사용자의 인증 데이터를 서버로 전송하는 함수
   *
   * @param {Object} data - Google에서 받은 사용자의 인증 데이터
   */
  const googleSocialLogin = async (data) => {
    await auth
      .googleAuthenticate(data)
      .then((res) => {
        props.saveUserInfo(res.data.access_token, res.data.refresh_token);
      })
      .catch((err) =>
        Swal.fire({
          text: t('alert.emailMember'),
          customClass: {
            popup: 'custom-popup-class', // 모달 다이얼로그 스타일
          },
        }),
      );
  };

  const onFailure = (error) => {
    console.log(error);
  };

  const login = useGoogleLogin({
    onSuccess: (response) => googleSocialLogin(response),
    onFailure: onFailure,
    flow: 'implicit',
  });
  const handleLoginClick = () => {
    login();
  };

  return (
    <>
      <style.GoogleLogin onClick={handleLoginClick}>
        <style.SocialLoginIcon src={process.env.PUBLIC_URL + '/images/Signup/google_icon.svg'} />
        {t('login.LoginInfo.GoogleLogin')}
      </style.GoogleLogin>
    </>
  );
}

export default GoogleLoginButton;
