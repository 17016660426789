import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const CanvasWrapper = styled.div`
  display: flex;
  width: 60vw;
  height: 80%;
  justify-content: space-between;
  user-select: none;
  align-items: center;
  gap: 20px;

  > canvas {
    max-width: 520px;
    max-height: 520px;
    width: 100%;
    height: 100%;
    margin: auto 16px;

    @media screen and (max-width: 768px) {
      max-width: 420px;
      max-height: 420px;
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  height: calc(20% - 30px);
  justify-content: center;
  justify-self: flex-end;
  align-items: center;
  user-select: none;
  gap: 40px;
`;

export const Button = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > span {
    margin-top: 10px;
  }
  cursor: pointer;
  ${(props) => {
    if (props?.hover) {
      return `&:hover {
    > div > img {
      filter: invert(22%) sepia(82%) saturate(2892%) hue-rotate(260deg) brightness(85%) contrast(101%);
    }
    > span {
      color: #7f2cda;
    }
  }`;
    }
  }};
`;

export const ButtonIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  border-radius: 100%;
  ${(props) => {
    if (props?.color) return `background-color: ${props.color};`;
    else return 'border: 1px solid #ededed;';
  }};

  @media screen and (max-width: 768px) {
    width: 40px;
    height: 40px;

    > img {
      width: 24px;
    }
  }
`;

export const CanvasButton = styled.img`
  cursor: pointer;
  width: 24px;
  user-select: none;

  @media screen and (max-width: 768px) {
    width: 16px;
  }
`;
