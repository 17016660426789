import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  div {
    &:first-child {
      max-width: 1140px;
    }
    &:last-child {
      width: 100%;
    }
  }
  @media screen and (max-width: 768px) {
    div {
      &:first-child {
        padding: 0 20px;
      }
    }
  }
`;

export const Title = styled.pre`
  font-weight: 700;
  font-size: 40px;
  line-height: 1.4em;
  color: #222222;
  text-align: center;
  margin: 0 auto 20px;
  @media screen and (max-width: 1440px) {
    font-size: 36px;
  }
  @media screen and (max-width: 1024px) {
    font-size: 32px;
  }
  @media screen and (max-width: 768px) {
    font-size: 28px;
  }
  @media screen and (max-width: 500px) {
    font-size: 22px;
  }
`;

export const SubTitle = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 1.4em;
  color: #767676;
  text-align: center;
  margin-bottom: 80px;
  @media screen and (max-width: 1440px) {
    font-size: 22px;
  }
  @media screen and (max-width: 1024px) {
    font-size: 20px;
  }
  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
`;

export const UploadImgWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 160px;
`;

export const UploadImg = styled.div`
  max-width: 900px;
  width: 100%;
  height: 420px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;

  > div > h4 {
    font-weight: 700;
    font-size: 32px;
    margin-bottom: 12px;
  }
  > div > span {
    font-weight: 700;
    font-size: 24px;
    color: #bcbcbc;
    margin-bottom: 60px;
  }
  > div > p {
    font-weight: 700;
    font-size: 18px;
    color: #aaaaaa;
    margin-bottom: 45px;
  }
  > div > button {
    all: unset;
    background: #fd5c5c;
    border-radius: 5px;
    font-weight: 600;
    font-size: 14px;
    color: #ffffff;
    padding: 10px 18px;
    cursor: pointer;
  }
  @media screen and (max-width: 1024px) {
    height: 360px;
    > div > h4 {
      font-size: 26px;
    }
    > div > span {
      font-size: 22px;
      margin-bottom: 60px;
    }
    > div > p {
      font-size: 16px;
      margin-bottom: 45px;
    }
    > div > button {
      font-size: 14px;
      padding: 10px 18px;
    }
  }
  @media screen and (max-width: 768px) {
    height: 280px;
    > div > h4 {
      font-size: 22px;
    }
    > div > span {
      font-size: 20px;
      margin-bottom: 60px;
    }
    > div > p {
      font-size: 14px;
      margin-bottom: 45px;
    }
    > div > button {
      font-size: 12px;
      padding: 10px 18px;
    }
  }
`;
