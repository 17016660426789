/**********************************
 * Name : TermsOfServiceModal.js
 * Author : Nam Sanglim
 * Introduction : portal로 서비스 이용약관 popup 구현
 ********************************** */

import React from 'react';
import ModalFrame from '../../src/containers/modalFrame';

export function TermsOfServiceModal(props) {
  return (
    <ModalFrame setOnModal={props.setOnModal} height={'100%'}>
      <div style={{ maxWidth: '1140px', display: 'flex', justifyConten: 'center', margin: 'auto', alignItems: 'center', height: '100%' }}>
        <span>업데이트 예정입니다.</span>
        <span
          onClick={(e) => {
            e.stopPropagation(); // 버블링 막기
            props.setOnModal(false);
          }}
          style={{ cursor: 'pointer', lineHeight: '1.5em', fontWeight: 'bold', textAlign: 'right' }}
        >
          [ 닫기 ]
        </span>
      </div>
    </ModalFrame>
  );
}
