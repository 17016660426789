/**********************************
 * Name : myPage.js
 * Author : Nam Sanglim
 * Introduction : 로그인 유저의 MyPage
 ********************************** */

import { useEffect, useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';
import Swal from 'sweetalert2';
import { useNavigate, useLocation } from 'react-router-dom';

import * as style from './styles';
import ppAPI from 'api/ppAPI';
import { DownloadImageListState, MyPageLeftState, TempCanvasContentState } from '../../state/state';
import { Make, Canvas, Product, Asset, DownloadImage } from 'components/MyPage';
import canvasAPI from 'api/canvasAPI';
import { TempMyPageState } from 'state/state';
import LeftSideBarButtons from 'components/MyPage/leftSideBarButtons';

// eslint-disable-next-line
function MyPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const [leftSide, setLeftSide] = useRecoilState(MyPageLeftState);
  const [myPps, setMyPps] = useState([]);
  const [canvasList, setCanvasList] = useState([]);
  const [normalImgList, setNormalImgList] = useState([]);
  const [downloadImageList, setDownloadImageList] = useRecoilState(DownloadImageListState);
  const [tempCanvasContent, setTempCanvasContent] = useRecoilState(TempCanvasContentState);
  const [tempMyPage, setTempMyPage] = useRecoilState(TempMyPageState);

  // 잘못된 리다이렉팅을 방지하기 위한 사이드바 목록
  const vaildSideBar = useMemo(() => {
    return ['make', 'canvas', 'product', 'asset', 'downloads'];
  }, []);

  useEffect(() => {
    if (tempCanvasContent) {
      console.log(tempCanvasContent);
      Swal.fire({
        text: 'You have a temporarily saved canvas.\nWould you like to reload it?',
        showCancelButton: true,
        confirmButtonText: 'yes',
        cancelButtonText: 'no',
      }).then((res) => {
        if (res.isConfirmed) {
          navigate('/canvas', { state: { canvas: { fabrics: tempCanvasContent } } });
        } else {
          setTempCanvasContent(false);
        }
      });
    }
    getMyPps();
    getMyCanvas();
    getNormalImgs();
    getDownloadImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // URL 쿼리 스트링에 따라 사이드바 항목 설정
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const sidebar = queryParams.get('sidebar');
    if (sidebar) {
      setLeftSide(sidebar);
    }
    if (!vaildSideBar.includes(sidebar)) {
      navigate('?sidebar=make');
    }

    return () => {
      setLeftSide('make');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  // tempMypage에 저장된 값이 있으면, 1초 후에 새로고침 한다. 새로고침을 1회만 하게 하고싶다. refresh()는 1초 후 새로고침을 하는 함수이다.
  useEffect(() => {
    async function checkTempMyPage() {
      if (tempMyPage) {
        await refresh();
        setTempMyPage(null);
      }
    }
    checkTempMyPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //새로고침 하는 함수
  const refresh = async () => {
    setTimeout(() => {
      getMyPps();
      getNormalImgs();
    }, 1000);
  };

  const getMyPps = async () => {
    await ppAPI
      .getProductPictures()
      .then((res) => {
        const sortedList = res.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        setMyPps(sortedList);
      })
      .catch((err) => console.log(err));
  };

  const getMyCanvas = async () => {
    canvasAPI.getCanvasList().then((res) => {
      const sortedList = res.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      setCanvasList(sortedList);
    });
  };

  const getNormalImgs = async () => {
    ppAPI.getNormalPictures().then((res) => {
      const sortedList = res.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      setNormalImgList(sortedList);
    });
  };

  const getDownloadImages = async () => {
    ppAPI.getDownloadImages().then((res) => {
      const sortedList = res.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      setDownloadImageList(sortedList);
    });
  };

  const handleLeftSideBar = (e) => {
    let target = e.target.id;
    if (e.target.tagName !== 'DIV') {
      target = e.target.parentNode.id;
    }

    e.preventDefault();

    navigate(`?sidebar=${target}`);
  };

  return (
    <style.MainContainer>
      <style.Left>
        <LeftSideBarButtons handleLeftSideBar={handleLeftSideBar} activeItem={leftSide} />
      </style.Left>
      <style.Right>
        {leftSide === 'make' ? (
          <Make />
        ) : leftSide === 'canvas' ? (
          <Canvas canvasList={canvasList} setCanvasList={setCanvasList} />
        ) : leftSide === 'product' ? (
          <Product myPps={myPps} setMyPps={setMyPps} />
        ) : leftSide === 'asset' ? (
          <Asset normalImgList={normalImgList} setNormalImgList={setNormalImgList} />
        ) : leftSide === 'downloads' ? (
          <DownloadImage downloadImageList={downloadImageList} setDownloadImageList={setDownloadImageList}></DownloadImage>
        ) : null}
      </style.Right>
    </style.MainContainer>
  );
}

export default MyPage;
