import axios from './config';
// 지금은 background지만 나중에 template으로 바꿔야함
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  templateList(category_id) {
    return axios.get(`studio/background_image/?category=${category_id}`);
  },
  templatePaginationList(category_id, query = '') {
    return axios.get(`studio/background_image/?category=${category_id}&` + query);
  },
  recommandList(id) {
    return axios.get('guidance/get_recommand_template/?template_id=' + id);
  },
};
