/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-undef */
import { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Canvas, Home, Login, MyPage, AIProduct, AiResult, Choice } from 'pages';
import GlobalStyle from 'globalStyles';
import { ThemeProvider } from 'styled-components';
import { defaultTheme, interTheme } from './themes';

import { useRecoilState } from 'recoil';
import { getCookie } from 'cookies-next';
import auth from 'api/accountAPI';
import { authState } from 'state';
import tier from 'api/data/membershipBenefits';
import RouteChangeTracker from 'components/RouteChangeTracker';
import DefaultLayout from 'layouts/DefaultLayout';
import Join from 'pages/Join/join';
import SelectWorkflow from './pages/BridgeServer/selectWorkflow';
import Workflow from './pages/BridgeServer/workflow';

// eslint-disable-next-line
function App() {
  RouteChangeTracker();
  const [userInfo, setUserInfo] = useRecoilState(authState);
  const setUserInformation = async () => {
    const access = getCookie('access_token');
    const refresh = getCookie('refresh_token');
    if (access && refresh) {
      await auth
        .getUser()
        .then((res) => {
          setUserInfo({
            isLoggedIn: true,
            name: res.data.name,
            email: res.data.email,
            social_img: res.data.social_img,
            user_id: res.data.id,
            access_token: access,
            refresh_token: refresh,
            canvas_sum: res.data.canvas_sum,
            download_sum: res.data.download_sum,
            tier: tier[res.data.level],
          });
        })
        .catch((e) => {
          // deleteCookie('access_token');
          // deleteCookie('refresh_token');
        });
    }
  };
  useEffect(() => {
    setUserInformation();
  }, []);
  return (
    <div className="App">
      <GlobalStyle />
      <Routes>
        <Route path="/" element={<DefaultLayout />}>
          {!userInfo.isLoggedIn && <Route index element={<Home />} />}
          {userInfo.isLoggedIn && <Route index element={<Navigate replace to="/mypage" />} />}
          {userInfo.isLoggedIn && <Route path="/mypage" element={<MyPage />} />}
          <Route path="/login" element={<Login />} />
          <Route path="/join" element={<Join />} />
        </Route>
        <Route path="/canvas" element={<Canvas />} />
        <Route path="/aiproduct" element={<AIProduct />} />
        <Route path="/ai-result" element={<AiResult />} />
        <Route path="/choice" element={<Choice />} />
        <Route
          path="/select-workflow"
          element={
            <ThemeProvider theme={interTheme}>
              <SelectWorkflow />
            </ThemeProvider>
          }
        />
        <Route
          path="/workflow/*"
          element={
            <ThemeProvider theme={interTheme}>
              <Workflow />
            </ThemeProvider>
          }
        />

        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </div>
  );
}

export default App;
