/**********************************
 * 파일명: [mainBanner.js]
 * 작성자: [Donghwoo Cho]
 * 설명: [홈페이지 내 메인 베너]
 **********************************/

import * as style from 'components/Home/MainBanner/styles';
import { useTranslation } from 'react-i18next';

/* eslint-disable max-lines-per-function, no-unused-vars */
function MainBanner() {
  const { t } = useTranslation();

  return (
    <style.Content>
      <style.Title>{t('home.uploadImage.Title3')}</style.Title>
      <style.SubTitle>{t('home.uploadImage.SubTitle2')}</style.SubTitle>
      <style.TemplateWrap>
        <img src={process.env.PUBLIC_URL + '/images/Home/templates.png'} alt={''} />
      </style.TemplateWrap>
    </style.Content>
  );
}

export default MainBanner;
