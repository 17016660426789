import * as style from './styles';
import React, { useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import heic2any from 'heic2any';
import imageCompression from 'browser-image-compression';
import canvasAPI from 'api/canvasAPI';
import { Base64State, ImageNameState, ProductCropState } from 'state/state';
import { productImageState } from 'state/imageState';
import { useTranslation } from 'react-i18next';
import { ProgressBar } from 'components';
import ReactGA from 'react-ga4';
import ppAPI from 'api/ppAPI';
import { TempMyPageState } from 'state/state';
import * as productState from 'state/productState';
import Swal from 'sweetalert2';

// eslint-disable-next-line max-lines-per-function
function AIProduct(props) {
  const { t } = useTranslation();
  const navigator = useNavigate();
  const imgRef = useRef();

  const [loading, setLoading] = useRecoilState(productState.loadingState);
  const [loadingPercent, setLoadingPercent] = useRecoilState(productState.loadingPercentState);
  const [beforeCrop, setBeforeCrop] = useRecoilState(productState.beforeCropState);
  const resetBeforeCrop = useResetRecoilState(productState.beforeCropState);
  const [cropImageUrl, setCropImageUrl] = useRecoilState(productState.cropImageUrlState);
  const [srcWidth, setSrcWidth] = useRecoilState(productState.srcWidth);
  const [srcHeight, setSrcHeight] = useRecoilState(productState.srcHeightState);
  const [Xscale, setXscale] = useRecoilState(productState.XscaleState);
  const [Yscale, setYscale] = useRecoilState(productState.YscaleState);
  const [, setPpSize] = useRecoilState(productState.ppSizeState);

  const [base64, setBase64] = useRecoilState(Base64State);
  const [, setCrop] = useRecoilState(ProductCropState);
  const [ppName, setPPName] = useRecoilState(ImageNameState); // pp 이름
  const tempMyPage = useRecoilValue(TempMyPageState);
  const [productImage, setProductImage] = useRecoilState(productImageState);

  useEffect(() => {
    return () => {
      setSrcHeight(null);
      setSrcWidth(null);
      setXscale(null);
      setYscale(null);
      setCropImageUrl('');
      setCrop({});
      resetBeforeCrop();
      setLoading(false);
      setLoadingPercent(null);

      console.log('aiProduct unmount');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // GA를 위한 함수
  const trackUploadEvent = () => {
    ReactGA.event({
      category: 'User',
      action: 'upload',
      label: 'upload pp from aiproduct',
    });
  };

  const processImageUpload = async (data, base64, uploadImage, srcWidth, srcHeight) => {
    try {
      setLoading(true);
      const res = await canvasAPI.grabCutBase64(data);
      console.log('res', res);

      setLoadingPercent(100);
      resetBeforeCrop();
      handleUploadSuccess(res, base64, uploadImage, srcWidth, srcHeight, data.remove);
    } catch (err) {
      setLoading(false);
      setLoadingPercent(null);
      handleUploadError();
      console.log(err);
    }
  };

  const updateProductImageState = (base64, uploadImage, maskImage, srcWidth, srcHeight) => {
    setProductImage({
      fileName: uploadImage,
      uploadImage: base64,
      maskImage: maskImage,
      width: srcWidth,
      height: srcHeight,
    });
  };

  const updateLoadingState = (isLoading, percent) => {
    setLoading(isLoading);
    setLoadingPercent(percent);
  };

  const processImageResult = (base64, uploadImage, maskImage, colors, templates, isRemoved) => {
    if (isRemoved) {
      sendProductPicture(base64, uploadImage, maskImage, colors, templates);
    } else {
      sendNormalPicture(base64, uploadImage, colors, templates);
    }
  };

  const navigateAfterUpload = (colors, templates, isRemoved) => {
    setTimeout(() => {
      console.log('color: ', colors, 'templates:', templates);
      navigator('/ai-result', { state: { colors, templates, remove: isRemoved } });
    }, 1000);
  };

  const handleUploadSuccess = (res, base64, uploadImage, srcWidth, srcHeight, isRemoved) => {
    const maskImage = isRemoved ? res.data.mask_img : base64;
    const colors = res.data.colors;
    const templates = res.data.templates;

    updateProductImageState(base64, uploadImage, maskImage, srcWidth, srcHeight);
    updateLoadingState(false, null);
    processImageResult(base64, uploadImage, maskImage, colors, templates, isRemoved);
    navigateAfterUpload(colors, templates, isRemoved);
  };

  const handleUploadError = () => {
    setTimeout(() => {
      Swal.fire({
        text: 'Please try again in a moment.',
        customClass: {
          popup: 'custom-popup-class',
        },
      });
    }, 200);
  };

  const calculateCropData = (beforeCrop, Xscale, Yscale) => {
    let width = beforeCrop.width * Xscale;
    let height = beforeCrop.height * Yscale;
    let x = beforeCrop.x * Xscale;
    let y = beforeCrop.y * Yscale;

    return {
      width: Math.round(width),
      height: Math.round(height),
      x: Math.round(x),
      y: Math.round(y),
    };
  };

  const nextButtonClick = async (e) => {
    const { id } = e.target;
    trackUploadEvent();

    if (beforeCrop) {
      let data = { upload_img: base64 };
      let img = base64;

      let cropData = calculateCropData(beforeCrop, Xscale, Yscale);

      data.remove = id === 'remove' ? true : false;
      data.crop_x = cropData.x;
      data.crop_y = cropData.y;
      data.w = cropData.width;
      data.h = cropData.height;

      // 만약 크롭된 이미지가 존재한다면, 크롭된 이미지를 업로드한다.
      if (cropImageUrl !== base64 && data.remove === false) {
        img = cropImageUrl;
        data.upload_img = cropImageUrl;
      }

      console.log('data', data);

      setCrop(cropData);
      await processImageUpload(data, img, ppName, srcWidth, srcHeight);
    }
  };

  const sendNormalPicture = async (upload_img, name, colors, templates) => {
    await ppAPI
      .postNormalPicture({
        upload_img: upload_img,
        upload_img_name: name,
        colors: colors,
        templates: templates,
      })
      .then((res) => {
        setProductImage((previous) => ({ ...previous, id: res.data.id }));
      })
      .catch((err) => console.log(err));
  };

  const sendProductPicture = async (upload_img, name, img_b64, colors, templates) => {
    await ppAPI
      .postProductPicture({
        upload_img: upload_img,
        upload_img_name: name,
        remove_bg: img_b64,
        colors: colors,
        templates: templates,
      })
      .then((res) => {
        setProductImage((previous) => ({ ...previous, id: res.data.id }));
      })
      .catch((err) => console.log(err));
  };

  const getCroppedImg = async (image, crop, fileName) => {
    const canvas = document.createElement('canvas'); // document 상에 canvas 태그 생성
    // 캔버스 영역을 크롭한 이미지 크기 만큼 조절
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    setXscale(scaleX);
    setYscale(scaleY);
    canvas.width = beforeCrop.width;
    canvas.height = beforeCrop.height;
    // getContext() 메서드를 활용하여 캔버스 렌더링 컨텍스트 함수 사용
    // 이 경우 drawImage() 메서드를 활용하여 이미지를 그린다
    const ctx = canvas.getContext('2d');
    // 화면에 크롭된 이미지를 그린다
    ctx.drawImage(
      image, // 원본 이미지
      beforeCrop.x, // 크롭한 이미지 x 좌표
      beforeCrop.y, // 크롭한 이미지 y 좌표
      beforeCrop.width, // 크롭한 이미지 가로 길이
      beforeCrop.height, // 크롭한 이미지 세로 길이
      // 캔버스 영역
      0, // 캔버스에서 이미지 시작 x 좌표
      0, // 캔버스에서 이미지 시작 y 좌표
      beforeCrop.width, // 캔버스에서 이미지 가로 길이
      beforeCrop.height, //  캔버스에서 이미지 세로 길이
    );
    // canvas 이미지를 base64 형식으로 인코딩된 URI 를 생성한 후 반환한다
    return new Promise((resolve) => {
      resolve(canvas.toDataURL());
    });
  };

  const makeClientCrop = async (crop) => {
    if (imgRef.current && crop.width && crop.height) {
      const scaleX = imgRef.current.naturalWidth / imgRef.current.width;
      const scaleY = imgRef.current.naturalHeight / imgRef.current.height;
      setXscale(scaleX);
      setYscale(scaleY);
      const croppedImageUrl = await getCroppedImg(imgRef.current, crop, 'newFile.jpeg');
      setCropImageUrl(croppedImageUrl);
    }
  };

  const onCropComplete = (crop, percentCrop) => {
    makeClientCrop(crop);
  };
  const onCropChange = (crop, percentCrop) => {
    // 퍼센트 크롭을 사용해도 된다:
    setBeforeCrop(crop);
  };

  const compressImage = async (image) => {
    //이미지 파일을 압축시켜줌
    try {
      const options = {
        // maxSizeMB: 0.2,
        maxWidthOrHeight: 1920,
      };
      return await imageCompression(image, options);
    } catch (e) {
      console.log(e);
    }
  };

  // eslint-disable-next-line max-lines-per-function
  const upload = async (e) => {
    let { files } = e.target;
    let reader = new FileReader();
    console.log('이름1', files[0].name.split('.')[0]);

    if (files[0] === undefined) {
      throw new Error('파일이 없습니다.');
    }

    let file = e.target.files[0];
    let afterFiles = files[0];
    const extensionFileName = afterFiles.name.split('.')[1].toLowerCase(); //확장자명 체크를 위해 소문자 변환 HEIC, heic

    if (extensionFileName === 'heic') {
      let blob = file;
      await heic2any({ blob: blob, toType: 'image/jpeg' })
        .then(function (resultBlob) {
          file = new File([resultBlob], file.name.split('.')[0] + '.jpg', {
            type: 'image/jpeg',
            lastModified: new Date().getTime(),
          });
          reader.readAsDataURL(file);
        })
        .catch(function (x) {
          console.log(x);
        });
    }
    console.log('이름2', file.name);
    setPPName(file.name);
    const compressed = await compressImage(file);
    const compressed_file = new File([compressed], file.name);

    if (compressed_file) {
      const imgTarget = compressed_file;
      if (imgTarget) {
        reader.readAsDataURL(imgTarget); // buffer에 저장함!!
        reader.onloadend = () => {
          const base64 = reader.result;
          if (base64) {
            setBase64(base64.toString());
          }
        };
      }
    }
  };

  const onImageLoaded = (e) => {
    imgRef.current = e.target;
    const { width, height } = e.target;
    //업로드한 Img의 가로 세율 비율을 재지정해줌
    setPpSize({ width: width, height: height });
    setSrcWidth(width);
    setSrcHeight(height);
    setCropImageUrl(base64);
  };

  return (
    <style.Wrapper>
      {loading && (
        <style.ProgressBarContainer>
          <ProgressBar percent={loadingPercent} />
        </style.ProgressBarContainer>
      )}
      <style.EditImageWrap>
        <style.CropDivBox srcRatio={srcWidth / srcHeight} w={srcWidth} h={srcHeight}>
          <ReactCrop crop={beforeCrop} onChange={onCropChange} onComplete={onCropComplete}>
            <style.ProductImg src={base64} alt="crop" onLoad={onImageLoaded} />
          </ReactCrop>
        </style.CropDivBox>
      </style.EditImageWrap>
      <style.RightSide>
        <style.InfoWrap>
          <div>{t('aiProduct.aiProduct.InfoWrap1_1')}</div>
          <span>{t('aiProduct.aiProduct.InfoWrap1_2')}</span>
        </style.InfoWrap>
        <style.Buttons id={'noRemove'} margin={'8px'} color={'#fcfcfc'} backgroundColor={'#f54545'} bottom={'150px'} onClick={nextButtonClick} boxShadow={'1px 2px 2px rgba(0, 0, 0, 0.25)'}>
          {t('aiProduct.aiProduct.NoRemove')}
        </style.Buttons>
        <style.Buttons id={'remove'} margin={'8px'} color={'#fcfcfc'} backgroundColor={'#f54545'} bottom={'90px'} onClick={nextButtonClick} boxShadow={'1px 2px 2px rgba(0, 0, 0, 0.25)'}>
          {t('aiProduct.aiProduct.Remove')}
        </style.Buttons>
        <label htmlFor={'uploadImg'}>
          <style.Buttons color={'#f54545'} backgroundColor={'#fcfcfc'} bottom={'30px'} textDeco={'underline'}>
            {t('aiProduct.aiProduct.ReUpload')}
          </style.Buttons>
        </label>
        <input style={{ display: 'none' }} accept="image/*, image/heic" id="uploadImg" name="img_url" type="file" content_type="multipart/form-data" ref={imgRef} onChange={upload} />
      </style.RightSide>
    </style.Wrapper>
  );
}

export default AIProduct;
