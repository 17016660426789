import { useTranslation } from 'react-i18next';
import * as styles from '../styles';
import { useState } from 'react';
import { objectList, selectedObject } from 'state/canvasState';
import { rightSidebarState } from 'state/sidebarState';
import { useSetRecoilState } from 'recoil';
import { addObjectSelectEvent, addShape, generateObjectId, generateUniqueName } from 'utils/canvasObjectGenerateUtil';

// eslint-disable-next-line
function ObjectControlButtonList(props) {
  const { t } = useTranslation();

  const [canvas] = useState(document.getElementById('canvas').fabric);
  const setCanvasObjects = useSetRecoilState(objectList);
  const setRightState = useSetRecoilState(rightSidebarState);
  const setSelectedIndex = useSetRecoilState(selectedObject);

  // canvas 선택한 객체 앞으로가기
  // 객체를 앞으로 이동시키기
  const forwardObj = () => {
    const target = canvas.getActiveObject();
    const targetIndex = canvas.getObjects().indexOf(target);
    const lastIndex = canvas.getObjects().length - 1;
    const targetShadow = canvas.getObjects().find((obj) => obj.parentId === target.id);
    if (targetIndex >= lastIndex) return; // 맨 앞에 있는 객체라면 더 이상 앞으로 이동시키지 않음

    // 대상 객체를 앞으로 이동
    canvas.bringForward(target);

    // 대상 객체의 그림자를 찾아서 같은 방향으로 이동
    if (targetShadow) {
      // 그림자 객체도 한 단계 앞으로 이동
      canvas.bringForward(targetShadow);
    }

    canvas.requestRenderAll();
    canvas.fire('object:modified');
  };

  // 객체를 뒤로 이동시키기
  const backwardObj = () => {
    const target = canvas.getActiveObject();
    const targetShadow = canvas.getObjects().find((obj) => obj.parentId === target.id);
    // shadow가 존재하고, target의 index가 1이라면, 무시
    if (targetShadow && canvas.getObjects().indexOf(target) === 1) return; // 맨 뒤에 있는 객체라면 더 이상 뒤로 이동시키지 않음
    if (canvas.getObjects().indexOf(target) === 0) return; // 맨 뒤에 있는 객체라면 더 이상 뒤로 이동시키지 않음

    // 대상 객체를 뒤로 이동
    canvas.sendBackwards(target);

    // 대상 객체의 그림자를 찾아서 같은 방향으로 이동
    if (targetShadow) {
      canvas.sendBackwards(target);
      // 그림자 객체도 두 단계 뒤로 이동
      canvas.sendBackwards(targetShadow);
      canvas.sendBackwards(targetShadow);
    }

    canvas.requestRenderAll();
    canvas.fire('object:modified');
  };

  // cnavas 선택한 객체 뒤집기
  const reverseObj = () => {
    const target = canvas.getActiveObject();
    const targetShadow = canvas.getObjects().find((obj) => obj.parentId === target.id);

    target.flipX = !target.flipX;

    //그림자 객체가 있을 경우 그림자까지 같이 뒤집는다
    if (targetShadow) {
      targetShadow.flipX = !targetShadow.flipX;
    }

    canvas.requestRenderAll();
    canvas.fire('object:modified');
  };

  const handleObjectPostProcessing = (fabricObj) => {
    console.log('User Object Copied');
    const canvasObjectInfo = {
      id: fabricObj.id,
      name: fabricObj.name,
      type: fabricObj.type,
      src: fabricObj.src,
    };
    console.log('handleObjectPostProcessing', fabricObj);
    setCanvasObjects((prev) => [...prev, canvasObjectInfo]);
    addObjectSelectEvent(fabricObj, setSelectedIndex, setRightState);
    return fabricObj;
  };

  // canvas 선택한 객체 복사하기
  const copyObj = async () => {
    const target = canvas.getActiveObject();
    const objects = canvas.getObjects().filter((obj) => !obj.isShadow);
    if (!target) return;

    target.clone(async (clonedObj) => {
      const newId = generateObjectId(target.type); // 새로운 ID 생성
      const newName = generateUniqueName(objects, target.name); // 새로운 이름 생성
      clonedObj.set({ left: target.left + 10, top: target.top + 10, id: newId, name: newName, src: target.src, originSrc: target.originSrc }); // 복사된 객체를 원본 객체와 약간 떨어진 위치에 배치
      await addShape(canvas, clonedObj, handleObjectPostProcessing); // 복사된 객체를 캔버스에 추가

      canvas.requestRenderAll(); // 캔버스 렌더링 갱신
    });
  };

  // canvas 선택한 객체 삭제하기
  const deleteObj = () => {
    const target = canvas.getActiveObject();
    const targetShadow = canvas.getObjects().find((obj) => obj.parentId === target.id);

    if (!target) return;
    if (targetShadow) {
      canvas.remove(targetShadow);
    }

    canvas.remove(target);

    // 전역 상태 정리
    setCanvasObjects((objects) => objects.filter((obj) => obj.id !== target.id));
    setSelectedIndex(null);
    setRightState('components');

    canvas.requestRenderAll();
    canvas.fire('object:modified');
  };

  return (
    <styles.BtnWrap>
      <styles.BtnWrapRow>
        <button onClick={forwardObj}>
          <img width={30} height={30} src={process.env.PUBLIC_URL + '/images/Canvas/SideBar/upArrow.svg'} alt="forward" />
          <span>{t('canvas.option.Forward')}</span>
        </button>
        <button onClick={backwardObj}>
          <img width={30} height={30} src={process.env.PUBLIC_URL + '/images/Canvas/SideBar/downArrow.svg'} alt="backward" />
          <span>{t('canvas.option.Backward')}</span>
        </button>
      </styles.BtnWrapRow>
      <styles.BtnWrapRow>
        <button onClick={reverseObj}>
          <img width={30} height={30} src={process.env.PUBLIC_URL + '/images/Canvas/SideBar/mirror.svg'} alt="reverse" />
          <span>{t('canvas.option.Reverse')}</span>
        </button>
        <button id="copy" onClick={copyObj}>
          <img width={30} height={30} src={process.env.PUBLIC_URL + '/images/Canvas/SideBar/copy.svg'} alt="copy" />
          <span>{t('canvas.option.Copy')}</span>
        </button>
        <button onClick={deleteObj}>
          <img width={30} height={30} src={process.env.PUBLIC_URL + '/images/Canvas/SideBar/trash.svg'} alt="delete" />
          <span>{t('canvas.option.Delete')}</span>
        </button>
      </styles.BtnWrapRow>
    </styles.BtnWrap>
  );
}

export default ObjectControlButtonList;
