import styled from 'styled-components';

export const CrossBackground = styled.div`
  position: relative;
  width: ${(props) => props?.size || '200px'};
  height: ${(props) => props?.size || '200px'};
  border-radius: 4px;
  flex-shrink: 0;
  background: linear-gradient(
    135deg,
    ${(props) => props?.left || 'black'} 0,
    ${(props) => props?.left || 'black'} 50%,
    ${(props) => props?.right || 'black'} 0,
    ${(props) => props?.right || 'black'} 50%
  );
  cursor: ${(props) => props?.pointer && 'pointer'};
  > img {
    filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.5));
  }

  @media screen and (max-width: 768px) {
    width: ${(props) => props?.size || '160px'};
    height: ${(props) => props?.size || '160px'};
  }
`;

export const ProdImage = styled.img`
  position: absolute;
  top: ${(props) => props?.top || '50%'};
  left: ${(props) => props?.left || '50%'};
  transform: translate(-50%, -50%);
  width: 100px;
  max-width: 70%;
  max-height: 70%;
  object-fit: contain;
`;

export const SingleBackground = styled.div`
  position: relative;
  width: 200px;
  height: 200px;
  box-shadow: ${(props) => props?.shadow && '0px 0px 15px rgba(0, 0, 0, 0.2)'};
  box-sizing: border-box;
  border: ${(props) => (props?.active === 'off' ? 'none' : props?.active ? '2px solid #f54545' : '2px solid #dbdbdb')};
  border-radius: 4px;
  background: ${(props) => (props?.color === 'none' ? 'url("/images/Component/empty-layer.png")' : props.color)};
  cursor: ${(props) => props?.pointer && 'pointer'};
  flex-shrink: 0;
  ${(props) => {
    if (props?.color === 'none') {
      const size = props.size?.split('px')[0];
      return `background-size: ${size ? parseInt(size / 10) : '10'}px`;
    } else if (!['black', 'white'].includes(props.color)) return '> img { filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.5)); }';
  }};

  @media screen and (max-width: 768px) {
    width: 160px;
    height: 160px;
  }
`;

export const HalfBackground = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 200px;
  height: 200px;
  background: linear-gradient(
    180deg,
    ${(props) => props?.top || 'black'} 0,
    ${(props) => props?.top || 'black'} 50%,
    ${(props) => props?.bottom || 'black'} 0,
    ${(props) => props?.bottom || 'black'} 50%
  );
  cursor: ${(props) => props?.pointer && 'pointer'};
  > img {
    filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.5));
  }

  @media screen and (max-width: 768px) {
    width: 160px;
    height: 160px;
  }
`;

export const Half = styled.div`
  width: ${(props) => props?.size || '280px'};
  height: 50%;
  background: ${(props) => props?.color || 'none'};
`;

export const Thumbnail = styled.img`
  flex-shrink: 0;
  position: relative;
  width: 200px;

  @media screen and (max-width: 768px) {
    width: 160px;
  }
`;

export const TemplateThumbnail = styled.div`
  cursor: pointer;
  height: fit-content;
  position: relative;
  > img:last-child {
    filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.5));
  }
`;
