import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 1080px;
  height: calc(100vh - 60px);
  align-items: center;
  div {
    &:first-child {
      max-width: 1140px;
    }
    &:last-child {
      width: 100%;
    }
  }
  @media screen and (max-width: 768px) {
    div {
      &:first-child {
        padding: 0 20px;
      }
    }
  }
`;

export const Title = styled.pre`
  font-weight: 700;
  font-size: 40px;
  line-height: 1.4em;
  color: #222222;
  text-align: center;
  margin: 0 auto 20px;
  @media screen and (max-width: 1440px) {
    font-size: 36px;
  }
  @media screen and (max-width: 1024px) {
    font-size: 32px;
  }
  @media screen and (max-width: 768px) {
    font-size: 28px;
  }
  @media screen and (max-width: 500px) {
    font-size: 22px;
  }
`;

export const SubTitle = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 1.4em;
  color: #767676;
  text-align: center;
  margin-bottom: 80px;
  @media screen and (max-width: 1440px) {
    font-size: 22px;
  }
  @media screen and (max-width: 1024px) {
    font-size: 20px;
  }
  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
`;

export const TemplateWrap = styled.div`
  height: 780px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  > img {
    object-fit: contain;
  }

  @media screen and (max-width: 1440px) {
    height: 700px;
  }
  @media screen and (max-width: 1024px) {
    height: 620px;
  }
  @media screen and (max-width: 768px) {
    height: 480px;
    > img {
      height: 100%;
    }
  }
  @media screen and (max-width: 500px) {
    height: 420px;
  }
`;
