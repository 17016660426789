import icons from 'constants/icons';
import styled from 'styled-components';

export const LeftSidebarContainer = styled.div`
  display: flex;
  position: relative;
  overflow: visible;
  z-index: 1;
`;

export const Navbar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80px;
  background-color: #f2f4f4;
  border-right: 1px solid #dbdbdb;

  @media screen and (max-width: 1440px) {
    width: 80px;
  }
  @media screen and (max-width: 1024px) {
    width: 60px;
  }
`;

export const NavbarBtn = styled.button`
  all: unset;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  background: ${(props) => (props.current ? '#ffffff' : null)};
  cursor: ${(props) => (props.enabled ? '' : 'pointer')};
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 15%);
  }

  > img {
    max-width: 30px;
    width: 100%;
  }
  > span {
    font-size: 12px;
    text-align: center;
  }
  @media screen and (max-width: 1440px) {
    width: 80px;
    height: 80px;

    > img {
      max-width: 30px;
      width: 100%;
    }
    > span {
      font-size: 12px;
      padding: 0 5px;
    }
  }
  @media screen and (max-width: 1024px) {
    width: 70px;
    height: 70px;

    > img {
      max-width: 20px;
      width: 100%;
    }
    > span {
      font-size: 10px;
    }
  }
`;

export const TabCloseBtn = styled.div`
  cursor: pointer;
  align-self: center;
  position: absolute;
  width: 20px;
  height: 20px;
  left: 370px;
  border-radius: 50%;
  background: url(${icons.iconArrow}) no-repeat center center;
  background-size: contain;
  transform: scaleX(-1);
  filter: hue-rotate(30deg);
  @media screen and (max-width: 1440px) {
    left: 280px;
  }
  @media screen and (max-width: 1024px) {
    left: 260px;
  }
`;
