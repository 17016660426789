/**********************************
 * Name : defaultView.js
 * Author : Park JeongMyeong
 * Introduction : Template 선택 Component 중 처음에 보여주는 Default View Component
 ********************************** */
import React, { useEffect, useState } from 'react';
import * as Style from '../styles';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import './style.css';
import templateAPI from 'api/templateAPI';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { bgImageState, thumbnailState } from 'state/imageState';
import { addImage, addObjectSelectEvent, generateObjectId, getTemplate } from 'utils/canvasObjectGenerateUtil';
import { objectList, selectedObject, workspaceSizeState } from 'state/canvasState';
import { rightSidebarState } from 'state/sidebarState';

const NextArrow = ({ className, style, onClick }) => <Style.Arrow style={{ ...style, width: 16, left: '92%', height: '100%' }} onClick={onClick} className={className}></Style.Arrow>;
const PrevArrow = ({ className, style, onClick }) => <Style.Arrow style={{ ...style, width: 16, height: '100%' }} onClick={onClick} className={className}></Style.Arrow>;

// eslint-disable-next-line
function DefaultView(props) {
  const { t } = useTranslation();
  const setWorkspaceSize = useSetRecoilState(workspaceSizeState);

  // thumbnail 카테고리를 담아 관리하는 상수
  const thumbnailKey = {
    yellow: { name: t('canvas.defaultView.yellow'), id: 4 },
    skyblue: { name: t('canvas.defaultView.skyblue'), id: 10 },
    white: { name: t('canvas.defaultView.white'), id: 1 },
    beige: { name: t('canvas.defaultView.beige'), id: 2 },
    brown: { name: t('canvas.defaultView.brown'), id: 3 },
    purple: { name: t('canvas.defaultView.purple'), id: 5 },
    grey: { name: t('canvas.defaultView.grey'), id: 6 },
    black: { name: t('canvas.defaultView.black'), id: 7 },
    green: { name: t('canvas.defaultView.green'), id: 8 },
    coral: { name: t('canvas.defaultView.coral'), id: 11 },
    pink: { name: t('canvas.defaultView.pink'), id: 12 },
  };

  const [colorPages, setColorPages] = useState({
    yellow: { id: 4, page: 1 },
    skyblue: { id: 10, page: 1 },
    white: { id: 1, page: 1 },
    beige: { id: 2, page: 1 },
    brown: { id: 3, page: 1 },
    purple: { id: 5, page: 1 },
    grey: { id: 6, page: 1 },
    black: { id: 7, page: 1 },
    green: { id: 8, page: 1 },
    coral: { id: 11, page: 1 },
    pink: { id: 12, page: 1 },
  });

  const { viewDetail, viewSelect } = props;
  const [thumbnail, setThumbnail] = useRecoilState(thumbnailState);
  const [bgImage, setBgImage] = useRecoilState(bgImageState);
  const [canvasObjectList, setCanvasObjectList] = useRecoilState(objectList);
  const setRightState = useSetRecoilState(rightSidebarState);
  const setSelectedIndex = useSetRecoilState(selectedObject);
  const settings = {
    speed: 500,
    slidesToShow: 1.8,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  useEffect(() => {
    if (thumbnail == false) {
      // 초기 시작 시 recoil 에 저장되어 있지 않으므로
      getThumbnail();
    }
  }, []);

  const bgClick = async (imgData, color) => {
    const id = generateObjectId('bg');
    const { fabric: canvas } = document.getElementById('canvas');
    const template = await addImage(canvas, await getTemplate(imgData.id), { id, templateId: imgData.id, name: id, isBackground: true }, () => {
      canvas.forEachObject((obj) => {
        if (obj.id !== id && obj.isBackground) {
          canvas.remove(obj);
          canvas.requestRenderAll();
          console.log(canvasObjectList);
          setCanvasObjectList(
            [...canvasObjectList].filter((object) => {
              return object.id !== obj.id;
            }),
          );
        }
      });
    });
    const canvasObjectInfo = {
      id,
      name: template.name,
      type: template.type,
      src: template.src,
    };
    setCanvasObjectList((prev) => [...prev, canvasObjectInfo]);
    addObjectSelectEvent(template, setSelectedIndex, setRightState);
    setWorkspaceSize({
      width: template.width * template.scaleX,
      height: template.height * template.scaleY,
    });
    canvas.sendToBack(template);
  };

  // 원래 형식에 맞게 함수 재작성 - 강승원
  // 카테고리를 상수로 따로 관리하여 유지보수가 편하도록 함수 및 상수 재작성 - 박정명
  const getThumbnail = async () => {
    // 정의해 둔 카테고리를 돌면서
    Object.keys(thumbnailKey).map(async (key) => {
      // server 값을 받아오고
      await templateAPI.templateList(thumbnailKey[key].id).then((res) => {
        // thumbnail이라는 state를 업데이트 시켜줌
        setThumbnail((prev) => ({ ...prev, [key]: res.data.results }));
      });
    });
  };

  const thumbnailPagination = async (color, id, page) => {
    await templateAPI.templatePaginationList(id, `page=${page}`).then((res) => {
      setThumbnail((prev) => ({
        ...prev,
        [color]: [...(prev[color] || []), ...res.data.results],
      }));
    });
  };

  const loadNextTemplates = async (color, index) => {
    console.log('index : ', index);
    if (index === thumbnail[color].length - 5) {
      console.log('load next templates');
      setColorPages((prev) => ({ ...prev, [color]: { ...prev[color], page: colorPages[color].page + 1 } }));
      await thumbnailPagination(color, colorPages[color].id, colorPages[color].page + 1);
    }
  };

  return (
    <>
      <Style.MainContainer>
        <Style.Tcategory>
          <Style.TopTitle>{t('canvas.defaultView.Title')}</Style.TopTitle>
          {Object.keys(thumbnailKey).map((key, idx) => {
            return (
              <Style.Section key={idx}>
                <Style.TitleDiv>
                  <Style.SubTitle>{thumbnailKey[key].name}</Style.SubTitle>
                  <Style.DetailButton onClick={() => viewDetail(thumbnailKey[key].id)}>{t('canvas.defaultView.More')}</Style.DetailButton>
                </Style.TitleDiv>
                <Slider {...settings} afterChange={(index) => loadNextTemplates(key, index)}>
                  {thumbnail[key] && // thumbnail을 서버에서 못받아왔을 경우를 고려하여
                    thumbnail[key].map((i, idx) => {
                      return (
                        <Style.TemplateThumbnail
                          key={idx}
                          onClick={() => {
                            bgClick(i, thumbnailKey[key].id);
                            viewSelect(i.id); // Selected View로 이미지 넘겨주기
                          }}
                          src={i.image_small}
                        />
                      );
                    })}
                </Slider>
              </Style.Section>
            );
          })}
        </Style.Tcategory>
        {/*<style.AIrecom htmlFor="sample">AI 배경 추천 받기</style.AIrecom>*/}
        {/*<input type="file" id="sample" onChange={selectFile} style={{ display: 'None' }} />*/}
      </Style.MainContainer>
    </>
  );
}

export default DefaultView;
