import React, { useState } from 'react';
import { ChoiceDesign } from 'components';
import * as style from 'components/MyPage/styles';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { bgImageState, productImageState } from 'state/imageState';
import ppAPI from 'api/ppAPI';
import { authState } from 'state';
import accountAPI from 'api/accountAPI';
import Swal from 'sweetalert2';

function Canvas({ downloadImageList, setDownloadImageList }) {
  const { t } = useTranslation();
  const resetBackground = useResetRecoilState(bgImageState);
  const [isChoice, setIsChoice] = useState(false);
  const [selected, setSelected] = useState();
  const [userInfo, setUserInfo] = useRecoilState(authState);

  // const folders = ['product 1', 'product 2', 'product 3'];
  const images = ['/images/GuidePhoto/mainGuide01.png', '/images/GuidePhoto/mainGuide02.png'];

  console.log('downloadImageList', downloadImageList);

  const imageClick = (image) => {
    console.log('image', image);
    resetBackground();
    setSelected(image);
    setIsChoice(true);
  };

  const downloadImage = async (url) => {
    let a = document.createElement('a');
    const response = await fetch(url);
    const href = await response.blob();
    a.href = URL.createObjectURL(href);
    a.download = 'myCanvas.png';
    a.click();
  };

  const handleDeleteImage = (id) => {
    Swal.fire({
      text: t('mypage.product.DeleteAlert'),
      showCancelButton: true,
      confirmButtonText: 'yes',
      cancelButtonText: 'no',
      customClass: {
        popup: 'custom-popup-class', // 모달 다이얼로그 스타일
      },
    }).then((res) => {
      if (res.isConfirmed) {
        console.log(downloadImageList);
        console.log(id);

        ppAPI
          .deleteDownloadImage(id)
          .then((res) => {
            accountAPI
              .getUser()
              .then((res) => {
                console.log('에?', res);
                setUserInfo({ ...userInfo, download_sum: res.data.download_sum });
              })
              .catch((e) => {
                console.log(e);
              });
            // console.log('삭제됨');
            setDownloadImageList(downloadImageList.filter((item) => item.id !== Number(id)));
          })
          .catch((err) => {
            Swal.fire({
              text: t('mypage.canvas.DeleteFail'),
              customClass: {
                popup: 'custom-popup-class', // 모달 다이얼로그 스타일
              },
            });

            console.log(err);
          });
      }
    });
  };

  return (
    <div style={{ height: '100%' }}>
      <style.CanvasTop>{t('mypage.download.MyDownload')}</style.CanvasTop>
      {isChoice ? (
        <style.CanvasType>
          <ChoiceDesign pp={selected} ppList={downloadImageList} />
        </style.CanvasType>
      ) : (
        <style.Wrapper>
          <style.CanvasType>
            <div>
              <span>{t('mypage.download.RecentDownloads')}</span>
              <span>
                Usage {userInfo.download_sum}/{userInfo.tier.download_sum}
              </span>
            </div>
            <div>
              {downloadImageList.map((image, index) => (
                <style.Canvases key={index}>
                  <div style={{ position: 'relative', justifyContent: 'center', alignItems: 'center' }}>
                    <img src={`${process.env.PUBLIC_URL}${image.upload_img}`} crossOrigin="Anonymous" />
                    <button onClick={() => handleDeleteImage(image.id)}>{t('mypage.download.Delete')}</button>
                    <button style={{ backgroundColor: '#4848f3', right: 80 }} onClick={() => downloadImage(image.upload_img)}>
                      {t('mypage.download.Download')}
                    </button>
                  </div>
                </style.Canvases>
              ))}
            </div>
          </style.CanvasType>
        </style.Wrapper>
      )}
    </div>
  );
}

export default Canvas;
