import React, { useState } from 'react';
import * as style from './styles';
import { useRecoilState } from 'recoil';
import { JoinProcessState } from 'state/state';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

function JoinName(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [joinState, setJoinState] = useRecoilState(JoinProcessState);

  // 입력값 확인
  const [checkInput, setCheckInput] = useState({ focusOn: false, input: false });
  const [userName, setUserName] = useState(props.joinInfo.name);

  const nextJoinProcess = () => {
    if (userName === '') {
      Swal.fire({
        text: t('login.JoinName.ErrorMessage'),
        customClass: {
          popup: 'custom-popup-class',
        },
      }).then((res) => {
        if (res.isConfirmed) {
          return;
        }
      });
    } else {
      navigate('/join?phase=3');
    }
  };
  const prevJoinProcess = () => {
    navigate('/join?phase=1');
  };

  const handleLoginInfo = (e) => {
    const { name, value } = e.target;
    if (value) {
      props.setJoinInfo({ ...props.joinInfo, [name]: value });
      setUserName(value);
      setCheckInput({ ...checkInput, input: true });
    } else {
      setCheckInput({ ...checkInput, input: false });
      setUserName('');
    }
  };

  return (
    <style.SignUpWrapper>
      <style.LoginMessage>{t('login.JoinName.LoginMessage')}</style.LoginMessage>
      <style.LoginText>
        {t('login.JoinName.LoginText1_1')}
        <br />
        {t('login.JoinName.LoginText1_2')}
      </style.LoginText>
      <style.inputBoxInfo>{t('login.JoinName.inputBoxInfo')}</style.inputBoxInfo>
      <style.inputBox
        onChange={handleLoginInfo}
        onFocus={() => setCheckInput({ ...checkInput, focusOn: true })}
        placeholder={t('login.JoinName.inputBox')}
        value={userName}
        name={'name'}
        type="text"
        required
      />
      {userName === '' && checkInput.focusOn && !checkInput.input && <style.WarningMessage color={'#e61503'}>{t('login.JoinName.inputBox')}</style.WarningMessage>}
      <style.GrayButton marginTop={'42px'} onClick={nextJoinProcess}>
        {t('login.JoinName.GrayButton')}
      </style.GrayButton>
      <style.BackBtn src={process.env.PUBLIC_URL + '/images/Signup/back-arrow.png'} onClick={prevJoinProcess} />
    </style.SignUpWrapper>
  );
}

export default JoinName;
