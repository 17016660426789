import React, { useState } from 'react';
import * as style from './styles';
import { useRecoilState } from 'recoil';
import { JoinProcessState } from 'state/state';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

function JoinPassword(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [joinState, setJoinState] = useRecoilState(JoinProcessState);

  const [checkInput, setCheckInput] = useState({ focusOn: false, input: false, focusOn2: false, input2: false });
  const [userPW, setUserPW] = useState(props.joinInfo.password1);
  const [userPW2, setUserPW2] = useState(props.joinInfo.password2);

  const nextJoinProcess = () => {
    if (userPW !== userPW2) {
      Swal.fire({
        text: t('login.JoinPassword.ErrorMessage'),
        customClass: {
          popup: 'custom-popup-class',
        },
      });
    } else if (userPW !== '' && userPW2 !== '') navigate('/join?phase=4');
  };
  const prevJoinProcess = () => {
    navigate('/join?phase=2');
  };

  const handleLoginInfo = (e) => {
    const { name, value } = e.target;
    if (value) {
      props.setJoinInfo({ ...props.joinInfo, [name]: value });
      setUserPW(value);
      setCheckInput({ ...checkInput, input: true });
    } else {
      setCheckInput({ ...checkInput, input: false });
      setUserPW('');
    }
  };
  const handleLoginInfo2 = (e) => {
    const { name, value } = e.target;
    if (value) {
      props.setJoinInfo({ ...props.joinInfo, [name]: value });
      setUserPW2(value);
      setCheckInput({ ...checkInput, input2: true });
    } else {
      setCheckInput({ ...checkInput, input2: false });
      setUserPW2('');
    }
  };

  return (
    <style.SignUpWrapper>
      <style.LoginMessage>{t('login.JoinPassword.LoginMessage')}</style.LoginMessage>
      <style.LoginText>
        {t('login.JoinPassword.LoginText1_1')}
        <br />
        {t('login.JoinPassword.LoginText1_2')}
      </style.LoginText>
      <style.inputBoxInfo>{t('login.JoinPassword.Password')}</style.inputBoxInfo>
      <style.inputBox
        onChange={handleLoginInfo}
        onFocus={() => setCheckInput({ ...checkInput, focusOn: true })}
        placeholder={t('login.JoinPassword.inputBox1')}
        value={userPW}
        name={'password1'}
        type="password"
        required
      />
      {userPW === '' && checkInput.focusOn && !checkInput.input && <style.WarningMessage color={'#e61503'}>{t('login.JoinPassword.WarningMessage1')}</style.WarningMessage>}
      <style.inputBoxInfo marginTop={'18px'}>{t('login.JoinPassword.ConfirmPassword')}</style.inputBoxInfo>
      <style.inputBox
        onChange={handleLoginInfo2}
        onFocus={() => setCheckInput({ ...checkInput, focusOn2: true })}
        placeholder={t('login.JoinPassword.inputBox2')}
        value={userPW2}
        name={'password2'}
        type="password"
        required
      />
      {userPW2 === '' && checkInput.focusOn2 && !checkInput.input2 && <style.WarningMessage color={'#e61503'}>{t('login.JoinPassword.WarningMessage2')}</style.WarningMessage>}
      <style.GrayButton marginTop={'42px'} onClick={nextJoinProcess}>
        {t('login.JoinPassword.GrayButton')}
      </style.GrayButton>
      <style.BackBtn src={process.env.PUBLIC_URL + '/images/Signup/back-arrow.png'} onClick={prevJoinProcess} />
    </style.SignUpWrapper>
  );
}

export default JoinPassword;
