import styled from 'styled-components';

export const LoginContents = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 20px;
  height: calc(100vh - 60px);
`;
export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 90%;
  /* background-color: blue; */

  @media screen and (max-width: 1440px) {
    max-width: 1040px;
    padding: 60px 0;
  }
  @media screen and (max-width: 1280px) {
    max-width: 980px;
    padding: 40px 0;
  }
  @media screen and (max-width: 1040px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const LoginDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 40px;
  width: 60%;
  height: 100%;
  max-height: 720px;
  background-color: #ffffff;
  border-radius: 20px 0 0 20px;
  position: relative;
  @media screen and (max-width: 1040px) {
    width: 100%;
    max-width: 500px;
    border-radius: 20px;
  }
`;

export const LoginMessage = styled.span`
  font-size: 40px;
  margin-bottom: 26px;
  line-height: 1.2em;
  color: #191919;
  font-weight: bold;

  @media screen and (max-width: 1440px) {
    font-size: 32px;
  }
  @media screen and (max-width: 1280px) {
    font-size: 28px;
  }
`;

export const LoginText = styled.div`
  color: #8d95a0;
  font-size: 22px;
  margin-bottom: 42px;
  line-height: 1.45em;

  @media screen and (max-width: 1440px) {
    font-size: 20px;
  }
  @media screen and (max-width: 1280px) {
    font-size: 18px;
  }
`;

export const LoginText2 = styled.span`
  color: ${(props) => props.color};
  text-decoration: ${(props) => props.decoration};
  cursor: ${(props) => props.cursor};
  margin-bottom: 42px;
  line-height: 1.44em;
  font-size: 18px;
  @media screen and (max-width: 1440px) {
    font-size: 16px;
  }
  @media screen and (max-width: 1280px) {
    font-size: 14px;
  }
`;

export const ImageDiv = styled.div`
  height: 100%;
  max-height: 720px;
  border-radius: 0 20px 20px 0;
  align-items: flex-start;
  @media screen and (max-width: 1040px) {
    display: none;
  }
`;

export const RightImage = styled.img`
  width: auto;
  height: 100%;
  max-width: 100%;
  object-fit: contain;
`;

export const SocialLoginWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

export const GoogleLogin = styled.button`
  all: unset;
  background-color: ${(props) => props.BGcolor};
  cursor: pointer;
  border-radius: 5px;
  height: 76px;
  border: solid 1.4px #ededed;
  display: flex;
  align-items: center;
  font-size: 22px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  gap: 90px;
  padding: 0 22px;

  @media screen and (max-width: 1440px) {
    height: 62px;
    gap: 70px;
    font-size: 20px;
  }
  @media screen and (max-width: 1280px) {
    height: 54px;
    gap: 50px;
    font-size: 18px;
  }
`;

export const DivideLine = styled.span`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.4px;
  color: #999;
  margin: 26px 0;
  text-align: center;
  display: flex;
  align-items: center;
`;

export const StartWithEmail = styled.span`
  border-radius: 12px;
  width: 100%;
  height: 76px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  font-weight: 500;
  cursor: pointer;
  margin-bottom: 24px;
  background-color: #f2f4f4;
  color: #8d95a0;
  margin-top: ${(props) => props.marginTop};

  @media screen and (max-width: 1440px) {
    height: 62px;
    font-size: 20px;
  }
  @media screen and (max-width: 1280px) {
    height: 54px;
    font-size: 18px;
  }
`;

export const PointColor = styled.span`
  color: #287ced;
  text-decoration: underline;
  cursor: pointer;
`;

export const JoinUsText = styled.span`
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5em;
  letter-spacing: -0.5px;
  color: #8d95a0;
  display: flex;
  justify-content: center;
  gap: 14px;
  @media screen and (max-width: 1440px) {
    font-size: 18px;
  }
  @media screen and (max-width: 1280px) {
    font-size: 16px;
  }
`;

export const JoinUsBtn = styled.button`
  all: unset;
  font-weight: 500;
  color: #287ced;
  cursor: pointer;
  text-align: center;
`;

export const inputBox = styled.input`
  width: 100%;
  height: 76px;
  border-radius: 12px;
  border: solid 1.4px #ededed;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.55px;
  padding-left: 24px;
  color: #999;

  :hover,
  :focus-visible {
    border: solid 1.4px #8a24e2;
    color: #222;
  }
  @media screen and (max-width: 1440px) {
    height: 62px;
  }
  @media screen and (max-width: 1280px) {
    height: 54px;
  }
`;

export const LoginBtn = styled.button`
  all: unset;
  border-radius: 12px;
  width: 100%;
  height: 76px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  font-weight: 500;
  cursor: pointer;
  margin-bottom: 24px;
  background-color: #faa1a1;
  color: #ffffff;
  margin-top: 42px;

  @media screen and (max-width: 1440px) {
    height: 62px;
    font-size: 20px;
  }
  @media screen and (max-width: 1280px) {
    height: 54px;
    font-size: 18px;
  }
`;
export const inputBoxInfo = styled.span`
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.5px;
  color: #767676;
  margin-bottom: 8px;
  margin-top: ${(props) => props.marginTop};
`;

export const BackBtn = styled.img`
  width: 88px;
  background-color: #f2f4f4;
  border-radius: 10px;
  padding: 24px 30px;
  position: absolute;
  bottom: 0;
  cursor: pointer;
`;

export const TermsOfService = styled.span`
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.6px;
  color: ${(props) => props.color};
  text-decoration: ${(props) => props.decoration};
`;

export const ServiceComment = styled.div`
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.45px;
  color: #999;
  margin-top: 6px;
`;

export const DivideSection = styled.div`
  width: 100%;
  height: 2px;
  border-radius: 13px;
  background-color: #ededed;
  margin: 26px 0;
`;
