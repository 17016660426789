/**********************************
 * 파일명: qrGenerater.js
 * 작성자: Jiwon Kang
 * 설명: 캔버스 QR코드 생성기
 **********************************/

import React, { useCallback, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import QRPopup from '../replaceImage/qrPopup/qrPopup';
import QRCode from 'qrcode';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as style from 'pages/Canvas/styles.js';
import { authState } from 'state';
import { TempCanvasContentState } from 'state/state';
import { propertiesWhenSerializing } from 'utils/canvasSettingUtil';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line
function QRGenerater(props) {
  const navigate = useNavigate();
  const [randomKey, setRandomKey] = useState(null);
  const [qrImage, setQrImage] = useState(null);
  const [qrButtonClick, setQrButtonClick] = useState(false);

  const userInfo = useRecoilValue(authState);
  const setTempCanvasContent = useSetRecoilState(TempCanvasContentState);
  const { t } = useTranslation();

  const { bgTemplateId, setSvgPP } = props;

  // QR코드 생성
  const generateQR = async (bgImgId) => {
    if (userInfo.isLoggedIn) {
      setQrButtonClick(true);
      const randomKey = generateRandomKey();
      setRandomKey(randomKey);
      const query = `{"bgImgId": ${bgImgId}, "randomKey": "${randomKey}", "user_id": ${userInfo['user_id']}, "templateRoll": ${50}}`;
      try {
        setQrImage(await QRCode.toDataURL(query));
      } catch (err) {
        console.error(err);
      }
    } else {
      fireSwalModal().then((res) => {
        if (res.isConfirmed) {
          const canvas = document.getElementById('canvas')?.fabric;
          let json = canvas.toDatalessJSON(propertiesWhenSerializing);
          const tempCanvas = JSON.stringify(json);
          setTempCanvasContent(tempCanvas);
          navigate('/login');
        }
      });
    }
  };

  // 랜덤키 생성기
  const generateRandomKey = () => {
    const lenRandomKey = 5;
    let randomKey = '';
    const alpha = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
    const amountNum = Math.floor(Math.random() * lenRandomKey) + 1; // 1 ~ 5(lenRandomKey)

    for (let i = 0; i < amountNum; i++) {
      randomKey += Math.floor(Math.random() * 10); // 0 ~ 9
    }
    for (let i = 0; i < lenRandomKey - amountNum; i++) {
      randomKey += alpha[Math.floor(Math.random() * 26)];
    }
    return randomKey;
  };

  const fireSwalModal = useCallback(() => {
    return Swal.fire({
      text: t('alert.qrButtonLogin'),
      showCancelButton: true,
      confirmButtonText: 'yes',
      cancelButtonText: 'no',
      customClass: {
        popup: 'custom-popup-class',
      },
    });
  }, [t]);

  return (
    <style.QrContainer>
      <style.QrButtonWrap>
        <style.QrButton
          onClick={() => {
            generateQR(bgTemplateId);
          }}
        >
          Generate QR
        </style.QrButton>
      </style.QrButtonWrap>
      {qrButtonClick ? <QRPopup setQrButtonClick={setQrButtonClick} qrImage={qrImage} randomKey={randomKey} setSvgPP={setSvgPP} /> : null}
    </style.QrContainer>
  );
}

export default QRGenerater;
