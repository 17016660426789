/**********************************
 * 파일명: leftSideBarButtons.js
 * 작성자: Jiwon Kang
 * 설명: MyPage의 좌측 사이드바 버튼
 **********************************/

/* eslint-disable max-lines-per-function */
import React from 'react';
import { useTranslation } from 'react-i18next';
import icons from 'constants/icons';
import { AssetIcon, SidebarItem } from './styles';

function LeftSideBarButtons(props) {
  const { t } = useTranslation();
  const { handleLeftSideBar, activeItem } = props;

  return (
    <React.Fragment>
      <h6>{t('mypage.mypage.Contents')}</h6>
      <SidebarItem isActive={activeItem === 'make'} id={'make'} onClick={handleLeftSideBar}>
        <img src={icons.make} alt={'makeButton'} />
        <span>{t('mypage.mypage.Make')}</span>
      </SidebarItem>
      <p></p>
      <SidebarItem isActive={activeItem === 'canvas'} id={'canvas'} onClick={handleLeftSideBar}>
        <img src={icons.canvas} alt={'canvasButton'} />
        <span>{t('mypage.mypage.Canvas')}</span>
      </SidebarItem>
      <SidebarItem isActive={activeItem === 'product'} id={'product'} onClick={handleLeftSideBar}>
        <img src={icons.product} alt={'productButton'} />
        <span>{t('mypage.mypage.Product')}</span>
      </SidebarItem>
      <SidebarItem isActive={activeItem === 'asset'} id={'asset'} onClick={handleLeftSideBar}>
        <AssetIcon src={icons.asset} alt={'assetButton'} />
        <span>{t('mypage.mypage.Asset')}</span>
      </SidebarItem>
      <SidebarItem isActive={activeItem === 'downloads'} id={'downloads'} onClick={handleLeftSideBar}>
        <img src={icons.download} alt={'downloadButton'} />
        <span>{t('mypage.mypage.Download')}</span>
      </SidebarItem>
    </React.Fragment>
  );
}

export default LeftSideBarButtons;
