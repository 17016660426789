import styled from 'styled-components';

export const Wrap = styled.div`
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 110;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > div {
    position: relative;
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;

    > button {
      all: unset;
      cursor: pointer;
      border-radius: 10px;
      box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.3);
      background-color: #f54545;
      font-size: 20px;
      font-weight: bold;
      letter-spacing: -0.5px;
      color: #fff;
      padding: 18px 24px;
      margin-bottom: 10px;
      margin-top: 20px;
    }
  }
`;

export const Text = styled.label`
  font-size: 24px;
  margin: 10px;
`;

export const CloseBtn = styled.img`
  position: absolute;
  right: 20px;
  top: 25px;
  cursor: pointer;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
  background-color: #fff;
  border-radius: 70%;
  padding: 5px;
`;

export const SyncLoaderWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
`;
