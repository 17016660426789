import { atom } from 'recoil';

export const canvasSizeState = atom({
  key: 'canvasSize', // atom를 지칭할 수 있는 unique ID
  default: {
    // 초기값 설정
    width: 500,
    height: 500,
  },
});

export const selectedColorState = atom({
  key: 'selectedColor', // atom를 지칭할 수 있는 unique ID
  default: {
    // 초기값 설정
    fill: '',
    stroke: '',
    textBgColor: '',
  },
});

export const selectedShadowState = atom({
  key: 'selectedShadow', // atom를 지칭할 수 있는 unique ID
  default: {
    blur: 0,
    offsetX: 0,
    offsetY: 0,
  },
});

export const selectedTextState = atom({
  key: 'selectedText', // atom를 지칭할 수 있는 unique ID
  default: {
    id: '',
    text: '',
    fill: '',
    textBackgroundColor: '',
    fontSize: 40,
    fontStyle: 'normal',
    fontFamily: 'serif',
    fontWeight: 'normal',
    underline: false,
    linethrough: null,
    overline: false,
    fill: 'black',
    stroke: '',
    strokeWidth: null,
    textAlign: 'center',
    lineHeight: null,
  },
});

// text를 넣는 트리거 state
export const addTextState = atom({
  key: 'addText',
  default: {
    text: '',
    fontFamily: '',
    made: false,
  },
});

// canvas 안에 들어가 있는 object들의 모임 fabric 객체 리스트
export const objectList = atom({
  key: 'objectList',
  default: [],
});
// canvas 안에 들어가 있는 object 중 선택된 object의 id
export const selectedObject = atom({
  key: 'selectedObject',
  default: '',
});

// canvas에 도형을 추가할 trigger state
export const addShapeState = atom({
  key: 'addShape',
  default: {
    type: '',
    made: false,
  },
});

// canvas에 이미 누끼딴 이미지를 추가할 trigger state
export const addMyPpState = atom({
  key: 'addMyPp',
  default: {
    src: '',
    made: false,
  },
});

// 도형 수정 state
export const selectedShapeState = atom({
  key: 'selectedShape', // atom를 지칭할 수 있는 unique ID
  default: {
    id: '',
    stroke: '',
    strokeWidth: null,
    fill: '',
  },
});

//그림자 컨트롤을 위한 state
export const shadowHandleState = atom({
  key: 'shadowHandleState',
  default: {
    used: false,
    blur: 20,
    opacity: 50,
    offsetX: 20,
    offsetY: 20,
    skewX: 0,
    skewY: 0,
    color: 'rgba(0, 0, 0, 0.5)',
    scaleY: 0, // shadow 자체의 scaleY와는 다름, 보정값임에 유의
  },
});

export const correctionState = atom({
  key: 'correctionState',
  default: {
    used: false,
    brightness: 0,
    saturation: 0,
    contrast: 0,
  },
});

// canvas의 history를 관리하기 위함
export const countCanvasHistoryState = atom({
  key: 'countCanvasHistoryState',
  default: {
    count: 0, // hisotry(undo stack) 개수
    lastState: null, // 저장하고자 하는 상태
    lastStore: 'history', // 접근하고자 하는 저장소
    reset: true, // tempHistory(redo stack) 초기화
  },
});

// canvas의 초기 사이즈를 관리하기 위함
export const canvasInitialSizeState = atom({
  key: 'canvasInitialSizeState',
  default: {
    width: 0,
    height: 0,
    ratio: 1,
  },
});

// canvas 사이즈 비율 유지 여를 관리하기 위함
export const isRatioLockState = atom({
  key: 'isRatioLockState',
  default: false,
});

// zoom 을 조정했는지를 확인하기 위함
export const zoomTriggerState = atom({
  key: 'zoomTriggerState',
  default: false,
});

// canvas 저장 혹은 다운로드 되는 공간인 workspace를 관리하기 위함
export const workspaceState = atom({
  key: 'workspaceState',
  default: {
    width: 0,
    height: 0,
    scaleX: 1,
    scaleY: 1,
    ratio: 1,
  },
});

// canvas의 각 image 객체에 대한 resize 됬을 때의 값을 저장하기 위함. resize 전후, restore 후에 사용
export const imageCropState = atom({
  key: 'imageCropState',
  default: {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    scaleX: 0,
    scaleY: 0,
  },
});

// color picker의 width를 컨트롤하기 위함
export const colorPickerWidthState = atom({
  key: 'colorPickerState',
  default: 0,
});

// shadow 모드를 컨트롤하기 위함
export const isManualShadowState = atom({
  key: 'isManualState',
  default: { isManual: true, shadowAngle: null },
});

/**
 * workspace의 크기를 관리하는 상태
 */
export const workspaceSizeState = atom({
  key: 'workspaceSizeState',
  default: {
    width: 500,
    height: 500,
  },
});

export const resizeTriggerState = atom({
  key: 'resizeTriggerState',
  default: false,
});

export const canvasLoadingState = atom({
  key: 'canvasLoadingState',
  default: false,
});
