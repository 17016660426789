/**********************************
 * 파일명: shadowPannel.js
 * 작성자: Jiwon Kang
 * 설명: 캔버스의 오른쪽 사이드바의 그림자 담당 컴포넌트
 **********************************/

import React, { useEffect, useState } from 'react';
import { fabric } from 'fabric';
import * as styles from './styles';
import { useTranslation } from 'react-i18next';
import { addShadowEvent, generateBase64Shadow, generateCloneShadow } from 'utils/canvasObjectGenerateUtil';
import ppAPI from 'api/ppAPI';

// eslint-disable-next-line
function ShadowPannel() {
  const getDefaultShadowInfoState = () => {
    return {
      blur: 0.2,
      opacity: 0.5,
      offsetX: 20,
      offsetY: 20,
      skewX: 0,
      skewY: 0,
      scaleY: 0,
    };
  };

  const t = useTranslation('translation').t;
  const [shadowType, setShadowType] = useState('manual'); // ['manual', 'floor', 'topdown']
  const [shadowInfo, setShadowInfo] = useState(null);
  const [canvas, setCanvas] = useState(null);
  const [isUsingShadow, setIsUsingShadow] = useState(null);
  const [targetShadow, setTargetShadow] = useState(null);
  const [eventHandlers, setEventHandlers] = useState(null);

  // 상태 초기화 및 선택된 오브젝트가 그림자를 사용하고 있는지 확인하고 상태를 업데이트
  useEffect(() => {
    const canvas = document.getElementById('canvas').fabric;
    setCanvas(canvas);

    const object = canvas.getActiveObject();
    const shadow = canvas.getObjects().find((obj) => obj.isShadow && obj.parentId === object.id);
    if (shadow) setShadowType(shadow.shadowAngle);
    const isShadowUsed = shadow !== undefined;

    if (isShadowUsed) {
      setIsUsingShadow(true);
      setShadowInfo({
        blur: shadow.blur,
        opacity: shadow.opacity,
        offsetX: shadow.left - object.left,
        offsetY: shadow.top - object.top,
        skewX: shadow.skewX,
        skewY: shadow.skewY,
        scaleY: shadow.scaleY,
      });
      setTargetShadow(shadow);
    }

    if (!isShadowUsed) {
      setIsUsingShadow(false);
      setShadowInfo(getDefaultShadowInfoState());
      setTargetShadow(shadow);
    }
  }, []);

  const handleTogglePanel = () => {
    if (isUsingShadow) {
      setIsUsingShadow(false);
      removeShadow();
    }
    if (!isUsingShadow) {
      setIsUsingShadow(true);
      addManualShadow();
    }
  };

  const addManualShadow = async () => {
    const defaultShadowInfo = getDefaultShadowInfoState();
    const obj = canvas.getActiveObject();
    const shadow = await generateCloneShadow(obj, defaultShadowInfo);
    const eventHandler = addShadowEvent(obj, shadow);
    setEventHandlers(eventHandler);

    console.log('obj', obj);
    console.log('shadow', shadow);

    const index = canvas.getObjects().indexOf(obj);
    canvas.insertAt(shadow, index);
    canvas.requestRenderAll();

    setShadowType('manual');
    setTargetShadow(shadow);
    setShadowInfo(defaultShadowInfo);
  };

  const removeShadow = () => {
    const shadow = targetShadow;
    console.log('shadow removed', shadow);
    canvas.remove(shadow);
    canvas.requestRenderAll();
  };

  const handleShadowBlur = (event, value) => {
    const newValue = Number(value);
    // 기존의 블러 필터를 제거
    targetShadow.filters = targetShadow.filters.filter((filter) => !(filter instanceof fabric.Image.filters.Blur));

    // 새로운 블러 필터를 추가
    const blurFilter = new fabric.Image.filters.Blur({
      blur: newValue / 100,
    });
    targetShadow.filters.push(blurFilter);

    // 필터 적용
    targetShadow.applyFilters();
    targetShadow.set('blur', (newValue / 100).toFixed(2));
    setShadowInfo((prev) => {
      return { ...prev, blur: (newValue / 100).toFixed(2) };
    });
    canvas.requestRenderAll();
  };

  const handleInputBlur = (event) => {
    let newValue = typeof event === 'number' ? event : parseFloat(event.target.value);
    handleShadowBlur(event, newValue);
  };

  const handleShadowOpacity = (event, newValue) => {
    targetShadow.set('opacity', newValue / 100);
    setShadowInfo((prev) => {
      return { ...prev, opacity: newValue / 100 };
    });
    canvas.requestRenderAll();
  };

  const handleInputOpacity = (event) => {
    let newValue = typeof event === 'number' ? event : parseFloat(event.target.value);
    if (isNaN(newValue)) {
      newValue = 0;
    }
    handleShadowOpacity(event, newValue);
  };

  const handleShadowOffsetX = (event, newValue) => {
    const obj = canvas.getActiveObject();
    if (!obj) return;

    // 원본 객체의 left 위치와 새로운 offsetX 값을 이용하여 그림자의 left 값을 계산
    const newLeft = obj.left + newValue;

    // shadowInfo 상태 업데이트
    setShadowInfo((prev) => {
      return { ...prev, offsetX: newValue };
    });

    // 그림자 객체의 위치 업데이트
    targetShadow.set({ left: newLeft });
    canvas.requestRenderAll();
  };

  const handleInputOffsetX = (event) => {
    let newValue = typeof event === 'number' ? event : parseFloat(event.target.value);
    if (isNaN(newValue)) {
      newValue = 0;
    }
    handleShadowOffsetX(event, newValue);
  };

  const handleShadowOffsetCommitted = (event, newValue) => {
    // 슬라이더 조작이 완료되면 이벤트 리스너를 다시 등록
    if (eventHandlers) {
      eventHandlers.remove();
    }
    const newEventHandlers = addShadowEvent(canvas.getActiveObject(), targetShadow);
    setEventHandlers(newEventHandlers);
  };

  const handleShadowOffsetY = (event, newValue) => {
    const obj = canvas.getActiveObject();
    if (!obj) return;

    const newTop = obj.top + newValue;
    setShadowInfo((prev) => {
      return { ...prev, offsetY: newValue };
    });

    if (targetShadow) {
      targetShadow.set({ top: newTop });
      canvas.requestRenderAll();
    }
  };

  const handleInputOffsetY = (event) => {
    let newValue = typeof event === 'number' ? event : parseFloat(event.target.value);
    if (isNaN(newValue)) {
      newValue = 0;
    }
    handleShadowOffsetY(event, newValue);
  };

  const handleShadowSkewX = (event, newValue) => {
    setShadowInfo((prev) => {
      return { ...prev, skewX: newValue };
    });
    targetShadow.set({ skewX: newValue });
    canvas.requestRenderAll();
  };

  const handleInputSkewX = (event) => {
    let newValue = typeof event === 'number' ? event : parseFloat(event.target.value);
    if (isNaN(newValue)) {
      newValue = 0;
    }
    handleShadowSkewX(event, newValue);
  };

  const handleShadowSkewY = (event, newValue) => {
    setShadowInfo((prev) => {
      return { ...prev, skewY: newValue };
    });
    targetShadow.set({ skewY: newValue });
    canvas.requestRenderAll();
  };

  const handleInputSkewY = (event) => {
    let newValue = typeof event === 'number' ? event : parseFloat(event.target.value);
    if (isNaN(newValue)) {
      newValue = 0;
    }
    handleShadowSkewY(event, newValue);
  };

  const handleShadowScaleY = (event, newValue) => {
    const obj = canvas.getActiveObject();
    if (!obj) return;

    const newScaleY = (obj.scaleY + newValue) / 100;

    setShadowInfo((prev) => {
      return { ...prev, scaleY: newValue };
    });

    targetShadow.set({ scaleY: newScaleY });
    canvas.requestRenderAll();
  };

  const handleInputScaleY = (event) => {
    let newValue = typeof event === 'number' ? event : parseFloat(event.target.value);
    if (isNaN(newValue)) {
      newValue = 0;
    }
    handleShadowScaleY(event, newValue);
  };

  const handleKeyDown = (event) => {
    // 엔터 키가 눌렸는지 확인
    if (event.key === 'Enter') {
      // 입력 필드에서 포커스 제거
      event.target.blur();
    }
  };

  const handleManualButtonClicked = () => {
    if (shadowType === 'manual') return;
    removeShadow();
    setShadowType('manual');
    addManualShadow();
  };

  const handleFloorButtonClicked = () => {
    if (shadowType === 'floor') return;
    removeShadow();
    setShadowType('floor');
    addBackendShadow('floor');
  };

  const handleTopDownButtonClicked = () => {
    if (shadowType === 'topdown') return;
    removeShadow();
    setShadowType('topdown');
    addBackendShadow('topdown');
  };

  const addBackendShadow = async (type) => {
    const background = canvas.getObjects().find((obj) => obj.isBackground);
    let bgName = null;
    if (background) bgName = background.type === 'image' ? parseInt(background.templateId) : null;
    const target = canvas.getActiveObject();
    const maskImage = target.src;
    const originImage = target.originSrc;
    const angle = type;
    const data = { bgName, maskImage, originImage, angle };

    const shadowData = await getBackendShadow(data);
    const shadow = await generateBase64Shadow(target, shadowData);

    if (eventHandlers) {
      eventHandlers.remove();
    }
    const newEventHandlers = addShadowEvent(canvas.getActiveObject(), shadow);
    setEventHandlers(newEventHandlers);

    const index = canvas.getObjects().indexOf(target);
    canvas.insertAt(shadow, index);

    targetShadow.set('shadowAngle', type);
    setTargetShadow(shadow);
    manageBackendShadowInfo(shadow);
    console.log('shadow', shadow);
    canvas.requestRenderAll();
  };

  const manageBackendShadowInfo = (shadow) => {
    setShadowInfo({
      blur: shadow.blur,
      opacity: shadow.opacity,
      offsetX: 0,
      offsetY: 0,
      skewX: shadow.skewX,
      skewY: shadow.skewY,
      scaleY: shadow.scaleY,
    });
  };

  const getBackendShadow = async (data) => {
    const res = await ppAPI.generatePpShadow(data);
    console.log('res', res);
    return res.data;
  };

  return (
    <>
      <styles.SwitchWrap>
        <span>
          <img src={process.env.PUBLIC_URL + '/images/Canvas/SideBar/shadowIcon.svg'} alt="shadowIcon" />
          <span>{t('canvas.layer.Shadow')}</span>
        </span>
        <styles.switchBtn
          className="switch"
          onChange={() => {
            handleTogglePanel();
          }}
        >
          <input type="checkbox" checked={isUsingShadow} onChange={() => setIsUsingShadow((prev) => !prev)} />
          <span className="slider round"></span>
        </styles.switchBtn>
      </styles.SwitchWrap>
      {/* 그림자 스위치 클릭 시 나오는 세부 수정 사항 */}
      {isUsingShadow ? (
        <>
          {/* 그림자 유형 선택 */}
          <div style={{ display: 'flex', width: '100%' }}>
            <styles.makeShadowButton
              onClick={() => {
                handleManualButtonClicked();
              }}
            >
              Manual
            </styles.makeShadowButton>
            <styles.makeShadowButton
              onClick={() => {
                handleFloorButtonClicked();
              }}
              style={{ marginLeft: '10px' }}
            >
              90°
            </styles.makeShadowButton>
            <styles.makeShadowButton
              onClick={() => {
                handleTopDownButtonClicked();
              }}
              style={{ marginLeft: '10px' }}
            >
              180°
            </styles.makeShadowButton>
          </div>

          {/* Blur 설정 */}
          <styles.HSVDiv>
            <styles.HSVHeader>
              <styles.OptionText>{t('canvas.shadow.Intensity')}</styles.OptionText>
            </styles.HSVHeader>
            <styles.HSVBody>
              <styles.HSVSlider max={100} min={0} value={(shadowInfo.blur * 100).toFixed(0)} onChange={handleShadowBlur} valueLabelDisplay="auto" />
              <styles.ValueInput name="blur" value={(shadowInfo.blur * 100).toFixed(0)} onChange={handleInputBlur} onKeyDown={handleKeyDown} />
            </styles.HSVBody>
          </styles.HSVDiv>

          {/* Opacity 설정 */}
          <styles.HSVDiv>
            <styles.HSVHeader>
              <styles.OptionText>{t('canvas.shadow.Opacity')}</styles.OptionText>
            </styles.HSVHeader>
            <styles.HSVBody>
              <styles.HSVSlider max={100} min={0} value={(shadowInfo.opacity * 100).toFixed(0)} onChange={handleShadowOpacity} valueLabelDisplay="auto" />
              <styles.ValueInput name="opacity" value={(shadowInfo.opacity * 100).toFixed(0)} onChange={handleInputOpacity} onKeyDown={handleKeyDown} />
            </styles.HSVBody>
          </styles.HSVDiv>

          {/* offsetX 설정 */}
          <styles.HSVDiv>
            <styles.HSVHeader>
              <styles.OptionText>{t('canvas.shadow.Horizontal')}</styles.OptionText>
            </styles.HSVHeader>
            <styles.HSVBody>
              <styles.HSVSlider min={-200} max={200} value={shadowInfo.offsetX} onChange={handleShadowOffsetX} valueLabelDisplay="auto" onChangeCommitted={handleShadowOffsetCommitted} />
              <styles.ValueInput name="offsetX" value={shadowInfo.offsetX} onChange={handleInputOffsetX} onBlur={handleShadowOffsetCommitted} onKeyDown={handleKeyDown} />
            </styles.HSVBody>
          </styles.HSVDiv>

          {/* offsetY 설정 */}
          <styles.HSVDiv>
            <styles.HSVHeader>
              <styles.OptionText>{t('canvas.shadow.Vertical')}</styles.OptionText>
            </styles.HSVHeader>
            <styles.HSVBody>
              <styles.HSVSlider min={-200} max={200} value={shadowInfo.offsetY} onChange={handleShadowOffsetY} valueLabelDisplay="auto" onChangeCommitted={handleShadowOffsetCommitted} />
              <styles.ValueInput name="offsetY" value={shadowInfo.offsetY} onChange={handleInputOffsetY} onBlur={handleShadowOffsetCommitted} onKeyDown={handleKeyDown} />
            </styles.HSVBody>
          </styles.HSVDiv>

          {/* skewX 설정 */}
          <styles.HSVDiv>
            <styles.HSVHeader>
              <styles.OptionText>{t('canvas.shadow.SkewX')}</styles.OptionText>
            </styles.HSVHeader>
            <styles.HSVBody>
              <styles.HSVSlider min={-100} max={100} value={shadowInfo.skewX} onChange={handleShadowSkewX} valueLabelDisplay="auto" />
              <styles.ValueInput name="skewX" value={shadowInfo.skewX} onChange={handleInputSkewX} onKeyDown={handleKeyDown} />
            </styles.HSVBody>
          </styles.HSVDiv>

          {/* skewY 설정 */}
          <styles.HSVDiv>
            <styles.HSVHeader>
              <styles.OptionText>{t('canvas.shadow.SkewY')}</styles.OptionText>
            </styles.HSVHeader>
            <styles.HSVBody>
              <styles.HSVSlider min={-100} max={100} value={shadowInfo.skewY} onChange={handleShadowSkewY} valueLabelDisplay="auto" />
              <styles.ValueInput name="skewY" value={shadowInfo.skewY} onChange={handleInputSkewY} onKeyDown={handleKeyDown} />
            </styles.HSVBody>
          </styles.HSVDiv>

          {/* scaleY 설정 */}
          <styles.HSVDiv>
            <styles.HSVHeader>
              <styles.OptionText>{t('canvas.shadow.ScaleY')}</styles.OptionText>
            </styles.HSVHeader>
            <styles.HSVBody>
              <styles.HSVSlider max={100} min={-100} step={1} value={shadowInfo.scaleY} onChange={handleShadowScaleY} valueLabelDisplay="auto" />
              <styles.ValueInput name="scaleY" value={shadowInfo.scaleY} onChange={handleInputScaleY} onKeyDown={handleKeyDown} />
            </styles.HSVBody>
          </styles.HSVDiv>
        </>
      ) : null}
    </>
  );
}

export default ShadowPannel;
