import * as style from 'pages/Home/styles';
import UploadImage from '../../components/Home/UploadImage/uploadImage';
import MainBanner from '../../components/Home/MainBanner/mainBanner';
import Partners from '../../components/Home/Partners/partners';
import SubBanner from '../../components/Home/SubBanner/subBanner';
import Footer from 'components/Footer/footer';

function Home() {
  return (
    <style.Body>
      <UploadImage />
      <MainBanner />
      <Partners />
      <SubBanner />
      <Footer />
    </style.Body>
  );
}

export default Home;
