import styled from 'styled-components';

export const ZoomContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
`;

export const Canvas = styled.canvas`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`;
