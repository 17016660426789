import * as style from './styles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { selectedImageState } from 'state/imageState';
import { objectList, selectedObject } from 'state/canvasState';
import { initialSize } from 'utils/canvasSettingUtil';
import { rightSidebarState, showUploadState } from 'state/sidebarState';
import { addImage, addImageEvent, addObjectSelectEvent, generateObjectId, resizeImage } from 'utils/canvasObjectGenerateUtil';
import Swal from 'sweetalert2';
import colors from 'constants/colors';
import canvasAPI from 'api/canvasAPI';
import SyncLoader from 'react-spinners/SyncLoader';

const override = {
  display: 'block',
  position: 'absolute',
  margin: '0 auto',
  borderColor: 'red',
};

// eslint-disable-next-line
function QRPopup(props) {
  const { t } = useTranslation();
  const { setQrButtonClick, qrImage, randomKey } = props;
  const setShowUpload = useSetRecoilState(showUploadState);
  const setSelectedImage = useSetRecoilState(selectedImageState);
  const setSelectedIndex = useSetRecoilState(selectedObject);
  const setRightState = useSetRecoilState(rightSidebarState);
  const setCanvasObjects = useSetRecoilState(objectList);
  const resetSelectedImage = useResetRecoilState(selectedImageState);

  let [loading, setLoading] = useState(false);

  const fireFailModal = () => {
    Swal.fire({ text: 'Fail to load. Try again' });
  };

  const handleTimeout = (requestImg) => {
    setLoading(false);
    fireFailModal();
    clearInterval(requestImg);
    setQrButtonClick(false);
  };

  const addEvent = (img) => {
    addObjectSelectEvent(img, setSelectedIndex, setRightState);
    addImageEvent(img, resetSelectedImage, setSelectedImage, setCanvasObjects);
  };

  const updateImageState = async (res) => {
    const { data } = res;
    const date = new Date();
    const time = date.getTime();
    const imageData = {
      ppImage: data.remove_bg_img.split('?')[0] + '?' + time,
      uploadImage: data.upload_img.split('?')[0] + '?' + time,
      maskImage: data.remove_bg_img.split('?')[0] + '?' + time,
      fileName: 'newUploadImage',
    };
    const { fabric: canvas } = document.getElementById('canvas');
    const id = generateObjectId('pp');
    const image = await addImage(canvas, imageData.ppImage, { id, src: imageData.ppImage, originSrc: imageData.uploadImage, name: id, initialSize, type: 'image' });
    resizeImage(canvas, image);
    addEvent(image);

    setQrButtonClick(false);
    setShowUpload('none');
  };

  const handleFail = (e, waitResponse, requestImg) => {
    clearInterval(waitResponse);
    clearInterval(requestImg);
    setLoading(false);
    setQrButtonClick(false);
    fireFailModal();
  };

  const fetchImageData = (waitResponse, requestImg) => {
    canvasAPI
      .getQrImgArrive({ randomKey: randomKey })
      .then((res) => {
        setLoading(false);
        clearInterval(waitResponse);
        clearInterval(requestImg);
        updateImageState(res);
      })
      .catch((e) => handleFail(e, waitResponse, requestImg));
  };

  const startImageRequest = () => {
    setLoading(true);
    const waitResponse = setTimeout(() => handleTimeout(requestImg), 15000);
    const requestImg = setInterval(() => fetchImageData(waitResponse, requestImg), 2000);
  };

  const clickOKBtn = () => {
    startImageRequest();
  };

  return (
    <style.Wrap>
      {loading ? (
        <style.SyncLoaderWrapper>
          <SyncLoader color={colors.deny} loading={loading} cssOverride={override} size={20} aria-label="Loading Spinner" data-testid="loader" />
        </style.SyncLoaderWrapper>
      ) : (
        <div style={{ backgroundColor: colors.bg1 }}>
          <style.Text>{t('canvas.qrPopup.info_1')}</style.Text>
          <style.CloseBtn src={process.env.PUBLIC_URL + '/images/Canvas/SideBar/cancleBtn.svg'} onClick={() => setQrButtonClick(false)} />
          <img src={qrImage} width={200} alt={'QRimage'} />
          <style.Text>{t('canvas.qrPopup.info_2')}</style.Text>
          <button onClick={() => clickOKBtn()}>{t('canvas.qrPopup.ok')}</button>
        </div>
      )}
    </style.Wrap>
  );
}

export default QRPopup;
