import React, { useEffect, useState, useRef } from 'react';
import * as styles from './styles';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { add, debounce, set } from 'lodash';
import HistoryManageButtons from '../HistoryManageButtons/HistoryManageButtons';
// Recoil 상태 임포트
import { canvasLoadingState, workspaceSizeState, resizeTriggerState, selectedObject, objectList, selectedTextState, selectedShapeState } from 'state/canvasState';
import { leftSidebarState, rightSidebarState } from 'state/sidebarState';
import { bgImageState, productImageState, ImageNameState, selectedImageState } from 'state/imageState';
import { TempCanvasContentState, zoomState } from 'state/state';

// canvasSettingUtil.js에서 관련된 함수들 임포트
import {
  addSingleSelectionEvent,
  generateCanvas,
  fillCanvasSize,
  moveObjectsWhenResize,
  resetViewPort,
  addPanningEvent,
  addZoomEvent,
  addSelectionClearEvent,
  initCanvasHistory,
  disabledToggle,
  addKeyListeners,
  settingTransparentBg,
} from 'utils/canvasSettingUtil';

// canvasObjectGenerateUtil.js에서 관련된 함수들 임포트
import {
  generateCrossBackground,
  generateHalfBackground,
  generateSingleBackground,
  generateObjectId,
  addClipPath,
  addPP,
  addImage,
  getTemplate,
  addShape,
  addObjectSelectEvent,
  addShapeEvnet,
  addTextEvnet,
  addImageEvent,
  addShadowEvent,
  getObjectById,
} from 'utils/canvasObjectGenerateUtil';
import { generateClipPath } from 'utils/canvasObjectGenerateUtil';
import { useLocation } from 'react-router-dom';
import { shapeTypeList } from 'constants/list';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line
function CanvasWorkspace() {
  const { t } = useTranslation();
  const [workspaceSize, setWorkspaceSize] = useRecoilState(workspaceSizeState);
  const workspaceSizeRef = useRef(null);
  const resetWorkspaceSize = useResetRecoilState(workspaceSizeState);
  const [canvas, setCanvas] = useState(null);
  const canvasRef = useRef(null);
  const location = useLocation();
  const bgImage = useRecoilValue(bgImageState);
  const resetBgImageState = useResetRecoilState(bgImageState);
  const ppImage = useRecoilValue(productImageState);
  const resetPpImageState = useResetRecoilState(productImageState);
  const [zoom, setZoom] = useRecoilState(zoomState);
  const [resizeTrigger, setResizeTrigger] = useRecoilState(resizeTriggerState);
  const [canvasObjectList, setCanvasObjectList] = useRecoilState(objectList);
  const resetObjectListState = useResetRecoilState(objectList);
  const [selectedIndex, setSelectedIndex] = useRecoilState(selectedObject);
  const [rightState, setRightState] = useRecoilState(rightSidebarState);
  const [initCanvasTrigger, setInitCanvasTrigger] = useState(false);
  const setSelectedText = useSetRecoilState(selectedTextState);
  const setSelectedShape = useSetRecoilState(selectedShapeState);
  const setSelectedImage = useSetRecoilState(selectedImageState);
  const resetRightState = useResetRecoilState(rightSidebarState);
  const resetSelectedText = useResetRecoilState(selectedTextState);
  const resetSelectedShape = useResetRecoilState(selectedShapeState);
  const resetSelectedImage = useResetRecoilState(selectedImageState);
  const resetTempCanvasContent = useResetRecoilState(TempCanvasContentState);
  const [canvasLoading, setCanvasLoading] = useRecoilState(canvasLoadingState);

  const loadingTime = 1500;

  // 캔버스 시작시 로딩창 표시
  useEffect(() => {
    setCanvasLoading(true);

    const timer = setTimeout(() => {
      setCanvasLoading(false);
    }, loadingTime);

    return () => clearTimeout(timer);
  }, [setCanvasLoading]);

  // 컴포넌트 로딩시 캔버스 초기화
  useEffect(() => {
    initCanvas();
    setCanvas(canvasRef.current);
    setInitCanvasTrigger(true);
    return () => {
      if (canvas && typeof canvas.deleteHistoryEventListener === 'function') {
        canvas.deleteHistoryEventListener();
      }
      resetWorkspaceSize();
      resetObjectListState();
      resetBgImageState();
      resetPpImageState();
      resetRightState();
      setInitCanvasTrigger(false);

      console.log('Canvas Workspace Unmount');
    };
  }, []);

  // 컴포넌트 로딩시 캔버스 초기화 및 이벤트 등록
  // eslint-disable-next-line
  useEffect(() => {
    if (!initCanvasTrigger || !canvas || !canvasRef.current) return;
    canvasRef.current = canvas;
    document.getElementById('canvas').fabric = canvas;
    const loadCanvasData = location.state?.canvas;

    // load canvas
    if (loadCanvasData) loadCanvas(loadCanvasData);
    else if (!loadCanvasData) {
      const clipPath = generateClipPath(canvas, workspaceSize.width, workspaceSize.height);
      addClipPath(canvas, clipPath);
      addBackground({ canvas, bgImage, callback: handleObjectPostProcessing });
      addPP({ canvas, ppImage, callback: handleObjectPostProcessing, initialValue: { name: ppImage.fileName } });
    }
    // common
    const debounceResizeHandler = debounce(handleResize, 100);
    window.addEventListener('resize', debounceResizeHandler);
    const events = addEvents(canvas);
    canvas.addHistoryEventListener();
    disabledToggle('undo', true);
    disabledToggle('redo', true);

    console.log('Canvas Initializing Complete');
    console.log('canvas', canvas);
    return () => {
      window.removeEventListener('resize', debounceResizeHandler);
      events.zoomEvent.remove();
      events.panningEvent.remove();
      events.selectionClearEvent.remove();
      events.singleSelectionEvent.remove();
      // events.keyListenerEvent.remove();
      canvas.dispose();
      console.log('Canvas Initializing Unmount');
    };

    // eslint-disable-next-line
  }, [initCanvasTrigger]);

  // workspaceSize 변경 시 클립패스 크기 변경
  useEffect(() => {
    if (canvasRef.current === null) return;
    if (canvas === null) return;

    workspaceSizeRef.current = workspaceSize;

    handleResize();

    canvas.requestRenderAll();

    console.log('WorkSpace Size', workspaceSize);
    console.log('ClipPath Resized');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceSize]);

  // LeftSideBar의 리사이즈가 트리거 될때마다 줌 초기화
  useEffect(() => {
    if (canvasRef.current === null) return;
    if (canvas === null) return;

    resetZoom(canvas);
    console.log('Zoom Reset');
  }, [resizeTrigger]);

  // selectedObject 변경 시 캔버스 객체 선택 및 선택 해제
  useEffect(() => {
    if (canvasRef.current === null) return;
    if (canvas === null) return;

    if (selectedIndex === null || selectedIndex === '') {
      canvas.discardActiveObject();
      canvas.requestRenderAll();
      return;
    }

    const selectedObj = canvas.getObjects().find((obj) => obj.id === selectedIndex);
    if (selectedObj === undefined) return;

    canvas.setActiveObject(selectedObj);
    canvas.requestRenderAll();
  }, [selectedIndex]);

  /**
   * 캔버스를 초기화하는 함수
   * @returns fabric.Canvas
   */
  const initCanvas = async () => {
    const canvas = generateCanvas();
    fillCanvasSize(canvas);
    console.log('initCanvas canvas', canvas);

    document.getElementById('canvas').fabric = canvas;
    canvasRef.current = canvas;
    return canvas;
  };

  /**
   * 캔버스를 로드하는 함수
   * @param {JSON} loadCanvasData 서버에서 로드한 캔버스 JSON 데이터
   */
  const loadCanvas = (loadCanvasData) => {
    loadCanvasData.id || Swal.fire({ text: t('canvas.canvasHeader.SavePossible') }).then(() => resetTempCanvasContent()); // 임시 저장된 canvas 데이터 초기화
    canvas.loadFromJSON(JSON.parse(loadCanvasData.fabrics), () => {
      settingTransparentBg(canvas);
      canvas.forEachObject((object) => {
        if (object.parentId) {
          object.evented = false;
        }
        addObjectSelectEvent(object, setSelectedIndex, setRightState);
        if (object.isShadow) addShadowEvent(getObjectById(canvas, object.parentId), object);
        else if (object.type === 'text') addTextEvnet(object, resetSelectedText, setSelectedText, setCanvasObjectList);
        else if (object.type === 'image') addImageEvent(object, resetSelectedImage, setSelectedImage, setCanvasObjectList);
        else if (shapeTypeList.includes(object.type)) addShapeEvnet(object, resetSelectedShape, setSelectedShape, setCanvasObjectList);
        else console.log('Event listener not registerd object: ', object);
      });
      canvas.history = []; // history 초기화
    });
    console.log('loadCanvas canvas', canvas);
  };

  /**
   * 캔버스 리사이징 이벤트 핸들러
   * @returns void
   */
  const handleResize = () => {
    const canvas = canvasRef.current;
    if (!workspaceSizeRef.current) return;

    fillCanvasSize(canvas);

    const clipPath = generateClipPath(canvas, workspaceSizeRef.current.width, workspaceSizeRef.current.height);
    moveObjectsWhenResize(canvas, canvas.clipPath, clipPath);
    addClipPath(canvas, clipPath);

    canvasRef.current = canvas;
    console.log('Canvas Resized');

    canvas.requestRenderAll();
  };

  // 배경 객체 추가 함수
  /**
   * 배경 객체를 추가하는 함수
   * @param {fabric.canvas} canvas
   * @param {*} bgImage
   * @param {function} callback 배경 추가 후 실행될 콜백 함수
   * @returns
   */
  const addBackground = async ({ canvas, bgImage, callback }) => {
    const id = generateObjectId('bg');
    console.log('Adding Background..', bgImage);
    if (bgImage.type === 'template') {
      const template = await addImage(canvas, await getTemplate(bgImage.templateId), { templateId: bgImage.templateId, id: id, isBackground: true }, callback);
      matchCanvasSize(template);
      return template;
    } else if (bgImage.type === 'single') {
      if (bgImage.colors[0] === '#00000000') return; // 투명 배경 예외처리
      const singleBg = await generateSingleBackground(canvas, bgImage, id);
      addShape(canvas, singleBg, callback);
      return singleBg;
    } else if (bgImage.type === 'cross') {
      const crossBg = await generateCrossBackground(canvas, bgImage, id);
      addShape(canvas, crossBg, callback);
      return crossBg;
    } else if (bgImage.type === 'half') {
      const halfBg = await generateHalfBackground(canvas, bgImage, id);
      addShape(canvas, halfBg, callback);
      return halfBg;
    }
  };

  const handleObjectPostProcessing = (fabricObj) => {
    console.log('User Object Added');
    const canvasObjectInfo = {
      id: fabricObj.id,
      name: fabricObj.name,
      type: fabricObj.type,
      src: fabricObj.src,
    };
    console.log('handleObjectPostProcessing', fabricObj);
    setCanvasObjectList((prev) => [...prev, canvasObjectInfo]);
    addObjectSelectEvent(fabricObj, setSelectedIndex, setRightState);
    return fabricObj;
  };

  /**
   * 배경 객체를 캔버스의 크기에 맞게 조정하는 함수
   * @returns void
   */
  const matchCanvasSize = (object) => {
    setWorkspaceSize({
      width: object.width * object.scaleX,
      height: object.height * object.scaleY,
    });
    console.log('Canvas Size Matched', 'width:', object.width * object.scaleX, 'height:', object.height * object.scaleY);
    canvas.sendToBack(object);
  };

  const addEvents = (canvas) => {
    const zoomEvent = addZoomEvent(canvas, setZoom);
    const panningEvent = addPanningEvent(canvas);
    const selectionClearEvent = addSelectionClearEvent(canvas, setSelectedIndex, setRightState);
    const singleSelectionEvent = addSingleSelectionEvent(canvas);
    // const keyListenerEvent = addKeyListeners(canvas);
    initCanvasHistory(canvas);

    return { zoomEvent, panningEvent, selectionClearEvent, singleSelectionEvent };
  };

  const resetZoom = (canvas) => {
    canvas.setZoom(1);
  };

  /**
   * 객체를 리스트 아이템으로 변환하는 함수
   * @param object
   * @returns {{src, name, id, type}}
   */
  const convertObjectToListItem = (object) => {
    return {
      id: object.id,
      name: object.name,
      type: object.type,
      src: object.src,
    };
  };

  const handleLoadCanvasObjects = (object, updatedObjectList) => {
    object.setCoords();
    addObjectSelectEvent(object, setSelectedIndex, setRightState);
    if (object.parentId) {
      object.evented = false;
      addShadowEvent(getObjectById(canvas, object.parentId), object); // shadow event 추가
    }
    updatedObjectList.push(convertObjectToListItem(object));
  };

  /**
   * canvas의 history를 복사하는 함수
   * loadFromJSON 함수의 인자로 넘기기 위해 canvas의 history를 복사하는 함수
   * history 를 그냥 loadFromJSON 함수에 인자로 넣으면 참조값이 넘어가기 때문에 history를 복사하여 넘겨줌
   * @param history
   * @returns {any}
   */
  function cloneHistory(history) {
    return JSON.parse(JSON.stringify(history));
  }
  // canvas에서 가장 최근 undo 했던 것을 되돌리는 함수
  const redo = () => {
    if (canvas.historyTemp?.length > 0) {
      const history = canvas.redo();
      if (history) {
        canvas.deleteHistoryEventListener();
        canvas.loadFromJSON(cloneHistory(history), () => {
          const updatedObjectList = [];
          canvas.getObjects().forEach((object) => {
            handleLoadCanvasObjects(object, updatedObjectList);
          });
          setCanvasObjectList(updatedObjectList);
          settingTransparentBg(canvas);
          canvas.renderAll();
          canvas.addHistoryEventListener();
        });
      }
    }
    disabledToggle('undo', false);
    if (canvas.historyTemp.length === 0) disabledToggle('redo', true);
  };

  // canvas에서 가장 최근 추가ㆍ수정ㆍ삭제 했던 것을 되돌리는 함수
  const undo = () => {
    if (canvas.history?.length > 1) {
      const history = canvas.undo();
      if (history) {
        canvas.deleteHistoryEventListener();
        canvas.loadFromJSON(cloneHistory(history), () => {
          const updatedObjectList = [];
          canvas.getObjects().forEach((object) => {
            handleLoadCanvasObjects(object, updatedObjectList);
          });
          setCanvasObjectList(updatedObjectList);
          settingTransparentBg(canvas);
          canvas.renderAll();
          canvas.addHistoryEventListener();
        });
      }
    }
    disabledToggle('redo', false);
    if (canvas.history.length <= 1) disabledToggle('undo', true);
  };

  return (
    <div style={{ position: 'relative' }}>
      <HistoryManageButtons undo={undo} redo={redo} />
      <styles.Canvas id="canvas" />
    </div>
  );
}

export default CanvasWorkspace;
