import * as style from './styles';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import ReactCrop from 'react-image-crop';
import {useRecoilState, useResetRecoilState, useSetRecoilState} from 'recoil';
import heic2any from 'heic2any';
import imageCompression from 'browser-image-compression';
import { Base64State, brushState, ImageNameState, IsImageUploadingState, ProductCropState, TempCanvasContentState } from 'state/state';
import { normalImageState, selectedImageState } from 'state/imageState';
import { showUploadState } from 'state/sidebarState';
import canvasAPI from 'api/canvasAPI';
import { ProgressBar } from 'components';
import { useTranslation } from 'react-i18next';
import { rightSidebarState } from 'state/sidebarState';
import QRCode from 'qrcode';
import QRPopup from './qrPopup/qrPopup';
import ppAPI from 'api/ppAPI';
import { authState } from 'state';
import { SyncLoader } from 'react-spinners';
import { propertiesWhenSerializing } from 'utils/canvasSettingUtil';
import Swal from 'sweetalert2';
import { productImageState } from 'state/imageState';
import * as productState from 'state/productState';
import {
  addImage,
  addImageEvent,
  addObjectSelectEvent,
  generateObjectId,
  resizeImage
} from 'utils/canvasObjectGenerateUtil';
import {objectList, selectedObject} from "../../../state/canvasState";

function ReplaceImage(props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [uploadCheck, setUploadCheck] = useState(true);
  const [brushStatus, setBrushStatus] = useRecoilState(brushState);
  const [imgUpload, setImgUpload] = useState(false);

  const [ppName, setPPName] = useRecoilState(ImageNameState); // pp 이름

  const [loading, setLoading] = useState(false);
  const [loadingPercent, setLoadingPercent] = useState(null);
  const [, setNormalImage] = useRecoilState(normalImageState);
  const [rightState, setRightState] = useRecoilState(rightSidebarState);

  const [Imgvalues, setImgValues] = useState(null);

  const [base64, setBase64] = useRecoilState(Base64State);
  const [crop, setCrop] = useRecoilState(ProductCropState);
  const [showUpload, setShowUpload] = useRecoilState(showUploadState);

  // 크롭한 이미지 관련 상태
  const [srcWidth, setSrcWidth] = useState(1);
  const [srcHeight, setSrcHeight] = useState(1);
  const [beforeCrop, setBeforeCrop] = useState({});
  const imgRef = useRef();
  const [cropImageUrl, setCropImageUrl] = useState();

  // QR 관련
  const [qrButtonClick, setQrButtonClick] = useState(false);
  const [qrImage, setQrImage] = useState(null);
  const [randomKey, setRandomKey] = useState(null);
  const [selectedImage, setSelectedImage] = useRecoilState(selectedImageState);
  // QR에 user 정보를 실어주기 위함
  const [userInfo, setUserInfo] = useRecoilState(authState);

  // 이미지가 업로드중인지 아닌지 구분하여 spinner 띄우기 위한 state
  const [isImageUploading, setIsImageUploading] = useState(false);

  // 배경 제거 체크박스가 체크 되었는지 아닌지
  // const [isChecked, setIsChecked] = useState(false);

  const [tempCanvasContent, setTempCanvasContent] = useRecoilState(TempCanvasContentState);

  // 이미지 서버에 업로드
  const [productImage, setProductImage] = useRecoilState(productImageState);
  const [uploadImageName, setUploadImageName] = useRecoilState(productState.uploadImageState);
  const [Xscale, setXscale] = useRecoilState(productState.XscaleState);
  const [Yscale, setYscale] = useRecoilState(productState.YscaleState);
  const [cropSize, setCropSize] = useState({ width: 0, height: 0 });
  const cropRef = useRef(null);

  // canvas 에 이미지 추가
  const setSelectedIndex = useSetRecoilState(selectedObject);
  const resetSelectedImage = useResetRecoilState(selectedImageState);
  const [canvasObjects, setCanvasObjects] = useRecoilState(objectList);


  // QR 생성기
  const generateQR = async (bgImgId) => {
    if (userInfo.isLoggedIn) {
      setQrButtonClick(true);
      const randomKey = generateRandomKey();
      setRandomKey(randomKey);
      const query = `{"bgImgId": ${bgImgId}, "randomKey": "${randomKey}", "user_id": ${userInfo['user_id']}}`;
      try {
        setQrImage(await QRCode.toDataURL(query));
      } catch (err) {
        console.error(err);
      }
    } else {
      Swal.fire({
        text: 'Canvas saving is available after login.\nWould you like to login?\nThe canvas will be temporarily stored.',
        showCancelButton: true,
        confirmButtonText: 'yes',
        cancelButtonText: 'no',
        customClass: {
          popup: 'custom-popup-class', // 모달 다이얼로그 스타일
        },
      }).then((res) => {
        if (res.isConfirmed) {
          const canvas = document.getElementById('canvas')?.fabric;
          let json = canvas.toDatalessJSON(propertiesWhenSerializing);
          setTempCanvasContent(json);
          navigate('/login');
        }
      });
    }
  };

  // 랜덤키 생성기
  const generateRandomKey = () => {
    const lenRandomKey = 5;
    let randomKey = '';
    const alpha = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
    const amountNum = Math.floor(Math.random() * lenRandomKey) + 1; // 1 ~ 5(lenRandomKey)

    for (let i = 0; i < amountNum; i++) {
      randomKey += Math.floor(Math.random() * 10); // 0 ~ 9
    }
    for (let i = 0; i < lenRandomKey - amountNum; i++) {
      randomKey += alpha[Math.floor(Math.random() * 26)];
    }
    return randomKey;
  };

  /**
   * 이미지 파일 압축 함수 - 이미지 파일이 클 경우 (ex 17MB 정도) 프로그램이 느려지는 현상 방지
   * @param image - 이미지 파일
   * @returns {Promise<*>}
   */
  const compressImage = async (image) => {
    setIsImageUploading(true);
    //이미지 파일 압축
    try {
      const options = {
        // maxSizeMB: 0.2,
        maxWidthOrHeight: 1920,
      };
      return await imageCompression(image, options);
    } catch (e) {
      console.log(e);
    }
  };

  /**
   * 이미지 파일 업로드 함수
   * @type {(function(*): Promise<void>)|*}
   */
  const onDrop = useCallback(async (files) => {
    const acceptTypes = ['png', 'jpg', 'jpeg', 'heic'];
    const type = files[0].name.split('.')[1].toLowerCase();
    if (!acceptTypes.includes(type)) {
      Swal.fire({
        text: 'canvas.detailEdit.FileTypeAlert',
        customClass: {
          popup: 'custom-popup-class', // 모달 다이얼로그 스타일
        },
      });
    }
    if (type === 'heic') {
      let blob = files[0];
      await heic2any({ blob: blob, toType: 'image/jpeg' })
        .then(function (resultBlob) {
          files = new File([resultBlob], files[0].name.split('.')[0] + '.jpg', {
            type: 'image/jpeg',
            lastModified: new Date().getTime(),
          });
          reader.readAsDataURL(files);
        })
        .catch(function (x) {
          console.log(x);
        });
    } else {
      files = files[0];
    }
    setUploadImageName(files.name);
    const compressed = await compressImage(files);
    const compressed_file = await new File([compressed], files.name);
    let reader = new FileReader();
    if (compressed_file) {
      const imgTarget = compressed_file;
      if (imgTarget) {
        reader.readAsDataURL(imgTarget); // buffer에 저장함!!
        reader.onloadend = () => {
          const base64 = reader.result;
          if (base64) {
            setImgValues(compressed_file);
            setBase64(base64.toString());
            setImgUpload(true);
          }
        };
      }
    }
    setIsImageUploading(false);
  });

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
  });

  // canvas 선택한 객체 삭제하기
  const deleteObj = (canvas) => {
    const target = canvas.getActiveObject();
    const targetShadow = canvas.getObjects().find((obj) => obj.parentId === target.id);

    if (!target) return;
    if (targetShadow) {
      canvas.remove(targetShadow);
    }

    canvas.remove(target);

    // 전역 상태 정리
    setCanvasObjects((objects) => objects.filter((obj) => obj.id !== target.id));
    setSelectedIndex(null);
    setRightState('components');

    canvas.requestRenderAll();
    canvas.fire('object:modified');
  };
  const addEvent = (img) => {
    addObjectSelectEvent(img, setSelectedIndex, setRightState);
    addImageEvent(img, resetSelectedImage, setSelectedImage, setCanvasObjects);
  };

  const addImageToCanvas = async (src, originSrc) => {
    const {fabric: canvas} = document.getElementById('canvas');
    if(showUpload === 'update') deleteObj(canvas); // Replace Image 로 들어왔을 때 기존 이미지 삭제 후 업로드 이미지 추가
    const id = generateObjectId('pp');
    const image = await addImage(canvas, src, {id, src, originSrc: originSrc || src, name: id}, () => {});

    resizeImage(canvas, image);
    addEvent(image);
  }


  /**
   * crop 된 이미지를 반환하는 함수
   * @param crop
   * @returns {Promise<unknown>}
   */
  const getCroppedImg = async (crop) => {
    return new Promise((resolve, reject) => {
      const canvas = document.createElement('canvas');

      const image = imgRef.current;
      const scaleX = image.naturalWidth / image.width; // 실제 이미지와 화면상 이미지의 너비 스케일
      const scaleY = image.naturalHeight / image.height; // 실제 이미지와 화면상 이미지의 높이 스케일

      canvas.width = crop.width;
      canvas.height = crop.height;
      const ctx = canvas.getContext('2d');
      // 스케일링 비율을 적용하여 이미지를 그립니다.
      ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width, crop.height);
      resolve(canvas.toDataURL('image/jpeg'));
    });
  };
  /**
   * 이미지 크롭 관련 함수 - 이미지에 crop 을 적용했다면 cropImageUrl을 변경
   * @param crop
   * @returns {Promise<void>}
   */
  const makeClientCrop = async (crop) => {
    if (crop.width && crop.height) {
      try {
        const croppedImageUrl = await getCroppedImg(crop);
        setCropImageUrl(croppedImageUrl);
      } catch (error) {
        console.error('에러 : ', error);
      }
    }
  };

  /**
   * 이미지 로드시 이미지 객체 설정 및 너비와 높이를 설정
   */
  const onImageLoaded = (e) => {
    imgRef.current = e.target;
    const img = new Image();
    img.src = base64;
    img.onload = function () {
      setSrcWidth(this.width);
      setSrcHeight(this.height);
    };
  };

  /**
   * data 에 upload 할 이미지 정보 저장
   * @param img
   * @param data
   * @param id
   * @returns {Promise<void>}
   */
  const resizeUploadImage = async (img, data, id) => {
    let width = beforeCrop.width ?? srcWidth; // 크롭한 이미지 가로 길이
    let height = beforeCrop.height ?? srcHeight; // 크롭한 이미지 세로 길이
    let x = beforeCrop.x ?? 0; // 크롭한 이미지 x 좌표
    let y = beforeCrop.y ?? 0;
    data.crop_x = Math.round(x);
    data.crop_y = Math.round(y);
    data.w = Math.round(width);
    data.h = Math.round(height);
    data.remove = id == 'remove' ? true : false;
    data.upload_img = base64.toString();
  };
  /**
   * crop 한 이미지라면, img 와 data.upload_img 에 cropImageUrl을 적용
   * @param img
   * @param data
   * @returns {Promise<void>}
   */
  const applyCropped = async (img, data) => {
    setLoading(true);
    if (cropImageUrl && cropImageUrl !== base64) {
      img = cropImageUrl;
      data.upload_img = cropImageUrl;
    }
  };
  /**
   * remove bg 버튼 클릭시 API 호출 함수
   */
  const sendProductPicture = async (upload_img, name, img_b64, colors, templates) => {
    await ppAPI
      .postProductPicture({
        upload_img: upload_img,
        upload_img_name: name,
        remove_bg: img_b64,
        colors: colors,
        templates: templates,
      })
      .then((res) => {
        setProductImage((previous) => ({ ...previous, id: res.data.id }));
      })
      .catch((err) => console.log(err));
  };
  /**
   * no remove bg 버튼 클릭시 API 호출 함수
   */
  const sendNormalPicture = async (upload_img, name, colors, templates) => {
    await ppAPI
      .postNormalPicture({
        upload_img: upload_img,
        upload_img_name: name,
        colors: colors,
        templates: templates,
      })
      .then((res) => {
        setProductImage((previous) => ({ ...previous, id: res.data.id }));
      })
      .catch((err) => console.log(err));
  };

  /**
   * Canvas 로 돌아가도록 설정 초기화 및 alert 창 표시
   */
  const updateLoadingState = (isLoading, percent, showUpload) => {
    setLoading(isLoading);
    setLoadingPercent(percent);
    setShowUpload(showUpload);
    Swal.fire({
      text: t('alert.successUpload'),
      customClass: {
        popup: 'custom-popup-class', // 모달 다이얼로그 스타일
      },
    });
  };

  /**
   *  isRemove 값에 따라 이미지 저장 API 를 호출
   */
  const processImageResult = (base64, uploadImage, maskImage, colors, templates, isRemoved) => {
    if (isRemoved) {
      addImageToCanvas(maskImage, base64);
      sendProductPicture(base64, uploadImage, maskImage, colors, templates);
    } else {
      addImageToCanvas(cropImageUrl ? cropImageUrl : base64, base64); // crop 한 이미지라면 cropImageUrl 을, 아니라면 base64 를 적용
      sendNormalPicture(base64, uploadImage, colors, templates);
    }
  };
  /**
   * 이미지 업로드 성공 시 실행되는 함수
   */
  const handleUploadSuccess = async (res, base64, uploadImage, srcWidth, srcHeight, isRemoved) => {
    const maskImage = res.data.mask_img;
    const colors = res.data.colors;
    const templates = res.data.templates;
    await processImageResult(base64, uploadImage, maskImage, colors, templates, isRemoved);
    await updateLoadingState(false, null, 'none');
  };

  /**
   * 이미지 업로드시 API 호출 함수
   */
  const processImageUpload = async (data, base64, uploadImage, srcWidth, srcHeight) => {
    try {
      setLoading(true);
      const res = await canvasAPI.grabCutBase64(data);
      setLoadingPercent(100);
      setBeforeCrop({});

      await handleUploadSuccess(res, base64, uploadImage, srcWidth, srcHeight, data.remove);
    } catch (err) {
      setLoading(false);
      setLoadingPercent(null);
      console.log(err);
    }
  };

  /**
   * remove background, no remove background 버튼 클릭시 실행
   * @param e
   * @returns {Promise<void>}
   */
  const onProduct = async (e) => {
    const { id } = e.target;
    if (imgUpload) {
      e.preventDefault();
      if (beforeCrop) {
        let data = {};
        let img = base64;
        await resizeUploadImage(img, data, id);
        await applyCropped(img, data);
        await processImageUpload(data, img, uploadImageName, srcWidth, srcHeight);
      } else {
        Swal.fire({
          text: t('alert.specifyRectangle'),
          customClass: {
            popup: 'custom-popup-class', // 모달 다이얼로그 스타일
          },
        });
      }
    } else {
      Swal.fire({
        text: t('alert.uploadPicture'),
        customClass: {
          popup: 'custom-popup-class', // 모달 다이얼로그 스타일
        },
      });
    }
  };

  return (
    <>
      {qrButtonClick ? (
        <QRPopup setQrButtonClick={setQrButtonClick} qrImage={qrImage} randomKey={randomKey} setSvgPP={(value) => props.setSvgPP(value)} style={{ border: '10px solid yellow' }} />
      ) : null}
      {uploadCheck ? (
        <>
          <style.UploadWrapper>
            <img src={process.env.PUBLIC_URL + '/images/Canvas/SideBar/cancleBtn.svg'} onClick={() => setShowUpload('none')} />
            <h1>
              {t('canvas.detailEdit.Title1_1')}
              <br />
              {t('canvas.detailEdit.Title1_2')}
            </h1>
            <span>
              {t('canvas.detailEdit.SubTitle1')}
              <br />
              {t('canvas.detailEdit.SubTitle2')}
            </span>
            <style.qrButton
              onClick={() => {
                generateQR(props.bgTemplateId);
              }}
            >
              {t('canvas.detailEdit.QRButton')}
            </style.qrButton>
            {loading ? (
              <ProgressBar percent={loadingPercent} />
            ) : imgUpload ? (
              <>
                <style.CropDivBox>
                  <style.CropDiv>
                    <ReactCrop ref={cropRef} crop={beforeCrop} onChange={(newCrop) => setBeforeCrop(newCrop)} onComplete={makeClientCrop}>
                      <style.ProductImg ref={imgRef} src={base64} onLoad={onImageLoaded} />
                    </ReactCrop>
                  </style.CropDiv>
                </style.CropDivBox>
                <p>
                  <style.selectButton id={'noRemove'} onClick={(e) => onProduct(e)}>
                    No remove background
                  </style.selectButton>
                  <style.selectButton id={'remove'} onClick={(e) => onProduct(e)}>
                    Remove background
                  </style.selectButton>
                </p>
              </>
            ) : (
              <>
                {isImageUploading ? (
                  <style.SyncLoaderWrapper style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                    <SyncLoader color={'#f54545'} loading={true} size={20} aria-label="Loading Spinner" data-testid="loader" />
                    <style.UploadingText>Image Uploading ...</style.UploadingText>
                  </style.SyncLoaderWrapper>
                ) : (
                  <div {...getRootProps()}> {isDragActive ? <p style={{ textAlign: 'center' }}>{t('canvas.detailEdit.Uploading')}</p> : <button>{t('canvas.detailEdit.Button')}</button>}</div>
                )}
              </>
            )}
          </style.UploadWrapper>
        </>
      ) : null}
    </>
  );
}

export default ReplaceImage;
