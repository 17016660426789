import * as styles from 'components/ChoiceDesign/styles';
import { useTranslation } from 'react-i18next';
import { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { constSelector, useRecoilState } from 'recoil';
import { bgImageState, productImageState } from 'state/imageState';
import studioAPI from 'api/studioAPI';
import ReactGA from 'react-ga4';
import { ProdImage } from 'components/AiResult/styles';
import Swal from 'sweetalert2';

export default function ChoiceDesign(props) {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { state } = useLocation();

  const [resultListIndex, setResultListIndex] = useState();
  const [productImage, setProductImage] = useRecoilState(productImageState);
  const [bgImage, setBgImage] = useRecoilState(bgImageState);
  const [background, setBackground] = useRecoilState(bgImageState); // const [product, setProduct] = useState('https://cdn.zeplin.io/62e3c8bc2119b414cff30322/assets/2221CC56-FE90-4034-9EB1-02242558F69C.png');
  const [ctx, setCtx] = useState();
  const [bgUrl, setBgUrl] = useState('');
  const [stateid, setStateid] = useState();
  const canvasRef = useRef(null);
  const contextRef = useRef(null);
  const [obj, setObj] = useState();

  // initialize canvas
  useEffect(() => {
    const canvas = canvasRef.current;
    // canvas.width = props.width || 530;
    // canvas.height = props.height || 640;
    setStateid(0);
    const context = canvas.getContext('2d');
    contextRef.current = context;

    contextRef.current.canvas.setBackgroundColor = '#000000';
    console.log(canvas, contextRef);
    if (bgImage.type === 'template') {
      getTemplateImg();
    }
    // pp from mypage

    if (props.pp) {
      setObj(props.pp);
      setProductImage({ id: props.pp.id, maskImage: props.pp.remove_bg_img, ppImage: props.pp.remove_bg_img, uploadImage: props.pp.upload_img, fileName: 'my' });
    }
    setCtx(contextRef.current);
  }, []);

  //  const getMyProductPicture = async()=>{
  //   await ppAPI.getProductPicture()
  //  }

  const getProductPosition = (width, height, bgWidth, bgHeight) => {
    // ai-result에서 받아온
    // ai-result랑 같은 위치에 포지션되야 되기 때문에 ai-result에서 배경과 물체의 width 비율을 가져옴 140/240
    const backProductWidthRatio = 0.5;
    const ratio = height / width;
    const productWidth = bgWidth * backProductWidthRatio;
    const productHeight = productWidth * ratio;
    const top = bgHeight / 2 - productHeight / 2;
    const left = bgWidth / 2 - productWidth / 2;
    return [top, left, productWidth, productHeight];
  };
  useEffect(() => {
    imageToCanvas();
  }, [bgUrl, ctx]);

  const getTemplateImg = async () => {
    await studioAPI.getTemplate(bgImage.templateId).then((res) => {
      setBgUrl(res.data.img);
    });
  };

  const imageToCanvas = (productImg = productImage) => {
    if (ctx) {
      let pp = new Image();
      let bg = new Image();
      pp.crossOrigin = 'anonymous';
      bg.crossOrigin = 'anonymous';
      const canvas = canvasRef.current;
      // bg
      if (bgImage.type === 'single' && bgImage.colors[0] != 'none') {
        pp.onload = () => {
          canvas.width = 500;
          canvas.height = 500;
          ctx.fillStyle = bgImage.colors[0];
          ctx.fillRect(0, 0, 500, 500);
          if (props.pp) setProductImage((previous) => ({ ...previous, width: pp.naturalWidth, height: pp.naturalHeight }));
          let [ppTop, ppLeft, ppWidth, ppHeight] = getProductPosition(pp.width, pp.height, 500, 500);
          ctx.drawImage(pp, ppLeft, ppTop, ppWidth, ppHeight);
        };
      } else if (bgImage.type === 'half') {
        pp.onload = () => {
          canvas.width = 500;
          canvas.height = 500;
          // top square

          ctx.fillStyle = bgImage.colors[0];
          ctx.fillRect(0, 0, 500, 500 / 2);
          if (props.pp) setProductImage((previous) => ({ ...previous, width: pp.naturalWidth, height: pp.naturalHeight }));
          // bottom square
          ctx.fillStyle = bgImage.colors[1];
          ctx.fillRect(0, 500 / 2, 500, 500);
          let [ppTop, ppLeft, ppWidth, ppHeight] = getProductPosition(pp.width, pp.height, 500, 500);
          ctx.drawImage(pp, ppLeft, ppTop, ppWidth, ppHeight);
        };
      } else if (bgImage.type === 'cross') {
        pp.onload = () => {
          canvas.width = 500;
          canvas.height = 500;
          // top triangle
          ctx.beginPath();
          ctx.moveTo(0, 0);
          ctx.lineTo(500, 0);
          ctx.lineTo(0, 500);
          ctx.fillStyle = bgImage.colors[0];
          ctx.fill();

          // bottom triangle
          ctx.beginPath();
          ctx.moveTo(500, 500);
          ctx.lineTo(500, 0);
          ctx.lineTo(0, 500);
          ctx.fillStyle = bgImage.colors[1];
          ctx.fill();
          ctx.closePath();
          if (props.pp) setProductImage((previous) => ({ ...previous, width: pp.naturalWidth, height: pp.naturalHeight }));
          let [ppTop, ppLeft, ppWidth, ppHeight] = getProductPosition(pp.width, pp.height, 500, 500);

          ctx.drawImage(pp, ppLeft, ppTop, ppWidth, ppHeight);
        };
      } else if (bgImage.type === 'template') {
        bg.onload = () => {
          canvas.width = bg.width;
          canvas.height = bg.height;
          ctx.drawImage(bg, 0, 0, bg.width, bg.height);
          let [ppTop, ppLeft, ppWidth, ppHeight] = getProductPosition(pp.width, pp.height, bg.width, bg.height);
          if (props.pp) setProductImage((previous) => ({ ...previous, width: pp.naturalWidth, height: pp.naturalHeight }));
          ctx.drawImage(pp, ppLeft, ppTop, ppWidth, ppHeight);
        };
        // pp.onload = () => {
        // };
        bg.src = bgUrl;
      } else if (bgImage.type == 'single' && bgImage.colors[0] == 'none') {
        pp.onload = () => {
          canvas.width = 500;
          canvas.height = 500;
          let [ppTop, ppLeft, ppWidth, ppHeight] = getProductPosition(pp.width, pp.height, 500, 500);
          if (props.pp) setProductImage((previous) => ({ ...previous, width: pp.naturalWidth, height: pp.naturalHeight }));
          ctx.drawImage(pp, ppLeft, ppTop, ppWidth, ppHeight);
        };
      } else {
        pp.onload = () => {
          canvas.width = 500;
          canvas.height = 500;
          ctx.fillStyle = '#ffffff00';
          ctx.fillRect(0, 0, 500, 500);
          let [ppTop, ppLeft, ppWidth, ppHeight] = getProductPosition(pp.width, pp.height, 500, 500);
          if (props.pp) setProductImage((previous) => ({ ...previous, width: pp.naturalWidth, height: pp.naturalHeight }));
          ctx.drawImage(pp, ppLeft, ppTop, ppWidth, ppHeight);
        };
      }
      pp.src = productImg.maskImage;
    }
  };

  // buttons
  const editButtonClick = () => {
    ReactGA.event({
      category: 'User',
      action: 'edit',
      label: 'edit image from choice',
    });
    navigate('/canvas');
  };

  const downloadButtonClick = () => {
    ReactGA.event({
      category: 'User',
      action: 'download',
      label: 'download from choice',
    });
    const canvas = canvasRef.current;
    const url = canvas.toDataURL('image/png');
    const link = document.createElement('a');
    link.download = 'my-canvas.png';
    link.href = url;
    link.click();
  };

  const prevButtonClick = async (e) => {
    if (state) {
      console.log('prev button click');
      await setBackground({
        type: 'template',
        templateId: state[stateid]['id'],
      });
      if (stateid == 0) {
        Swal.fire({
          text: t('canvas.choiceDesign.FirstAlert'),
          customClass: {
            popup: 'custom-popup-class', // 모달 다이얼로그 스타일
          },
        });
      } else {
        setStateid(stateid - 1);
        await getTemplateImg();
      }
    } else {
      console.log(obj);
      if (obj.id == props.ppList[0].id) {
        Swal.fire({
          text: t('canvas.choiceDesign.FirstAlert'),
          customClass: {
            popup: 'custom-popup-class', // 모달 다이얼로그 스타일
          },
        });
      } else {
        const indx = props.ppList.findIndex((item) => item.id === obj.id);
        const objt = props.ppList.find((item, index) => index === indx - 1);
        setObj(() => objt);
        setProductImage(() => ({ id: objt.id, maskImage: objt.remove_bg_img, ppImage: objt.remove_bg_img, uploadImage: objt.upload_img, fileName: 'my' }));
        imageToCanvas({ id: objt.id, maskImage: objt.remove_bg_img, ppImage: objt.remove_bg_img, uploadImage: objt.upload_img, fileName: 'my' });
      }
      // console.log(canvasRef);
      // console.log(myPps[0].id);
      // console.log(props.pp.id);
    }
  };

  const nextButtonClick = async (e) => {
    if (state) {
      console.log('next button click');
      await setBackground({
        type: 'template',
        templateId: state[stateid]['id'],
      });
      if (stateid === state.length - 1) {
        Swal.fire({
          text: t('canvas.choiceDesign.LastAlert'),
          customClass: {
            popup: 'custom-popup-class', // 모달 다이얼로그 스타일
          },
        });
      } else {
        setStateid(stateid + 1);
        await getTemplateImg();
      }
    } else {
      console.log('props pplist', props.ppList);
      if (obj.id === props.ppList[props.ppList.length - 1].id) {
        Swal.fire({
          text: t('canvas.choiceDesign.LastAlert'),
          customClass: {
            popup: 'custom-popup-class', // 모달 다이얼로그 스타일
          },
        });
      } else {
        const indx = props.ppList.findIndex((item) => item.id === obj.id);
        const objt = props.ppList.find((item, index) => index === indx + 1);
        setObj(() => objt);
        setProductImage(() => ({ id: objt.id, maskImage: objt.remove_bg_img, ppImage: objt.remove_bg_img, uploadImage: objt.upload_img, fileName: 'my' }));
        imageToCanvas({ id: objt.id, maskImage: objt.remove_bg_img, ppImage: objt.remove_bg_img, uploadImage: objt.upload_img, fileName: 'my' });
      }
    }
  };

  return (
    <styles.MainContainer style={{ height: '100vh' }}>
      <styles.CanvasWrapper>
        <styles.CanvasButton src="/images/ChoiceDesign/prev.svg" onClick={prevButtonClick} />

        <canvas
          ref={canvasRef}
          style={{
            maxWidth: 'calc(100% - 100px)',
            maxHeight: '90%',
            width: 'auto',
            height: 'auto',
            border: '2.5px solid #d9d9d9',
            borderRadius: '20px',
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/ChoiceDesign/transparentBg.jpeg)`,
            userSelect: 'none',
          }}
        />
        <styles.CanvasButton src="/images/ChoiceDesign/next.svg" onClick={nextButtonClick} />
      </styles.CanvasWrapper>
      <styles.ButtonWrapper>
        <styles.Button hover={true} onClick={editButtonClick}>
          <styles.ButtonIcon>
            <img src="/images/ChoiceDesign/edit-photo.svg" />
          </styles.ButtonIcon>
          <span>{t('canvas.choiceDesign.EditImage')}</span>
        </styles.Button>
        {/*<styles.Button hover={true}>*/}
        {/*  <styles.ButtonIcon>*/}
        {/*    <img src="/images/ChoiceDesign/mecro.svg" />*/}
        {/*  </styles.ButtonIcon>*/}
        {/*  <span>{t('canvas.choiceDesign.Mecro')}</span>*/}
        {/*</styles.Button>*/}
        <styles.Button>
          <styles.ButtonIcon color="#f54545" onClick={downloadButtonClick}>
            <img src="/images/ChoiceDesign/download.svg" />
          </styles.ButtonIcon>
          <span>{t('canvas.choiceDesign.Download')}</span>
        </styles.Button>
      </styles.ButtonWrapper>
    </styles.MainContainer>
  );
}

ChoiceDesign.propTypes = {
  files: PropTypes.arrayOf(PropTypes.string),
};
