/**********************************
 * 파일명: [colors.js]
 * 작성자: [Jiwon Kang]
 * 설명: [색상 상수]
 **********************************/

const colors = {
  primary: '#fd5c5c', // 메인 키컬러
  sub: '#8a24e2', // 서브 키컬러
  deny: '#e61503', //부정 컬러
  allow: '#678d12', // 긍정 컬러
  guide: '#287ced', // 유도 컬러

  fontBlack: '#222222', // 글자 검정
  fontGray: '#767676', // 글자 회색
  fontGrayLight: '#999999', // 글자 밝은 회색
  fontGrayDark: '#AAAAAA', // 글자 어두운 회색
  fontGrayLightMetalic: '#E6E8EB', // 글자 금속 회색
  fontGrayMetalic: '#8d95A0', // 글자 어두운 금속 회색

  bg1: '#fefefe', // 배경 흰색
  bg2: '#fcfcfc', // 배경 밝은 회색
  bg3: '#f9fafb', // 배경 밝은 회색
  bg4: '#f8f8f8', // 배경 밝은 회색
  bg5: '#f2f4f4', // 배경 밝은 회색
  bg6: '#f2f3f5', // 배경 밝은 회색

  lineBlack: '#222222', // 선 검정
  lineGray: '#dbdbdb', // 선 회색
  lineGrayLight: '#ededed', // 선 밝은 회색
};

export default colors;
