/**********************************
 * Name : RecommendTemplateBase.js
 * Author : Kwak Heegun
 * Introduction : 구조 기반 유사 템플릿 추천 Component
 ********************************** */
import React, { useEffect, useRef, useState } from 'react';
import * as styles from './styles';
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';
import * as Style from '../styles';
import useFetch from './useFetch';
import { bgImageState } from 'state/imageState';
import { useRecoilValue } from 'recoil';
import studioAPI from '../../../api/studioAPI';

function RecommendTemplateBase(props) {
  const { settings, recommendListBase, bgClick, onRecommendStateChange, setRecommendListBase } = props;

  const loader = useRef(null);
  const screen = useRef(null);
  const [view, setView] = useState('default');
  const { t } = useTranslation();
  const selectedBg = useRecoilValue(bgImageState);
  const [page, setPage] = useState(1);

  const { loading, list, hasMore } = useFetch(selectedBg.templateId, page, 'base');

  const handleRecommendStateChange = (newState) => {
    onRecommendStateChange(newState);
  };

  const handleObserver = (entries) => {
    const target = entries[0];
    if (target.isIntersecting && hasMore && !loading) {
      setPage((prev) => prev + 1);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      threshold: 0.0,
    });

    if (loader.current) {
      observer.observe(loader.current);
    }

    return () => {
      if (loader.current) observer.unobserve(loader.current);
    };
  }, [loader.current, hasMore, loading, view]);

  const handleRecommendListBase = (newList) => {
    setRecommendListBase(newList);
  };

  // 추가적인 템플릿 정보를 로드하는 함수
  const loadMoreTemplates = async () => {
    const nextPage = page + 1;
    const query = `page=${nextPage}`;

    const response = await studioAPI.recommendColorPaginationList(selectedBg.templateId, query);

    const results = response.data.results;

    const updatedRecommendListBase = [...recommendListBase, ...results];
    handleRecommendListBase(updatedRecommendListBase);

    setPage(nextPage);
  };

  const handleBeforeChange = async (index) => {
    const lastSlideReached = index === recommendListBase.length - 2;
    if (lastSlideReached) {
      await loadMoreTemplates();
    }
  };

  if (view === 'default') {
    return (
      <styles.SimilarTemplate>
        <styles.Section>
          <styles.TitleDiv>
            <styles.SubTitle>{t('canvas.defaultView.similarBase')}</styles.SubTitle>
            <styles.DetailButton
              onClick={() => {
                setView('detail');
                handleRecommendStateChange(2);
              }}
            >
              {t('canvas.defaultView.More')}
            </styles.DetailButton>
          </styles.TitleDiv>
          <Slider {...settings} beforeChange={(index) => handleBeforeChange(index)}>
            {recommendListBase &&
              recommendListBase.map((i, idx) => (
                <styles.TemplateThumbnail
                  key={idx}
                  onClick={() => {
                    bgClick(i);
                  }}
                  src={i.image_small}
                />
              ))}
          </Slider>
        </styles.Section>
      </styles.SimilarTemplate>
    );
  } else if (view === 'detail') {
    return (
      <Style.MainContainer ref={screen}>
        <Style.TitleDiv>
          <Style.TopTitle>{t('canvas.detailView.More')}</Style.TopTitle>
          <Style.BackBtn
            onClick={() => {
              setView('default');
              handleRecommendStateChange(0);
            }}
          >
            {t('canvas.detailView.Back')}
          </Style.BackBtn>
        </Style.TitleDiv>
        <Style.TemplateGrid>
          {list.map((i, idx) => {
            return (
              <Style.TemplateThumbnail
                key={idx}
                onClick={() => {
                  bgClick(i);
                }}
                src={i.image_small}
              />
            );
          })}
          {loading && <img src="/logo.png" />}
          <div ref={loader} />
        </Style.TemplateGrid>
      </Style.MainContainer>
    );
  }
}

export default RecommendTemplateBase;
