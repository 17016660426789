/**********************************
 * 파일명: [leftNavBarBtn.js]
 * 작성자: [Donghwoo Cho]
 * 설명: [캔버스 작업 페이지 왼쪽 사이드바의 네비게이션 버튼 컴포넌트]
 **********************************/

import * as style from './styles';
import { useRecoilState } from 'recoil';
import { leftSidebarState } from 'state/sidebarState';

function LeftNavbarBtn(props) {
  const [leftSideBar, setLeftSidebar] = useRecoilState(leftSidebarState);
  const handleLeftSidebar = (variant) => (variant === leftSideBar ? setLeftSidebar(null) : setLeftSidebar(variant));

  return (
    <style.NavbarBtn onClick={() => handleLeftSidebar(props.variant)}>
      <img src={props.src} alt={props.title} />
      <span>{props.title}</span>
    </style.NavbarBtn>
  );
}

export default LeftNavbarBtn;
