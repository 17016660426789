/**********************************
 * Name : productPhotography.js
 * Author : Nam Sanglim
 * Final_Editor : Lee JunSeok ->  이미지 업로드 버튼 누르면 상위 컴포넌트로 blob 넘기기
 * Introduction : 상품 이미지 선택하는 사이드 바 컴포넌트
 ********************************** */
import React, { useEffect } from 'react';
import * as leftSideBarStyle from '../styles';
import * as style from './styles';
import { authState } from 'state';
import { ImageNameState } from 'state/state';
import { useRecoilValue, useRecoilState, useSetRecoilState, useResetRecoilState } from 'recoil';
import ppAPI from 'api/ppAPI';
import Slider from 'react-slick';
import { addMyPpState, objectList, selectedObject } from 'state/canvasState';
import { rightSidebarState, showUploadState } from 'state/sidebarState';
import { canvasListState, myNormalImgState, myPpState, selectedImageState } from 'state/imageState';
import canvasAPI from 'api/canvasAPI';
import { useTranslation } from 'react-i18next';
import { addImage, addImageEvent, addObjectSelectEvent, generateObjectId, resizeImage } from 'utils/canvasObjectGenerateUtil';
const NextArrow = ({ className, style, onClick }) => (
  <leftSideBarStyle.Arrow style={{ ...style, width: 16, left: '92%', height: '100%' }} onClick={onClick} className={className}></leftSideBarStyle.Arrow>
);
const PrevArrow = ({ className, style, onClick }) => <leftSideBarStyle.Arrow style={{ ...style, width: 16, height: '100%' }} onClick={onClick} className={className}></leftSideBarStyle.Arrow>;

// eslint-disable-next-line
function ProductPhotography() {
  const { t } = useTranslation();
  const userInfo = useRecoilValue(authState);
  const setRightState = useSetRecoilState(rightSidebarState);
  const setSelectedIndex = useSetRecoilState(selectedObject);
  // 내가 업로드한 누끼딴 이미지 목록
  const [myPp, setMyPp] = useRecoilState(myPpState);
  // 내가 업로드한 누끼 안 딴 이미지 목록
  const [myNormalImg, setMyNormalImg] = useRecoilState(myNormalImgState);
  // 내가 작업한 캔버스의 썸네일 이미지 목록
  const [canvasList, setCanvasList] = useRecoilState(canvasListState);

  const [imageName, setImageName] = useRecoilState(ImageNameState);

  const [canvasObjects, setCanvasObjects] = useRecoilState(objectList);

  // 캔버스에 누끼딴 이미지 목록에서 하나를 클릭하여 추가히기 위한 trigger
  const resetSelectedImage = useResetRecoilState(selectedImageState);
  const [selectedImage, setSelectedImage] = useRecoilState(selectedImageState);
  // upload 한 image 의 url
  const [showUpload, setShowUpload] = useRecoilState(showUploadState);
  useEffect(() => {
    if (userInfo.isLoggedIn) {
      if (myPp == false || myNormalImg == false || canvasList == false) {
        loadMyAssets();
      }
    }
  }, []);

  const loadMyAssets = () => {
    getMyProductPicture();
    getMyNormalPicture();
    getMyCanvas();
  };

  const getMyProductPicture = async () => {
    await ppAPI.getProductPictures().then((res) => {
      setMyPp(res.data);
    });
  };

  const getMyNormalPicture = async () => {
    await ppAPI.getNormalPictures().then((res) => {
      setMyNormalImg(res.data);
    });
  };

  const getMyCanvas = async () => {
    canvasAPI.getCanvasList().then((res) => {
      const sortedList = res.data.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
      setCanvasList(sortedList);
    });
  };

  const setSelectedImageStatePp = (i) => {
    setSelectedImage((state) => ({
      ...state,
      src: i.remove_bg_img,
      uploadImage: i.upload_img,
      maskImage: i.remove_bg_img,
    }));
  };
  const setSelectedImageStateNormal = (i) => {
    setSelectedImage({
      uploadImage: i.upload_img,
      maskImage: i.upload_img,
    });
  };
  const setSelectedImageStateCanvas = (i) => {
    setSelectedImage({
      uploadImage: i.origin_img,
      maskImage: i.origin_img,
    });
  };

  /**
   * canvas에 이미지 추가하는 함수
   * @param {Object} i - 이미지 정보
   * @param {String} src - 이미지의 src (이미지의 타입에 따라 처리해야할 src 종류가 다름)
   */
  const addImageToCanvas = async (src, originSrc) => {
    const { fabric: canvas } = document.getElementById('canvas');
    const id = generateObjectId('pp');
    const image = await addImage(canvas, src, { id, src, originSrc: originSrc || src, name: id }, () => {});
    resizeImage(canvas, image);
    addEvent(image);
  };

  const addEvent = (img) => {
    addObjectSelectEvent(img, setSelectedIndex, setRightState);
    addImageEvent(img, resetSelectedImage, setSelectedImage, setCanvasObjects);
  };

  const selectImage = (i, imgType) => {
    // objlist에 이름을 변경하여 넣기 위해 이름 update
    setImageName('image ' + i.id);
    // pp인지 아닌지에 따라 캔버스에 올라가는 이미지의 src가 다름
    if (imgType === 'ppImg') {
      addImageToCanvas(i.remove_bg_img, i.upload_img);
      setSelectedImageStatePp(i);
    } else if (imgType === 'normalImg') {
      addImageToCanvas(i.upload_img);
      setSelectedImageStateNormal(i);
    } else {
      addImageToCanvas(i.origin_img);
      setSelectedImageStateCanvas(i);
    }
    setShowUpload('none');
  };

  //모드 슬라이드 세팅
  const settings = {
    rtl: true,
    speed: 500,
    centerMode: true,
    slidesToShow: 1.8,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <leftSideBarStyle.MainContainer>
      <label htmlFor={'uploadImg'}>
        <style.uploadPP onClick={() => setShowUpload('create')}>{t('canvas.option.ImageUpload')}</style.uploadPP>
      </label>
      <style.PPvideo>
        <style.video video controls>
          <source src="/video/topViewKor.mp4" type="video/mp4" />
        </style.video>
      </style.PPvideo>
      <div>
        {userInfo.isLoggedIn && (
          <style.SliderWrapper>
            <style.SliderItem>
              <style.SliderTitleWrapper>
                <span>{t('canvas.option.MyPP')}</span>
                <style.RefreshButton onClick={() => loadMyAssets()}>Refresh</style.RefreshButton>
              </style.SliderTitleWrapper>
              <Slider {...settings} initialSlide={myPp.length + 1}>
                {myPp.map((i, idx) => {
                  return <leftSideBarStyle.TemplateThumbnail crossOrigin="Anonymous" key={idx} src={i.remove_bg_img} onClick={() => selectImage(i, 'ppImg')} />;
                })}
              </Slider>
            </style.SliderItem>
            <style.SliderItem>
              <style.SliderTitleWrapper>
                <span>{t('canvas.option.MyAsset')}</span>
              </style.SliderTitleWrapper>
              <Slider {...settings} initialSlide={myNormalImg.length + 1}>
                {myNormalImg.map((i, idx) => {
                  return <leftSideBarStyle.TemplateThumbnail crossOrigin="Anonymous" key={idx} src={i.upload_img} onClick={() => selectImage(i, 'normalImg')} />;
                })}
              </Slider>
            </style.SliderItem>
            <style.SliderItem>
              <style.SliderTitleWrapper>
                <span>{t('canvas.option.MyCanvas')}</span>
              </style.SliderTitleWrapper>
              <Slider {...settings} initialSlide={canvasList.length + 1}>
                {canvasList.map((i, idx) => {
                  return <leftSideBarStyle.TemplateThumbnail crossOrigin="Anonymous" key={idx} src={i.origin_img} onClick={() => selectImage(i, 'canvasImg')} />;
                })}
              </Slider>
            </style.SliderItem>
          </style.SliderWrapper>
        )}
      </div>
    </leftSideBarStyle.MainContainer>
  );
}

export default ProductPhotography;
