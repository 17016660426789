import styled from 'styled-components';
import React from 'react';

export const Contents = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 100px);
  background-image: url(/images/BackGround/background.jpeg);
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #fbfbfb;
`;
export const SignupBlock = styled.form`
  max-height: 680px;
  max-width: 640px;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  margin: auto;
  position: relative;
  box-shadow: 3px 3px 6px 0 rgb(0 0 0 / 16%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 60px;
  padding: 50px;

  @media screen and (max-width: 768px) {
    width: 90%;
    padding: 40px 20px;
    height: fit-content;
  }
`;
export const TitleDiv = styled.div`
  font-size: 19px;
  font-family: 'NS-B';
  color: #343434;
`;
export const InputListBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`;
export const InputList = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;
export const InputListSpan = styled.span`
  width: 130px;
  font: inherit;
  color: currentColor;
  padding: 6px 0 7px;
  animation-name: mui-auto-fill-cancel;
  letter-spacing: inherit;
  animation-duration: 10ms;
  -webkit-tap-highlight-color: transparent;
`;
export const Input = styled.input`
  height: 40px;
  width: 100%;
  color: rgba(0, 0, 0, 0.87);
  cursor: text;
  font-size: 1rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.1876em;
  letter-spacing: 0.00938em;
  border: 1px solid #a9a9a9;
  border-radius: 2px;
`;
export const SignupButtonBlock = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
`;
export const SignupButton = styled.button`
  height: 47px;
  cursor: pointer;
  background: linear-gradient(96deg, #bda0e2 -15%, #ff69a0 29%, #ff69a0 69%, #fdd5a7);
  border: 1px solid #ffffff00;
  border-radius: 8px;
  color: #ffffff;
  font-size: 16px;
  font-family: 'NS-B';
  width: 100%;
  &:hover {
    border: 1px solid #ffffff;
  }
`;
export const SocialLogin = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
`;

export const KakaoLogin = styled.img`
  cursor: pointer;
  box-shadow: rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px;
  border-radius: 5px;
`;

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

const Icon = styled.svg`
  fill: none;
  stroke: #ec79b3;
  stroke-width: 2px;
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 1px solid;
  border-color: ${(props) => (props.checked ? '#ec79b3' : '#A9A9A9')};
  transition: all 150ms;
  ${Icon} {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }
`;

const Button = styled.button`
  padding: 0;
  background-color: transparent !important;
  background-image: none !important;
  border-color: transparent;
  border: none;
  color: #ffffff;
`;

export const Checkbox = ({ checked, onClick, value, ...props }) => (
  <Button type="button" onClick={onClick} value={value}>
    <CheckboxContainer>
      <StyledCheckbox checked={checked}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
    </CheckboxContainer>
  </Button>
);

export const CheckboxBlock = styled.div``;

export const CheckboxSpan = styled.span`
  font-size: ${(props) => (props.lang === 'eng' ? '76%' : '14px')};
  font-family: 'NS-B';
  word-break: keep-all;
  color: ${(props) => (props.checked ? '#ec79b3' : '#A9A9A9')};
  margin-left: 5px;
  border-bottom: 1px solid transparent;
  cursor: pointer;
  transition: 0.2s ease-in;
  &:hover {
    border-bottom: 1px solid ${(props) => (props.checked ? '#ec79b3' : '#A9A9A9')};
  }
`;

export const CheckboxList = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  background: white;
  z-index: 1;
  width: 500px;
  height: 350px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.16);
  border-radius: 1em;
`;

export const ModalTitle = styled.div`
  display: flex;
  font-size: 19px;
  font-family: 'NS-B';
  color: #343434;
  align-items: center;
  justify-content: center;
  padding: 30px 0px;
`;

export const ModalContent = styled.div`
  display: flex;
  width: 90%;
  word-break: break-word;
  height: 180px;
  overflow-y: scroll;
  padding: 0px 20px;
`;

export const ModalConfirm = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
  background-image: none;
  background-color: #fd69a1;
  border-radius: 50px;
  font-size: 15px;
  margin-top: 20px;
  cursor: pointer;
  padding: 15px 0px;
  color: white;
`;
