import studioAPI from 'api/studioAPI';
import { fabric } from 'fabric';
import { removeXAmzParams, settingObjectOnCenter } from './canvasSettingUtil';

/********** Background 관련 함수 **********/

// Single 배경 객체 생성 함수
export const generateSingleBackground = async (canvas, bgImage, id) => {
  const x = canvas.getWidth() / 2;
  const y = canvas.getHeight() / 2;
  return new fabric.Rect({
    id: id,
    name: 'Single Background',
    width: 500,
    height: 500,
    originX: 'center',
    originY: 'center',
    left: x,
    top: y,
    fill: bgImage.colors[0],
    isBackground: true,
    type: 'rect',
    bgType: 'single',
  });
};

// Half 배경 객체 생성 함수
export const generateHalfBackground = async (canvas, bgImage, id) => {
  const x = canvas.getWidth() / 2;
  const y = canvas.getHeight() / 2;
  const top = new fabric.Rect({
    left: 0,
    top: 0,
    width: 500,
    height: 250,
    fill: bgImage.colors[0],
  });
  const bottom = new fabric.Rect({
    left: 0,
    top: 250,
    width: 500,
    height: 250,
    fill: bgImage.colors[1],
  });

  return new fabric.Group([top, bottom], {
    id: id,
    name: 'Half Background',
    left: x,
    top: y,
    originX: 'center',
    originY: 'center',
    isBackground: true,

    bgType: 'half',
  });
};

// Cross 배경 객체 생성 함수
export const generateCrossBackground = async (canvas, bgImage, id) => {
  const leftPoints = [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 500,
    },
    {
      x: 500,
      y: 0,
    },
  ];

  const rightPoints = [
    {
      x: 500,
      y: 0,
    },
    {
      x: 0,
      y: 500,
    },
    {
      x: 500,
      y: 500,
    },
  ];

  const left = new fabric.Polygon(leftPoints, {
    left: 0,
    top: 0,
    fill: bgImage.colors[0],
    objectCaching: false,
  });

  const right = new fabric.Polygon(rightPoints, {
    left: 0,
    top: 0,
    fill: bgImage.colors[1],
    objectCaching: false,
  });

  const x = canvas.getWidth() / 2;
  const y = canvas.getHeight() / 2;
  return new fabric.Group([left, right], {
    id: id,
    name: 'Cross Background',
    left: x,
    top: y,
    originX: 'center',
    originY: 'center',
    isBackground: true,

    bgType: 'cross',
  });
};

// Canvas의 Background 객체를 반환하는 함수
export const getBackground = (canvas) => {
  return canvas.getObjects().find((object) => object.id?.startsWith('bg'));
};

export const getObjectById = (canvas, id) => {
  return canvas.getObjects().find((object) => object.id === id);
};

/********** Workspace 관련 함수 **********/

// Workspace 객체 생성 함수
export const generateWorkspace = (width, height, scaleX = 1, scaleY = 1) =>
  new fabric.Rect({
    width: width,
    height: height,
    scaleX,
    scaleY,
    selectable: false,
    lockMovementX: true,
    lockMovementY: true,
    lockRotation: true,
    evented: false,
  });

// Canvas의 Workspace 객체를 반환하는 함수
export const getWorkspace = (canvas) => canvas.getObjects().filter((object) => object.id === 'workspace')[0];

/********** Shape 관련 함수 **********/

// Circle 생성 함수
export const getCircle = (id) => {
  return new fabric.Circle({
    id,
    radius: 20,
    left: 10,
    fill: 'red',
    strokeWidth: 1,
    name: 'Cirle',
    type: 'circle',
    shadow: { used: false },
  });
};

// Rect 생성 함수
export const getRect = (id) => {
  return new fabric.Rect({
    id,
    top: 10,
    left: 20,
    fill: 'blue',
    width: 50,
    height: 50,
    angle: 0,
    name: 'Rectangle',
    type: 'rect',
    shadow: { used: false },
  });
};

// Shape 속성 객체 반환 함수
export const getShapeProperties = (target) => {
  return {
    id: target.id,
    fill: target.fill,
    stroke: target.stroke,
    shadow: target.shadow ? target.shadow : null,
    type: target.type,
    strokeWidth: target.strokeWidth,
  };
};

/********** Text 관련 함수 **********/

// 기본 Text 생성 함수
export const getDefaultTextbox = (textTrigger, id) => {
  return new fabric.Textbox(textTrigger.text, {
    id,
    top: 50,
    left: 50,
    fontSize: 30,
    fontStyle: 'normal',
    fontFamily: textTrigger.fontFamily,
    fontWeight: 100,
    underline: false,
    linethrough: false,
    overline: false,
    noScaleCache: false,
    fill: 'black',
    stroke: 'black',
    strokeWidth: 1,
    textAlign: 'center',
    lineHeight: 1, // 적절한 줄 간격을 위함
    textBackgroundColor: '',
    type: 'text',
    name: textTrigger.text.slice(0, 8) + '...',
    minScaleLimit: 0.2,
    editable: false, // 클릭해서 수정함을 방지하기 위함
  });
};

// Text 속성 객체 반환 함수
export const getTextProperties = (target) => {
  return {
    fontSize: target.fontSize,
    fontStyle: target.fontStyle,
    fontWeight: target.fontWeight,
    fontFamily: target.fontFamily,
    underline: target.underline,
    linethrough: target.linethrough,
    overline: target.overline,
    stroke: target.stroke,
    strokeWidth: target.strokeWidth,
    textAlign: target.textAlign,
    lineHeight: target.lineHeight,
    text: target.text,
    type: target.type,
  };
};

/********** PP 관련 함수 **********/

// PP 리스트를 반환하는 함수
export const getPpList = (canvas) => canvas.getObjects().filter((object) => object.id?.startsWith('pp'));

/********** 공통 생성 함수 **********/

/**
 * Object ID를 생성하는 함수
 * @param {String} type
 * @returns
 */
export const generateObjectId = (type) => type + '-' + new Date().getTime();

// GuildeLine 생성하는 함수
export const generateGuideLine = (event) => {
  // 내가 현재 움직이고 있는 객체가 target
  const { canvas } = event.target;
  const { target } = event;

  // 가까운 객체와의 거리가 snapDistance 보다 작으면 정렬
  const snapDistance = 10;

  // 타깃 객체의 left, top, right, bottom 위치 구하기
  const targetLeft = target.left;
  const targetTop = target.top;
  const targetRight = target.left + target.width * target.scaleX;
  const targetBottom = target.top + target.height * target.scaleY;

  // 가장 가까운 객체와 그에 대한 거리 및 방향
  let closestObject = null;
  let closestDistance = Infinity;
  let closestDirection = null;

  let mins = [];
  let direction;

  canvas?.forEachObject((object) => {
    if (object === target) return; // 타깃 객체인 나 자신은 제외
    if (object.type === 'guideLine') return; // guideLine은 제외
    if (object.id?.startsWith('shadow')) return; // shadow 제외
    if (object.id?.startsWith('resize')) return; // resize 버튼 제외
    // if (object.id.startsWith('bg')) return;

    // 캔버스 위의 객체의 left, top, right, bottom 위치 구하기
    const objectLeft = object.left;
    const objectTop = object.top;
    const objectRight = object.left + object.width * object.scaleX;
    const objectBottom = object.top + object.height * object.scaleY;

    // 타깃 객체와 다른 객체와의 거리 계산
    const distanceInLeft = Math.sqrt(Math.pow(targetLeft - objectLeft, 2));
    const distanceInTop = Math.sqrt(Math.pow(targetTop - objectTop, 2));
    const distanceInRight = Math.sqrt(Math.pow(targetRight - objectRight, 2));
    const distanceInBottom = Math.sqrt(Math.pow(targetBottom - objectBottom, 2));
    const distanceOutLeft = Math.sqrt(Math.pow(object.left - (target.left + target.width * target.scaleX), 2));
    const distanceOutTop = Math.sqrt(Math.pow(object.top - (target.top + target.height * target.scaleY), 2));
    const distanceOutRight = Math.sqrt(Math.pow(target.left - (object.left + object.width * object.scaleX), 2));
    const distanceOutBottom = Math.sqrt(Math.pow(target.top - (object.top + object.height * object.scaleY), 2));
    let distances = [
      { distance: distanceInLeft, direction: 'inLeft' },
      { distance: distanceInTop, direction: 'inTop' },
      { distance: distanceInRight, direction: 'inRight' },
      { distance: distanceInBottom, direction: 'inBottom' },
      { distance: distanceOutLeft, direction: 'outLeft' },
      { distance: distanceOutTop, direction: 'outTop' },
      { distance: distanceOutRight, direction: 'outRight' },
      { distance: distanceOutBottom, direction: 'outBottom' },
    ];
    distances.sort((a, b) => a.distance - b.distance);
    const minDistance = distances[0]['distance'];
    direction = distances[0]['direction'];

    // 가장 가까운 객체 업데이트
    if (minDistance < closestDistance) {
      closestObject = object;
      closestDistance = minDistance;
      closestDirection = direction;

      // 가장 가까운 거리가 2개 일 수도 있음. ex) 왼쪽 위 모서리 정렬
      mins = distances.slice(0, 1);
      if (distances[1]['distance'] < snapDistance) {
        mins = distances.slice(0, 2);
      }
    }
  });

  // 가장 가까운 객체와의 정렬
  if (closestObject) {
    canvas.forEachObject((obj) => {
      if (obj.type === 'guideLine') {
        canvas.off('object:removed');
        canvas.remove(obj);
        canvas.on('object:removed');
      }
    });
    mins.forEach((object) => {
      if (object['distance'] < snapDistance) {
        closestDirection = object['direction'];

        // guideLine 생성
        let line;

        switch (closestDirection) {
          case 'inLeft':
            line = new fabric.Line([closestObject.left, 0, closestObject.left, canvas.getHeight()]);
            target.set({ left: closestObject.left });
            break;
          case 'inTop':
            line = new fabric.Line([0, closestObject.top, canvas.getWidth(), closestObject.top]);
            target.set({ top: closestObject.top });
            break;
          case 'inRight':
            line = new fabric.Line([closestObject.left + closestObject.width * closestObject.scaleX, 0, closestObject.left + closestObject.width * closestObject.scaleX, canvas.getHeight()]);
            target.set({ left: closestObject.left + closestObject.width * closestObject.scaleX - target.width * target.scaleX });
            break;
          case 'inBottom':
            line = new fabric.Line([0, closestObject.top + closestObject.height * closestObject.scaleY, canvas.getWidth(), closestObject.top + closestObject.height * closestObject.scaleY]);
            target.set({ top: closestObject.top + closestObject.height * closestObject.scaleY - target.height * target.scaleY });
            break;
          case 'outLeft':
            line = new fabric.Line([closestObject.left, 0, closestObject.left, canvas.getHeight()]);
            target.set({ left: closestObject.left - target.width * target.scaleX });
            break;
          case 'outTop':
            line = new fabric.Line([0, closestObject.top, canvas.getWidth(), closestObject.top]);
            target.set({ top: closestObject.top - target.height * target.scaleY });
            break;
          case 'outRight':
            line = new fabric.Line([closestObject.left + closestObject.width * closestObject.scaleX, 0, closestObject.left + closestObject.width * closestObject.scaleX, canvas.getHeight()]);
            target.set({ left: closestObject.left + closestObject.width * closestObject.scaleX });
            break;
          case 'outBottom':
            line = new fabric.Line([0, closestObject.top + closestObject.height * closestObject.scaleY, canvas.getWidth(), closestObject.top + closestObject.height * closestObject.scaleY]);
            target.set({ top: closestObject.top + closestObject.height * closestObject.scaleY });
            break;
          default:
        }
        line.evented = false;
        line.stroke = '#00ff00';
        line.strokeWidth = 1;
        line.type = 'guideLine';

        canvas.off('object:added');
        canvas.add(line);
        canvas.on('object:added');
      } else {
        canvas.forEachObject((obj) => {
          if (obj.type === 'guideLine') {
            canvas.off('object:removed');
            canvas.remove(obj);
            canvas.on('object:removed');
          }
        });
      }
      canvas.renderAll();
    });
  }

  canvas.requestRenderAll(); // Canvas 다시 렌더링
}; /**
 * 캔버스에 클립패스를 추가하는 함수
 * @param {fabric.canvas} canvas
 * @param {fabric.canvas.clipPath} clipPath
 */
export const addClipPath = (canvas, clipPath) => {
  canvas.clipPath = clipPath;
  canvas.requestRenderAll();
};
// 탬플릿 배경 이미지 요청 함수
export const getTemplate = async (id) => {
  const template = id && (await studioAPI.getTemplate(id));
  return template ? template.data.img : null;
};
/**
 * 이미지 객체를 캔버스에 추가하는 함수
 * @param {*} canvas
 * @param {*} url
 * @param {*} initialValue
 * @param {*} callback 추가 후 실행될 콜백 함수
 * @returns
 */
export const addImage = async (canvas, url, initialValue, callback) => {
  const fabricImg = await generateImage(canvas, removeXAmzParams(url), initialValue);
  fabricImg.set(initialValue);
  settingObjectOnCenter(canvas, fabricImg);

  canvas.add(fabricImg);

  const processedFabricImg = await postProcess(canvas, fabricImg, callback);
  return processedFabricImg;
};
// PP 객체 추가 함수
export const addPP = async ({ canvas, ppImage, initialValue, callback, originImage }) => {
  if (!ppImage) return;
  initialValue = { ...initialValue, id: generateObjectId('pp'), originSrc: ppImage.uploadImage };
  const pp = await addImage(canvas, ppImage.ppImage, initialValue, callback);
  return pp;
};
/**
 * 이미지 객체를 생성하는 함수
 * @param {*} canvas
 * @param {*} url
 * @param {*} initialValue
 * @returns
 */
const generateImage = (canvas, url, initialValue) => {
  const originSrc = url?.uploadImage;
  return new Promise((resolve) => {
    const x = canvas.width / 2;
    const y = canvas.height / 2;

    fabric.Image.fromURL(
      url,
      (img) => {
        const id = generateObjectId('image');
        img.set({ ...initialValue, left: x, top: y });
        img.originX = 'center';
        img.originY = 'center';
        img.scaleToWidth(300);
        img.type = 'image';
        img.src = url;
        img.originSrc = originSrc;
        img.id = id;
        img.name = id;
        if (initialValue?.isBackground) {
          img.scaleToWidth(500);
        }
        resolve(img); // 이미지가 캔버스에 추가된 후 resolve를 호출
      },
      { crossOrigin: 'anonymous' },
    );
  });
};
/**
 * 이미지 객체 추가 후 후처리 함수
 * @param {*} canvas
 * @param {*} fabricObj
 * @param {*} callback 추가 후 실행될 콜백 함수
 * @returns fabric Object
 */
const postProcess = async (canvas, fabricObj, callback) => {
  const obj = fabricObj;
  console.log('postProcess', obj);
  canvas.requestRenderAll();

  if (callback) callback(obj);
  return obj;
};
/**
 * Fabric Shape 객체를 캔버스에 추가하는 함수
 * @param {*} canvas
 * @param {*} fabricShape
 * @param {*} callback
 * @returns
 */
export const addShape = async (canvas, fabricShape, callback) => {
  canvas.add(fabricShape);
  settingObjectOnCenter(canvas, fabricShape);

  const processedFabricShape = await postProcess(canvas, fabricShape, callback);
  return processedFabricShape;
};

// 객체 선택시 selected에 object의 id를 저장하는 함수
export const addObjectSelectEvent = (object, setSelected, setRightState) => {
  object.on('selected', () => {
    setSelected(object.id);
    setRightState(object.type);
    console.log('selected', object);
  });
  object.on('deselected', () => {
    setSelected(null);
    setRightState('components');
  });
};

/**
 * 도형 객체 이벤트를 추가하는 함수
 * @param {fabric.Object} shape
 * @param {function} resetSelectedShape 선택된 객체의 속성을 초기화하는 함수
 * @param {function} setSelectedShape 선택된 객체의 속성을 저장하는 함수
 * @param {function} setCanvasObjects 객체 목록에 추가하는 함수
 * @returns void
 */
export const addShapeEvnet = (shape, resetSelectedShape, setSelectedShape, setCanvasObjects) => {
  shape.on('deselected', () => resetSelectedShape());
  shape.on('selected', (e) => setSelectedShape(getShapeProperties(e.target)));
  setCanvasObjects((state) => [...state, { id: shape.id, type: shape.type, name: shape.name }]);
};

/**
 * 텍스트 객체 이벤트를 추가하는 함수
 * @param {fabric.Object} shape
 * @param {function} resetSelectedText 선택된 객체의 속성을 초기화하는 함수
 * @param {function} setSelectedText 선택된 객체의 속성을 저장하는 함수
 * @param {function} setCanvasObjects 객체 목록에 추가하는 함수
 * @returns void
 */
export const addTextEvnet = (text, resetSelectedText, setSelectedText, setCanvasObjects) => {
  text.on('selected', (e) => setSelectedText(getTextProperties(e.target)));
  text.on('deselected', () => resetSelectedText());
  text.on('mousedblclick', () => document.getElementById('text')?.focus());
  text.on('scaling', () => {
    text.on('mouseup', (e) => {
      const { width, target } = e.transform;
      const scaledWidth = width * text.scaleX;
      const fontSize = +(target.scaleY * text.fontSize).toFixed(1).split('.0')[0];
      text.set({ fontSize, scaleY: 1, scaleX: 1, width: scaledWidth });
      text.off('mouseup'); // 이벤트가 중복해서 발생하는 현상 제거
      setSelectedText({ ...getTextProperties(target), fontSize });
    });
    text.on('mouseup'); // 위에서 차단한 이벤트 차단 해제 설정
  });
  setCanvasObjects((state) => [...state, { id: text.id, type: 'text', name: text.text.substring(0, 10) }]);
};

/**
 * 이미지 객체 이벤트를 추가하는 함수
 * @param {fabric.Object} shape
 * @param {function} resetSelectedImage 선택된 객체의 속성을 초기화하는 함수
 * @param {function} setSelectedImage 선택된 객체의 속성을 저장하는 함수
 * @param {function} setCanvasObjects 객체 목록에 추가하는 함수
 * @returns void
 */
export const addImageEvent = (img, resetSelectedImage, setSelectedImage, setCanvasObjects) => {
  const { id, src, originSrc, width, height } = img;
  img.on('deselected', () => resetSelectedImage());
  img.on('selected', () => setSelectedImage({ id, src, originSrc, width, height }));
  setCanvasObjects((state) => [...state, { id, src, name: id, type: 'image' }]);
};

/**
 * 클립패스를 생성하는 함수
 * @param {fabric.canvas} canvas
 * @param {number} width
 * @param {number} height
 * @param {number} scaleX (default: 1)
 * @param {number} scaleY (default: 1)
 * @returns
 */
export const generateClipPath = (canvas, width, height, scaleX = 1, scaleY = 1) => {
  const clipPath = new fabric.Rect({
    width: width,
    height: height,
    scaleX,
    scaleY,
    selectable: false,
    lockMovementX: true,
    lockMovementY: true,
    lockRotation: true,
    evented: false,
    originX: 'center',
    originY: 'center',
    left: canvas.width / 2,
    top: canvas.height / 2,
  });
  return clipPath;
};

export const generateCloneShadow = (obj, shadowInfo) => {
  return new Promise((resolve, reject) => {
    obj.clone((cloned) => {
      cloned.set({
        blur: shadowInfo.blur,
        originX: 'center',
        originY: 'center',
        opacity: shadowInfo.opacity,
        left: obj.left + shadowInfo.offsetX,
        top: obj.top + shadowInfo.offsetY,
        isShadow: true,
        id: `shadow-${obj.id}`,
        parentId: obj.id,
        selectable: false,
        evented: false,
      });

      const shadow = makeImgBlack(cloned);
      const shadowWithBlur = makeImgBlur(shadow, shadowInfo.blur);

      resolve(shadowWithBlur);
    });
  });
};

// eslint-disable-next-line
export const generateBase64Shadow = async (obj, data) => {
  const base64 = 'data:image/png;base64,' + data.shadow;
  const blur = data.blur / 100;
  const opacity = data.opacity / 100;
  const matrix = data.matrix;

  return new Promise((resolve, reject) => {
    // base64 이미지를 fabric.Image로 변환
    fabric.Image.fromURL(
      base64,
      (img) => {
        img.set({
          originX: 'center',
          originY: 'center',
          blur: blur,
          opacity: opacity,
          left: obj.left,
          top: obj.top,
          scaleX: obj.scaleX,
          scaleY: obj.scaleY,
          isShadow: true,
          id: `shadow-${obj.id}`,
          parentId: obj.id,
          selectable: false,
          evented: false,
        });

        const shadow = makeImgBlack(img);
        const shadowWithBlur = makeImgBlur(shadow, blur);
        const shadowWithMatrix = transformByMatrix(shadowWithBlur, matrix);

        resolve(shadowWithMatrix);
      },
      { crossOrigin: 'anonymous' },
    );
  });
};

export const makeImgBlack = (img) => {
  const image = img;
  let filter = new fabric.Image.filters.ColorMatrix({
    matrix: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0],
  });
  image.filters.push(filter);
  image.applyFilters();
  return image;
};

export const makeImgBlur = (img, blur) => {
  const image = img;
  let filter = new fabric.Image.filters.Blur({
    blur: blur,
  });
  image.filters.push(filter);
  image.applyFilters();
  return image;
};

export const transformByMatrix = (obj, matrix) => {
  const object = obj;
  const transformMatrix = [matrix[0][0], matrix[0][1], matrix[1][0], matrix[1][1], matrix[0][2], matrix[1][2]];
  object.transformMatrix = transformMatrix;

  return object;
};

// eslint-disable-next-line
export const addShadowEvent = (obj, shadowObj) => {
  // 초기 오프셋 계산
  let offsetX = shadowObj.left - obj.left;
  let offsetY = shadowObj.top - obj.top;

  const onObjectMoving = function (e) {
    shadowObj.set({
      left: obj.left + offsetX,
      top: obj.top + offsetY,
    });
  };

  const onShadowMoving = function (e) {
    offsetX = shadowObj.left - obj.left;
    offsetY = shadowObj.top - obj.top;
  };

  const onObjectScaling = function (e) {
    shadowObj.set({
      scaleX: obj.scaleX,
      scaleY: obj.scaleY,
      left: obj.left + offsetX,
      top: obj.top + offsetY,
    });
  };

  const onObjectRotating = function (e) {
    shadowObj.set({
      angle: obj.angle,
      left: obj.left + offsetX,
      top: obj.top + offsetY,
    });
  };

  shadowObj.on('moving', onShadowMoving);
  obj.on('moving', onObjectMoving);
  obj.on('scaling', onObjectScaling);
  obj.on('rotating', onObjectRotating);

  return {
    remove: function () {
      obj.off('moving', onObjectMoving);
      obj.off('scaling', onObjectScaling);
      obj.off('rotating', onObjectRotating);
      shadowObj.off('moving', onShadowMoving);
    },
  };
};

export const generateUniqueName = (canvasObjects, baseName) => {
  // 기본 이름에서 괄호와 숫자를 제거 (예: "objectname (1)"에서 "objectname" 추출)
  const baseNameMatch = baseName.match(/^(.+?)(?: \(\d+\))?$/);
  let cleanBaseName = baseName;
  if (baseNameMatch) {
    cleanBaseName = baseNameMatch[1];
  }
  let newName = cleanBaseName;
  let suffix = 1;
  // 이름에 해당하는 숫자를 추출하는 정규식 업데이트
  const regex = new RegExp(`^${cleanBaseName.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')} \\((\\d+)\\)$`);
  // 기존에 존재하는 이름과의 충돌을 방지하기 위한 최대 숫자 찾기
  const numbers = canvasObjects
    .map((obj) => {
      const match = obj.name.match(regex);
      return match ? parseInt(match[1], 10) : 0;
    })
    .filter((num) => !isNaN(num));
  const maxNumber = numbers.length ? Math.max(...numbers) : 0;
  if (maxNumber > 0) {
    suffix = maxNumber + 1;
    newName = `${cleanBaseName} (${suffix})`;
  } else if (canvasObjects.some((obj) => obj.name === cleanBaseName)) {
    // 기본 이름이 이미 존재하는 경우, 숫자를 추가
    newName = `${cleanBaseName} (${suffix})`;
  }
  // 새로운, 고유한 이름 반환
  return newName;
};

/**
 * 이미지의 크기를 캔버스의 크기에 맞게 조정하는 함수
 * @param img
 */
export const resizeImage = (canvas, img) => {
  if (canvas.clipPath?.width / (img.width * img.scaleX) < canvas.clipPath?.height / (img.height * img.scaleY)) {
    img.scaleToWidth(+(canvas.clipPath?.width / 2.15).toFixed(0));
  } else {
    img.scaleToHeight(+(canvas.clipPath?.height / 2.15).toFixed(0));
  }
};
