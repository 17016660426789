import styled from 'styled-components';
import colors from 'constants/colors';

export const Wrap = styled.div`
  height: 100vh;
  overflow: hidden;
  @media screen and (max-width: 1200px) {
    height: unset;
  }
`;

export const Flex = styled.div`
  display: flex;
  max-width: ${(props) => (props.wrap ? '1140px' : null)};
  width: ${(props) => (props.wrap ? '100%' : null)};
  height: ${(props) => (props.wrap ? '100%' : null)};
  margin: ${(props) => (props.wrap ? 'auto' : null)};
  padding: ${(props) => (props.wrap ? '20px 0 80px' : null)};
  gap: ${(props) => (props.gap ? props.gap : null)};
  justify-content: ${(props) => (props.wrap ? 'space-between' : null)};

  @media screen and (max-width: 1200px) {
    overflow: hidden;
    width: ${(props) => (props.width ? props.width : '100%')};
    max-width: ${(props) => (props.wrap ? '980px' : null)};
    flex-direction: ${(props) => (props.dir ? props.dir : null)};
    justify-content: ${(props) => (props.wrap ? 'unset' : null)};
    margin: ${(props) => (props.margin ? props.margin : null)};
    align-items: ${(props) => (props.wrap ? 'center' : null)};
    padding: ${(props) => (props.wrap ? '5% 0' : null)};
    gap: 3%;
  }
  @media screen and (max-width: 768px) {
    padding: unset;
  }
`;

export const SingleFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 1200px) {
    overflow: hidden;
    width: ${(props) => (props.width ? props.width : '100%')};
    flex-direction: column;
  }
  @media screen and (max-width: 768px) {
    padding: unset;
    flex-direction: column;
  }
`;

export const MyProduct = styled.img`
  width: 100%; /* 뷰포트 너비의 15% */
  height: auto; /* 뷰포트 너비의 15%, 너비와 높이를 동일하게 유지 */
  object-fit: contain;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25);
  background: ${(props) => (props.color === 'none' ? `url(${process.env.PUBLIC_URL}/images/Component/empty-layer.png) 0% 0% / 36px` : props.color)};
  cursor: pointer;

  @media screen and (max-width: 1200px) {
    width: 50%;
    height: auto;
  }

  @media screen and (max-width: 768px) {
    width: 25vw; /* 더 작은 뷰포트에서는 크기를 증가 */
    height: 25vw;
  }
`;

export const Single = styled.img`
  object-fit: contain;
  background: ${(props) => props.color};
  cursor: pointer;
  width: 30%;
  height: auto; /* 너비와 높이를 동일하게 유지 */
  max-width: 90px; /* 최대 너비 제한 */
  max-height: 90px; /* 최대 높이 제한 */

  @media screen and (max-width: 1200px) {
    width: 100%;
    height: auto;
  }

  @media screen and (max-width: 768px) {
    width: 7vw;
    height: 7vw;
    max-width: 70px;
    max-height: 70px;
  }
`;

export const Column = styled(Flex)`
  flex-direction: column;
  gap: 1.5%;
  margin-left: 2%;
  margin-right: 2%;
  overflow: scroll;
  justify-content: ${(props) => (props.center ? 'center' : null)};
  align-items: center;
  box-sizing: border-box;
  overflow-y: auto; // 세로 스크롤만 허용
  overflow-x: hidden; // 가로 스크롤은 허용하지 않음

  @media screen and (max-width: 1200px) {
    flex-direction: ${(props) => (props.dir ? props.dir : 'row')};
    padding: 0 1vw;
    margin-bottom: 2vh;
    overflow-y: hidden;
    overflow-x: auto;
  }
`;
