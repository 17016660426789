import styled from 'styled-components';

export const HeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  width: 100%;
  border-bottom: 1px solid #dbdbdb;
  padding: 0 2rem;
  position: sticky;
  top: 0;
  z-index: 99;
  background-color: #ffffff;
`;

export const Left = styled.div`
  display: flex;
  gap: 25px;

  > img {
    cursor: pointer;
  }

  > button {
    all: unset;
    cursor: pointer;
    padding: 10px 15px;
    border-radius: 6px;
    background-color: #f2f4f4;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.35px;
    color: #767676;
    transition: all 0.3s ease;

    &:hover {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 15%);
    }
  }

  @media screen and (max-width: 1024px) {
    > a > img {
      width: 26px;
    }
    > button {
      padding: 8px 12px;
      font-size: 12px;
    }
  }
  @media screen and (max-width: 768px) {
    > a > img {
      width: 22px;
    }
    > button {
      padding: 6px 10px;
      font-size: 10px;
    }
  }
`;

export const Write = styled.div`
  display: flex;
  gap: 10px;

  > input {
    all: unset;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
    border: 1px solid #e61503;
    border-radius: 4px;
    padding: 8px;
    font-size: 14px;
    min-width: 160px;
  }
  > button {
    padding: 10px 15px;
    border-radius: 4px;
    border: 1px solid #e61503;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.35px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      box-shadow: 0 4px 4px rgb(0 0 0 / 15%);
    }
  }

  @media screen and (max-width: 1024px) {
    > button {
      padding: 8px 12px;
    }
    > button > img {
      width: 18px;
    }
    > button {
      font-size: 12px;
    }
  }
  @media screen and (max-width: 768px) {
    > input {
      font-size: 14px;
    }
    > button {
      padding: 6px 10px;
    }
    > button > img {
      width: 16px;
    }
    > button {
      font-size: 10px;
    }
  }
`;

export const Button = styled.button`
  background-color: ${(props) => props.backgroundColor || '#e61503'};
  color: ${(props) => props.color || '#FFFFFF'};
`;
