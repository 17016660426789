/**********************************`
 `* Name : LoginButton.js`
 `* Author : Kim Minjeong(minjj0905)`
 `* Introduction : Header 내부 컴포넌트 버튼, 로그인 상태에 따라 '로그인/가입` / '로그아웃' 으로 표시할 예정'
 `*`
 `********************************** */

import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { BorderedButton } from './loginButtonStyles';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { UserLoginState } from 'state/state';
import { authState } from 'state';
import { getCookie, deleteCookie } from 'cookies-next';
import auth from 'api/accountAPI';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';

// login button 컴포넌트 생성
function LoginButton() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [, setUserState] = useRecoilState(UserLoginState);
  const userState = useRecoilValue(authState);
  const resetUser = useResetRecoilState(authState);

  const logoutClick = async () => {
    await auth
      .logout({
        refresh: getCookie('refresh_token'),
      })
      .then((res) => {
        resetUser();
        deleteCookie('access_token');
        deleteCookie('refresh_token');
        Swal.fire({
          text: t('alert.logout'),
          customClass: {
            popup: 'custom-popup-class', // 모달 다이얼로그 스타일
          },
        });
        Swal.fire({
          text: t('alert.logout'),
          customClass: {
            popup: 'custom-popup-class', // 모달 다이얼로그 스타일
          },
        });
      })
      .catch((e) => console.log(e));
    if (location.pathname === '/mypage') navigate('/');
  };

  const UserStateChange = () => {
    setUserState('loginInfo');
  };
  const navigateLogin = (e) => {
    navigate('/login');
  };
  return (
    <div style={{ display: 'flex', alignItems: 'center' }} onClick={UserStateChange}>
      {userState.isLoggedIn ? (
        <BorderedButton onClick={logoutClick}>{t('Header.loginButton.Logout')}</BorderedButton>
      ) : (
        <BorderedButton onClick={navigateLogin}>{t('Header.loginButton.Login')}</BorderedButton>
      )}
    </div>
  );
}

export default LoginButton;
