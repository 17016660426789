import { atom } from 'recoil';

const authState = atom({
  key: 'authState', // atom를 지칭할 수 있는 unique ID
  default: {
    // 초기값 설정
    isLoggedIn: false,
    name: '',
    email: '',
    social_img: '',
    user_id: '',
    access_token: '',
    refresh_token: '',
    canvas_sum: 0,
    download_sum: 0,
    tier: {},
  },
});

export default authState;
