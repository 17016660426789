import { atom } from 'recoil';

export const loadingState = atom({
  key: 'loadingState',
  default: false,
});

export const loadingPercentState = atom({
  key: 'loadingPercentState',
  default: null,
});

export const beforeCropState = atom({
  key: 'beforeCropState',
  default: {},
});

export const cropImageUrlState = atom({
  key: 'cropImageUrlState',
  default: undefined,
});

export const srcWidth = atom({
  key: 'srcWidth',
  default: null,
});

export const srcHeightState = atom({
  key: 'srcHeightState',
  default: 1,
});

export const XscaleState = atom({
  key: 'XscaleState',
  default: undefined, // 초기값이 설정되지 않은 경우 undefined 사용
});

export const YscaleState = atom({
  key: 'YscaleState',
  default: undefined,
});

export const ppSizeState = atom({
  key: 'ppSizeState',
  default: { width: 451, height: 554 },
});

export const uploadImageState = atom({
  key: 'uploadImageState',
  default: '',
});
