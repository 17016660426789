import * as styles from './styles';
import React from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { brushState } from 'state/state';
import { rightSidebarState } from 'state/sidebarState';
import { escapeRestoreState, svgConverterTriggerState } from 'state/triggerState';

// eslint-disable-next-line
function BrushBlock() {
  const { t } = useTranslation();

  const [brushStatus, setBrushStatus] = useRecoilState(brushState);
  const handleBrush = (e, brushValue) => {
    setBrushStatus({ ...brushStatus, type: brushValue });
  };
  const handleBrushSize = (e, newValue) => {
    if (Number(newValue) < 5) setBrushStatus({ ...brushStatus, size: 5 });
    else setBrushStatus({ ...brushStatus, size: Number(newValue) });
  };

  const [, setTrigger] = useRecoilState(svgConverterTriggerState);
  const [escapeRestore, setEscapeRestore] = useRecoilState(escapeRestoreState);

  return (
    <div id={'brushBlock'}>
      <styles.ControllerDiv>
        <styles.BrushModeDiv>
          <styles.Wrapper>
            <styles.optionText> {t('canvas.brushBlock.OptionText')} </styles.optionText>
          </styles.Wrapper>
          <styles.StyledToggleGroup value={brushStatus.type} exclusive onChange={handleBrush}>
            <styles.BrushToggleButton value={'eraser'} aria-label="delete brush">
              <div>
                <img src={process.env.PUBLIC_URL + '/images/Canvas/eraseIcon.svg'} alt={t('canvas.brushBlock.Erase')} />
              </div>
              <span> {t('canvas.brushBlock.Erase')}</span>
            </styles.BrushToggleButton>
            <styles.BrushToggleButton value={'restore'} aria-label="recover brush">
              <div>
                <img src={process.env.PUBLIC_URL + '/images/Canvas/restoreIcon.svg'} alt={t('canvas.brushBlock.Restore')} />
              </div>
              <span> {t('canvas.brushBlock.Restore')}</span>
            </styles.BrushToggleButton>
          </styles.StyledToggleGroup>
        </styles.BrushModeDiv>
        <span>{t('canvas.brushBlock.BrushModeDiv')}</span>
        <styles.BrushSizeDiv>
          <span>{t('canvas.brushBlock.BrushSizeDiv')}</span>
          <styles.SizeSlider value={brushStatus.size} onChange={handleBrushSize} valueLabelDisplay="auto" />
        </styles.BrushSizeDiv>
        <button onClick={() => setTrigger(true)}>{t('canvas.brushBlock.Complete')}</button>
        <button onClick={() => setEscapeRestore(true)}>{t('cancel')}</button>
      </styles.ControllerDiv>
    </div>
  );
}

export default BrushBlock;
