import { atom } from 'recoil';

// 한영 언어 상태관리
export const languageState = atom({
  key: 'language',
  default: 'kor',
});

// 확대 축소 상태관리
export const zoomlevelState = atom({
  key: 'zoomlevel',
  default: 1,
});

// 확대 축소 퍼센트 상태관리
export const zoomState = atom({
  key: 'zoom',
  default: 1,
});

// pp image 확대 축소 상태관리
export const ppZoomState = atom({
  key: 'ppZoom',
  default: 1,
});

// 브러쉬 사이즈 상태관리
export const brushState = atom({
  key: 'brush',
  default: {
    size: 5,
    type: 'eraser',
  },
});

// 사이드바 상태관리 => 어디 사이드바??
export const sideBarState = atom({
  key: 'sideBarState',
  default: 'template',
});

// 회원가입 로직 상태관리
export const JoinProcessState = atom({
  key: 'JoinProcess',
  default: 'join1',
});

// 유저 로그인 및 회원가입 로직 상태관리
export const UserLoginState = atom({
  key: 'UserLogin',
  default: 'loginInfo',
});

// 이미지 base64 변환
export const Base64State = atom({
  key: 'Base64',
  default: '',
});

// 이미지명 상태관리
export const ImageNameState = atom({
  key: 'ImageName',
  default: 'Image',
});

// 마이페이지 왼쪽 사이드바 상태관리
export const MyPageLeftState = atom({
  key: 'MyPageLeft',
  default: 'make',
});

export const ProductCropState = atom({
  key: 'Crop',
  default: {},
});

export const ProductCanvasDetailState = atom({
  key: 'ProductCanvasDetail',
  default: {
    shadow: false,
  },
});

// 이미지 업로드 완료 여부 상태
export const IsImageUploadingState = atom({
  key: 'IsImageUploading',
  default: false,
});

// 비회원으로 canvas 작업할 때, 로그인 하고 왔을 때 다시 복구하기 위한 canvas 임시 저장 state
export const TempCanvasContentState = atom({
  key: 'TempCanvasContent',
  default: false,
});

// Mypage의 Product와 Asset에서 이미지를 추가했을때, 복귀를 위한 this.state
export const TempMyPageState = atom({
  key: 'TempMyPageState',
  default: null,
});

// 다운로드 한 canvas 목록 관리를 위함
export const DownloadImageListState = atom({
  key: 'DownloadImageList',
  default: [],
});
