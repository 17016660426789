import { atom } from 'recoil';

// canvas left sidebar state
// template
export const leftSidebarState = atom({
  key: 'leftSidebar',
  default: null,
});

// canvas right sidebar state
// components, detail, combinePic
export const rightSidebarState = atom({
  key: 'rightSidebar',
  default: 'components',
});

// States : update, none, create, true ????
// none 꺼버림 create 이미지 업로드 update 이미지 리플레이스
export const showUploadState = atom({
  key: 'showUpload',
  default: 'none',
});
