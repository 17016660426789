import * as style from './styles';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { addObjectSelectEvent, addShape, addShapeEvnet, generateObjectId, getCircle, getRect, getShapeProperties } from 'utils/canvasObjectGenerateUtil';
import { objectList, selectedObject, selectedShapeState } from 'state/canvasState';
import { fabric } from 'fabric';
import { rightSidebarState } from 'state/sidebarState';

// eslint-disable-next-line
function Shapes() {
  const { t } = useTranslation();
  const setRightState = useSetRecoilState(rightSidebarState);
  const setSelectedIndex = useSetRecoilState(selectedObject);
  const setSelectedShape = useSetRecoilState(selectedShapeState);
  const setCanvasObjects = useSetRecoilState(objectList);
  const resetSelectedShape = useResetRecoilState(selectedShapeState);
  const shapeList = ['circle', 'rectangle', 'EmptyCircle', 'EmptyRectangle', 'CutRectangle', 'RoundRectangle', 'triangle', 'pentagon', 'Hexagon', 'Heptagon', 'Octagon', 'star'];

  /**
   * 이벤트를 추가하는 함수
   * @param {fabric.Object} shape
   * @returns void
   */
  const addEvent = (shape) => {
    addObjectSelectEvent(shape, setSelectedIndex, setRightState);
    addShapeEvnet(shape, resetSelectedShape, setSelectedShape, setCanvasObjects);
  };

  /**
   * 도형 객체를 추가하는 함수
   * @param {object} e
   * @returns void
   */
  const handleClickShape = (e) => {
    const { fabric: canvas } = document.getElementById('canvas');
    const id = generateObjectId(e.target.id);
    switch (e.target.id) {
      case 'circle':
        const circle = getCircle(id);
        addShape(canvas, circle, () => addEvent(circle));
        break;
      case 'rectangle':
        const rect = getRect(id);
        addShape(canvas, rect, () => addEvent(rect));
        break;
      default:
        const svgURL = process.env.PUBLIC_URL + `/images/shapes/${e.target.id}.svg`;
        fabric.loadSVGFromURL(svgURL, (objects, options) => {
          const svg = fabric.util.groupSVGElements(objects, options);
          svg.set({ id, name: e.target.id, src: process.env.PUBLIC_URL + `/images/shapes/${e.target.id}.svg`, shadow: { used: false } });
          addShape(canvas, svg, () => addEvent(svg));
        });
        break;
    }
  };

  return (
    <style.MainContainer>
      <p>{t('canvas.layer.ShapeDescryption')}</p>
      <style.fontBlock>
        {shapeList.map((shape, index) => (
          <style.svgImage key={index} src={process.env.PUBLIC_URL + `/images/shapes/${shape}.svg`} id={shape} onClick={handleClickShape} />
        ))}
      </style.fontBlock>
    </style.MainContainer>
  );
}

export default Shapes;
