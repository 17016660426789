/**********************************
 * Name : useFetch.js
 * Author : Kwak Heegun
 * Introduction : Pagination에서 Fetch를 위해 필요한 JS File.
 ********************************** */

import { useEffect, useState } from 'react';
import studioAPI from 'api/studioAPI';
const useFetch = (templateId, page, mode) => {
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const query = `page=${page}`;

      try {
        let response;

        if (mode === 'color') {
          response = await studioAPI.recommendColorPaginationList(templateId, query);
        } else if (mode === 'base') {
          response = await studioAPI.recommendBasePaginationList(templateId, query);
        } else {
          throw new Error(`Unsupported mode: ${mode}`);
        }

        setList((prevList) => [...prevList, ...response.data.results]);
        setHasMore(response.data.next != null);
      } catch (error) {
        console.error('Fetching error:', error);
      } finally {
        setLoading(false);
      }
    };

    if (!templateId) {
      // templateId가 없으면 아무것도 하지 않음
      // 단색, 혹은 배경이 없을 경우를 위한 예외 처리
      console.log('templateId');
      return;
    }
    console.log('fetchData');
    fetchData();
  }, [templateId, page, mode]);

  return { loading, list, hasMore };
};

export default useFetch;
