import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

  *, *&:before, *&:after {
    font-family: 'Pretendard' !important;
    box-sizing: inherit;
  }

  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: white;
    word-break: keep-all;
  }
  body ::-webkit-scrollbar {
    display: none !important;
  }

  a {
    text-decoration: none !important;
    &:hover {
      text-decoration: none !important;
    }
  }
  
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
  }

  .minidots .slick-dots li.slick-active button&:before {
    content: '•' !important;
    color: #e85389 !important;
    font-size: 16px;
  }
  .minidots .slick-dots li button&:before {
    color: #afafaf;
    font-size: 16px;
  }
  .slick-arrow.slick-disabled {
    display: none !important;
  }

  .custom-popup-class {
    font-size: 13px; /* 모달 다이얼로그의 텍스트 크기를 조절하세요. */
  }

  @media screen and (max-width: 1120px) {
    .minidots .slick-dots li button&:before {
      font-size: 13px;
      top: -4px;
    }
  }

  @media screen and (max-width: 768px) {
    .minidots .slick-dots li button&:before {
      font-size: 11px;
      top: -7px;
    }
  }

  /* Font for Canvas edit */
  @font-face {
    font-family: 'Black And White Picture';
    src: url('./font/canvas/BlackAndWhitePicture-Regular.woff2') format('woff2');
}

@font-face {
    font-family: 'Gamja Flower';
    src: url('./font/canvas/GamjaFlower-Regular.woff2') format('woff2');
}

@font-face {
    font-family: 'Gmarket Sans';
    src: url('./font/canvas/GmarketSansTTFMedium.woff2') format('woff2');
}

@font-face {
    font-family: 'Gothic A1';
    src: url('./font/canvas/GothicA1-Medium.woff2') format('woff2');
}

@font-face {
    font-family: 'Omyu Pretty';
    src: url('./font/canvas/omyuPretty.woff2') format('woff2');
}

@font-face {
    font-family: 'Pretendard';
    src: url('./font/canvas/PretendardVariable.woff2') format('woff2');
}

@font-face {
    font-family: 'Hi Melody';
    src: url('./font/canvas/HiMelody-Regular.woff2') format('woff2');
}

@font-face {
    font-family: 'Nanum Brush Script';
    src: url('./font/canvas/NanumBrushScript-Regular.woff2') format('woff2');
}

@font-face {
    font-family: 'Nanum Gothic';
    src: url('./font/canvas/NanumGothic-Regular.woff2') format('woff2');
}

@font-face {
    font-family: 'Nanum Myeongjo';
    src: url('./font/canvas/NanumMyeongjo-Regular.woff2') format('woff2');
}

@font-face {
    font-family: 'Nanum Pen Script';
    src: url('./font/canvas/NanumPenScript-Regular.woff2') format('woff2');
}

@font-face {
    font-family: 'Poor Story';
    src: url('./font/canvas/PoorStory-Regular.woff2') format('woff2');
}

@font-face {
    font-family: 'Single Day';
    src: url('./font/canvas/SingleDay-Regular.woff2') format('woff2');
}

@font-face {
    font-family: 'Stylish';
    src: url('./font/canvas/Stylish-Regular.woff2') format('woff2');
}

  /* Font Start */
  @font-face {
    font-family: 'NS-EB';
    src: url('font/NanumSquareEB.ttf') format('ttf');
  }
  @font-face {
    font-family: 'NS-B';
    src: url('font/NanumSquareB.ttf') format('ttf');
  }
  @font-face {
    font-family: 'NS-R';
    src: url('font/NanumSquareR.ttf') format('ttf');
  }
  @font-face {
    font-family: 'NanumGothic';
    src: url('font/NanumGothic.ttf') format('ttf');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Pretendard';
    font-weight: 900;
    font-display: swap;
    src: local('Pretendard Black'), url('./font/Pretendard-Black.woff2') format('woff2')
  }

  @font-face {
    font-family: 'Pretendard';
    font-weight: 800;
    font-display: swap;
    src: local('Pretendard ExtraBold'), url('./font/Pretendard-ExtraBold.woff2') format('woff2')
  }

  @font-face {
    font-family: 'Pretendard';
    font-weight: 700;
    font-display: swap;
    src: local('Pretendard Bold'), url('./font/Pretendard-Bold.woff2') format('woff2')
  }

  @font-face {
    font-family: 'Pretendard';
    font-weight: 600;
    font-display: swap;
    src: local('Pretendard SemiBold'), url('./font/Pretendard-SemiBold.woff2') format('woff2')
  }

  @font-face {
    font-family: 'Pretendard';
    font-weight: 500;
    font-display: swap;
    src: local('Pretendard Medium'), url('./font/Pretendard-Medium.woff2') format('woff2')
  }

  @font-face {
    font-family: 'Pretendard';
    font-weight: 400;
    font-display: swap;
    src: local('Pretendard Regular'), url('./font/Pretendard-Regular.woff2') format('woff2')
  }

  @font-face {
    font-family: 'Pretendard';
    font-weight: 300;
    font-display: swap;
    src: local('Pretendard Light'), url('./font/Pretendard-Light.woff2') format('woff2')
  }

  @font-face {
    font-family: 'Pretendard';
    font-weight: 200;
    font-display: swap;
    src: local('Pretendard ExtraLight'), url('./font/Pretendard-ExtraLight.woff2') format('woff2')
  }

  @font-face {
    font-family: 'Pretendard';
    font-weight: 100;
    font-display: swap;
    src: local('Pretendard Thin'), url('./font/Pretendard-Thin.woff2') format('woff2')
  }
  /* Font End */
`;

export default GlobalStyle;
