// Layer.js
import * as styles from './styles';
import React, { useState, useEffect, useRef } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { ImageNameState, ProductCanvasDetailState } from 'state/state';
import { rightSidebarState } from 'state/sidebarState';
import { objectList, selectedObject } from 'state/canvasState';
import LayerComponent from './layerComponent'; // 분리한 LayerComponent 컴포넌트

// eslint-disable-next-line
function Layer() {
  const { t } = useTranslation();
  const [editing, setEditing] = useState(false);
  const [imageName, setImageName] = useRecoilState(ImageNameState);
  const [objList, setObjectList] = useRecoilState(objectList);
  const [selected, setSelected] = useRecoilState(selectedObject);
  const [rightState, setRightState] = useRecoilState(rightSidebarState);
  const clickCountRef = useRef(0);

  const rightStateHandler = (id, type) => {
    setSelected(id);
    setRightState(type);
  };

  const componentContainerClickHandler = (e, obj) => {
    if (e.target.className !== 'canvasComponent') {
      rightStateHandler(obj.id, obj.type);
      clickCountRef.current = 0;
    } else {
      clickCountRef.current += 1;
      setTimeout(() => {
        if (clickCountRef.current === 1 && !editing) {
          rightStateHandler(obj.id, obj.type);
        }
        clickCountRef.current = 0;
      }, 180);
    }
  };

  const componentDoubleClickHandler = (e) => {
    e.currentTarget.contentEditable = 'true';
    e.currentTarget.focus();
    clickCountRef.current = 0;
    setEditing(true);
  };

  const componentBlurHandler = (e, obj, index) => {
    e.currentTarget.contentEditable = 'false';
    if (e.currentTarget.innerText !== obj.name) {
      setObjectList((prev) => {
        const updated = [...prev];
        updated[index] = { ...obj, name: e.currentTarget.innerText };
        return updated;
      });
    }

    setEditing(false);
  };

  const getImageSource = (obj) => {
    if (obj.type === 'text') return process.env.PUBLIC_URL + '/images/Canvas/textIcon.svg';
    else if (obj.type === 'image') return obj.src;
    else if (obj.type === 'pp') return obj.src;
    else return process.env.PUBLIC_URL + '/images/Canvas/shapeIcon.svg';
  };

  return (
    <>
      <styles.Wrapper id={'layer'}>
        <h2 style={{ userSelect: 'none' }}>{t('canvas.layer.Components')}</h2>
        {objList.map((obj, index) => {
          if (obj.isShadow || obj.type === 'crop') return null;
          const source = getImageSource(obj);
          return (
            <LayerComponent
              key={index}
              obj={obj}
              index={index}
              selected={selected}
              source={source}
              onComponentClick={componentContainerClickHandler}
              onComponentDoubleClick={componentDoubleClickHandler}
              onComponentBlur={componentBlurHandler}
            />
          );
        })}
      </styles.Wrapper>
    </>
  );
}

export default Layer;
