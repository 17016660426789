/**********************************
 * 파일명: [resize.js]
 * 작성자: [Jiwon Kang]
 * 설명: [ 캔버스 작업 페이지 리사이즈 컴포넌트 ]
 **********************************/
import React, { useEffect, useState } from 'react';
import * as style from './styles';
import * as leftSidebarStyle from '../styles';
import { useRecoilState } from 'recoil';
import { workspaceSizeState, isRatioLockState, resizeTriggerState } from 'state/canvasState';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';

// eslint-disable-next-line
function Resize(props) {
  const { t } = useTranslation();
  const [workspaceSize, setWorkspaceSize] = useRecoilState(workspaceSizeState);
  const [isRatioLock, setIsRatioLock] = useRecoilState(isRatioLockState);
  const [tempWorkspaceSize, setTempWorkspaceSize] = useState(workspaceSize);
  const [resizeTrigger, setResizeTrigger] = useRecoilState(resizeTriggerState);

  const handleTempSizeChange = (e) => {
    const { name, value } = e.target;

    if (isNaN(value) && value < 0) return;
    const numberValue = Number(value);

    setTempWorkspaceSize((prevSize) => ({
      ...prevSize,
      [name]: numberValue,
    }));
  };

  const applySizeChange = () => {
    if (tempWorkspaceSize.width > 5000 || tempWorkspaceSize.height > 5000) {
      fireSwal();
      return;
    }
    setWorkspaceSize(tempWorkspaceSize);
    setResizeTrigger((prev) => !prev);
  };

  const fireSwal = () => {
    Swal.fire({
      text: 'Canvas width or height cannot exceed 5000.',
      customClass: {
        popup: 'custom-popup-class', // 모달 다이얼로그 스타일
      },
    });
  };

  const clickLogo = async (e) => {
    const value = e.currentTarget.getAttribute('value');
    if (value) {
      const width = +value.split('x')[0];
      const height = +value.split('x')[1];
      setIsRatioLock(false);
      setWorkspaceSize({ width, height });
      setResizeTrigger((prev) => !prev);
    }
  };

  return (
    <leftSidebarStyle.MainContainer>
      <style.Title>{t('canvas.canvas.Resize')}</style.Title>
      <style.CustomSizeBlock>
        <style.SubTitle>{t('canvas.layer.CustomSize')}</style.SubTitle>
        <style.WidthHeightBox>
          <style.WidthHeightCol>
            <style.WidthHeightText>{t('canvas.option.Width')}</style.WidthHeightText>
            <style.WidthHeightInput
              id="canvasWidth"
              name="width"
              type="number"
              defaultValue={workspaceSize.width.toFixed(0)}
              onChange={(e) => {
                handleTempSizeChange(e);
              }}
            />
          </style.WidthHeightCol>
          <style.linkIcon
            onClick={() => {
              setIsRatioLock(!isRatioLock);
            }}
            src={process.env.PUBLIC_URL + `${isRatioLock ? '/images/Canvas/SideBar/linkIcon.svg' : '/images/Canvas/SideBar/unlinkIcon.svg'}`}
          />
          <style.WidthHeightCol>
            <style.WidthHeightText>{t('canvas.option.Height')}</style.WidthHeightText>
            <style.WidthHeightInput
              id="canvasHeight"
              name="height"
              type="number"
              defaultValue={workspaceSize.height.toFixed(0)}
              onChange={(e) => {
                handleTempSizeChange(e);
              }}
            />
          </style.WidthHeightCol>
        </style.WidthHeightBox>
      </style.CustomSizeBlock>
      <style.ResizeBtnBox>
        <style.ResizeBtn onClick={applySizeChange}>{t('canvas.canvas.Resize')}</style.ResizeBtn>
      </style.ResizeBtnBox>
      <style.DivLine />
      <style.AllSizeBlock>
        <style.SubTitle>{t('canvas.option.AllSize')}</style.SubTitle>
        <style.SizeList>
          <style.SizeRow onClick={clickLogo} value="2560x1440">
            <style.Icon src={process.env.PUBLIC_URL + '/images/Canvas/CanvasSize/facebook.svg'} />
            <style.TextBox>
              <style.ShopTitle>{t('canvas.logoName.FaceBook')}</style.ShopTitle>
              <style.SizeInfo>2560 x 1440</style.SizeInfo>
            </style.TextBox>
          </style.SizeRow>
          <style.SizeRow onClick={clickLogo} value="1920x1080">
            <style.Icon src={process.env.PUBLIC_URL + '/images/Canvas/CanvasSize/instagram.svg'} />
            <style.TextBox onClick={clickLogo}>
              <style.ShopTitle>{t('canvas.logoName.Instagram')}</style.ShopTitle>
              <style.SizeInfo>1920 x 1080</style.SizeInfo>
            </style.TextBox>
          </style.SizeRow>
          <style.SizeRow onClick={clickLogo} value="1280x1024">
            <style.Icon src={process.env.PUBLIC_URL + '/images/Canvas/CanvasSize/tiktok.svg'} />
            <style.TextBox onClick={clickLogo}>
              <style.ShopTitle>{t('canvas.logoName.TikTok')}</style.ShopTitle>
              <style.SizeInfo>1280 x 1024</style.SizeInfo>
            </style.TextBox>
          </style.SizeRow>
          <style.SizeRow onClick={clickLogo} value="1080x720">
            <style.Icon src={process.env.PUBLIC_URL + '/images/Canvas/CanvasSize/youtube.svg'} />
            <style.TextBox onClick={clickLogo}>
              <style.ShopTitle>{t('canvas.logoName.Youtube')}</style.ShopTitle>
              <style.SizeInfo>1080 x 720</style.SizeInfo>
            </style.TextBox>
          </style.SizeRow>
          <style.SizeRow onClick={clickLogo} value="720x480">
            <style.Icon src={process.env.PUBLIC_URL + '/images/Canvas/CanvasSize/naver.svg'} />
            <style.TextBox onClick={clickLogo}>
              <style.ShopTitle>{t('canvas.logoName.NaverShopping')}</style.ShopTitle>
              <style.SizeInfo>720 x 480</style.SizeInfo>
            </style.TextBox>
          </style.SizeRow>
          <style.SizeRow onClick={clickLogo} value="480x272">
            <style.Icon src={process.env.PUBLIC_URL + '/images/Canvas/CanvasSize/amazon.svg'} />
            <style.TextBox onClick={clickLogo}>
              <style.ShopTitle>{t('canvas.logoName.AmazonShopping')}</style.ShopTitle>
              <style.SizeInfo>480 x 272</style.SizeInfo>
            </style.TextBox>
          </style.SizeRow>
          <style.SizeRow onClick={clickLogo} value="800x600">
            <style.Icon src={process.env.PUBLIC_URL + '/images/Canvas/CanvasSize/coupang.svg'} />
            <style.TextBox onClick={clickLogo}>
              <style.ShopTitle>{t('canvas.logoName.CoupangShopping')}</style.ShopTitle>
              <style.SizeInfo>800 x 600</style.SizeInfo>
            </style.TextBox>
          </style.SizeRow>
        </style.SizeList>
      </style.AllSizeBlock>
    </leftSidebarStyle.MainContainer>
  );
}

export default Resize;
