import * as style from './styles';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { setCookie, deleteCookie } from 'cookies-next';
import { ChangePassword, LoginEmail, LoginInfo } from 'components/Join';
import authState from 'state/authState';
import tier from 'api/data/membershipBenefits';
import auth from 'api/accountAPI';
import Swal from 'sweetalert2';
import { UserLoginState } from 'state/state';
import FindPassword from 'components/Login/FindPassword';

function Login() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userState = useRecoilValue(UserLoginState);
  const [, setUser] = useRecoilState(authState);

  const saveUserInfo = async (access, refresh) => {
    setCookie('access_token', access);
    setCookie('refresh_token', refresh);
    await auth
      .getUser()
      .then((res) => {
        setUser({
          isLoggedIn: true,
          name: res.data.name,
          email: res.data.email,
          social_img: res.data.social_img,
          user_id: res.data.id,
          access_token: access,
          refresh_token: refresh,
          canvas_sum: res.data.canvas_sum,
          download_sum: res.data.download_sum,
          tier: tier[res.data.level],
        });
        Swal.fire({
          text: t('alert.login'),
          customClass: {
            popup: 'custom-popup-class', // 모달 다이얼로그 스타일
          },
        });
        navigate('/mypage');
      })
      .catch((e) => {
        deleteCookie('access_token');
        deleteCookie('refresh_token');
        console.log(e);
        Swal.fire({
          text: t('alert.loginFailed'),
          customClass: {
            popup: 'custom-popup-class', // 모달 다이얼로그 스타일
          },
        });
      });
  };

  return (
    <style.LoginContents>
      <style.Wrapper>
        <style.LoginDiv>
          {userState === 'loginEmail' ? <LoginEmail saveUserInfo={saveUserInfo} /> : userState === 'forgetPW' ? <FindPassword /> : <LoginInfo saveUserInfo={saveUserInfo} />}
        </style.LoginDiv>
        <style.ImageDiv>
          <style.RightImage src={process.env.PUBLIC_URL + '/images/Signup/JoMaloneBG.jpg'} />
        </style.ImageDiv>
      </style.Wrapper>
    </style.LoginContents>
  );
}

export default Login;
