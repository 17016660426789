import * as style from './styles';
import { useTranslation } from 'react-i18next';

/* eslint-disable max-lines-per-function, no-unused-vars */
function SubBanner() {
  const { t } = useTranslation();

  return (
    <style.MainContainer>
      <style.SubContent flexDirection={'column-reverse'}>
        <style.ImgWrap>
          <style.SubContentImg src={process.env.PUBLIC_URL + '/images/Home/SubBanner/bannerImg.jpg'} alt="" />
        </style.ImgWrap>
        <style.SubContentText>
          <style.subTitle>{t('home.subBanner.SubTitle1')}</style.subTitle>
          <style.Title>
            {t('home.subBanner.Title1_1')}
            <br /> {t('home.subBanner.Title1_2')}
          </style.Title>
          <style.Info>
            {t('home.subBanner.Info1_1')} <br />
            {t('home.subBanner.Info1_2')}
            <br /> {t('home.subBanner.Info1_3')}
          </style.Info>
        </style.SubContentText>
      </style.SubContent>
      <style.SubContent flexDirection={'column'}>
        <style.SubContentText>
          <style.subTitle>{t('home.subBanner.SubTitle2')}</style.subTitle>
          <style.Title>
            {t('home.subBanner.Title2_1')} <br />
            {t('home.subBanner.Title2_2')} <br />
            {t('home.subBanner.Title2_3')}
          </style.Title>
          <style.Info>
            {t('home.subBanner.Info3_1')}
            <br />
            {t('home.subBanner.Info3_2')}
            <br />
          </style.Info>
        </style.SubContentText>
        <style.ImgWrap>
          <style.SubContentImg src={process.env.PUBLIC_URL + '/images/Home/SubBanner/bannerImg2.jpg'} alt="" />
        </style.ImgWrap>
      </style.SubContent>
    </style.MainContainer>
  );
}

export default SubBanner;
