/**********************************
 * 파일명: [leftSideBar.js]
 * 작성자: [Donghwoo Cho]
 * 설명: [캔버스 작업 페이지 왼쪽 사이드바 컴포넌트]
 **********************************/

import LeftNavbar from './leftNavbar';
import LeftTabbar from './leftTabbar';

function LeftSideBar() {
  return (
    <>
      <LeftNavbar />
      <LeftTabbar />
    </>
  );
}

export default LeftSideBar;
