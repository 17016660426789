import styled from 'styled-components';
import React from 'react';

export const ChangePasswordContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: red;

  &:nth-last-child(2) {
    margin-bottom: 0;
  }
`;

export const LoginInfoContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
`;

export const LoginContents = styled.div`
  display: flex;
  justify-content: center;
  border-radius: 20px;
  align-items: center;
`;
export const Wrapper = styled.div`
  display: flex;
  max-width: 1200px;
  width: 100%;
  padding: 80px 0;

  @media screen and (max-width: 1440px) {
    max-width: 1040px;
  }
  @media screen and (max-width: 1280px) {
    max-width: 980px;
  }
`;

export const LoginDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 60px;
  width: 54%;
  background-color: #ffffff;
  border-radius: 20px 0 0 20px;
`;

export const LoginMessage = styled.span`
  font-size: 40px;
  margin-bottom: 26px;
  line-height: 1.2em;
  color: #191919;
  font-weight: bold;

  @media screen and (max-width: 1440px) {
    font-size: 32px;
  }
  @media screen and (max-width: 1280px) {
    font-size: 28px;
  }
  @media screen and (max-width: 768px) {
    font-size: 24px;
  }
`;

export const LoginText = styled.div`
  color: #8d95a0;
  font-size: 22px;
  margin-bottom: 42px;
  line-height: 1.45em;

  @media screen and (max-width: 1440px) {
    font-size: 20px;
  }
  @media screen and (max-width: 1280px) {
    font-size: 18px;
  }
  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
`;

export const LoginText2 = styled.span`
  color: ${(props) => props.color};
  text-decoration: ${(props) => props.decoration};
  cursor: ${(props) => props.cursor};
  margin-bottom: 42px;
  line-height: 1.44em;
  font-size: 18px;
  @media screen and (max-width: 1440px) {
    font-size: 16px;
  }
  @media screen and (max-width: 1280px) {
    font-size: 14px;
  }
  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
  white-space: pre-line;
`;

export const ImageDiv = styled.div`
  width: 46%;
`;

export const RightImage = styled.img`
  max-width: 550px;
  width: 100%;
  max-height: 860px;
  object-fit: cover;
  border-radius: 0 20px 20px 0;
`;

export const SocialLoginWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

export const GoogleLogin = styled.button`
  all: unset;
  background-color: ${(props) => props.BGcolor};
  cursor: pointer;
  border-radius: 5px;
  height: 76px;
  border: solid 1.4px #ededed;
  display: flex;
  align-items: center;
  font-size: 22px;
  line-height: 1.2em;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  gap: 90px;
  padding: 0 22px;

  @media screen and (max-width: 1440px) {
    height: 62px;
    gap: 70px;
    font-size: 20px;
  }
  @media screen and (max-width: 1280px) {
    height: 54px;
    gap: 50px;
    font-size: 18px;
  }
  @media screen and (max-width: 768px) {
    gap: 20%;
    font-size: 16px;
  }
`;

export const SocialLoginIcon = styled.img`
  @media screen and (max-width: 768px) {
    width: 40px;
  }
  @media screen and (max-width: 435px) {
    width: 30px;
  }
`;

export const DivideLine = styled.span`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.4px;
  color: #999;
  margin: 26px 0;
  text-align: center;
  display: flex;
  align-items: center;
`;

export const GrayButton = styled.span`
  border-radius: 12px;
  width: 100%;
  height: 76px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  font-weight: 500;
  cursor: pointer;
  margin-bottom: 24px;
  min-height: 54px;
  background-color: #f2f4f4;
  color: #8d95a0;
  margin-top: ${(props) => props.marginTop};

  @media screen and (max-width: 1440px) {
    height: 62px;
    font-size: 20px;
  }
  @media screen and (max-width: 1280px) {
    height: 54px;
    font-size: 18px;
  }
  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
`;

export const PointColor = styled.span`
  color: #287ced;
  text-decoration: underline;
  cursor: pointer;
`;

export const JoinUsText = styled.span`
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5em;
  letter-spacing: -0.5px;
  color: #8d95a0;
  display: flex;
  justify-content: center;
  gap: 14px;
  @media screen and (max-width: 1440px) {
    font-size: 18px;
  }
  @media screen and (max-width: 1280px) {
    font-size: 16px;
  }
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

export const JoinUsBtn = styled.button`
  all: unset;
  font-weight: 500;
  color: #287ced;
  cursor: pointer;
  text-align: center;
`;

export const inputBox = styled.input`
  width: 100%;
  height: 76px;
  border-radius: 12px;
  border: solid 1.4px #ededed;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.55px;
  padding-left: 24px;
  color: #999;

  :hover,
  :focus-visible {
    border: solid 1.4px #8a24e2;
    color: #222;
  }
  @media screen and (max-width: 1440px) {
    height: 68px;
    font-size: 20px;
  }
  @media screen and (max-width: 1280px) {
    height: 54px;
    padding-left: 20px;
    font-size: 18px;
  }
  @media screen and (max-width: 768px) {
    height: 48px;
    padding-left: 16px;
    font-size: 16px;
  }
`;

export const LoginBtn = styled.button`
  all: unset;
  border-radius: 12px;
  width: 100%;
  height: 76px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  font-weight: 500;
  cursor: pointer;
  margin-bottom: 24px;
  background-color: #faa1a1;
  color: #ffffff;
  margin-top: 42px;

  @media screen and (max-width: 1440px) {
    height: 62px;
    font-size: 20px;
  }
  @media screen and (max-width: 1280px) {
    height: 54px;
    font-size: 18px;
  }
`;
export const inputBoxInfo = styled.span`
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.5px;
  color: #767676;
  margin-bottom: 8px;
  margin-top: ${(props) => props.marginTop};
  @media screen and (max-width: 1440px) {
    font-size: 18px;
  }
  @media screen and (max-width: 1280px) {
    font-size: 16px;
  }
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

export const BackBtn = styled.img`
  width: 88px;
  background-color: #f2f4f4;
  border-radius: 10px;
  padding: 24px 30px;
  position: absolute;
  bottom: 0;
  cursor: pointer;

  @media screen and (max-width: 1040px) {
    margin-top: 50px;
    position: unset;
    bottom: unset;
    width: 70px;
    padding: 18px 24px;
  }
`;

export const TermsOfService = styled.span`
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.6px;
  cursor: pointer;
  color: ${(props) => props.color};
  text-decoration: ${(props) => props.decoration};
`;

export const WarningMessage = styled.span`
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  margin-top: 8px;
  letter-spacing: -0.5px;
  color: ${(props) => props.color};
`;

export const ServiceComment = styled.div`
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.45px;
  color: #999;
  margin-top: 6px;
`;

export const DivideSection = styled.div`
  width: 100%;
  height: 2px;
  border-radius: 13px;
  background-color: #ededed;
  margin: 26px 0;
`;

const Button = styled.button`
  padding: 0;
  background-color: transparent !important;
  background-image: none !important;
  border-color: transparent;
  border: none;
  color: #ffffff;
`;
const Icon = styled.svg`
  fill: none;
  stroke: #ffffff;
  stroke-width: 2px;
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 26px;
  height: 26px;
  border: 2px solid;
  border-color: ${(props) => (props.checked ? '#FFFFFF' : '#dbdbdb')};
  background-color: ${(props) => (props.checked ? '#f54545' : '#FFFFFF')};
  border-radius: 5px;
  transition: all 150ms;
  cursor: pointer;
  ${Icon} {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }
`;
const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

export const Checkbox = ({ checked, onClick, value, ...props }) => (
  <Button type="button" onClick={onClick} value={value}>
    <CheckboxContainer>
      <StyledCheckbox checked={checked}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
    </CheckboxContainer>
  </Button>
);
