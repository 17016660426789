import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1140px;
  width: 100%;
  gap: 80px;
  margin-bottom: 80px;
  @media screen and (max-width: 1024px) {
    margin-bottom: 60px;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 40px;
  }
`;

export const Title = styled.span`
  font-weight: 700;
  line-height: 1.4em;
  font-size: 40px;
  color: #222222;
  text-align: center;
  @media screen and (max-width: 1440px) {
    font-size: 36px;
  }
  @media screen and (max-width: 1024px) {
    font-size: 32px;
  }
  @media screen and (max-width: 768px) {
    font-size: 28px;
  }
  @media screen and (max-width: 500px) {
    font-size: 22px;
  }
`;

export const logos = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px 80px;
  flex-wrap: wrap;

  > img {
    max-width: 200px;
    max-height: 100px;
    object-fit: contain;
  }
  @media screen and (max-width: 1024px) {
    justify-content: space-evenly;
  }
  @media screen and (max-width: 768px) {
    justify-content: center;
    gap: 30px;
    > img {
      max-width: 100px;
      max-height: 50px;
    }
  }
`;
