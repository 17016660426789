import axios from 'axios';
import { getCookie, setCookie } from 'cookies-next';
import accountAPI from './accountAPI';

export const getBaseURL = () => {
  if (process.env.NODE_ENV === 'development') {
    // Develop
    console.warn = function no_console() {};

    return process.env.REACT_APP_LOCAL_DOMAIN;
  } else if (process.env.NODE_ENV === 'production') {
    // Production
    console.log = function no_console() {};
    console.warn = function no_console() {};

    return process.env.REACT_APP_DOMAIN;
  }
};

export const isProduction = () => {
  if (process.env.NODE_ENV === 'development') {
    return '';
  } else if (process.env.NODE_ENV === 'production') {
    return 'format=json';
  }
};

axios.defaults.baseURL = getBaseURL();
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';

axios.interceptors.response.use(
  function (config) {
    return config;
  },
  function (error) {
    console.log('error response', error);
    if (error.response?.status === 401) {
      const access = getCookie('access_token');
      const refresh = getCookie('refresh_token');
      console.log(access, refresh);
      if (access) {
        if (refresh) {
          accountAPI
            .tokenpost({
              access: access,
              refresh: refresh,
            })
            .then((res) => {
              // console.log('좋아연', res.data);
              setCookie('access_token', res.data.access, { expires: res.data.access_token_expiration });
            })
            .catch((err) => {
              // refresh token으로 access token 요청하기.
            });
        } else {
          // alert('토큰이 만료되었습니다');
          window.location.href = '/';
        }
      }
    }
    return Promise.reject(error);
  },
);

export default axios;
