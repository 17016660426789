import * as style from './styles';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { openNewWinodwTab } from 'utils/windowUtil';

/* eslint-disable max-lines-per-function, no-unused-vars */
function Footer() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div style={{ width: '100%' }}>
      <style.footer>
        <style.footerTop>
          <style.FavorfitCommunity>
            <p>{t('Footer.footer.FavorfitCommunity_p')}</p>
            <span>{t('Footer.footer.FavorfitCommunity_span')}</span>
            <button onClick={() => navigate('/login')}>{t('Footer.footer.FavorfitCommunity_button')}</button>
          </style.FavorfitCommunity>
          <style.FavorfitInfo>
            <div>
              <p>{t('Footer.footer.Function')}</p>
              <span>{t('Footer.footer.AIMaching')}</span>
              <span>{t('Footer.footer.Template')}</span>
              <span>{t('Footer.footer.Artist')}</span>
            </div>
            <div>
              <p>{t('Footer.footer.Data')}</p>
              <span>{t('Footer.footer.Blog')}</span>
              <span>{t('Footer.footer.UserGuide')}</span>
              <span>{t('Footer.footer.Document')}</span>
            </div>
            <div>
              <p>{t('Footer.footer.AboutUs')}</p>
              <span>{t('Footer.footer.Value')}</span>
              <span>{t('Footer.footer.inquiry')}</span>
            </div>
          </style.FavorfitInfo>
        </style.footerTop>
      </style.footer>
      <div style={{ width: '100%', border: '0.5px solid #F9F8F9' }}></div>
      <style.footerBottom>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <style.Contact>
            <img src={process.env.PUBLIC_URL + '/images/Footer/gmailIcon.svg'} alt={'gmail'} />
            <img src={process.env.PUBLIC_URL + '/images/Footer/instagramIcon.webp'} alt={'instagram'} />
            <img src={process.env.PUBLIC_URL + '/images/Footer/youtubeIcon.svg'} alt={'youtube'} />
          </style.Contact>
          <style.CompanyInfo>
            <p>{t('Footer.footer.CompanyName')}</p>
            <span>{t('Footer.footer.Favorfit')}</span>
          </style.CompanyInfo>
          <div style={{ gap: '20px' }}>
            <style.CompanyInfo>
              <p>{t('Footer.footer.RepresentativeName')}</p>
              <span>{t('Footer.footer.SankyOuh')}</span>
            </style.CompanyInfo>
            <style.CompanyInfo>
              <p>{t('Footer.footer.RegistrationNumber1_1')}</p>
              <span>{t('Footer.footer.RegistrationNumber1_2')}</span>
            </style.CompanyInfo>
          </div>
          <style.CompanyInfo>
            <p>{t('Footer.footer.Address1_1')}</p>
            <span>{t('Footer.footer.Address1_2')}</span>
          </style.CompanyInfo>
        </div>
        <style.StoreBtn>
          <img src={process.env.PUBLIC_URL + '/images/Footer/playStore.svg'} alt={'playStore'} onClick={() => openNewWinodwTab('https://play.google.com/store/apps/details?id=com.favorfitapp')} />
          <img src={process.env.PUBLIC_URL + '/images/Footer/appStore.svg'} alt={'appStore'} onClick={() => openNewWinodwTab('https://apps.apple.com/kr/app/favorfit-product/id6448986531')} />
        </style.StoreBtn>
      </style.footerBottom>
    </div>
  );
}

export default Footer;
