/**********************************
 * 파일명: [leftNavBar.js]
 * 작성자: [Donghwoo Cho]
 * 설명: [캔버스 작업 페이지 왼쪽 사이드바의 네비게이션 컴포넌트]
 **********************************/

import * as style from './styles';
import { useTranslation } from 'react-i18next';
import LeftSideBarIcon from './leftNavbarBtn';
import icons from 'constants/icons';

function LeftNavbar() {
  const { t } = useTranslation();

  return (
    <style.Navbar>
      <LeftSideBarIcon src={icons.template} title={t('canvas.canvas.Templates')} variant={'template'} />
      <LeftSideBarIcon src={icons.text} title={t('canvas.canvas.Text')} variant={'text'} />
      <LeftSideBarIcon src={icons.image} title={t('canvas.canvas.ImageUpload')} variant={'images'} />
      <LeftSideBarIcon src={icons.shape} title={t('canvas.canvas.Shapes')} variant={'shapes'} />
      <LeftSideBarIcon src={icons.resize} title={t('canvas.canvas.Resize')} variant={'resize'} />
    </style.Navbar>
  );
}

export default LeftNavbar;
