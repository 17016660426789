import * as styles from './canvasStyles';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { canvasToJSON } from 'utils/canvasControlUtil';
import { authState } from 'state';
import { ImageNameState, TempCanvasContentState } from 'state/state';
import { propertiesWhenSerializing, settingInitialCanvas, settingTransparentBg, settingViewport } from 'utils/canvasSettingUtil';
import Swal from 'sweetalert2';
import ReactGA from 'react-ga4';
import canvasAPI from 'api/canvasAPI';
import accountAPI from 'api/accountAPI';

// eslint-disable-next-line
function CanvasHeader() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [imageName, setImageName] = useRecoilState(ImageNameState);
  const [userInfo, setUserInfo] = useRecoilState(authState);
  const setTempCanvasContent = useSetRecoilState(TempCanvasContentState);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setImageName(value);
  };

  const logoClick = (callback) => {
    Swal.fire({
      text: t('alert.leaveCanvas'),
      showCancelButton: true,
      confirmButtonText: 'yes',
      cancelButtonText: 'no',
    }).then((res) => {
      if (res.isConfirmed) callback();
    });
  };

  /**
   * 로그인이 되어 있지 않을 때 로그인 유도 및 작업중인 canvas 임시 저장을 위한 함수
   * @param {string} text
   * @returns void
   */
  const saveCanvasTempForGuest = (text) => {
    Swal.fire({
      text,
      showCancelButton: true,
      confirmButtonText: 'yes',
      cancelButtonText: 'no',
    }).then((res) => {
      if (res.isConfirmed) {
        const canvas = document.getElementById('canvas')?.fabric;
        const json = canvas.toDatalessJSON(propertiesWhenSerializing);
        const tempCanvas = JSON.stringify(json);
        setTempCanvasContent(tempCanvas);
        navigate('/login');
      }
    });
  };

  /**
   * 특정 기능을 사용 전 유효성 확인 후 기능 실행하는 함수
   * @param {string} type
   * @param {function} callback 해당 기능 사용 가능 여부 확인 후 실행될 콜백 함수
   * @returns void
   */
  // eslint-disable-next-line
  const checkUserAvalibility = (type, callback) => {
    if (type === 'save') {
      userInfo.isLoggedIn ? callback() : saveCanvasTempForGuest('Canvas saving is available after login.\nWould you like to login?\nThe canvas will be temporarily stored.');
    } else if (type === 'download') {
      if (!userInfo.isLoggedIn) saveCanvasTempForGuest('After logging in, you can store the downloaded images in your FavorFit cloud');
      else if (userInfo.download_sum < userInfo.tier.download_sum) callback();
      else if (userInfo.download_sum >= userInfo.tier.download_sum) Swal.fire({ text: 'you can no longer upload download image to cloud \nPlease delete your download image or upgrade your plan' });
    }
  };

  /**
   * 캔버스 저장 함수
   * @returns void
   */
  const saveCanvas = async () => {
    const { fabric: canvas } = document.getElementById('canvas');
    ReactGA.event({
      category: 'User',
      action: 'save',
      label: 'save canvas from canvas',
    });

    const preSettingValue = settingInitialCanvas(canvas);
    const canvasData = canvasToJSON(canvas);

    const loadCanvasData = location.state?.canvas;
    const id = loadCanvasData?.id || canvas.id;
    const { id: canvasId } = (await canvasAPI.postCanvas({ ...canvasData, ...(id && { id }), userId: userInfo.user_id }).catch((err) => console.log(err)))?.data;
    const { canvas_sum } = (await accountAPI.getUser().catch((err) => console.log(err)))?.data;
    setUserInfo({ ...userInfo, canvas_sum });

    canvasId && canvas.set({ id: canvasId });
    canvas.setZoom(preSettingValue.preZoom);
    settingTransparentBg(canvas);
    settingViewport(canvas, preSettingValue.preVpt);

    Swal.fire({ text: t('canvas.canvasHeader.SaveComplete') });
  };

  /**
   * 캔버스 다운로드 함수
   * @returns void
   */
  const downloadCanvas = async () => {
    const { fabric: canvas } = document.getElementById('canvas');
    ReactGA.event({
      category: 'User',
      action: 'download',
      label: 'download image from canvas',
    });

    const preSettingValue = settingInitialCanvas(canvas);
    const canvasData = canvasToJSON(canvas, 4);

    const link = document.createElement('a');
    link.href = canvasData.origin_img;
    link.download = imageName || 'my-canvas.png';
    link.click();

    await canvasAPI.saveDownloadImage({ upload_img: link.href, upload_img_name: link.download }).catch((err) => console.log(err));
    const { download_sum } = (await accountAPI.getUser().catch((err) => console.log(err)))?.data;
    setUserInfo({ ...userInfo, download_sum });

    canvas.setZoom(preSettingValue.preZoom);
    settingTransparentBg(canvas);
    settingViewport(canvas, preSettingValue.preVpt);
  };

  return (
    <styles.HeaderWrap>
      <styles.Left>
        <img src={process.env.PUBLIC_URL + '/images/Header/favorfitLogoP.svg'} onClick={() => logoClick(() => navigate('/'))} alt="" />
        <button onClick={() => logoClick(window.history.length ? () => navigate(-1) : () => navigate('/'))}>{t('canvas.canvasHeader.Back')}</button>
      </styles.Left>
      <styles.Write>
        <input type={'text'} defaultValue={imageName} placeholder={t('canvas.canvasHeader.CanvasNameInput')} onChange={handleInputChange} />
        <styles.Button onClick={() => checkUserAvalibility('save', saveCanvas)} backgroundColor="#FFFFFF" color="#e61503">
          <span>{t('canvas.canvasHeader.SaveImage')}</span>
        </styles.Button>
        <styles.Button onClick={() => checkUserAvalibility('download', downloadCanvas)}>
          <img src={process.env.PUBLIC_URL + '/images/Header/downloadBtn.svg'} alt="" />
          <span>{t('canvas.canvasHeader.DownloadImage')}</span>
        </styles.Button>
      </styles.Write>
    </styles.HeaderWrap>
  );
}

export default CanvasHeader;
