import styled from 'styled-components';

export const MainContainer = styled.div`
  max-width: 350px;
  width: 350px;
  height: 100%;
  padding: 18px 10px 18px 18px;
  overflow-y: scroll;
  position: relative;
  background-color: #ffffff;
  position: absolute;

  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #fd5c5c;
    opacity: 0.75;
    border-radius: 10px;
  }

  > p {
    margin-bottom: 18px;
  }
  @media screen and (max-width: 1440px) {
    max-width: 270px;
  }
  @media screen and (max-width: 1024px) {
    max-width: 250px;
  }
`;

export const searchWrap = styled.div`
  display: flex;
  margin-bottom: 20px;

  > input {
    all: unset;
    height: 44px;
    width: 100%;
    border-radius: 4px;
    border: solid 1px #dbdbdb;
    background-color: #fff;
    padding-left: 5px;
  }
`;

export const textImage = styled.img`
  /* margin-right: 5rem; */
  width: 5rem;
  height: 7rem;
  cursor: pointer;
  /* display: flex; */
  /* padding: 0 3rem; */
  :hover {
    stroke: black;
    box-shadow: 10px;
    width: 8rem;
    height: 10rem;
  }
`;

export const fontBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  > div {
    display: flex;
    height: 44px;
    padding: 0 12px;
    border-radius: 4px;
    background-color: #f8f8f8;
  }
`;
