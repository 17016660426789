import * as styles from './styles';
import React, { useState, useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { rightSidebarState } from 'state/sidebarState';

import {
  objectList,
  selectedObject,
  shadowHandleState,
  correctionState,
  imageCropState,
  isManualShadowState,
  workspaceSizeState
} from 'state/canvasState';
import ObjectControlButtonList from './common/objectControlButtonList';
import { genPpShadowState, postRequestShadowState } from 'state/triggerState';
import { bgImageState } from 'state/imageState';
import { showUploadState } from 'state/sidebarState';

import RecommendTemplateColor from './recommendTemplateColor';
import RecommendTemplateBase from './recommendTemplateBase';
import studioAPI from '../../../api/studioAPI';
import CancelButton from './common/cancelButton';
import ShadowPannel from './shadowPannel';
import ColorPannel from './colorPannel';
import {addImage, addObjectSelectEvent, generateObjectId, getTemplate} from "../../../utils/canvasObjectGenerateUtil";

const NextArrow = ({ className, style, onClick }) => <styles.Arrow style={{ ...style, width: 16, left: '92%', height: '100%' }} onClick={onClick} className={className}></styles.Arrow>;
const PrevArrow = ({ className, style, onClick }) => <styles.Arrow style={{ ...style, width: 16, height: '100%' }} onClick={onClick} className={className}></styles.Arrow>;

// eslint-disable-next-line
function ImageEditor(props) {
  const { t } = useTranslation();

  const setRightState = useSetRecoilState(rightSidebarState);

  //선택된 객체의 id값
  const [selectedIndex, setSelectedIndex] = useRecoilState(selectedObject);

  // right sidebar를 업데이트 하기 위함
  const canvasObjects = useRecoilValue(objectList);

  const setCrop = useSetRecoilState(imageCropState);

  // pp의 shadow를 gen 하기 위해 사용하는 trigger state
  const setGenPpShadow = useSetRecoilState(genPpShadowState);

  const isManualShadow = useRecoilValue(isManualShadowState);

  const [showUpload, setShowUpload] = useRecoilState(showUploadState);
  const [canvasObjectList, setCanvasObjectList] = useRecoilState(objectList);
  const setWorkspaceSize = useSetRecoilState(workspaceSizeState);

  const changeImg = () => {
    // console.log('objects', selectedIndex, objects);
    setShowUpload('update');
  };

  const brushImage = () => {
    setRightState('brush');
    const canvas = document.getElementById('canvas').fabric;
    const object = canvas.getActiveObject();
    setCrop({ x: object.cropX, y: object.cropY, width: object.width, height: object.height, scaleX: object.scaleX, scaleY: object.scaleY });
  };

  useEffect(() => {
    if (selectedIndex?.split('-')[0] !== 'bg') setGenPpShadow((prevCount) => prevCount + 1);
  }, [isManualShadow, selectedIndex, setGenPpShadow]);

  // 비슷한 템플릿 추천
  const selectedBg = useRecoilValue(bgImageState);

  const [recommendListColor, setRecommendListColor] = useState([]);
  const [recommendListBase, setRecommendListBase] = useState([]);
  const [, setBgImage] = useRecoilState(bgImageState);
  const settings = {
    speed: 500,
    slidesToShow: 1.8,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  useEffect(() => {
    getRecommendListBase();
    getRecommendListColor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const changeBg = async (imgData) => {
    const id = generateObjectId('bg');
    const { fabric: canvas } = document.getElementById('canvas');
    const template = await addImage(canvas, await getTemplate(imgData.id), { id, templateId: imgData.id, name: id, isBackground: true }, () => {
      canvas.forEachObject((obj) => {
        if (obj.id !== id && obj.isBackground) {
          canvas.remove(obj);
          canvas.requestRenderAll();
          console.log(canvasObjectList);
          setCanvasObjectList(
              [...canvasObjectList].filter((object) => {
                return object.id !== obj.id;
              }),
          );
        }
      });
    });
    const canvasObjectInfo = {
      id,
      name: template.name,
      type: template.type,
      src: template.src,
    };
    setCanvasObjectList((prev) => [...prev, canvasObjectInfo]);
    addObjectSelectEvent(template, setSelectedIndex, setRightState);
    setWorkspaceSize({
      width: template.width * template.scaleX,
      height: template.height * template.scaleY,
    });
    canvas.sendToBack(template);
  };
  const bgClick = (imgData) => {
    props.getTemplate(imgData.id);
    changeBg(imgData);
    console.log('imgData.id : ', imgData.id)
    setBgImage((prev) => ({ ...prev, type: 'template', templateId: imgData.id, name: imgData.name }));
  };

  const getRecommendListColor = async () => {
    // 단색 배경 또는 배경 없을 시 예외 처리를 위함
    if (!selectedBg.templateId) return;
    const res = await studioAPI.getRecommendColor(selectedBg.templateId);
    setRecommendListColor(res.data.templates);
  };

  const getRecommendListBase = async () => {
    // 단색 배경 또는 배경 없을 시 예외 처리를 위함
    if (!selectedBg.templateId) return;
    const res = await studioAPI.getRecommendBase(selectedBg.templateId);
    setRecommendListBase(res.data.templates);
  };

  const [recommendState, setRecommendState] = useState(0);
  const handleRecommendStateChange = (newState) => {
    setRecommendState(newState);
  };
  return (
      <>
        <styles.Wrapper id={'layer'}>
          <div style={{ textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '5px 10px 15px 10px' }}>
            <h2 style={{ userSelect: 'none' }}>{canvasObjects?.find((obj) => obj.id === selectedIndex)?.name}</h2>
            <CancelButton />
          </div>
          <styles.ModifyImg>
            <div onClick={changeImg}>
              <img src={process.env.PUBLIC_URL + '/images/Canvas/SideBar/imgChangeIcon.svg'} alt="imgChangeIcon" />
              <span>{t('canvas.layer.ReplaceImage')}</span>
            </div>
            <div onClick={brushImage}>
              <img src={process.env.PUBLIC_URL + '/images/Canvas/SideBar/imgCropIcon.svg'} alt="imgCropIcon" />
              <span>{t('canvas.layer.EditImage')}</span>
            </div>
          </styles.ModifyImg>
          <styles.SettingImg>
            {/* 그림자 */}
            <ShadowPannel />
            {/* 색보정 */}
            <ColorPannel />
          </styles.SettingImg>
          {/* 앞으로 가기, 뒤로 가기, 뒤집기, 복사, 삭제 */}
          <ObjectControlButtonList type={'image'} />
          {/* 비슷한 템플릿 추천 */}
          {/* selectedBg.templateId가 없을 경우는 나타나지 않음 */}
          {selectedBg.templateId ? (
              <styles.RecommendationContainer>
                {recommendState === 0 || recommendState === 1 ? (
                    <RecommendTemplateColor
                        settings={settings}
                        recommendListColor={recommendListColor}
                        bgClick={bgClick}
                        onRecommendStateChange={handleRecommendStateChange}
                        setRecommendListColor={setRecommendListColor}
                    />
                ) : null}
                {recommendState === 0 || recommendState === 2 ? (
                    <RecommendTemplateBase
                        settings={settings}
                        recommendListBase={recommendListBase}
                        bgClick={bgClick}
                        onRecommendStateChange={handleRecommendStateChange}
                        setRecommendListBase={setRecommendListBase}
                    />
                ) : null}
              </styles.RecommendationContainer>
          ) : null}
        </styles.Wrapper>
      </>
  );
}

export default ImageEditor;
