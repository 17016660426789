import styled from 'styled-components';

export const footer = styled.div`
  background: #f9f8f9;
`;
export const footerTop = styled.div`
  max-width: 1140px;
  padding: 60px 0;
  width: 100%;
  display: flex;
  margin: auto;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 40px;
    padding: 40px 20px;
  }
`;
export const FavorfitCommunity = styled.div`
  display: flex;
  flex-direction: column;
  > p {
    font-size: 22px;
    font-weight: 500;
    line-height: 1.2em;
    letter-spacing: -0.65px;
    color: #222;
    margin-bottom: 8px;
  }
  > span {
    font-size: 18px;
    letter-spacing: -0.55px;
    text-align: left;
    color: #767676;
    margin-bottom: 30px;
  }

  > input {
    border-radius: 4px;
    border: solid 1.4px #ededed;
    background-color: #fff;
    font-size: 16px;
    letter-spacing: -0.45px;
    color: #aaa;
    height: 55px;
    padding-left: 16px;
    margin-bottom: 16px;
    @media screen and (max-width: 768px) {
      max-width: 500px;
    }
  }
  > button {
    all: unset;
    width: fit-content;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.45px;
    color: #fff;
    border-radius: 4px;
    background-color: #e61503;
    padding: 14px 24px;
    cursor: pointer;
  }
  @media screen and (max-width: 1024px) {
    > p {
      font-size: 20px;
    }
    > span {
      font-size: 16px;
    }
    > input {
      font-size: 14px;
      height: 50px;
    }
    > button {
      font-size: 14px;
      padding: 12px 20px;
    }
  }
  @media screen and (max-width: 768px) {
    > p {
      font-size: 18px;
    }
    > span {
      font-size: 14px;
    }
    > input {
      font-size: 12px;
      height: 40px;
    }
    > button {
      font-size: 12px;
      padding: 10px 16px;
    }
  }
`;
export const FavorfitInfo = styled.div`
  display: flex;
  gap: 60px;
  > div {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  > div > p {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.55px;
    color: #767676;
    margin-bottom: 8px;
  }
  > div > span {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.65px;
    color: #222;
  }
  @media screen and (max-width: 1024px) {
    > div > p {
      font-size: 14px;
    }
    > div > span {
      font-size: 16px;
    }
  }
  @media screen and (max-width: 768px) {
    > div > p {
      font-size: 12px;
    }
    > div > span {
      font-size: 14px;
    }
  }
  @media screen and (max-width: 500px) {
    justify-content: space-between;
  }
`;

export const footerBottom = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1140px;
  padding: 60px 0 60px;
  width: 100%;
  margin: auto;
  > div {
    &:first-child {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 12px;
    }
    > span {
      height: 18px;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: -0.55px;
      color: #222;
    }
    > div {
      display: flex;
    }
  }
  @media screen and (max-width: 1024px) {
    > span {
      font-size: 16px;
    }
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
    padding: 40px 20px;
    > span {
      font-size: 12px;
    }
  }
`;

export const CompanyInfo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 18px;
  gap: 8px;
  > p {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.55px;
    color: #222;
  }
  > span {
    font-size: 18px;
    letter-spacing: -0.55px;
    color: #767676;
  }
  @media screen and (max-width: 1024px) {
    > p {
      font-size: 16px;
    }
    > span {
      font-size: 16px;
    }
  }
  @media screen and (max-width: 768px) {
    > p {
      font-size: 12px;
    }
    > span {
      font-size: 12px;
    }
  }
`;

export const Contact = styled.div`
  display: flex;
  gap: 16px;
  align-items: flex-end;
  > img {
    object-fit: contain;
    width: 40px;
    cursor: pointer;
  }
  @media screen and (max-width: 1024px) {
    > img {
      width: 36px;
    }
  }
  @media screen and (max-width: 768px) {
    > img {
      width: 28px;
    }
  }
`;

export const StoreBtn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  > img {
    user-select: none;
    object-fit: contain;
    width: 100%;
    height: 64px;
    cursor: pointer;
  }
  @media screen and (max-width: 1024px) {
    > img {
      height: 36px;
    }
  }
  @media screen and (max-width: 768px) {
    > img {
      width: 100px;
      height: 32px;
    }
  }
`;
