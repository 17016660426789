/**********************************
 * 파일명: [leftTabbar.js]
 * 작성자: [Donghwoo Cho]
 * 설명: [캔버스 작업 페이지 왼쪽 탭 컴포넌트]
 **********************************/

import * as style from './styles';
import { useRecoilState } from 'recoil';
import { leftSidebarState } from 'state/sidebarState';
import Resize from '../Resize/resize';
import SelectTemplate from '../template/selectTemplate';
import ProductPhotography from '../productPhotography/productPhotography';
import Shapes from '../shapes/shapes';
import Font from '../Font/font';

function LeftTabbar() {
  const [leftSideBar, setLeftSideBar] = useRecoilState(leftSidebarState);

  const handleClose = () => {
    setLeftSideBar(null);
  };

  return (
    <style.LeftSidebarContainer>
      {leftSideBar === 'template' && <SelectTemplate />}
      {leftSideBar === 'images' && <ProductPhotography />}
      {leftSideBar === 'text' && <Font />}
      {leftSideBar === 'shapes' && <Shapes />}
      {leftSideBar === 'resize' && <Resize />}
      {leftSideBar && <style.TabCloseBtn onClick={() => handleClose()} />}
    </style.LeftSidebarContainer>
  );
}

export default LeftTabbar;
